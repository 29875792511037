import { Col, Row, Space } from 'antd';
import { useFormik } from 'formik';
import __, { find, get, isArray, isEmpty, isNil, map, negate, size } from 'lodash';
import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';

import { AiAlert, AiButton, AiSpin } from '../../../assets/common';
import { tc_meeting_breadcrumb } from '../../../assets/img';
import {
  AlertMessage,
  AsyncMultipleSelect,
  BreadCrumb,
  FieldError,
  ModelTC,
} from '../../../components';
import SearchByCategoryForTcMeeting from '../../../components/SearchByCategoryForTcMeeting/SearchByCategoryForTcMeeting';
import SearchBySecretariatForTcMeeting from '../../../components/SearchBySecretariatForTcMeeting/SearchBySecretariatForTcMeeting';
import { useCreateMeeting } from '../../../hooks/useCreateMeeting';
import { isCheckError } from '../../../hooks/useFormik';
import { useGetMeeting } from '../../../hooks/useGetMeeting';
// import { useSecretarialOptions } from '../../../hooks/useSecretarialOptions';
import { useUpdateMeeting } from '../../../hooks/useUpdateMeeting';
import { meetingApi } from '../../../services/tcMeeting';
import {
  DATE_TIME_FORMAT,
  MEETING_CATEGORY_OPTIONS,
  MEETING_CATEGORY_VALUES,
  NOTIFICATION_TYPE,
  ROLE,
} from '../../../utils/constants';
import {
  getBusinessIdToPopulateForAdditionalView,
  getAsyncOptionAdvocatorsHrPartners,
  getFieldValueForTCMeetingInFirstTime,
  getLabelNameForSecretariatOfTcMeeting,
  isDifferentFieldPdcMeeting,
} from '../../../utils/helper';
import { showNotification } from '../../../utils/notification';
import MeetingStatus from '../components/MeetingStatus/MeetingStatus';
import StepBar from '../components/StepBar/StepBar';
import { clearAgendaState } from '../redux/agendaSlice';
import { addOrUpdateMeeting, clearMeeting, initialStateMeeting } from '../redux/meetingDetailSlice';
import { addOrUpdateStepper } from '../redux/stepperSlice';
import MeetingCategory from './components/MeetingCategory';
import styles from './meeting-details.module.scss';
import { validationSchema } from './meeting-validations';
import ModalMeetingDetails from './ModalMeetingDetails';
import {
  AiContainerForm,
  AiContainerGrBtn,
  AiDatePicker,
  AiH3,
  AiInput,
  AiLabel,
  AiTimePickerField,
  MeetingTitle,
} from './Styled';
import SearchByAdditionalViewAccessForTcMeeting from '../../../components/SearchByAdditionalViewAccessForTcMeeting/SearchByAdditionalViewAccessForTcMeeting';
import { useShowPopupSaveAgenda } from '../../../hooks/useShowPopupSaveAgenda';
import { useGetRoleInMeeting } from '../../../hooks/useGetRoleInMeeting';

const MeetingDetails = () => {
  const [visibleRemoveMeeting, setVisibleRemoveMeeting] = useState(false);
  const [visible, setVisible] = useState(false);

  const meeting = useSelector((state) => state.app.meetingModule.preMeeting.meeting);
  const roleActive = useSelector((state) => state.user.roleActive);
  const [isSubmit, setIsSubmit] = useState(false);
  const [loadingDeleteMeeting, setLoadingDeleteMeeting] = useState(false);
  const [isOpenCategoryDropdown, setIsOpenCategoryDropdown] = useState();
  const [meetingSecretariatValue, setMeetingSecretariatValue] = useState([]);
  const [additionalViewAccessValue, setAdditionalViewAccessValue] = useState([]);
  // Category Value
  const [isLoadAddtionalDataInFirstTime, setIsLoadAddtionalDataInFirstTime] = useState(true);
  const [isChangedMeetingCategory, setIsChangedMeetingCategory] = useState(false);

  // Init state to conpare data changes
  const [compareData, setCompareData] = useState({
    initData: {},
    currentData: {},
  });

  const { isShowPopupSaveAgenda, handleKeepNavigate, setIsShowPopupSaveAgenda } =
    useShowPopupSaveAgenda(!isEmpty(isDifferentFieldPdcMeeting(compareData)));

  const handleSaveBeforeExit = async () => {
    setIsShowPopupSaveAgenda(false);
    const values = formik.values;
    const result = await formik.validateForm(values);

    if (isEmpty(result)) {
      await handleSubmit(values);
      setTimeout(() => {
        history.push('/calendar');
      }, 2000);
    } else {
      dispatch(addOrUpdateMeeting(formik.values));
    }
  };

  const handleConfirmExit = () => {
    handleKeepNavigate();
  };

  const isAdminRole = useMemo(() => {
    return roleActive?.roleName ? roleActive?.roleName.includes(ROLE.ADMIN) : false;
  }, [roleActive]);

  // Set Meeting Secretariats
  useEffect(() => {
    formik.setFieldValue('meeting_secretariats', meetingSecretariatValue || []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meetingSecretariatValue]);

  // Set Meeting Additional View Access
  useEffect(() => {
    formik.setFieldValue('meeting_additional_view_accesses', additionalViewAccessValue || []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [additionalViewAccessValue]);

  const [breadCrumbList] = useState([
    { name: 'TC Meeting', icon: tc_meeting_breadcrumb },
    { name: 'Meeting Details' },
  ]);
  const dispatch = useDispatch();
  const history = useHistory();

  let { idMeeting } = useParams();

  const isEditting = negate(isEmpty)(idMeeting);

  const {
    data: meetingDetail,
    fetch: fetchMetting,
    loading: loadingGetMeeting,
  } = useGetMeeting({ idMeeting });

  // const { getAsyncOptionsForSecretarial, secretarialOptions, opuDivisionOption, businessOption } = useSecretarialOptions('add_meeting');

  const { data: meetingRole } = useGetRoleInMeeting({ idMeeting });

  const isOnlyViewMeeting = useMemo(() => {
    if (!idMeeting || isNaN(Number(idMeeting))) return false;

    return (
      !meetingRole?.is_secretariat_or_facilitator &&
      !meetingRole?.is_chairman &&
      !meetingRole?.is_commitee &&
      !isAdminRole &&
      meetingRole?.is_additional_view_access
    );
  }, [meetingRole, idMeeting, isAdminRole]);

  const resetMeetingDetails = () => {
    if (!idMeeting) return;

    formik.setValues({
      ...initialStateMeeting,
      meeting_id: idMeeting || null,
    });
    dispatch(clearMeeting());
    dispatch(clearAgendaState());
    setMeetingSecretariatValue([]);
    setAdditionalViewAccessValue([]);
  };

  useEffect(() => {
    if (meetingDetail?.meeting_id) {
      dispatch(
        addOrUpdateMeeting({
          ...meetingDetail,
          category: find(MEETING_CATEGORY_OPTIONS, {
            name: meetingDetail.category,
          }),
          category_value: {
            name: meetingDetail.category_value,
            category_label: meetingDetail.category_label,
          },
          meeting_secretariats: meetingDetail?.meeting_secretariats,
          meeting_additional_view_accesses: meetingDetail?.meeting_additional_view_accesses,
        })
      );
      setMeetingSecretariatValue(
        getFieldValueForTCMeetingInFirstTime(meetingDetail?.meeting_secretariats)
      );
      setAdditionalViewAccessValue(
        getFieldValueForTCMeetingInFirstTime(meetingDetail?.meeting_additional_view_accesses)
      );
      setCompareData((prev) => ({
        ...prev,
        initData: {
          ...prev.initData,
          meeting_secretariats: getFieldValueForTCMeetingInFirstTime(
            meetingDetail?.meeting_secretariats
          ),
          meeting_additional_view_accesses: getFieldValueForTCMeetingInFirstTime(
            meetingDetail?.meeting_additional_view_accesses
          ),
        },
        currentData: {
          ...prev.currentData,
          meeting_secretariats: getFieldValueForTCMeetingInFirstTime(
            meetingDetail?.meeting_secretariats
          ),
          meeting_additional_view_accesses: getFieldValueForTCMeetingInFirstTime(
            meetingDetail?.meeting_additional_view_accesses
          ),
        },
      }));
    } else {
      resetMeetingDetails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meetingDetail, dispatch]);

  const convertMeetingFromStore = useCallback((meeting) => {
    const {
      category,
      chairman,
      meeting_committee,
      meeting_facilitator,
      meeting_secretariats,
      meeting_additional_view_accesses,
      end_time,
      start_time,
      date,
      category_value,
      ...rest
    } = meeting;
    const dateWithFormat = moment(date).format('YYYY-MM-DD');
    return {
      ...rest,
      meeting_category: category.name,
      chairman_id: __(chairman).castArray().head()?.value,
      meeting_committee_ids: map(meeting_committee, 'value'),
      meeting_facilitator_ids: map(meeting_facilitator, 'value'),
      meeting_secretariats: meeting_secretariats,
      meeting_additional_view_accesses,
      end_time: moment(`${dateWithFormat} ${end_time}`, DATE_TIME_FORMAT.DATE_TIME).toDate(),
      start_time: moment(`${dateWithFormat} ${start_time}`, DATE_TIME_FORMAT.DATE_TIME).toDate(),
      on_step: 1,
      category_value: category_value?.name || null,
      category_label:
        category_value?.category_label || formik.values.category_value?.category_label || null,
    };
  }, []);

  const {
    createMeeting,
    isSuccess: isCreateMeetingSuccess,
    error: createMeetingError,
  } = useCreateMeeting({
    onSuccess: (data) => {
      history.push(`/meeting/${data.result.meeting_id}`);
    },
  });

  const {
    updateMeeting,
    isSuccess: isUpdateMeetingSuccess,
    error: updateMeetingError,
  } = useUpdateMeeting({
    onSuccess: fetchMetting,
  });

  const createOrEditMeeting = useCallback(
    (meeting) => {
      const data = convertMeetingFromStore(meeting);
      dispatch(
        addOrUpdateStepper({ currentTabActive: 2, currentTabFinished: [1], tabsActive: [1, 2] })
      );
      if (!isEditting) {
        return createMeeting(data);
      } else {
        return updateMeeting({ idMeeting, ...data });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isEditting, createMeeting, convertMeetingFromStore]
  );

  const handleSubmit = async (values) => {
    dispatch(addOrUpdateMeeting(values));
    const response = await createOrEditMeeting(values);
    if (!response?.result?.is_secretariat_or_facilitator && !isAdminRole) {
      return resetMeetingDetails();
    }
    if (response) {
      handleAddOrUpdateMeetingToStore(response.result);
      await handleUpdateStepNumber(1);
    }
    setVisible(false);
    return response;
  };

  const handleUpdateStepNumber = async (stepNumber) => {
    if (!idMeeting) return;

    await meetingApi.updateStepMeeting({
      body: {
        on_step: stepNumber,
      },
      idMeeting,
    });
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: meeting,
    validationSchema,
    onSubmit: handleSubmit,
  });

  // Update compare data
  useEffect(() => {
    setCompareData((prev) => ({
      ...prev,
      currentData: {
        ...prev.currentData,
        ...formik.values,
      },
    }));
  }, [formik.values]);

  useEffect(() => {
    formik.setValues(meeting);
    formik.setFieldValue(
      'meeting_secretariats',
      getFieldValueForTCMeetingInFirstTime(
        meeting?.meeting_secretariats ||
          meeting?.roles?.meeting_secretariats ||
          meeting?.roles?.secretariat
      )
    );
    formik.setFieldValue(
      'meeting_additional_view_accesses',
      getFieldValueForTCMeetingInFirstTime(
        meeting?.meeting_additional_view_accesses ||
          meeting?.roles?.meeting_additional_view_accesses ||
          meeting?.roles?.additional_view_access
      )
    );
    setMeetingSecretariatValue(
      getFieldValueForTCMeetingInFirstTime(
        meeting?.meeting_secretariats ||
          meeting?.roles?.meeting_secretariats ||
          meeting?.roles?.secretariat
      )
    );
    setAdditionalViewAccessValue(
      getFieldValueForTCMeetingInFirstTime(
        meeting?.meeting_additional_view_accesses ||
          meeting?.roles?.meeting_additional_view_accesses ||
          meeting?.roles?.additional_view_access
      )
    );
    setCompareData((prev) => ({
      ...prev,
      initData: {
        ...prev.initData,
        ...meeting,
        meeting_secretariats: getFieldValueForTCMeetingInFirstTime(
          meeting?.meeting_secretariats ||
            meeting?.roles?.meeting_secretariats ||
            meeting?.roles?.secretariat
        ),
        meeting_additional_view_accesses: getFieldValueForTCMeetingInFirstTime(
          meeting?.meeting_additional_view_accesses ||
            meeting?.roles?.meeting_additional_view_accesses ||
            meeting?.roles?.additional_view_access
        ),
      },
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meeting]);

  const onChangeInputData = (value, key) => {
    formik.setFieldValue(key, value);
  };

  const onChange = (date, dateString) => {
    formik.setFieldValue('date', dateString);
  };

  const onChangeSelectData = (event, key) => {
    formik.setFieldValue(key, event);
  };

  const handleCheckValidateForm = () => {
    setIsSubmit(true);
    if (size(formik.errors) > 0) {
      setIsSubmit(false);
      setVisible(true);
    } else {
      dispatch(addOrUpdateMeeting(formik.values));
    }
  };

  const exitCreateOrEditMeeting = () => {
    dispatch(clearMeeting());
    dispatch(clearAgendaState());
    history.push('/calendar');
  };
  const handleExit = () => {
    history.push('/calendar');
    // if (!formik.dirty) return history.push('/calendar');
    // setVisibleSaveAsDraft(true);
  };

  const handleNext = async () => {
    const errRes = await formik.validateForm();
    dispatch(addOrUpdateMeeting(formik.values));
    if (!isEmpty(errRes)) {
      setVisible(true);
      return;
    }

    if (!isOnlyViewMeeting) {
      const response = await createOrEditMeeting(formik.values);
      // if (!response?.result?.is_secretariat_or_facilitator && !isAdminRole) {
      //   return resetMeetingDetails();
      // }
      if (response) {
        handleAddOrUpdateMeetingToStore(response.result);
        idMeeting = get(response, ['result', 'meeting_id']);
      }
    }
    if (!idMeeting) return;

    dispatch(
      addOrUpdateStepper({ currentTabActive: 2, currentTabFinished: [1], tabsActive: [1, 2] })
    );
    await handleUpdateStepNumber(1);
    await handleUpdateStepNumber(2);
    history.push(`/meeting/${idMeeting}/agendas`);
  };

  const handleAddOrUpdateMeetingToStore = (data) => {
    const newData = handleConvertDataToSaveStore(data);
    dispatch(
      addOrUpdateMeeting({
        ...newData,
        category: find(MEETING_CATEGORY_OPTIONS, {
          name: newData.category,
        }),
        category_value: {
          name: newData.category_value,
          category_label: newData.category_label,
        },
        meeting_secretariats: newData.meeting_secretariats,
        meeting_additional_view_accesses: newData.meeting_secretariats,
        is_secretariat_or_facilitator: newData?.is_secretariat_or_facilitator,
      })
    );
  };

  // Convert the post response to store
  const handleConvertDataToSaveStore = (data) => {
    const tempChairman = size(data.roles.chairman) > 0 ? data.roles.chairman : [];
    const tempCommittee = size(data.roles.committee) > 0 ? data.roles.committee : [];
    const tempFacilitator = size(data.roles.facilitator) > 0 ? data.roles.facilitator : [];
    // const tempSecretariat = size(data.roles.secretariat) > 0 ? data.roles.secretariat : [];
    const { name, start_at, end_at, is_secretariat_or_facilitator, ...rest } = data;

    return {
      ...rest,
      meeting_name: name,
      date: moment(new Date(start_at)).format(DATE_TIME_FORMAT.DATE_SPACE),
      start_time: getTime(start_at),
      end_time: getTime(end_at),
      chairman: map(tempChairman, (it) => ({ label: it.name, value: it.id })),
      meeting_committee: map(tempCommittee, ({ name, id }) => ({
        label: name,
        value: id,
      })),
      meeting_facilitator: map(tempFacilitator, ({ name, id }) => ({
        label: name,
        value: id,
      })),
      is_secretariat_or_facilitator: is_secretariat_or_facilitator || false,
    };
  };

  const getTime = (dateInput) => {
    if (!dateInput) return '';
    const date = moment(dateInput).local().toDate();
    let hour = date.getHours();
    let minutes = date.getMinutes();

    hour = `0${hour}`.slice(-2);
    minutes = `0${minutes}`.slice(-2);

    return `${hour}:${minutes}`;
  };

  const handleSetMeetingSecretariatValue = (val) => {
    const isCheck =
      meetingSecretariatValue.filter((item) => item?.label_name === val?.label_name).length > 0;
    if (!isCheck) {
      setMeetingSecretariatValue((prev) => {
        return [...prev, val];
      });
    }
    setIsLoadAddtionalDataInFirstTime(false);
  };

  const handleSetAdditionalViewAccess = (val) => {
    const isCheck =
      additionalViewAccessValue.filter((item) => item?.label_name === val?.label_name).length > 0;
    if (!isCheck) {
      setAdditionalViewAccessValue((prev) => {
        return [...prev, val];
      });
    }
  };

  useEffect(() => {
    if (
      !roleActive?.roleId ||
      isEmpty(formik.values.category?.name) ||
      (isLoadAddtionalDataInFirstTime && !isEmpty(formik.values.meeting_secretariats))
    )
      return;

    if (
      isEmpty(formik.values.meeting_secretariats) &&
      ![MEETING_CATEGORY_VALUES.SKG, MEETING_CATEGORY_VALUES.JOB_FAMILY].includes(
        formik.values.category?.name
      )
    ) {
      setAdditionalViewAccessValue([]);
      formik.setFieldValue('meeting_additional_view_accesses', []);
      return;
    }

    const getDataToPopulateForAdditionalView = async (firstItem) => {
      try {
        const params = {
          role_id: firstItem?.role_id,
          meeting_category: formik.values.category?.name,
          business_id: getBusinessIdToPopulateForAdditionalView(
            formik.values.category?.name,
            firstItem
          ),
        };
        const res = await meetingApi.getDataToPopulateForAdditionalView(params, roleActive?.roleId);
        if (res.status === 200 && res.data.result.length > 0 && isArray(res.data.result)) {
          res.data.result.forEach((item) => {
            const tempVal = {
              ...item,
              label_name: getLabelNameForSecretariatOfTcMeeting(formik.values.category?.name, item),
              search_type: formik.values.category?.name,
            };
            handleSetAdditionalViewAccess(tempVal);
          });
        }
      } catch (error) {
        console.error(error);
      }
    };

    if (
      ![MEETING_CATEGORY_VALUES.SKG, MEETING_CATEGORY_VALUES.JOB_FAMILY].includes(
        formik.values.category?.name
      ) &&
      formik.values.meeting_secretariats.length === 1 &&
      isChangedMeetingCategory
    ) {
      getDataToPopulateForAdditionalView(formik.values.meeting_secretariats[0]);
      setIsChangedMeetingCategory(false);
    }
  }, [formik.values.meeting_secretariats, isLoadAddtionalDataInFirstTime]);

  // const isDisabledAdditionalViewAccess = useMemo(() => {
  //   return [MEETING_CATEGORY_VALUES.PETRONAS].includes(formik.values.category?.name) || isEmpty(formik.values.meeting_secretariats);
  // }, [formik.values.category, formik.values.meeting_secretariats]);

  const isDisabledMeetingSecretariatField = useMemo(() => {
    return [MEETING_CATEGORY_VALUES.PETRONAS].includes(formik.values.category?.name);
  }, [formik.values.category]);

  const handleSelectMeetingCategory = (val) => {
    formik.setFieldValue('category', val);
    formik.setFieldValue('category_value', null);
    setIsLoadAddtionalDataInFirstTime(false);

    const tempVal = {
      role: ROLE.HR_COE,
      role_id: 7,
      label_name: getLabelNameForSecretariatOfTcMeeting(MEETING_CATEGORY_VALUES.PETRONAS, {
        role: ROLE.HR_COE,
        role_id: 7,
      }),
      search_type: MEETING_CATEGORY_VALUES.PETRONAS,
    };
    if (val.name === MEETING_CATEGORY_VALUES.PETRONAS) {
      setMeetingSecretariatValue([tempVal]);
      setAdditionalViewAccessValue([tempVal]);
    } else if (
      [MEETING_CATEGORY_VALUES.SKG, MEETING_CATEGORY_VALUES.JOB_FAMILY].includes(val.name)
    ) {
      setAdditionalViewAccessValue([tempVal]);
    } else {
      setMeetingSecretariatValue([]);
      setAdditionalViewAccessValue([]);
    }
  };

  return (
    <>
      <BreadCrumb level={2} breadCrumbList={breadCrumbList} />
      <StepBar isOnlyViewMeeting={isOnlyViewMeeting} />
      {loadingGetMeeting ? (
        <AiSpin tip="Loading...">
          <AiAlert />
        </AiSpin>
      ) : (
        <form onSubmit={formik.handleSubmit}>
          <AiContainerForm>
            {size(formik.errors) > 0 &&
              formik.errors['meeting_secretariats'] &&
              isEmpty(formik.values.meeting_secretariats) &&
              isSubmit && (
                <div style={{ marginBottom: '20px' }}>
                  <AlertMessage
                    message="There are items that require your attention. Please fill out this field."
                    type="error"
                  />
                </div>
              )}

            {isCreateMeetingSuccess && (
              <div style={{ marginBottom: '20px' }}>
                <AlertMessage message="Meeting has been created successfully." type="success" />
              </div>
            )}

            {!isNil(createMeetingError) && !isEmpty(createMeetingError) && (
              <div style={{ marginBottom: '20px' }}>
                <AlertMessage message="Meeting hasn't been created." type="error" />
              </div>
            )}

            {isUpdateMeetingSuccess && (
              <div style={{ marginBottom: '20px' }}>
                <AlertMessage message="Meeting has been updated successfully." type="success" />
              </div>
            )}

            {!isNil(updateMeetingError) && !isEmpty(updateMeetingError) && (
              <div style={{ marginBottom: '20px' }}>
                <AlertMessage message="Meeting hasn't been updated." type="error" />
              </div>
            )}

            <MeetingTitle>
              <AiH3>Meeting Details</AiH3>
              <MeetingStatus status={meeting.status} />
            </MeetingTitle>
            <Row className="mb-3">
              <Col span={24}>
                <AiLabel>Meeting Name</AiLabel>
                <AiInput
                  placeholder="Enter Meeting Name"
                  name="meeting_name"
                  value={formik.values.meeting_name}
                  onChange={formik.handleChange}
                  status={isCheckError(formik, 'meeting_name') ? 'error' : ''}
                  disabled={isOnlyViewMeeting}
                />
                <FieldError name="meeting_name" {...formik} />
              </Col>
            </Row>
            <div className={styles.rowInput}>
              <div className={styles.inputItem}>
                <AiLabel>Date</AiLabel>
                <Space direction="vertical" style={{ width: '100%' }}>
                  <AiDatePicker
                    onChange={onChange}
                    format={DATE_TIME_FORMAT.DATE_SPACE}
                    status={isCheckError(formik, 'date') ? 'error' : ''}
                    value={
                      formik.values.date && moment(formik.values.date, DATE_TIME_FORMAT.DATE_SPACE)
                    }
                    placeholder="Select Date"
                    getPopupContainer={(trigger) => trigger.parentElement}
                    disabled={isOnlyViewMeeting}
                  />
                  <FieldError name="date" {...formik} />
                </Space>
              </div>
              <div className={styles.inputItem}>
                <AiLabel>Meeting Category</AiLabel>
                <MeetingCategory
                  selected={formik.values.category}
                  setSelected={(event) => {
                    handleSelectMeetingCategory(event);
                    setIsChangedMeetingCategory(true);
                  }}
                  options={MEETING_CATEGORY_OPTIONS}
                  placeholder="Select Meeting Category"
                  status={isCheckError(formik, 'category') ? 'error' : ''}
                  disabled={isOnlyViewMeeting}
                />
                <FieldError name="category.id" {...formik} />
              </div>
              {!isEmpty(formik.values.category) &&
                ['Business', 'OPU', 'Sector', 'Division', 'SKG', 'Job Family'].includes(
                  formik.values.category?.name
                ) && (
                  <div className={styles.inputItem}>
                    <AiLabel>{formik.values.category?.name}</AiLabel>
                    <SearchByCategoryForTcMeeting
                      openDropdown={isOpenCategoryDropdown}
                      setOpenDropdown={setIsOpenCategoryDropdown}
                      setValue={({ val }) => formik.setFieldValue('category_value', val)}
                      searchName={formik.values.category?.name}
                      fieldValue={formik.values.category_value}
                      status={isCheckError(formik, 'category_value') ? 'error' : ''}
                      disabled={isOnlyViewMeeting}
                    />
                    <FieldError name="category_value" {...formik} />
                  </div>
                )}
              <div className={styles.inputItem}>
                <AiLabel>Start time</AiLabel>
                <AiTimePickerField
                  placeholder={'Select Time'}
                  value={
                    formik.values.start_time
                      ? moment(formik.values.start_time, DATE_TIME_FORMAT.TIME).local()
                      : null
                  }
                  style={{ width: '100%' }}
                  onChange={(_event, value) => onChangeInputData(value, 'start_time')}
                  status={isCheckError(formik, 'start_time') ? 'error' : ''}
                  showTime={{ format: DATE_TIME_FORMAT.TIME, use12Hours: true }}
                  format={DATE_TIME_FORMAT.TIME}
                  showNow={false}
                  inputReadOnly={true}
                  allowClear={false}
                  getPopupContainer={(trigger) => trigger.parentElement}
                  disabled={isOnlyViewMeeting}
                />
                <FieldError name="start_time" {...formik} />
              </div>
              <div className={styles.inputItem}>
                <AiLabel>End time</AiLabel>
                <AiTimePickerField
                  placeholder={'Select Time'}
                  value={
                    formik.values.end_time
                      ? moment(formik.values.end_time, DATE_TIME_FORMAT.TIME).local()
                      : null
                  }
                  style={{ width: '100%' }}
                  onChange={(_event, value) => onChangeInputData(value, 'end_time')}
                  status={isCheckError(formik, 'end_time') ? 'error' : ''}
                  showTime={{ format: DATE_TIME_FORMAT.TIME, use12Hours: true }}
                  format={DATE_TIME_FORMAT.TIME}
                  showNow={false}
                  inputReadOnly={true}
                  allowClear={false}
                  getPopupContainer={(trigger) => trigger.parentElement}
                  disabled={isOnlyViewMeeting}
                />
                <FieldError name="end_time" {...formik} />
              </div>
            </div>

            <Row className="mb-3">
              <Col span={24}>
                <AiLabel>Location</AiLabel>
                <AiInput
                  placeholder="Enter Location"
                  name="location"
                  value={formik.values.location}
                  onChange={formik.handleChange}
                  disabled={isOnlyViewMeeting}
                />
              </Col>
            </Row>
            <Row className="mb-3">
              <Col span={24}>
                <AiLabel>Chairman</AiLabel>
                <AsyncMultipleSelect
                  value={formik.values.chairman}
                  placeholder="Enter Chairman Name"
                  loadOptions={(e) => getAsyncOptionAdvocatorsHrPartners(e, roleActive.roleId)}
                  onChange={(e) => onChangeSelectData(e, 'chairman')}
                  status={isCheckError(formik, 'chairman') ? 'error' : ''}
                  isDisabled={isOnlyViewMeeting}
                />
                <FieldError name="chairman" {...formik} />
              </Col>
            </Row>
            <Row className="mb-3">
              <Col span={24}>
                <AiLabel>Committee Members</AiLabel>
                <AsyncMultipleSelect
                  placeholder={'Enter Committee Members Name'}
                  loadOptions={(e) => getAsyncOptionAdvocatorsHrPartners(e, roleActive.roleId)}
                  onChange={(e) => onChangeSelectData(e, 'meeting_committee')}
                  isDisabled={isOnlyViewMeeting}
                  value={formik.values.meeting_committee}
                />
              </Col>
            </Row>
            <Row className="mb-3">
              <Col span={24}>
                <AiLabel>Meeting Facilitators</AiLabel>
                <AsyncMultipleSelect
                  placeholder={'Enter Meeting Facilitators Name'}
                  onChange={(e) => onChangeSelectData(e, 'meeting_facilitator')}
                  loadOptions={(e) => getAsyncOptionAdvocatorsHrPartners(e, roleActive.roleId)}
                  isDisabled={isOnlyViewMeeting}
                  value={formik.values.meeting_facilitator}
                />
              </Col>
            </Row>
            <Row className="mb-3">
              <Col span={24}>
                <AiLabel>Meeting Secretariat</AiLabel>
                <SearchBySecretariatForTcMeeting
                  searchName={formik.values.category?.name}
                  setValue={({ val }) => {
                    handleSetMeetingSecretariatValue(val);
                    if (formik.values.meeting_secretariats.length === 0) {
                      setIsChangedMeetingCategory(true);
                    }
                  }}
                  fieldValues={meetingSecretariatValue}
                  setMeetingSecretariatValue={setMeetingSecretariatValue}
                  status={isCheckError(formik, 'meeting_secretariats') ? 'error' : ''}
                  isDisabled={isDisabledMeetingSecretariatField || isOnlyViewMeeting}
                />
                <FieldError name="meeting_secretariats" {...{ formik, forceTouched: true }} />
              </Col>
            </Row>
            <Row className="mb-3">
              <Col span={24}>
                <AiLabel noRequire={true}>Additional View Access</AiLabel>
                <SearchByAdditionalViewAccessForTcMeeting
                  searchName={formik.values.category?.name}
                  setValue={({ val }) => handleSetAdditionalViewAccess(val)}
                  fieldValues={additionalViewAccessValue}
                  setAdditionalViewAccessValue={setAdditionalViewAccessValue}
                  isDisabled={isOnlyViewMeeting}
                  // status={isCheckError(formik, 'meeting_additional_view_accesses') ? 'error' : ''}
                  // isDisabled={isDisabledAdditionalViewAccess}
                />
                {/* <FieldError name="meeting_additional_view_accesses" {...{ formik, forceTouched: true }} /> */}
              </Col>
            </Row>
          </AiContainerForm>
          <AiContainerGrBtn>
            <div>
              {!isOnlyViewMeeting && (
                <AiButton mode="danger" onClick={() => setVisibleRemoveMeeting(true)}>
                  Delete Meeting
                </AiButton>
              )}
            </div>
            {!isOnlyViewMeeting ? (
              <div>
                <AiButton htmlType="submit" onClick={handleCheckValidateForm}>
                  Save as Draft
                </AiButton>
                <AiButton className="ml-2" onClick={handleExit}>
                  Exit
                </AiButton>
                <AiButton mode="teal" className="ml-2" onClick={handleNext}>
                  Next
                </AiButton>
              </div>
            ) : (
              <div>
                <AiButton className="ml-2" onClick={handleExit}>
                  Exit
                </AiButton>
                <AiButton mode="teal" className="ml-2" onClick={handleNext}>
                  Next
                </AiButton>
              </div>
            )}
          </AiContainerGrBtn>
        </form>
      )}
      {/*
       * IMPLEMENT MODEL
       */}
      <ModelTC
        info={{
          type: 'deleteMeeting',
          visible: visibleRemoveMeeting,
          setVisible: setVisibleRemoveMeeting,
          handleSubmit: async () => {
            setLoadingDeleteMeeting(true);
            if (!idMeeting) {
              exitCreateOrEditMeeting();
            } else {
              try {
                let res = await meetingApi.deleteMeeting({ idMeeting });
                if (res.status === 200) {
                  exitCreateOrEditMeeting();
                  setVisibleRemoveMeeting(false);
                  setLoadingDeleteMeeting(false);
                }
              } catch (error) {
                showNotification(`Delete Meeting failed`, NOTIFICATION_TYPE.FAILED);
                setVisibleRemoveMeeting(false);
                setLoadingDeleteMeeting(false);
              }
            }
          },
          onClose: () => {},
          loading: loadingDeleteMeeting,
        }}
      />
      <ModelTC
        info={{
          type: 'withoutSaving',
          visible: isShowPopupSaveAgenda,
          setVisible: setIsShowPopupSaveAgenda,
          onClose: handleConfirmExit,
          handleSubmit: handleSaveBeforeExit,
        }}
      />
      <ModalMeetingDetails
        visible={visible}
        setVisible={setVisible}
        initialValues={formik.values}
        initialErrors={formik.errors}
        setValues={formik.setValues}
        validationSchema={validationSchema}
        // getAsyncOptionsForSecretarial={getAsyncOptionsForSecretarial}
        // opuDivisionOption={opuDivisionOption}
        // businessOption={businessOption}
        meetingSecretariatValue={meetingSecretariatValue}
        setMeetingSecretariatValue={setMeetingSecretariatValue}
        additionalViewAccessValue={additionalViewAccessValue}
        setAdditionalViewAccessValue={setAdditionalViewAccessValue}
        // isDisabledAdditionalViewAccess={isDisabledAdditionalViewAccess}
        setIsLoadAddtionalDataInFirstTime={setIsLoadAddtionalDataInFirstTime}
        setIsChangedMeetingCategory={setIsChangedMeetingCategory}
      />
    </>
  );
};
export default MeetingDetails;
