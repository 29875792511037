import { Divider } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import {
  arrow_calendar,
  left,
  plus_calendar,
  right,
  sp_chevron_down,
  circle_checked,
} from '../../../../assets/img';
import MeetingFilter from '../MeetingFilter/MeetingFilter';
import MonthCalendar from '../MonthCalendar/MonthCalendar';
import WeekCalendar from '../WeekCalendar/WeekCalendar';
import YearCalendar from '../YearCalendar/YearCalendar';
import { Container } from './styled';

import { isEmpty } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { useComponentVisible } from '../../../../hooks/index';
import { CALENDAR, PERMISSION, ROLE } from '../../../../utils/constants';
import { clearAgendaState } from '../../../Meeting/redux/agendaSlice';
import { clearMeeting } from '../../../Meeting/redux/meetingDetailSlice';
// import { clearPDCMeeting } from '../../../PDCMeeting/redux/meetingDetailSlice';
import SearchFilterMeeting from '../../../../components/SearchFilterMeeting/SearchFilterMeeting';

const MenuCalendar = (props) => {
  const {
    setFromDate,
    setToDate,
    decrease,
    increase,
    date,
    setStartDate,
    isMonthlyView,
    setIsMonthlyView,
    category,
    setCategory,
    permissions,
    changeYear,
    changeMonth,
    startDate,
  } = props;

  const { roleName } = useSelector((state) => state.user.roleActive);
  const location = useLocation();
  const [isShowMonthCalendar, setIsShowMonthCalendar] = useState(false);
  const [isShowYearCalendar, setIsShowYearCalendar] = useState(false);
  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);
  const [meetingCategorySelected, setMeetingCategorySelected] = useState(category.business);

  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    setStartDate((prev) => ({ ...prev, value: date }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date]);

  useEffect(() => {
    if (isEmpty(meetingCategorySelected)) {
      setCategory({ business: CALENDAR.MEETING_CATEGORY, value: '' });
    }
    if (!isEmpty(meetingCategorySelected))
      setCategory((prev) => ({
        business: meetingCategorySelected,
        value: prev.business === meetingCategorySelected ? prev.value : '',
      }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isComponentVisible]);

  const handleCreate = () => {
    dispatch(clearMeeting());
    // dispatch(clearPDCMeeting());
    dispatch(clearAgendaState());
    location.pathname === '/pdc' ? history.push('/pdc-meeting') : history.push('/meeting');
  };

  const [isShowMonthOfWeek, setShowMonthOfWeek] = useState(true);
  const [monthSelectedAntd, setMonthSelectedAntd] = useState(date);

  return (
    <Container data-testid="menu-calendar">
      <div className="d-flex justify-content-start align-items-center" style={{ gap: '15px' }}>
        <div
          style={{ border: '1px solid #E0E0E0', borderRadius: '6px' }}
          className="d-flex justify-content-center align-items-center"
        >
          <div
            style={{ padding: '10px 12px', cursor: 'pointer' }}
            onKeyDown={() => {}}
            onClick={decrease}
          >
            <img src={left} alt="" style={{ width: '10px', height: '10px' }} />
          </div>

          <Divider type="vertical" style={{ color: '#E0E0E0' }} />
          <div
            style={{ padding: '10px 12px', cursor: 'pointer' }}
            onKeyDown={() => {}}
            onClick={increase}
          >
            <img src={right} alt="" style={{ width: '10px', height: '10px' }} />
          </div>
        </div>

        <div className="month-year">
          <div
            onKeyDown={() => {}}
            onClick={() =>
              isMonthlyView ? setIsShowMonthCalendar(true) : setIsShowYearCalendar(true)
            }
            style={{ cursor: 'pointer' }}
            id="area12"
          >
            {isMonthlyView
              ? `${moment().month(date.getMonth()).format('MMM')} ${date?.getFullYear()}`
              : date?.getFullYear()}
            <img src={sp_chevron_down} alt="" />
          </div>
          {isShowMonthCalendar && (
            <>
              {isShowMonthOfWeek ? (
                <WeekCalendar
                  date={date}
                  setIsShowMonthCalendar={setIsShowMonthCalendar}
                  setStartDate={setStartDate}
                  isShowMonthCalendar={isShowMonthCalendar}
                  setFromDate={setFromDate}
                  setToDate={setToDate}
                  setShowMonthOfWeek={setShowMonthOfWeek}
                  monthSelectedAntd={monthSelectedAntd}
                  isShowMonthOfWeek={isShowMonthOfWeek}
                  setMonthSelectedAntd={setMonthSelectedAntd}
                />
              ) : (
                <MonthCalendar
                  monthSelectedAntd={monthSelectedAntd}
                  setIsShowMonthCalendar={setShowMonthOfWeek}
                  setMonthSelectedAntd={setMonthSelectedAntd}
                />
              )}
            </>
          )}
          {isShowYearCalendar && (
            <YearCalendar
              date={date}
              setIsShowYearCalendar={setIsShowYearCalendar}
              setStartDate={setStartDate}
            />
          )}
        </div>
        <div
          className="btn-today"
          onClick={() => {
            changeMonth && changeMonth(new Date().getMonth());
            changeYear && changeYear(new Date().getFullYear());
            if (
              (isMonthlyView && moment(startDate.value).month() === new Date().getMonth()) ||
              (!isMonthlyView && moment(startDate.value).year() === new Date().getFullYear())
            ) {
              setStartDate({ value: new Date(), scrollToCurrentDate: true });
            } else {
              setStartDate({ value: new Date(), scrollAfterLoadData: true });
            }
          }}
        >
          Today
        </div>
      </div>
      <div className="d-flex justify-content-center align-items-center">
        {(permissions.includes(PERMISSION.CREATE_MEETING) || roleName.includes(ROLE.ADMIN)) && (
          <button
            className="create"
            style={{ marginRight: '15px', backgroundColor: ' #00a19c', color: 'white' }}
            onKeyDown={() => {}}
            onClick={handleCreate}
          >
            <img src={plus_calendar} alt="" style={{ width: '10px', height: '10px' }} />
            Create Meeting
          </button>
        )}
        <div style={{ marginRight: '15px' }} ref={ref}>
          <div
            className="today"
            onKeyDown={() => {}}
            onClick={() => setIsComponentVisible(!isComponentVisible)}
          >
            <img src={circle_checked} alt="" />
            Calendar Filter
            <img src={arrow_calendar} alt="" style={{ width: '10px', height: '10px' }} />
          </div>
          {isComponentVisible && (
            <MeetingFilter
              meetingCategory={meetingCategorySelected}
              setMeetingCategory={setMeetingCategorySelected}
            />
          )}
        </div>
        {category.business.length === 1 &&
          ['Business', 'OPU', 'Division', 'Sector', 'SKG', 'Job Family'].includes(
            category.business[0]
          ) && (
            <div style={{ marginRight: '15px' }}>
              <SearchFilterMeeting
                business={category.business[0]}
                category={category}
                setCategory={setCategory}
              />
            </div>
          )}
        <div
          className={isMonthlyView ? 'monthly-and-yearly active' : 'monthly-and-yearly'}
          style={{
            borderTopLeftRadius: '6px',
            borderBottomLeftRadius: '6px',
            borderRight: 'none',
          }}
          onKeyDown={() => {}}
          onClick={() => {
            setIsMonthlyView(true);
            setStartDate((prev) => ({
              ...prev,
              scrollToCurrentDate: false,
              scrollAfterLoadData: false,
            }));
          }}
        >
          Monthly
        </div>
        <div
          className={isMonthlyView ? 'monthly-and-yearly' : 'monthly-and-yearly active'}
          style={{
            borderTopRightRadius: '6px',
            borderBottomRightRadius: '6px',
          }}
          onKeyDown={() => {}}
          onClick={() => {
            setIsMonthlyView(false);
            setStartDate((prev) => ({
              ...prev,
              scrollToCurrentDate: false,
              scrollAfterLoadData: false,
            }));
          }}
        >
          Yearly
        </div>
      </div>
    </Container>
  );
};

export default MenuCalendar;
