import React, { useState } from 'react';
import CustomAntModal from '../CustomAntModal/CustomAntModal';
import styled from 'styled-components';
import { AiCheckBox, BasicButton, font } from '../../assets/common';

export const Container = styled.div`
  font-family: ${font.inter};
  color: #3f3c4c;
  font-size: 20px;
  font-weight: 500;
  padding: 40px 0px;
`;
export const Title = styled.div`
  line-height: 32px;
  letter-spacing: 0.005em;
  text-align: left;
  margin-bottom: 20px;
`;

export const Text = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: #000000;
  margin-bottom: 20px;
`;

export const HighlightText = styled.span`
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  color: #000000;
`;

export const Legend = styled.span`
  font-size: 14px !important;
  line-height: 20px !important;
  color: #181818 !important;
`;
function ConfirmModalCopyToAgenda(props) {
  const { openCopyRemark, setOpenCopyRemark, copyToAgenda } = props;
  const [checked, setChecked] = useState(false);

  return (
    <div>
      <CustomAntModal
        open={openCopyRemark.open}
        setOpen={(e) => setOpenCopyRemark({ open: e, record: null })}
        width={800}
        destroyOnClose
        hideDefaultBtn={true}
        styles={{
          containerPaddingTopBottom: 50,
          containerPaddingLeftRight: 50,
          closePosition: 50,
        }}
      >
        <Container>
          <Title>Copy to Agenda</Title>
          <Text>
            Notes are available in this agenda. You may select <HighlightText>{'Copy Notes'}</HighlightText> to add them to the agenda. <br />
            Otherwise, you may click <HighlightText>{'Copy'}</HighlightText> to proceed without including the notes.
          </Text>
          <div
            className="d-flex justify-content-start align-items-center"
            style={{ gap: '10px', marginBottom: '10px', width: 'fit-content', cursor: 'pointer' }}
            onClick={() => setChecked(!checked)}
          >
            <AiCheckBox checked={checked} data-testid="modal-copy-to-agenda-checkbox" type="checkbox" />
            <Legend>Copy Notes</Legend>
          </div>
          <div className="d-flex justify-content-end">
            <BasicButton
              width="100px"
              height="44px"
              mode="teal"
              onClick={() => {
                copyToAgenda(openCopyRemark.record, checked);
                setOpenCopyRemark({ open: false, record: null });
              }}
            >
              Copy
            </BasicButton>
          </div>
        </Container>
      </CustomAntModal>
    </div>
  );
}

export default ConfirmModalCopyToAgenda;
