import { color } from '../../../assets/common';
import { rootStyles } from '../../../utils/stylesHelper';
export const customStyles = {
  ...rootStyles,
  valueContainer: (provided, _state) => ({
    ...provided,
    padding: '2px 8px 2px 15px',
    gap: '8px',
    fontSize: 14,
  }),
};
export const theme = (themeOption) => ({
  ...themeOption,
  borderRadius: '4px',
  colors: {
    ...themeOption.colors,
    primary: '#00a19c',
  },
});
export const stylesDisabled = {
  ...customStyles,
  control: (provided, _state) => ({
    ...provided,
    minHeight: 44,
    border: `1px solid ${color.disable}`,
    backgroundColor: color.disable,
    '&:hover': {
      borderColor: `${color.disable}`,
      boxShadow: `0 0 0 1px ${color.disable}`,
    },
  }),
  multiValue: (provided, _state) => {
    return {
      ...provided,
      flexDirection: 'row-reverse',
      background: `${color.disable}`,
      border: '1px solid #BDBDBD',
      borderRadius: '11px',
      paddingRight: '10px',
    };
  },
};
export const stylesDanger = {
  ...customStyles,
  control: (provided, _state) => ({
    ...provided,
    minHeight: 44,
    border: `1px solid ${color.danger}`,
    boxShadow: `0 0 0 1px ${color.danger}`,
    '&:hover': {
      borderColor: `${color.danger}`,
      boxShadow: `0 0 0 1px ${color.danger}`,
    },
  }),
};

export const stylesNormal = {
  ...customStyles,
  control: (provided, _state) => ({
    ...provided,
    minHeight: 44,
    border: `1px solid ${color.geyser}`,
    '&:hover': {
      borderColor: `${color.teal}`,
      boxShadow: `0 0 0 1px ${color.teal}`,
    },
  }),
};
