import { Tooltip } from 'antd';
import { isEmpty } from 'lodash';
import moment from 'moment';
import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { DATE_TIME_FORMAT, SP_CARD_DEFAULT } from '../../../../utils/constants';
import {
  decodeBase64ToImage,
  handleShowCT,
  handleShowRetirementDate,
  handleShowRetirementForSP,
  handleShowTextTooltip,
  handleShowTooltip,
  handleShowYIPAndYISG,
  hideOtherTalentLabel,
} from '../../../../utils/helper';
import { ApprovedLabel } from '../../../Meeting/AgendaDetails/components/Recommendations/Box/ApprovedLabel';
import styles from './box-dsp.module.scss';
import { useCardSetting } from '../../../../hooks/useCardSetting';

const convertLine = [
  { text: '1st Line', value: 'first_line' },
  { text: '2nd Line', value: 'second_line' },
  { text: '3rd Line', value: 'third_line' },
];

const CardBoxWrapper = styled.div`
  padding: 0 10px 15px;
`;

const GirdCardBoxPrintSP = (props) => {
  const { text, item, index, blockStyle } = props;
  const {
    positions,
    agenda_as_main_type,
    agenda_as_main_platform,
    agenda_as_main,
    agenda_as_main_start_at,
  } = useSelector((state) => state.app.meetingModule.agenda.agendaIncumbent);

  const boxRef = useRef();
  const cardSettings = useSelector(
    (state) => state.app.meetingModule.agenda.agendaIncumbent.cardSettings
  );
  const lineSelected = convertLine.find((f) => f.text === text).value;
  const lineSettings = cardSettings[lineSelected];
  useCardSetting({ boxRef, lineSettings });

  return (
    <CardBoxWrapper style={{ paddingTop: index === 0 ? '15px' : 0 }}>
      <div
        className={styles.card_box}
        style={{
          padding: '10px',
          border: '1px solid #e7e7f0',
          borderTop: `8px solid ${blockStyle.headerColor}`,
          borderRadius: '6px',
        }}
        data-testid="view-card-box"
        ref={boxRef}
      >
        <div
          className="d-flex no-gutters justify-content-start align-items-stretch "
          style={{ gap: '5px' }}
        >
          <div className={`${styles.left}`}>
            <div className="row flex-column no-gutters align-items-start h-100">
              <div className={styles.number}>{index + 1}</div>
              <div className={styles.image} data-cardSettings={SP_CARD_DEFAULT.PROFILE_PICTURE}>
                <img src={decodeBase64ToImage(item?.image)} alt="" />
              </div>
            </div>
          </div>

          <div className={`${styles.right}`}>
            <div className="d-flex no-gutters justify-content-between align-items-stretch">
              <div className={styles.name}>
                <Link
                  to={`/profile/${item?.staff_id}`}
                  target="_blank"
                  className={styles.recommendation__name}
                  draggable="false"
                  data-cardSettings={SP_CARD_DEFAULT.STAFF_NAME}
                >
                  {item?.birth_name} &#160;
                </Link>
                <div
                  className={styles.recommendation__name}
                  data-cardSettings={SP_CARD_DEFAULT.TOP_TALENT_STATUS}
                >
                  {!item?.isUnsearchable &&
                    !hideOtherTalentLabel(item.assignment_status_type, item.role_level, item.ct) &&
                    !['119463', '1032501'].includes(item?.staff_id) &&
                    handleShowCT(item.ct)}
                </div>
                <div
                  className={styles.recommendation__name}
                  data-cardSettings={SP_CARD_DEFAULT.MATCHING_PERCENTAGE}
                >
                  {!item?.isUnsearchable
                    ? item?.matching_percentage
                      ? `${item?.matching_percentage}%`
                      : ''
                    : ''}
                </div>
              </div>
            </div>

            <div className="mb-1" data-cardSettings={SP_CARD_DEFAULT.POSITION}>
              <span
                style={{
                  fontWeight: '500',
                  fontSize: '14px',
                  lineHeight: '14px',
                  color: '#3d3d3d',
                }}
              >
                {item?.position_name}
              </span>
            </div>
            <div style={{ fontSize: '12px' }}>
              <div className="mb-1" data-cardSettings={SP_CARD_DEFAULT.BUSINESS}>
                <span>Business: </span>
                <span style={{ fontWeight: '500' }}>{item?.business_unit}</span>
              </div>
              <div className="mb-1" data-cardSettings={SP_CARD_DEFAULT.SECTOR}>
                <span>Sector: </span>
                <span style={{ fontWeight: '500' }}>{item?.sector}</span>
              </div>
              <div className="mb-1" data-cardSettings={SP_CARD_DEFAULT.OPU}>
                <span>OPU: </span>
                <span style={{ fontWeight: '500' }}>{item?.opu}</span>
              </div>
              <div className="mb-1" data-cardSettings={SP_CARD_DEFAULT.DIVISION}>
                <span>Division: </span>
                <span style={{ fontWeight: '500' }}>{item?.division}</span>
              </div>
              <div className="mb-1" data-cardSettings={SP_CARD_DEFAULT.DEPARTMENT}>
                <span>Department: </span>
                <span style={{ fontWeight: '500' }}>{item?.department_name}</span>
              </div>
            </div>
            <div
              className="mb-1 d-flex justify-content-start"
              style={{ gap: '10px', flexWrap: 'wrap', alignItems: 'center' }}
            >
              <div data-cardSettings={SP_CARD_DEFAULT.STAFF_ID}>
                <span>ID: </span>
                <span style={{ fontWeight: 'bold' }}>
                  {' '}
                  {item?.identity_number || item?.staff_id}
                </span>
              </div>
              {!item?.isUnsearchable && (
                <>
                  {lineSettings
                    .map((i) => i.value)
                    .some((l) =>
                      [
                        SP_CARD_DEFAULT.FIRST_LINE,
                        SP_CARD_DEFAULT.SECOND_LINE,
                        SP_CARD_DEFAULT.THIRD_LINE,
                      ].includes(l)
                    ) && (
                    <Tooltip
                      placement="bottom"
                      title={handleShowTextTooltip(item, positions, lineSettings)}
                      color={'black'}
                      key={'black'}
                      overlayInnerStyle={{
                        borderRadius: '6px',
                        minWidth: 'max-content',
                        padding: '12px',
                        width: '100%',
                      }}
                      className={styles.sp_counter}
                    >
                      {handleShowTooltip(item, positions, lineSettings)}
                    </Tooltip>
                  )}
                </>
              )}
              <div data-cardSettings={SP_CARD_DEFAULT.APPROVAL_STATUS}>
                <ApprovedLabel positions={positions} item={item} isOldUI={true} />
              </div>
              {agenda_as_main && agenda_as_main === item.agenda_as_main && (
                <Tooltip
                  overlayClassName={styles.tooltip}
                  placement="top"
                  title={
                    <div className={styles.platformName}>
                      <span>Platform Name: {agenda_as_main_platform}</span>
                      <br />
                      {agenda_as_main_start_at && (
                        <span>
                          Date:{' '}
                          {moment(agenda_as_main_start_at).format(DATE_TIME_FORMAT.DATE_SPACE)}
                        </span>
                      )}
                    </div>
                  }
                >
                  <div className={styles.mainAgendaStatus}>{agenda_as_main_type || 'N/A'}</div>
                </Tooltip>
              )}
            </div>
          </div>
        </div>
        <div
          style={{
            border: '1px solid #CBD6E2',
            backgroundColor: '#F2F8FD',
            borderRadius: '6px',
            fontSize: '12px',
            padding: '10px',
          }}
          data-cardSettings={'border'}
        >
          {!item?.isUnsearchable && (
            <div className="mb-2 d-flex justify-content-start" style={{ gap: '20px' }}>
              <div data-cardSettings={SP_CARD_DEFAULT.YEAR_IN_POSITION}>
                <span>YIP: </span>
                <div style={{ fontWeight: 'bold' }}>
                  {handleShowYIPAndYISG(item?.year_in_position, item?.date_in_position)}
                </div>
              </div>
              <div data-cardSettings={SP_CARD_DEFAULT.YEAR_IN_SG}>
                <span>YISG: </span>
                <div style={{ fontWeight: 'bold' }}>
                  {item?.salary_grade} ({handleShowYIPAndYISG(item?.year_in_sg, item?.date_in_sg)})
                </div>
              </div>
            </div>
          )}
          <div className="mb-2 d-flex justify-content-start" style={{ gap: '28px' }}>
            <div data-cardSettings={SP_CARD_DEFAULT.AGE}>
              <span>Age: </span>
              <div style={{ fontWeight: 'bold' }}>{item?.age}</div>{' '}
            </div>
            {!item?.isUnsearchable && (
              <div data-cardSettings={SP_CARD_DEFAULT.RETIREMENT_DATE}>
                <span>Retirement: </span>
                <div style={{ fontWeight: 'bold' }}>
                  {handleShowRetirementDate(item.retirement_date)}
                  {handleShowRetirementForSP(item)}
                </div>
              </div>
            )}
            {!item?.isUnsearchable && (
              <div className="mb-2" data-cardSettings={SP_CARD_DEFAULT.PERFORMANCE_RATING}>
                <span>Performance Rating: </span>
                <div style={{ fontWeight: 'bold' }}>{item?.performance_rating}</div>
              </div>
            )}
          </div>
        </div>
      </div>
    </CardBoxWrapper>
  );
};

const GirdBoxPrintSP = (props) => {
  const { text, line, blockStyle, isPrinting } = props;

  return (
    <div
      className={`${styles.container} girdContainer`}
      style={{ border: 'none', maxHeight: '700px', overflow: 'hidden', overflowY: 'auto' }}
    >
      <table className={`${styles.collapse} ${styles.printTable}`} data-testid="box-line">
        <thead>
          <tr>
            <th colSpan={3}>
              <div
                className={styles.collapseHeader}
                style={{ backgroundColor: blockStyle.headerColor }}
              >
                <div data-testid="name-box-line" className={styles.left}>
                  {text}
                </div>
              </div>
            </th>
          </tr>
        </thead>
        <tbody
          style={{
            maxHeight: 670,
            overflow: 'hidden',
            overflowY: 'auto',
            verticalAlign: 'top',
          }}
        >
          {!isEmpty(line) ? (
            <>
              {line.map((item, index) => (
                <tr key={index}>
                  <td>
                    <GirdCardBoxPrintSP
                      item={item}
                      blockStyle={blockStyle}
                      index={index}
                      text={text}
                      isPrinting={isPrinting}
                    />
                  </td>
                </tr>
              ))}
            </>
          ) : (
            <div className="w-100" style={{ textAlign: 'center' }}>
              No data
            </div>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default GirdBoxPrintSP;
