import { notification } from 'antd';
import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';

import { FormInput, RoleTable } from '.';
import { pets_chevron_right } from '../../../../assets/img';
import GlobalAlertMessage from '../../../../components/GlobalAlertMessage/GlobalAlertMessage';
import { adminApi } from '../../../../services/admin';
import { pushMessage } from '../../../../store/alertMessageSlice';
import {
  ALERT_MESSAGE_TYPE,
  PERMISSION,
  USER_ACCESS_MESSAGE,
  USER_ID,
} from '../../../../utils/constants';
import styles from './create-user.module.scss';
import { WrapBtn } from './styled';

function validateUser(user) {
  if (!user.fullName || !user.staffId) return false;
}

export function CreateUser(props) {
  const dispatch = useDispatch();
  const { match } = props;
  const userId = match?.params?.userId;
  const [userInfo, setUserInfo] = useState({});
  const [isValid, setIsValid] = useState(true);
  const [selected, setSelected] = useState([]);
  const [roles, setRoles] = useState([]);
  const [rolesEdit, setRolesEdit] = useState([]);
  const history = useHistory();
  const roleActive = useSelector((state) => state.user.roleActive);
  const [inputEmail, setInputEmail] = useState('');
  const [messageError, setMessageError] = useState({
    status: false,
    message: '',
  });

  const handleShowMessage = (type, message) => {
    dispatch(
      pushMessage({
        type,
        message,
        timeShow: 3000,
        isScroll: true,
      })
    );
  };

  const fetchApiSaveUser = async () => {
    try {
      const rolePermission = {
        role: roleActive,
        id: USER_ID,
        permission: PERMISSION.SEARCH_USER_BY_EMAIL,
      };
      const body = {
        email: inputEmail,
        name: userInfo.fullName,
        staffId: userInfo.staffId,
        roles: roles,
      };
      const res = await adminApi.saveUser(rolePermission, body);
      if (res.status === 200) {
        notification.success({
          message: 'Created Successful !!',
          duration: 5,
        });
        history.push('/admin/user-access-management');
      }
    } catch (error) {
      if (error.response.status === 500 && error.response.data.errors === 'existed_object') {
        handleShowMessage(ALERT_MESSAGE_TYPE.ERROR, USER_ACCESS_MESSAGE.USER_EXIST_SYSTEM);
      } else {
        handleShowMessage(ALERT_MESSAGE_TYPE.ERROR, USER_ACCESS_MESSAGE.AN_OCCUR_ERROR);
      }
    }
  };

  const fetchApiSaveEditUser = async () => {
    try {
      const rolePermission = {
        role: roleActive,
        id: USER_ID,
        permission: PERMISSION.SEARCH_USER_BY_EMAIL,
      };
      const body = {
        roles: roles,
      };
      const res = await adminApi.saveEditUser(rolePermission, body, userId);
      if (res.status === 200) {
        notification.success({
          message: 'Updated Successful !!',
          duration: 5,
        });
        history.push('/admin/user-access-management');
      }
    } catch (error) {
      if (error.response.status === 500) {
        notification.error({
          message: error.response.data.errors,
          duration: 5,
        });
      } else {
        notification.error({
          message: 'An occur error',
          duration: 5,
        });
      }
    }
  };
  const onSaveUser = () => {
    if (!inputEmail.trim()) {
      setMessageError((prev) => {
        return { ...prev, status: true, email: 'Email is required' };
      });
      setInputEmail('');
      setUserInfo({});
    }
    const validate = validateUser(userInfo);
    if (validate === false) {
      setIsValid(false);
      return;
    }

    if (isEmpty(selected)) {
      notification.error({
        message: 'Please ensure at least one role is assigned to add user successfully',
        duration: 5,
      });
      return;
    }
    userId ? fetchApiSaveEditUser() : fetchApiSaveUser();
  };

  useEffect(() => {
    if (!userId) return;
    async function fetchApi() {
      try {
        const roles = {
          role: roleActive,
          id: USER_ID,
          permission: PERMISSION.SEARCH_USER_BY_EMAIL,
        };
        const res = await adminApi.getUserEdit(roles, userId);
        if (res.status === 200) {
          setInputEmail(res.data.result.email);
          setUserInfo({
            fullName: res.data.result.birth_name,
            staffId: res.data.result.staff_id,
            businessUnit: res.data.result.business_unit,
            jobFamily: res.data.result.job_family,
            positionName: res.data.result.position_name,
            company: res.data.result.opu_name,
            department: res.data.result.department_name,
            division: res.data.result.division,
            section: res.data.result.section,
            opu: res.data.result.opu_name,
            sector: res.data.result.sector,
            skg: res.data.result.skg_text,
          });
          setSelected(
            res.data.result.roles.map((item) => {
              return item.id;
            })
          );
          setRolesEdit(res.data.result.roles);
        }
      } catch (error) {
        console.error(error);
      }
    }
    fetchApi();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  return (
    <div className={styles.maxWidth} data-testid="upsert-user">
      <div className={styles.control}>
        <div className={styles.breadcrumb}>
          <p>Admin</p>
          <img src={pets_chevron_right} alt="pets_chevron_right" />
          <Link to="/admin/user-access-management">
            <p>User Access Management</p>
          </Link>
          <img src={pets_chevron_right} alt="pets_chevron_right" />
          <p className={styles.font_activate}>{!userId ? 'Create User' : 'Edit User'}</p>
        </div>
      </div>

      <GlobalAlertMessage style={{ margin: '25px 0 30px 0' }} />

      <div className={styles.control}>
        <div className={styles.title_page}>{!userId ? 'Create User' : 'Edit User'}</div>
      </div>

      <div className={styles.user}>
        <div className={`mb-3 ${styles.title}`}>
          {!userId ? 'Create Users Details' : 'Edit User Details'}
        </div>
        <FormInput
          userInfo={userInfo}
          setUserInfo={setUserInfo}
          isValid={isValid}
          setIsValid={setIsValid}
          inputEmail={inputEmail}
          setInputEmail={setInputEmail}
          userId={userId}
          setSelected={setSelected}
          setMessageError={setMessageError}
          messageError={messageError}
        />
        {!isEmpty(userInfo) && !userId && (
          <RoleTable
            userInfo={userInfo}
            selected={selected}
            setSelected={setSelected}
            setRoles={setRoles}
            rolesEdit={rolesEdit}
          />
        )}
        {!isEmpty(userInfo) && userId && !isEmpty(rolesEdit) && (
          <RoleTable
            userInfo={userInfo}
            selected={selected}
            setSelected={setSelected}
            setRoles={setRoles}
            rolesEdit={rolesEdit}
          />
        )}
      </div>

      <div className={styles.control}>
        <div className="d-flex justify-content-end">
          <div className="d-flex" style={{ gap: '8px' }}>
            <WrapBtn
              color="#00A19C"
              bg="#FFFFFF"
              onClick={() => history.push('/admin/user-access-management')}
            >
              Back
            </WrapBtn>
            <WrapBtn color="#FFFFFF" bg="#00A19C" onClick={onSaveUser} data-testid="click-save">
              Save Users
            </WrapBtn>
          </div>
        </div>
      </div>
    </div>
  );
}
