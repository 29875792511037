import { Spin } from 'antd';
import { cloneDeep, concat, get, isEmpty, isNaN, size } from 'lodash';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { AiButton, BasicButton } from '../../../assets/common';
import { AlertMessage, BreadCrumb, ModelTC, TalentComparison } from '../../../components';
import AlertNotification from '../../../components/AlertMessage/AlertNotification';
import GlobalAlertMessage from '../../../components/GlobalAlertMessage/GlobalAlertMessage';
import useConditionalMsRemark from '../../../hooks/useConditionalMsRemark';
import { useGetMeeting } from '../../../hooks/useGetMeeting';
import { useGetRoleInMeeting } from '../../../hooks/useGetRoleInMeeting';
import { useShowPopupSaveAgenda } from '../../../hooks/useShowPopupSaveAgenda';
import mobilitySimulationApi from '../../../services/mobilitySimulationApi';
import { meetingApi } from '../../../services/tcMeeting';
import { pushMessage } from '../../../store/alertMessageSlice';
import {
  AGENDA_STATUS,
  MEETING_DETAIL_STATUS,
  MESSAGE_TYPES,
  ROLE,
  ROLE_SG_MAPPING,
  ROUTE_BACK_HISTORY,
} from '../../../utils/constants';
import {
  convertDataChain,
  convertDataToUpdateMSAgenda,
  convertMobilitySimulationToViewAgenda,
  convertPositionSimulation,
  convertTalentSimulation,
  getDescForWithoutModalMS,
  handleShowMessageSuccessMS,
  isDifferentFieldMS,
} from '../../../utils/helper';
import { useAgendarControl } from '../../Meeting/AgendaDetails/AgendaDetails';
import RemarkTableDialog from '../../Meeting/AgendaDetails/components/RemarkTableDialog/RemarkTableDialog';
import {
  clearTemporaryIdMsAgenda,
  mobilitySimulationAgendaSave,
} from '../../Meeting/redux/agendaSlice';
import { clearRemarks, getRemarks } from '../../Meeting/redux/remarksSlice';
import MobilityChainSimulationAgenda from '../components//MobilityChainSimulationAgenda/MobilityChainSimulationAgenda';
import MobilityChainSimulationAgendaVertical from '../components//MobilityChainSimulationAgendaVertical/MobilityChainSimulationAgendaVertical';
import MobilityAgendaAddExist from '../components/MobilityAgendaAddExist/MobilityAgendaAddExist';
import MobilityAgendaForm from '../components/MobilityAgendaForm/MobilityAgendaForm';
import MobilityChainSimulation from '../components/MobilityChainSimulation/MobilityChainSimulation';
import MobilityDragDropAgenda from '../components/MobilityDragDropAgenda/MobilityDragDropAgenda';
import { MOBILITY_AGENDA_BREADCRUMB_LIST, MOBILITY_SIMULATION } from '../constants';
import { ModalMobilityPreview } from '../MobilitySimulationAdd/styled';
import * as styles from './mobility-agenda-add.module.scss';
import { addOrUpdateMeeting } from '../../Meeting/redux/meetingDetailSlice';

const initCreateMS = {
  isValidate: true,
  isShowMessage: {},
  name: 'Mobility Simulation',
  dataChain: [],
};

const initCompare = {
  title: '',
  start_time: undefined,
  end_time: undefined,
  advocators: [],
  hr_partners: [],
  remarks: '',
  ms_ids: [],
  all_remarks: [],
};

const MobilityAgendaAdd = () => {
  const history = useHistory();
  const { agendaId, idMeeting } = useParams();
  const refAgendaForm = useRef(null);
  const dispatch = useDispatch();
  const { data: meetingRole } = useGetRoleInMeeting({ idMeeting });
  const { data: meetingDetail } = useGetMeeting({ idMeeting });

  const roleActive = useSelector((state) => state.user.roleActive);
  const { createdAgenda, temporaryIdMsAgenda } = useSelector(
    (state) => state.app.meetingModule.agenda
  );
  const { createList, editList, deleteList } = useSelector(
    (state) => state.app.meetingModule.remarks
  );

  const [showExistMobility, setShowExistMobility] = useState(false);
  const [mobilitySimulations, setMobilitySimulations] = useState([]);
  const [mobilitySimulationIds, setMobilitySimulationIds] = useState([]);
  const [createdMS, setCreatedMS] = useState(initCreateMS);
  const [preview, setPreview] = useState([]);
  const [isCreateOrEdit, setIsCreateOrEdit] = useState(false);
  const [agendaDetail, setAgendaDetail] = useState(null);
  const [savingSimulation, setSavingSimulation] = useState(false);
  const [idDelete, setIdDelete] = useState('');
  const [loadingPage, setLoadingPage] = useState(false);
  const pageHistory = new URLSearchParams(window.location.search).get('history');
  const [isHighlighted, setIsHighlighted] = useState({ key: 0, position_code: 0 });
  const [isSeniorManager, setIsSeniorManager] = useState(false);

  // Alert state
  const [showValidateFormFail, setShowValidateFormFail] = useState(false);
  const [showWarningNullMS, setShowWarningNullMS] = useState(false);
  const [showSaveAsDraftSuccess, setShowSaveAsDraftSuccess] = useState(false);
  const [showWarningMaximum, setShowWarningMaximum] = useState(false);
  const [showSaveMobility, setShowSaveMobility] = useState(false);
  const [showUnExpected, setShowUnExpected] = useState(false);
  const [showPaperReadySuccess, setShowPaperReadySuccess] = useState(false);
  const [showFinalizeSuccess, setShowFinalizeSuccess] = useState(false);
  const [showUnFinalizeSuccess, setShowUnFinalizeSuccess] = useState(false);
  const [showWarningNotIsMain, setShowWarningNotIsMain] = useState(false);
  const [showWarningCannotDeleted, setShowWarningCannotDeleted] = useState(false);
  const [showReadyToEdit, setShowReadyToEdit] = useState(false);
  const [showAddRemarkSuccess, setShowAddRemarkSuccess] = useState(false);
  const [showDeleteRemarkSuccess, setShowDeleteRemarkSuccess] = useState(false);

  //Modal state
  const [showChangeToPaperReady, setShowChangeToPaperReady] = useState(false);
  const [showFinalize, setShowFinalize] = useState(false);
  const [showUnFinalize, setShowUnFinalize] = useState(false);
  const [showCompareTalent, setShowCompareTalent] = useState(false);
  const [modalSaveSimulation, setModalSaveSimulation] = useState(false);
  const [modalDeleteSimulation, setModalDeleteSimulation] = useState(false);
  const [showModalEditPaper, setShowModalEditPaper] = useState(false);

  const [showAllRemarks, setShowAllRemarks] = useState(false);
  const { showBtnAllRemarks, isCreateOrEditRemarks } = useConditionalMsRemark({
    meetingRole,
    meetingStatus: agendaDetail?.meeting_status,
    agendaStatus: agendaDetail?.status,
  });
  const [compareData, setCompareData] = useState({
    initData: initCompare,
    currentData: initCompare,
  });

  const isAdminRole = useMemo(() => {
    return roleActive?.roleName ? roleActive?.roleName.includes(ROLE.ADMIN) : false;
  }, [roleActive]);

  const isAdditionalViewAccess = useMemo(() => {
    if (!agendaId) return false;

    return (
      !meetingRole?.is_secretariat_or_facilitator &&
      !meetingRole?.is_chairman &&
      !meetingRole?.is_commitee &&
      !isAdminRole &&
      meetingRole?.is_additional_view_access
    );
  }, [meetingRole, agendaId, isAdminRole]);

  useEffect(() => {
    if (isEmpty(agendaDetail) && !agendaId) {
      setAgendaDetail(initCompare);
    } else {
      setCompareData({
        initData: agendaDetail,
        currentData: agendaDetail,
      });
    }
  }, [agendaDetail, agendaId]);

  useEffect(() => {
    if (isEmpty(agendaDetail)) return;
    const isExistCreate = Array.isArray(createList) ? createList : [];
    const isExistEdit = Array.isArray(editList) ? editList : [];
    const isExistDelete = Array.isArray(deleteList) ? deleteList : [];
    setCompareData((prev) => ({
      currentData: {
        ...prev.currentData,
        ms_ids: mobilitySimulationIds,
        all_remarks: [...isExistCreate, ...isExistEdit, ...isExistDelete],
      },
      initData: prev.initData,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(mobilitySimulationIds), agendaDetail, createList, editList, deleteList]);

  const { isShowPopupSaveAgenda, handleKeepNavigate, setIsShowPopupSaveAgenda } =
    useShowPopupSaveAgenda(!isEmpty(isDifferentFieldMS(compareData, agendaId)) || isCreateOrEdit);

  useEffect(() => {
    if (isCreateOrEdit && isShowPopupSaveAgenda) {
      showMessagePreventRedirect();
      setIsShowPopupSaveAgenda(false);
    }
  }, [isShowPopupSaveAgenda]);

  function showMessagePreventRedirect() {
    dispatch(
      pushMessage({
        message: "Please complete your changes by clicking 'Save Simulation' button.",
        type: MESSAGE_TYPES.WARNING,
        timeShow: 3000, //time ms before the message auto close, default is 5000, 0 mean do not auto close
        isScroll: true, //make your page scroll to the message auto, default is false
      })
    );
  }

  const isShowPaperReady = useMemo(
    () =>
      !isNaN(Number(agendaId)) &&
      agendaDetail?.status === AGENDA_STATUS.PAPER_IN_PROGRESS &&
      meetingRole?.is_secretariat_or_facilitator,
    [agendaId, agendaDetail, meetingRole]
  );
  const isShowEditPaper = useMemo(
    () =>
      !isNaN(Number(agendaId)) &&
      [MEETING_DETAIL_STATUS.IN_PLANNING, MEETING_DETAIL_STATUS.PUBLISHED].includes(
        agendaDetail?.meeting_status
      ) &&
      agendaDetail?.status === AGENDA_STATUS.PAPER_READY &&
      meetingRole?.is_secretariat_or_facilitator,
    [agendaId, agendaDetail, meetingRole]
  );

  const isShowFinalizeAndUnFinalize = useMemo(
    () =>
      agendaDetail?.meeting_status === MEETING_DETAIL_STATUS.IN_PROGRESS &&
      meetingRole?.is_secretariat_or_facilitator &&
      [AGENDA_STATUS.PAPER_READY, AGENDA_STATUS.PAPER_FINALIZED].includes(agendaDetail?.status),
    [agendaDetail, meetingRole]
  );
  const isShowCompareTalent = useMemo(() => {
    return !(
      [MEETING_DETAIL_STATUS.CONCLUDED].includes(agendaDetail?.meeting_status) ||
      ([MEETING_DETAIL_STATUS.IN_REVIEW].includes(agendaDetail?.meeting_status) &&
        !meetingRole?.is_secretariat_or_facilitator)
    );
  }, [agendaDetail, meetingRole]);

  const fetchMSAgenda = useCallback(async () => {
    try {
      setLoadingPage(true);
      const response = await meetingApi.getAgendaMS(agendaId, roleActive?.roleId);

      if (response.status === 200) {
        const revertData = convertMobilitySimulationToViewAgenda(response.data.result);
        const msIds = revertData.mobility_simulations.map((item) => ({
          id: item.mbsm_id,
          is_main: item.is_main,
        }));
        setAgendaDetail({ ...revertData, ms_ids: msIds, all_remarks: [] });
        setMobilitySimulationIds(msIds);
        setLoadingPage(false);
      }
    } catch (error) {
      setLoadingPage(false);
      return console.error(error);
    }
  }, [agendaId, roleActive]);

  useEffect(() => {
    if (!isNaN(Number(agendaId))) {
      fetchMSAgenda();
    } else if (agendaId) {
      const ms = createdAgenda.find((item) => item.agenda_id === agendaId);
      const msDetailInRedux = convertMobilitySimulationToViewAgenda(ms);
      let msIds = [];

      if (!isEmpty(msDetailInRedux.ms_ids)) {
        msIds = msDetailInRedux.ms_ids.map((item) => ({
          id: item.id,
          is_main: item.is_main,
        }));
      }

      setAgendaDetail({ ...msDetailInRedux, all_remarks: [] });
      setMobilitySimulationIds(msIds);
    }
  }, [agendaId, createdAgenda, fetchMSAgenda]);

  useEffect(() => {
    if (meetingDetail) {
      dispatch(
        addOrUpdateMeeting({
          ...meetingDetail,
        })
      );
    }
  }, [dispatch, meetingDetail]);

  const handleSaveAsDraft = async () => {
    const formValue = await refAgendaForm.current.handleSubmitForm();
    if (!formValue) {
      return setShowValidateFormFail(true);
    }
    if (isEmpty(mobilitySimulations)) {
      return setShowWarningNullMS(true);
    }
    if (
      [MEETING_DETAIL_STATUS.IN_REVIEW].includes(meetingDetail?.status) &&
      isEmpty(mobilitySimulationIds.filter((item) => item.is_main))
    ) {
      return setShowWarningNotIsMain(true);
    }
    if (!agendaId) {
      // Handle add new agenda or Handle add new agenda when click save as drafft more than 2 times.
      dispatch(
        mobilitySimulationAgendaSave({
          ...formValue,
          mobilitySimulationIds: mobilitySimulationIds,
          agenda_id: temporaryIdMsAgenda || '',
        })
      );
      setAgendaDetail({
        ...formValue,
        ms_ids: mobilitySimulationIds,
        agenda_id: temporaryIdMsAgenda || '',
        all_remarks: [],
      });
    } else if (isNaN(Number(agendaId))) {
      // Handle update in redux
      dispatch(
        mobilitySimulationAgendaSave({
          ...formValue,
          mobilitySimulationIds: mobilitySimulationIds,
          agenda_id: agendaId,
        })
      );
    } else {
      // Handle update agenda
      await fetchUpdateMSAgenda();
    }
    setShowSaveAsDraftSuccess(true);
  };

  const handleEditPaper = async () => {
    setShowModalEditPaper(false);
    try {
      const res = await meetingApi.updateStatusAgenda({
        agendaId,
        status: AGENDA_STATUS.PAPER_IN_PROGRESS,
      });
      if (res.status === 200) {
        await fetchMSAgenda();
        setShowReadyToEdit(true);
      }
    } catch (error) {
      console.error(error);
      setShowUnExpected(true);
    }
  };

  const fetchUpdateMSAgenda = async () => {
    const formValue = await refAgendaForm.current.handleSubmitForm();
    const newFormData = !isEmpty(formValue) ? formValue : agendaDetail;
    const body = convertDataToUpdateMSAgenda({
      ...newFormData,
      mobilitySimulationIds: mobilitySimulationIds,
    });
    try {
      const response = await meetingApi.updateAgendaMs(agendaId, body, roleActive?.roleId);
      // call add or update remark
      if (!isEmpty(createList)) {
        const headers = { role: roleActive };
        const remarkList = createList.map((item) => ({
          staff_id: item?.staffId,
          remark: item?.remarks,
          category: item?.remarksCategory,
          version: item?.version || 0,
          remark_type: item?.remarkType,
        }));
        await meetingApi.addRemarks(
          headers,
          createList[0].agendaId,
          createList[0].staffId,
          remarkList
        );
      }
      if (!isEmpty(editList)) {
        const apiList = editList.map((item) => {
          return meetingApi.updateRemarks({
            role: roleActive,
            remark_id: item.remarkId,
            body: item.body,
          });
        });
        await Promise.allSettled(apiList);
      }
      if (!isEmpty(deleteList)) {
        const apiList = deleteList.map((item) => {
          return meetingApi.deleteRemarks({
            role: roleActive,
            agenda_id: agendaId,
            remark_id: item.remarkId,
          });
        });
        await Promise.allSettled(apiList);
      }
      dispatch(clearRemarks());
      dispatch(
        getRemarks({
          role: roleActive,
          agenda_id: agendaId,
          version: agendaDetail?.version || 0,
        })
      );

      if (response.status === 200) {
        fetchMSAgenda();
        return true;
      }
    } catch (error) {
      return console.error(error);
    }
  };

  const handleCreate = () => {
    if (mobilitySimulationIds.length >= 3) {
      setShowWarningMaximum(true);
      return;
    }
    setIsCreateOrEdit(true);
    setIsSeniorManager(false);
  };

  const handleBack = () => {
    if (isEmpty(isDifferentFieldMS(compareData, agendaId))) {
      dispatch(clearTemporaryIdMsAgenda());
      dispatch(clearRemarks());
    }

    switch (pageHistory) {
      case ROUTE_BACK_HISTORY.MEETING_DETAIL:
        history.push(`/meeting/detail/${idMeeting}`);
        break;
      case ROUTE_BACK_HISTORY.MEETING_AGENDA_STEP2:
        history.push(`/meeting/${idMeeting}/agendas`);
        break;
      default:
        history.goBack();
        break;
    }
  };

  const handleAddExisting = () => {
    if (mobilitySimulationIds.length >= 3) {
      setShowWarningMaximum(true);
      return;
    }
    setShowExistMobility(true);
  };

  const removeItemOnChain = (index) => {
    let newArr = cloneDeep(createdMS).dataChain.slice(0, index);
    const lastItem = newArr[newArr.length - 1];
    setIsSeniorManager(false);
    setIsHighlighted({
      key: lastItem ? Number(lastItem.key) : 0,
      position_code: lastItem ? lastItem.position_code : 0,
    });

    return setCreatedMS((prev) => {
      return {
        ...prev,
        dataChain: newArr,
      };
    });
  };

  const handleShowPreview = () => {
    let data = cloneDeep(createdMS.dataChain);
    data = data.map((item) => {
      delete item.isRemove;
      return item;
    });
    setPreview(data);
  };

  const handleResetSimulation = () => {
    setIsHighlighted({ key: 0, position_code: 0 });
    setCreatedMS((prev) => {
      return {
        ...prev,
        dataChain: [],
      };
    });
    setIsSeniorManager(false);
  };

  const validateSaveSimulation = () => {
    if (isEmpty(createdMS.dataChain)) {
      setShowValidateFormFail(true);
      return;
    }
    setModalSaveSimulation(true);
  };

  const getErrorMessage = () => {
    dispatch(
      pushMessage({
        message: `An unexpected error occurred.`,
        type: MESSAGE_TYPES.ERROR,
        timeShow: 0,
      })
    );
  };

  const handleSaveSimulation = async () => {
    try {
      setLoadingPage(true);
      const body = {
        mbsm_id: createdMS?.mbsm_id || '',
        name: createdMS.name,
        position_code: createdMS.dataChain[0].position_code,
        talent_chain: convertDataChain(createdMS.dataChain),
        created_from: MOBILITY_SIMULATION.CREATED_FROM_AGENDA,
        is_main: false,
      };
      const res = createdMS?.mbsm_id
        ? !isNaN(Number(agendaId))
          ? await mobilitySimulationApi.editMobilitySimulation(
              { ...body, agenda_id: agendaId },
              roleActive
            )
          : await mobilitySimulationApi.editMobilitySimulation({ ...body }, roleActive)
        : await mobilitySimulationApi.saveMobilitySimulation(body, roleActive);
      const data = res.data.result;
      if (res.status === 200 && data?.mbsm_id) {
        if (!createdMS?.mbsm_id) {
          setMobilitySimulationIds((prev) => [
            ...prev,
            { id: data.mbsm_id, is_main: data.is_main },
          ]);
        } else {
          fetchMobilitySimulationsDetail();
        }
        setShowSaveMobility(true);
      }
    } catch (error) {
      console.error(error);
      getErrorMessage();
    } finally {
      setIsCreateOrEdit(false);
      setCreatedMS(initCreateMS);
      setModalSaveSimulation(false);
      setLoadingPage(false);
    }
  };

  const handleIsMain = (id) => {
    const newMainIds = mobilitySimulationIds.map((item) => ({
      id: item.id,
      is_main: item.id === id,
    }));
    setMobilitySimulationIds(newMainIds);
  };

  const fetchMobilitySimulationsDetail = async () => {
    const listMS = [];
    const unexpectedId = [];
    for (const item of mobilitySimulationIds) {
      try {
        setSavingSimulation(true);
        const res = await mobilitySimulationApi.getMobilityDetail(item.id, roleActive.id);
        if (res.status === 200) {
          listMS.push({ ...res.data.result, mbsm_id: item.id });
          const isSM = listMS.some(
            (x) =>
              x.talents.some((y) => y.role_level === ROLE_SG_MAPPING.SENIOR_MANAGER) ||
              x.position.some((z) => z.role_level === ROLE_SG_MAPPING.SENIOR_MANAGER)
          );
          if (isSM) {
            setIsSeniorManager(true);
          }
        }
      } catch (error) {
        console.error(error);
        setShowUnExpected(true);
        unexpectedId.push(item.id);
        continue;
      }
    }
    if (!isEmpty(unexpectedId)) {
      setMobilitySimulationIds(
        mobilitySimulationIds.filter((item) => !unexpectedId.includes(item.id))
      );
    }

    if (
      [MEETING_DETAIL_STATUS.CONCLUDED].includes(meetingDetail?.status) &&
      meetingDetail?.is_secretariat_or_facilitator
    ) {
      const mainItem = mobilitySimulationIds.filter((i) => i.id && i.is_main);
      setMobilitySimulations(listMS.filter((i) => i.mbsm_id === mainItem[0].id));
    } else {
      setMobilitySimulations(listMS);
    }

    setSavingSimulation(false);
  };

  useEffect(() => {
    fetchMobilitySimulationsDetail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mobilitySimulationIds.length]);

  const handleChangePaperReady = async () => {
    try {
      let response = await meetingApi.changeAgendaStatusById(agendaId);
      if (response.status === 200) {
        fetchMSAgenda();
        setShowPaperReadySuccess(true);
      }
    } catch (error) {
      console.error(error);
    }
    setShowChangeToPaperReady(false);
  };

  const handleClickFinalizeOrUnFinalize = () => {
    if (agendaDetail.status !== AGENDA_STATUS.PAPER_READY) {
      setShowUnFinalize(true);
      return;
    }
    const isMain = mobilitySimulationIds.filter((item) => item.is_main);
    if (isMain.length) {
      setShowFinalize(true);
    } else {
      setShowWarningNotIsMain(true);
    }
  };

  const handleFinalize = async () => {
    try {
      let response = await meetingApi.finalizeSPAgenda(agendaId);
      if (response.status === 200) {
        await fetchUpdateMSAgenda();
        setShowFinalizeSuccess(true);
      }
    } catch (error) {
      console.error(error);
    }
    setShowFinalize(false);
  };

  const handleUnFinalize = async () => {
    try {
      let response = await meetingApi.unfinalizeSPAgenda(agendaId);
      if (response.status === 200) {
        fetchMSAgenda();
        setShowUnFinalizeSuccess(true);
      }
    } catch (error) {
      console.error(error);
    }
    setShowUnFinalize(false);
  };

  const handleEditSimulation = async (mbsm_id) => {
    if (!mbsm_id) {
      return getErrorMessage();
    }

    try {
      const res = await mobilitySimulationApi.getMobilityDetail(mbsm_id, roleActive.id);
      let positions = [];
      let talents = [];
      if (!isEmpty(res.data.result.position)) {
        const objPosition = res.data.result.position[0];
        if (objPosition.role_level === 'Senior Manager') {
          setIsSeniorManager(true);
        }
        positions = res.data.result.position.map((item) => {
          return convertPositionSimulation(item);
        });
      }
      if (!isEmpty(res.data.result.talents)) {
        const arrTalent = res.data.result.talents;
        const isSM = arrTalent.some((x) => x.role_level === 'Senior Manager');
        if (isSM) {
          setIsSeniorManager(true);
        }
        talents = res.data.result.talents.map((item) => {
          return convertTalentSimulation(item);
        });
      }
      const dataChain = concat(positions, talents).map((item) => {
        return {
          ...item,
          isRemove: true,
          isDisableIndex: true,
        };
      });

      setCreatedMS({
        isValidate: true,
        isShowMessage: {},
        mbsm_id,
        name: res.data.result.name,
        dataChain,
        hrPartners: get(res, 'data.result.hr_partners')
          ? get(res, 'data.result.hr_partners').map((item) => {
              return { label: item.name, id: item.id, value: item.id };
            })
          : [],
      });
      const lastItem = dataChain[dataChain.length - 1];
      setIsHighlighted({
        key: lastItem ? Number(lastItem.key) : 0,
        position_code: lastItem ? lastItem.position_code : 0,
      });

      setIsCreateOrEdit(true);
    } catch (error) {
      console.error(error);
    }
  };

  const handleDeleteSimulation = (mbsm_id) => {
    if (
      [MEETING_DETAIL_STATUS.IN_REVIEW].includes(agendaDetail?.meeting_status) &&
      meetingRole?.is_secretariat_or_facilitator &&
      size(mobilitySimulationIds) === 1
    ) {
      setShowWarningCannotDeleted(true);
      return;
    }
    setModalDeleteSimulation(true);
    setIdDelete(mbsm_id);
  };

  useEffect(() => {
    if (!agendaId || (!agendaDetail?.version && agendaDetail?.version !== 0)) {
      dispatch(clearRemarks());
      return;
    }

    dispatch(
      getRemarks({
        role: roleActive,
        agenda_id: agendaId,
        version: agendaDetail?.version || 0,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agendaDetail?.version, agendaId]);

  // Get status of agenda
  const agendaStatus = get(agendaDetail, 'status');
  const { isDisabledByAgendaStatus, isDisplayFinalized } = useAgendarControl(agendaStatus);

  const isViewOnlyAgenda = useMemo(() => {
    return (
      (!meetingRole.is_secretariat_or_facilitator &&
        agendaId &&
        agendaDetail?.meeting_status !== MEETING_DETAIL_STATUS.IN_PLANNING) ||
      isAdditionalViewAccess
    );
  }, [meetingRole, agendaId, agendaDetail]);

  const isEditAgendaWithMeetingSecretariatFacilitator = useMemo(() => {
    if (meetingDetail && meetingDetail.is_secretariat_or_facilitator && agendaId) {
      return (
        (meetingDetail?.status === MEETING_DETAIL_STATUS.IN_PROGRESS ||
          (meetingDetail?.status === MEETING_DETAIL_STATUS.PUBLISHED &&
            agendaDetail?.status === AGENDA_STATUS.PAPER_IN_PROGRESS)) &&
        agendaDetail?.status === AGENDA_STATUS.PAPER_READY
      );
    }
  }, [meetingDetail, agendaId, agendaDetail]);

  const isEnabledEditModeAgenda = useMemo(() => {
    return (
      (!isDisabledByAgendaStatus && !isViewOnlyAgenda && !isDisplayFinalized) ||
      (meetingDetail?.is_secretariat_or_facilitator &&
        meetingDetail?.status === MEETING_DETAIL_STATUS.IN_REVIEW) ||
      (isEditAgendaWithMeetingSecretariatFacilitator && !isDisplayFinalized)
    );
  }, [
    isDisabledByAgendaStatus,
    isDisplayFinalized,
    isEditAgendaWithMeetingSecretariatFacilitator,
    isViewOnlyAgenda,
    meetingDetail,
  ]);

  return (
    <div className="max-width">
      <BreadCrumb level={2} breadCrumbList={MOBILITY_AGENDA_BREADCRUMB_LIST} />
      <h3 className={styles.title}>Mobility Discussion</h3>

      <>
        <GlobalAlertMessage style={{ margin: '25px 0 30px 0' }} />
        <AlertNotification
          type="success"
          message="Mobility Discussion is Ready to be edit"
          show={showReadyToEdit}
          setShow={setShowReadyToEdit}
          timeShow={5000}
          style={{ margin: '25px 0 30px 0' }}
        />
        <AlertNotification
          type="success"
          message="Talent Remarks added Successfully!"
          show={showAddRemarkSuccess}
          setShow={setShowAddRemarkSuccess}
          timeShow={5000}
          style={{ margin: '25px 0 30px 0' }}
        />
        <AlertNotification
          type="success"
          message="Remarks sucessfully deleted"
          show={showDeleteRemarkSuccess}
          setShow={setShowDeleteRemarkSuccess}
          timeShow={5000}
          style={{ margin: '25px 0 30px 0' }}
        />
        <AlertNotification
          type="error"
          message="An unexpected error occurred."
          show={showUnExpected}
          setShow={setShowUnExpected}
          timeShow={5000}
          style={{ margin: '25px 0 30px 0' }}
        />
        <AlertNotification
          type="error"
          message="There are items that require your attention. Please fill out this field."
          show={showValidateFormFail}
          setShow={setShowValidateFormFail}
          timeShow={5000}
          style={{ margin: '25px 0 30px 0' }}
        />
        <AlertNotification
          type="warning"
          message="Please add at least one Mobility Simulation in Agenda"
          show={showWarningNullMS}
          setShow={setShowWarningNullMS}
          timeShow={5000}
          style={{ margin: '25px 0 30px 0' }}
        />
        <AlertNotification
          type="error"
          message="Maximum 3 Mobility Simulation only in one Agenda"
          show={showWarningMaximum}
          setShow={setShowWarningMaximum}
          timeShow={5000}
          style={{ margin: '25px 0 30px 0' }}
        />
        <AlertNotification
          type="success"
          message="Mobility Simulation have been saved successfully"
          show={showSaveMobility}
          setShow={setShowSaveMobility}
          timeShow={5000}
          style={{ margin: '25px 0 30px 0' }}
        />
        <AlertNotification
          type="success"
          message="Agenda have been saved as draft"
          show={showSaveAsDraftSuccess}
          setShow={setShowSaveAsDraftSuccess}
          timeShow={5000}
          style={{ margin: '25px 0 30px 0' }}
        />
        <AlertNotification
          type="success"
          message="Mobility Discussion is ready for the meeting"
          show={showPaperReadySuccess}
          setShow={setShowPaperReadySuccess}
          timeShow={5000}
          style={{ margin: '25px 0 30px 0' }}
        />
        <AlertNotification
          type="success"
          message="Mobility Discussion has been successfully finalized"
          show={showFinalizeSuccess}
          setShow={setShowFinalizeSuccess}
          timeShow={5000}
          style={{ margin: '25px 0 30px 0' }}
        />
        <AlertNotification
          type="success"
          message="Mobility Discussion has been successfully unfinalized"
          show={showUnFinalizeSuccess}
          setShow={setShowUnFinalizeSuccess}
          timeShow={5000}
          style={{ margin: '25px 0 30px 0' }}
        />
        <AlertNotification
          type="warning"
          message="Please ensure only one Mobility Simulation is set as main in one Mobility Simulation"
          show={showWarningNotIsMain}
          setShow={setShowWarningNotIsMain}
          timeShow={5000}
          style={{ margin: '25px 0 30px 0' }}
        />
        <AlertNotification
          type="warning"
          message="Mobility Simulation cannot be deleted"
          show={showWarningCannotDeleted}
          setShow={setShowWarningCannotDeleted}
          timeShow={5000}
          style={{ margin: '25px 0 30px 0' }}
        />
        {!isEmpty(createdMS.isShowMessage) && (
          <div style={{ margin: '25px 0 30px 0' }}>
            <AlertMessage {...createdMS.isShowMessage} />
          </div>
        )}
      </>

      <div className={styles.controlBar}>
        <AiButton onClick={handleBack}>Back to Agenda</AiButton>
        {isEnabledEditModeAgenda && (
          <AiButton
            data-testid="save-as-draft"
            onKeyDown={() => {}}
            onClick={() => (isCreateOrEdit ? showMessagePreventRedirect() : handleSaveAsDraft())}
          >
            Save as Draft
          </AiButton>
        )}
        {isShowPaperReady && (
          <AiButton mode="teal" onClick={() => setShowChangeToPaperReady(true)}>
            Paper Ready
          </AiButton>
        )}
        {isShowEditPaper && (
          <AiButton onClick={() => setShowModalEditPaper(true)}>Edit Paper</AiButton>
        )}
        {isShowFinalizeAndUnFinalize && (
          <AiButton
            mode={agendaDetail.status === AGENDA_STATUS.PAPER_READY ? 'teal' : ''}
            className="ml-2"
            onClick={handleClickFinalizeOrUnFinalize}
          >
            {agendaDetail.status === AGENDA_STATUS.PAPER_READY ? `Finalize` : `Unfinalized`}
          </AiButton>
        )}
      </div>

      <Spin spinning={loadingPage || savingSimulation} size="large">
        <MobilityAgendaForm
          ref={refAgendaForm}
          agendaDetail={agendaDetail}
          setCompareData={setCompareData}
        />
        {!isCreateOrEdit && (
          <>
            {' '}
            <div
              className={`${styles.createMobilityBar} ${
                (mobilitySimulations.length || isCreateOrEdit) && styles.added
              }`}
            >
              <div className={styles.title}>Add or Create Mobility Simulation</div>
              <div className={styles.controlButton}>
                {showBtnAllRemarks && (
                  <AiButton border={true} onClick={() => setShowAllRemarks(true)}>
                    Show All Remarks
                  </AiButton>
                )}

                {isShowCompareTalent && (
                  <AiButton border={true} onClick={() => setShowCompareTalent(true)}>
                    Compare Talent
                  </AiButton>
                )}
                {(isEnabledEditModeAgenda || mobilitySimulations.length <= 0) && (
                  <>
                    <AiButton border={true} onClick={handleCreate}>
                      Create Mobility Simulation
                    </AiButton>
                    <AiButton mode="teal" onClick={handleAddExisting}>
                      Add Existing Mobility Simulation
                    </AiButton>
                  </>
                )}
              </div>
              {showExistMobility && (
                <MobilityAgendaAddExist
                  open={showExistMobility}
                  setOpen={setShowExistMobility}
                  setMobilitySimulationIds={setMobilitySimulationIds}
                  mobilitySimulationIds={mobilitySimulationIds}
                  setCreatedMS={setCreatedMS}
                  setIsCreateOrEdit={setIsCreateOrEdit}
                  removeItemOnChain={removeItemOnChain}
                  setLoadingPage={setLoadingPage}
                  loadingPage={loadingPage}
                  setIsHighlighted={setIsHighlighted}
                />
              )}
            </div>
            {!isEmpty(mobilitySimulations) &&
              (mobilitySimulations.length === 1 ||
              ([agendaDetail?.meeting_status].includes(MEETING_DETAIL_STATUS.IN_PROGRESS) &&
                [AGENDA_STATUS.PAPER_FINALIZED].includes(agendaDetail?.status)) ? (
                <MobilityChainSimulationAgenda
                  data={
                    mobilitySimulations.length === 1
                      ? mobilitySimulations[0]
                      : mobilitySimulations.find(
                          (item) => item.mbsm_id === mobilitySimulationIds.find((i) => i.is_main).id
                        )
                  }
                  handleIsMain={handleIsMain}
                  isMain={
                    mobilitySimulationIds.filter(
                      (i) => i.id === mobilitySimulations[0].mbsm_id && i.is_main
                    ).length !== 0
                  }
                  handleDeleteSimulation={handleDeleteSimulation}
                  isHiddenEditMSField={!isEnabledEditModeAgenda}
                  handleEditSimulation={handleEditSimulation}
                  isShowRemark={isCreateOrEditRemarks}
                  agendaDetail={agendaDetail}
                  setShowAddRemarkSuccess={setShowAddRemarkSuccess}
                />
              ) : (
                <div className={styles.listVerticalWrapper}>
                  {mobilitySimulations.map((item) => (
                    <MobilityChainSimulationAgendaVertical
                      key={item.mbsm_id}
                      data={item}
                      handleIsMain={handleIsMain}
                      isMain={
                        mobilitySimulationIds.filter((i) => i.id === item.mbsm_id && i.is_main)
                          .length !== 0
                      }
                      isHiddenEditMSField={!isEnabledEditModeAgenda}
                      handleEditSimulation={handleEditSimulation}
                      handleDeleteSimulation={handleDeleteSimulation}
                      isShowRemark={isCreateOrEditRemarks}
                      agendaDetail={agendaDetail}
                      setShowAddRemarkSuccess={setShowAddRemarkSuccess}
                    />
                  ))}
                </div>
              ))}
          </>
        )}

        {isCreateOrEdit && (
          <>
            <div className={styles.controlBar}>
              <BasicButton
                style={{ border: 'none', marginRight: '8px' }}
                disabled={isEmpty(createdMS.dataChain)}
                onClick={handleShowPreview}
              >
                Preview Simulation
              </BasicButton>
              <AiButton border={true} onClick={handleResetSimulation}>
                Reset
              </AiButton>
              <AiButton mode="teal" onClick={validateSaveSimulation}>
                Save Simulation
              </AiButton>
            </div>
            <MobilityDragDropAgenda
              setMobilitySimulation={setCreatedMS}
              mobilitySimulation={createdMS}
              handleShowMessageSuccess={(message) =>
                handleShowMessageSuccessMS(message, setCreatedMS)
              }
              removeItemOnChain={removeItemOnChain}
              isShowRemark={isCreateOrEditRemarks}
              agendaDetail={agendaDetail}
              setShowAddRemarkSuccess={setShowAddRemarkSuccess}
              isHighlighted={isHighlighted}
              setIsHighlighted={setIsHighlighted}
              setIsSeniorManager={setIsSeniorManager}
              isSeniorManager={isSeniorManager}
            />
            <ModalMobilityPreview
              title="Mobility Chain Simulation"
              width={1283}
              open={!isEmpty(preview)}
              footer={null}
              onCancel={() => setPreview([])}
            >
              <MobilityChainSimulation data={preview} />
            </ModalMobilityPreview>
          </>
        )}

        {showCompareTalent && (
          <TalentComparison
            talentProfileDetails={{}}
            isPopupTalentComparison={showCompareTalent}
            setIsPopupTalentComparison={setShowCompareTalent}
          />
        )}
        {showAllRemarks && (
          <RemarkTableDialog
            isRemark={showAllRemarks}
            setIsRemark={setShowAllRemarks}
            isViewModeAgenda={!isCreateOrEditRemarks}
            agendaDetail={agendaDetail}
            setShowDeleteRemarkSuccess={setShowDeleteRemarkSuccess}
          />
        )}
      </Spin>

      {/* Modal TC */}
      <ModelTC
        info={{
          type: 'changeToPaperReadyMobility',
          visible: showChangeToPaperReady,
          setVisible: setShowChangeToPaperReady,
          handleSubmit: handleChangePaperReady,
          onClose: () => setShowChangeToPaperReady(false),
        }}
      />
      <ModelTC
        info={{
          type: 'finalizeMSAgenda',
          visible: showFinalize,
          setVisible: setShowFinalize,
          handleSubmit: handleFinalize,
          onClose: () => setShowChangeToPaperReady(false),
        }}
      />
      <ModelTC
        info={{
          type: 'unFinalizeMSAgenda',
          visible: showUnFinalize,
          setVisible: setShowUnFinalize,
          handleSubmit: handleUnFinalize,
          onClose: () => setShowChangeToPaperReady(false),
        }}
      />
      <ModelTC
        info={{
          type: 'saveSimulation',
          visible: modalSaveSimulation,
          setVisible: setModalSaveSimulation,
          onClose: () => setModalSaveSimulation(false),
          handleSubmit: () => handleSaveSimulation(),
          loading: loadingPage,
        }}
      />
      <ModelTC
        info={{
          type: 'editPaper',
          visible: showModalEditPaper,
          setVisible: setShowModalEditPaper,
          onClose: () => setShowModalEditPaper(false),
          handleSubmit: handleEditPaper,
          loading: savingSimulation,
        }}
      />
      <ModelTC
        info={{
          type: 'deleteSimulationFromAgenda',
          visible: modalDeleteSimulation,
          setVisible: setModalDeleteSimulation,
          onClose: () => setModalDeleteSimulation(false),
          handleSubmit: async () => {
            try {
              setSavingSimulation(true);
              setModalDeleteSimulation(false);
              const res = await mobilitySimulationApi.delete(
                [idDelete],
                MOBILITY_SIMULATION.CREATED_FROM_AGENDA
              );
              if (res.status === 200) {
                setCompareData((prev) => ({
                  ...prev,
                  initData: {
                    ...prev.initData,
                    ms_ids: mobilitySimulationIds.filter((item) => item.id !== idDelete),
                  },
                }));
                setMobilitySimulationIds((prev) => {
                  return prev.filter((item) => item.id !== idDelete);
                });
                setModalDeleteSimulation(false);
                setIdDelete('');
              }
            } catch (error) {
              console.error(error);
              setModalDeleteSimulation(false);
              setIdDelete('');
            } finally {
              setSavingSimulation(false);
            }
          },
          loading: savingSimulation,
        }}
      />
      <ModelTC
        info={{
          type: agendaId ? 'withoutSavingAgenda' : 'leaveWhileAddAgenda',
          title: `You are about to leave this page without saving.`,
          desc: getDescForWithoutModalMS(isDifferentFieldMS(compareData, agendaId), agendaId),
          visible: isShowPopupSaveAgenda === true && !isCreateOrEdit ? true : false,
          setVisible: setIsShowPopupSaveAgenda,
          handleSubmit: async () => {
            const formValue = await refAgendaForm.current.handleSubmitForm();
            if (!formValue) {
              setIsShowPopupSaveAgenda(false);
              setShowValidateFormFail(true);
              return;
            }
            if (isEmpty(mobilitySimulations)) {
              setIsShowPopupSaveAgenda(false);
              setShowWarningNullMS(true);
              return;
            }
            if (
              [MEETING_DETAIL_STATUS.IN_REVIEW].includes(meetingDetail?.status) &&
              isEmpty(mobilitySimulationIds.filter((item) => item.is_main))
            ) {
              setIsShowPopupSaveAgenda(false);
              setShowWarningNotIsMain(true);
              return;
            }
            await handleSaveAsDraft();
            handleKeepNavigate();
            setIsShowPopupSaveAgenda(false);
          },
          onClose: () => {
            handleKeepNavigate();
            dispatch(clearTemporaryIdMsAgenda());
            dispatch(clearRemarks());
            setIsShowPopupSaveAgenda(false);
          },
        }}
      />
    </div>
  );
};

export default MobilityAgendaAdd;
