import { MsalProvider, useMsal } from '@azure/msal-react';
import { get } from 'lodash';
import moment from 'moment';
import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, Router, Switch, useLocation } from 'react-router-dom';

import { Layout } from './components';
import { LayoutPreivew } from './components/Layout/LayoutPreview';
import LayoutRoute from './layoutRoute';
import {
  AccessDenied,
  AgendaDetails,
  Agendas,
  CreateUser,
  // Dashboard,
  DashboardSP,
  ErrorLoginPage,
  LoginPage,
  Logout,
  MeetingConcludeConfirmScreen,
  MeetingConfirmScreen,
  MeetingDetails,
  MobilitySimulationAdd,
  OverallMeeting,
  RoleDetail,
  RoleList,
  SearchTalentHeader,
  SuccessionPlanningView,
  // SystemMaintenance,
  TalentCommittee,
  TalentFinder,
  TalentProfile,
  TalentProfileBlock,
  UserAccessList,
} from './pages';
import AuditLogs from './pages/Admin/AuditLogs/AuditLogs.';
import MasterDataManagement from './pages/Admin/MasterDataManagement/MasterDataManagement';
import ProfileManagement from './pages/Admin/ProfileManagement/ProfileManagement';
import SPManagement from './pages/Admin/SPManagement/SPManagement';
import HistoricalViewDetails from './pages/Dashboard/HistoricalDashboard/HistoricalTalentOverview/HistoricalViewDetails/HistoricalViewDetails';
import SuccessionPlanningDashboard from './pages/Dashboard/HistoricalDashboard/SuccessionPlanningDashboard/SuccessionPlanningDashboard';
import HelpDocument from './pages/HelpDocument/HelpDocument';
import ViewMeetingDetails from './pages/Meeting/MeetingDetails/ViewMeetingDetails';
import MobilityAgendaAdd from './pages/MobilitySimulation/MobilityAgendaAdd/MobilityAgendaAdd';
import MobilitySimulation from './pages/MobilitySimulation/MobilitySimulation';
import MobilitySimulationView from './pages/MobilitySimulation/MobilitySimulationView/MobilitySimulationView';
import ReportBuilder from './pages/ReportBuilder/ReportBuilder';
import ReportDetails from './pages/ReportBuilder/ReportDetails/ReportDetails';
import PositionBlock from './pages/SuccessionPlanningView/PositionBlock';
import { Preview } from './pages/TalentFinderPage/Preview/Preview';
import AddEditTalentReviewAgenda from './pages/TalentReviewAgenda/AddEditTalentReviewAgenda/AddEditTalentReviewAgenda';
import userApi from './services/userApi';
import { setTokenImage } from './store/userSlice';
import { DATE_TIME_FORMAT, PERMISSION } from './utils/constants';
import { confirmAutoLogout } from './utils/helper';
import { history } from './utils/history';
import Homepage from './pages/Homepage/Homepage';
import HomepageManagement from './pages/Admin/HomepageManagement/HomepageManagement';
import CreateEditNewsAnnouncement from './pages/Admin/HomepageManagement/NewsAndAnnouncement/CreateEditNewsAnnouncement/CreateEditNewsAnnouncement';
import NewsAndAnnouncements from './pages/NewsAndAnnouncements/NewsAndAnnouncements';
/** LAYOUTS */
/** PAGES */
const AnalyticSearchPage = React.lazy(() => import('./pages/AnalyticSearchPage'));

// One day Time in ms (milliseconds)
const midnight = '12:00 AM';
let now = null;

export const Pages = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { instance } = useMsal();
  const { tokenImage, createdTokenDate } = useSelector((state) => state.user);
  const isLogin = Boolean(Number(process.env.REACT_APP_BYPASS_LOGIN) || 0);
  const currentAccount = instance.getActiveAccount();
  const username = useSelector((state) => state.user.username);

  const getTokenImage = useCallback(async () => {
    try {
      const res = await userApi.getTokenImage();
      const imageToken = get(res, 'data.result');
      dispatch(setTokenImage(imageToken));
    } catch (error) {
      console.error(error);
      dispatch(setTokenImage(''));
    }
  }, [dispatch]);

  const checkTokenDate = useCallback(async () => {
    const createdTokenMoment = moment(createdTokenDate);
    const isSameDate = createdTokenMoment.isSame(moment(), 'day');
    if (isSameDate && tokenImage) return;
    const oneMinutesAfterCreated = moment(createdTokenDate).add(1, 'minutes');
    if (moment().isBefore(oneMinutesAfterCreated)) return;
    await getTokenImage();
  }, [createdTokenDate, getTokenImage, tokenImage]);

  useEffect(() => {
    if (!window.location.href.includes('/error', -1) && location.pathname !== '/admin')
      userApi.logAction({ url: window.location.href });
  }, [location.pathname]);

  useEffect(() => {
    if (location.pathname !== '/' && ((currentAccount?.name && username) || isLogin))
      checkTokenDate();
  }, [checkTokenDate, currentAccount, isLogin, location.pathname, username]);

  return (
    <Switch>
      <LayoutRoute
        exact
        requireAuth={false}
        path="/logout"
        layout={Layout} //Layout to be used to wrap the component
        component={Logout} //Component to be loaded for the given path
      />
      {/* <LayoutRoute
        exact
        requireAuth={true}
        path="/dashboard"
        layout={Layout}
        component={Dashboard}
      /> */}
      <LayoutRoute
        exact
        requireAuth={true}
        path="/calendar"
        layout={Layout}
        component={TalentCommittee}
      />
      <LayoutRoute
        exact
        requireAuth={true}
        path="/mobility-simulation"
        layout={Layout}
        component={MobilitySimulation}
      />
      <LayoutRoute
        exact
        requireAuth={true}
        path={[
          '/mobility-simulation/add',
          '/mobility-simulation/:idEdit/edit',
          '/mobility-simulation/:idDuplicate/duplicate',
        ]}
        layout={Layout}
        component={MobilitySimulationAdd}
      />
      <LayoutRoute
        exact
        requireAuth={true}
        path={['/mobility-simulations/:id/details/:message', '/mobility-simulations/:id/details']}
        layout={Layout}
        component={MobilitySimulationView}
      />
      {/* <Route exact requireAuth={false} path="/admin" component={SystemMaintenance} /> */}
      <LayoutRoute
        exact
        requireAuth={true}
        permission={PERMISSION.AD_USER_ACCESS_MANAGEMENT}
        path="/admin/user-access-management"
        layout={Layout}
        component={UserAccessList}
      />
      <LayoutRoute
        exact
        requireAuth={true}
        path="/admin/role-management"
        layout={Layout}
        component={RoleList}
        permission={PERMISSION.AD_ROLE_MANAGEMENT}
      />
      <LayoutRoute
        exact
        requireAuth={true}
        path="/admin/role-management/:roleId/:type"
        layout={Layout}
        component={RoleDetail}
        permission={PERMISSION.AD_ROLE_MANAGEMENT}
      />
      <LayoutRoute
        exact
        requireAuth={true}
        path="/profile/:id"
        layout={Layout}
        component={TalentProfile}
        permission={PERMISSION.VIEW_TALENT_PROFILE}
      />
      <LayoutRoute
        exact
        requireAuth={true}
        path="/profile-block"
        layout={Layout}
        component={TalentProfileBlock}
      />
      <LayoutRoute
        exact
        requireAuth={true}
        path="/search-talent-profile/:searchKey/:pageDefault/:mode"
        layout={Layout}
        component={SearchTalentHeader}
      />
      <LayoutRoute
        exact
        requireAuth={true}
        path="/talent-finder"
        layout={Layout}
        component={TalentFinder}
      />
      <LayoutRoute
        exact
        requireAuth={true}
        path="/succession-planning-page"
        layout={Layout}
        component={SuccessionPlanningView}
        permission={PERMISSION.SEARCH_VIEW_SP_DASHBOARD}
      />
      <LayoutRoute
        exact
        requireAuth={true}
        path="/agenda/succession-planning-dashboard/:positionCode"
        layout={Layout}
        component={DashboardSP}
        permission={PERMISSION.SEARCH_VIEW_SP_DASHBOARD}
      />
      <LayoutRoute
        exact
        requireAuth={true}
        path="/historical-approved-sp/:agendaId"
        layout={Layout}
        component={DashboardSP}
        permission={PERMISSION.SEARCH_VIEW_SP_DASHBOARD}
      />
      <LayoutRoute
        exact
        requireAuth={true}
        path="/position-block"
        layout={Layout}
        component={PositionBlock}
      />
      <LayoutRoute
        exact
        // requireAuth={true}
        path="/meeting/:idMeeting?"
        layout={Layout}
        component={MeetingDetails}
      />
      <LayoutRoute
        exact
        // requireAuth={true}
        path="/meeting/detail/:idMeeting"
        layout={Layout}
        component={ViewMeetingDetails}
      />
      <LayoutRoute path="/meeting/:idMeeting/agendas" layout={Layout} component={Agendas} />
      <LayoutRoute
        exact
        requireAuth={true}
        path="/meeting/:idMeeting/mobility-agenda/create"
        layout={Layout}
        component={MobilityAgendaAdd}
      />
      <LayoutRoute
        exact
        requireAuth={true}
        path="/meeting/:idMeeting/mobility-agenda/:agendaId"
        layout={Layout}
        component={MobilityAgendaAdd}
      />
      <LayoutRoute
        exact
        requireAuth={true}
        path="/meeting/:idMeeting/talent-review-agenda/create"
        layout={Layout}
        component={AddEditTalentReviewAgenda}
      />
      <LayoutRoute
        exact
        requireAuth={true}
        path="/meeting/:idMeeting/talent-review-agenda/:agendaId"
        layout={Layout}
        component={AddEditTalentReviewAgenda}
      />
      <LayoutRoute
        exact
        // requireAuth={true}
        path="/meeting/:idMeeting/agenda/create"
        layout={Layout}
        component={AgendaDetails}
      />
      <LayoutRoute
        exact
        // requireAuth={true}
        path="/meeting/:idMeeting/agenda/:agendaId"
        layout={Layout}
        component={AgendaDetails}
      />
      <LayoutRoute
        exact
        // requireAuth={true}
        path="/meeting/:idMeeting/agenda/:agendaId/versions/:versionId"
        layout={Layout}
        component={AgendaDetails}
        componentProps={{ isReadOnly: true }}
      />
      <LayoutRoute
        exact
        // requireAuth={true}
        path="/meeting/overall/:idMeeting"
        layout={Layout}
        component={OverallMeeting}
      />
      {/* <Route exact requireAuth={false} path="/" component={SystemMaintenance} /> */}
      <Route exact requireAuth={false} path="/" layout={Layout} component={LoginPage} />
      <LayoutRoute
        exact
        requireAuth={true}
        path="/access-denied"
        layout={Layout}
        component={AccessDenied}
      />
      <LayoutRoute
        exact
        requireAuth={false}
        path="/error"
        layout={Layout}
        component={ErrorLoginPage}
      />
      <LayoutRoute
        exact
        requireAuth={true}
        path="/admin/user-access-management/create-user"
        layout={Layout}
        component={CreateUser}
        permission={PERMISSION.AD_USER_ACCESS_MANAGEMENT}
      />
      <LayoutRoute
        exact
        requireAuth={true}
        path="/admin/user-access-management/:userId/edit"
        layout={Layout}
        component={CreateUser}
        permission={PERMISSION.AD_USER_ACCESS_MANAGEMENT}
      />
      <LayoutRoute
        exact
        requireAuth={true}
        path="/meeting-in-review/:meetingId"
        layout={Layout}
        component={MeetingConfirmScreen}
      />
      <LayoutRoute
        exact
        requireAuth={true}
        path="/meeting-conclude"
        layout={Layout}
        component={MeetingConcludeConfirmScreen}
      />
      <LayoutRoute
        exact
        requireAuth={true}
        path="/preview"
        layout={LayoutPreivew}
        component={Preview}
      />
      <LayoutRoute
        exact
        requireAuth={true}
        path={['/analytics/positions/:typePosition', '/analytics/top-talent/:typeTalent']}
        layout={Layout}
        component={AnalyticSearchPage}
      />
      <LayoutRoute
        // exact
        requireAuth={true}
        path="/admin/profile-management"
        layout={Layout}
        component={ProfileManagement}
      />
      <LayoutRoute
        // exact
        requireAuth={true}
        path="/admin/sp-management"
        layout={Layout}
        component={SPManagement}
      />
      <LayoutRoute
        exact
        requireAuth={true}
        path="/admin/audit-logs"
        layout={Layout}
        component={AuditLogs}
      />
      <LayoutRoute
        exact
        requireAuth={true}
        path="/admin/home-management/:page"
        layout={Layout}
        component={HomepageManagement}
      />
      <LayoutRoute
        requireAuth={true}
        path="/admin/master-data-management"
        layout={Layout}
        component={MasterDataManagement}
      />
      <LayoutRoute
        requireAuth={true}
        path="/help-document"
        layout={Layout}
        component={HelpDocument}
      />
      <LayoutRoute
        requireAuth={true}
        path="/dashboard/succession-planning/:type/:year/:month/:type_position/:role"
        layout={Layout}
        component={SuccessionPlanningDashboard}
      />
      <LayoutRoute
        exact
        requireAuth={true}
        path="/dashboard/talent-overview-details/:typeOfTalent/:year/:month/:companyFilterType/:companyFilterValue/:talentFilter/:companyFilterLabel/:companyFilterValueLabel"
        layout={Layout}
        component={HistoricalViewDetails}
      />
      <LayoutRoute
        exact
        requireAuth={true}
        path="/report-builder"
        layout={Layout}
        component={ReportBuilder}
      />
      <LayoutRoute
        exact
        requireAuth={true}
        path="/report-builder/:reportId"
        layout={Layout}
        component={ReportDetails}
      />
      <LayoutRoute
        exact
        requireAuth={true}
        path="/report-builder/:isEdit/:reportId"
        layout={Layout}
        component={ReportDetails}
      />
      <LayoutRoute exact requireAuth={true} path="/homepage" layout={Layout} component={Homepage} />
      <LayoutRoute
        exact
        requireAuth={true}
        path="/home/news-announcement-list"
        layout={Layout}
        component={NewsAndAnnouncements}
      />
      <LayoutRoute
        exact
        requireAuth={true}
        path="/admin/home-management/news-announcement/create"
        layout={Layout}
        component={CreateEditNewsAnnouncement}
      />
      <Redirect to={{ pathname: '/' }}></Redirect>
    </Switch>
  );
};

const App = ({ instance }) => {
  const handleAutoLogout = useCallback(() => {
    now = moment().format(DATE_TIME_FORMAT.TIME);
    if (now === midnight) {
      instance.logoutRedirect({ postLogoutRedirectUri: '/' });
      confirmAutoLogout();
    }
  }, [instance]);

  useEffect(() => {
    let intervalId = setInterval(handleAutoLogout, 1000);
    return () => {
      clearInterval(intervalId);
    };
  }, [handleAutoLogout]);

  return (
    <Router history={history}>
      <MsalProvider instance={instance}>
        <Pages />
      </MsalProvider>
    </Router>
  );
};

export default App;
