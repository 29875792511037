import React from 'react';
import { meeting_filter_arrow_down, search_dropdown } from '../../assets/img';
import SearchBusinessMeetingFilter from '../SearchBusinessMeetingFilter/SearchBusinessMeetingFilter';

const SearchFilterMeeting = (props) => {
  const { business, category, setCategory } = props;

  const columns = ['Business', 'OPU', 'Division', 'Sector'].includes(business)
    ? ['Business', 'Sector', 'OPU', 'Division']
    : business === 'SKG'
    ? ['SKG']
    : business === 'Job Family'
    ? ['Job Family']
    : [];

  return (
    <SearchBusinessMeetingFilter
      selected={category.value}
      setSelected={(value) => setCategory((prev) => ({ ...prev, value }))}
      columns={columns}
      business={business}
      uiDisplay={
        <div className="today" onKeyDown={() => {}} style={{ width: '250px' }}>
          <img src={search_dropdown} alt="search" />
          <span
            style={{
              width: '150px',
              textAlign: 'left',
              fontWeight: '400',
              fontSize: '14px',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              display: 'inline-block',
            }}
          >
            {category.value ? category.value : `Search By ${business}`}
          </span>
          <img src={meeting_filter_arrow_down} alt="arrow" width={15} height={15} />
        </div>
      }
    />
  );
};

export default SearchFilterMeeting;
