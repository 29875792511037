import React, { useEffect, useState } from 'react';
import AsyncSelect from 'react-select/async';
import { SearchAsyncWrap, stylesDanger, stylesDisabled, stylesNormal, theme } from './Styled';
import { Rectangle } from '../../../assets/img';
import { isEmpty } from 'lodash';

const AsyncMultipleSelect = ({
  loadOptions,
  placeholder,
  onChange,
  value,
  status,
  isDisabled,
  isMulti = true,
  className,
}) => {
  const [currentStatus, setCurrentStatus] = useState(stylesNormal);
  useEffect(() => {
    setCurrentStatus(status === 'error' ? stylesDanger : stylesNormal);
  }, [status]);
  return (
    <SearchAsyncWrap>
      <AsyncSelect
        isDisabled={isDisabled}
        cacheOptions
        isMulti={isMulti}
        isSearchable
        placeholder={placeholder}
        styles={isDisabled ? stylesDisabled : currentStatus}
        theme={theme}
        loadOptions={loadOptions}
        onChange={onChange}
        value={value}
        menuPlacement="auto"
        maxMenuHeight={180}
        components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
        className={className}
      />
      {isEmpty(value) && <img className={'down_icon'} src={Rectangle} alt="" />}
    </SearchAsyncWrap>
  );
};
export default AsyncMultipleSelect;
