import __, { find, isEmpty, some, toString } from 'lodash';
import moment from 'moment';
import React, { useMemo } from 'react';
import styles from './box.module.scss';
import { DATE_TIME_FORMAT } from '../../../../../../utils/constants';
import { Tooltip } from 'antd';

const APPROVED_TYPE = {
  TR: 'tr',
  SP: 'sp',
};

// eslint-disable-next-line react/display-name
const Label = React.memo(
  ({ isApproved, talentReviewDate, approvedDate, talent_review_platform, isOldUI, blockStyle }) => {
    const approveType = useMemo(() => {
      if (isApproved && (talentReviewDate || approvedDate)) {
        return moment(moment(talentReviewDate).format('YYYY-MM-DD')).isAfter(
          moment(approvedDate).format('YYYY-MM-DD')
        )
          ? APPROVED_TYPE.TR
          : APPROVED_TYPE.SP;
      }
      return '';
    }, [approvedDate, isApproved, talentReviewDate]);

    const toolTipTitle = useMemo(
      () =>
        approveType === APPROVED_TYPE.TR && !isOldUI ? (
          <div style={{ fontFamily: "'Inter', sans-serif" }}>
            <span style={{ fontWeight: 600 }}>Talent Review Platform Name: </span>
            <span style={{ fontWeight: 400 }}>{talent_review_platform}</span>
          </div>
        ) : (
          ''
        ),
      [approveType, talent_review_platform, isOldUI]
    );
    return (
      <>
        <Tooltip
          placement="top"
          title={toolTipTitle}
          color={'black'}
          key={'black'}
          overlayInnerStyle={{ borderRadius: '6px' }}
          data-testid="show-tooltip-platform"
        >
          <div
            className={styles.approve_label}
            style={
              blockStyle
                ? {
                    fontSize: `10px`,
                    fontWeight: 500,
                    color: blockStyle.typeTextColor,
                    backgroundColor: blockStyle.typeColor,
                    borderColor: `transparent`,
                    marginBottom: '5px',
                  }
                : {
                    color: isApproved ? '#007874' : '#5B3075',
                    backgroundColor: isApproved ? '#E0F3F3' : '#EBE1F1',
                  }
            }
          >
            {!isApproved && 'New'}
            {approveType && (
              <>
                {isOldUI ? (
                  'Approved '
                ) : (
                  <>{approveType === APPROVED_TYPE.SP ? 'Approved SP ' : 'Approved TR '}</>
                )}
                <>
                  (
                  {moment(
                    approveType === APPROVED_TYPE.TR ? talentReviewDate : approvedDate
                  ).format(DATE_TIME_FORMAT.DATE_SPACE)}
                  )
                </>
              </>
            )}
          </div>
        </Tooltip>
      </>
    );
  }
);

const isAnyEmpty = (...arr) => some(arr, isEmpty);

const convertDate = (target) => (isEmpty(target) ? null : moment(target, true).toDate());

const isEqualPostions = (position1, position2) => toString(position1) === toString(position2);

const includesPosition = (compared_positions, position_code) =>
  some(compared_positions, ({ position_code: comparedPositionCode }) =>
    isEqualPostions(comparedPositionCode, position_code)
  );

const getLastedApprovedPosition = ({ positions, compared_positions }) =>
  __(positions)
    .filter(({ position_code }) => includesPosition(compared_positions, position_code))
    .maxBy(({ last_approved_date }) => convertDate(last_approved_date));

// eslint-disable-next-line react/display-name
export const ApprovedLabel = React.memo(({ positions, item, isOldUI, blockStyle }) => {
  const { compared_positions, talent_review_platform } = item;
  const lastedApprovedPosition = useMemo(
    () =>
      isAnyEmpty(positions, compared_positions)
        ? null
        : getLastedApprovedPosition({ positions, compared_positions }),
    [positions, compared_positions]
  );

  if (!lastedApprovedPosition?.last_approved_date) {
    return <Label isApproved={false} blockStyle={blockStyle} />;
  }

  const { approved_date, talent_review_date } = find(compared_positions, ({ position_code }) =>
    isEqualPostions(position_code, lastedApprovedPosition.position_code)
  );

  const approvedDate = convertDate(approved_date);
  const talentReviewDate = convertDate(talent_review_date);
  const isApproved = moment(lastedApprovedPosition.last_approved_date).isSame(
    approvedDate,
    'second'
  );

  return (
    <Label
      isApproved={isApproved}
      talentReviewDate={talentReviewDate}
      approvedDate={approvedDate}
      talent_review_platform={talent_review_platform}
      isOldUI={isOldUI}
      blockStyle={blockStyle}
    />
  );
});
