import { isEmpty } from 'lodash';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';

import { document_icon, sp_trash, upload_icon } from '../../../../assets/img';
import { ModelTC } from '../../../../components';
import { useGetMeeting } from '../../../../hooks/useGetMeeting';
import { meetingApi } from '../../../../services/tcMeeting';
import {
  DocumentIcon,
  DragDropText,
  DragDropTextLimit,
  FilePreviewContainer,
  FileUploadContainer,
  FileUploadTitle,
  FormField,
  ProgressContainer,
  ProgressContent,
  TrashIcon,
  UploadFileBtn,
  UploadFileProgress,
  UploadIcon,
} from './Styled';
import { useShowAlertWhenRefresh } from '../../../../hooks/useShowAlertWhenRefresh';
import { useShowAlertUploadDownloadWhenNavigate } from '../../../../hooks/useShowAlertUploadDownloadWhenNavigate';
import { useDispatch, useSelector } from 'react-redux';
import { STATUS_UPLOAD } from '../../../../utils/constants';
import {
  clearDataCancelUploadMoM,
  setDataCancelUploadMoM,
} from '../../../../store/cancelUploadSlice';

const DEFAULT_MAX_FILE_SIZE_IN_BYTES = 1024 * 1024 * 200;

const FileUpload = ({
  updateFilesCb,
  setFileUploadStatus,
  setFileSizeUpload,
  maxFileSizeInBytes = DEFAULT_MAX_FILE_SIZE_IN_BYTES,
  isClickedSave,
  ...otherProps
}) => {
  const fileInputField = useRef(null);
  const [files, setFiles] = useState([]);
  const [fileUrl, setFileUrl] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [visibleDeleteMoM, setVisibleDeleteMoM] = useState(false);
  const dispatch = useDispatch();
  const { uploadMoM } = useSelector((state) => state.cancelUpload);

  const { idMeeting } = useParams();
  const { data: meetingDetail } = useGetMeeting({ idMeeting });

  const { setIsUploadingOrDownloading } = useShowAlertUploadDownloadWhenNavigate('upload');
  const { setShowAlertRefresh } = useShowAlertWhenRefresh();

  const handleUploadBtnClick = () => {
    fileInputField.current.click();
  };

  const callUpdateFilesCb = (url) => {
    setFileUrl(url);
    updateFilesCb(url);
  };

  const handleNewFileUpload = (e) => {
    const { files: newFiles } = e.target;
    if (newFiles.length <= 0) {
      return;
    }

    const isCheck = handleCheckFile(newFiles[0]);
    if (!isCheck) {
      setFiles([newFiles[0]]);
    } else {
      handleResetFileUpload();
    }
  };

  const handleDeleteMoM = async () => {
    if (isEmpty(idMeeting)) {
      return;
    }

    try {
      setLoading(true);
      const response = await meetingApi.deleteMoMForMeeting(idMeeting);
      if (response) {
        handleResetFileUpload();
        setVisibleDeleteMoM(false);
        handleSetFileError(false, false, false, false, true);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleResetFileUpload = () => {
    setFiles([]);
    setFileUrl(null);
    updateFilesCb(null);
  };

  const handleCheckFile = (file) => {
    if (file && file.size > maxFileSizeInBytes) {
      setFileSizeUpload(file.size);
      handleSetFileError(false, false, false, true, false);
      return true;
    }
    if (file && file.name && !file.name.toLowerCase().includes('.pdf')) {
      handleSetFileError(false, false, true, false, false);
      return true;
    }
    return false;
  };

  const handleSetFileError = (
    isUploadSuccess,
    isUploadError,
    notPdfFile,
    limit200MB,
    isDeletedMoM,
    isExistFile
  ) => {
    setFileUploadStatus({
      isSuccess: isUploadSuccess,
      hasError: isUploadError,
      notPdfFile,
      limit200MB,
      isDeletedMoM,
      isExistFile,
    });
  };

  useEffect(() => {
    if (!uploadMoM || uploadMoM.meeting_id !== idMeeting || !uploadMoM.is_refresh) return;
    const bodyUploading = {
      meeting_id: idMeeting,
      status: STATUS_UPLOAD.CANCEL,
      file_name: uploadMoM.file_name,
    };
    dispatch(clearDataCancelUploadMoM());
    meetingApi.cancelUploadMoM(bodyUploading);
  }, [uploadMoM, idMeeting, dispatch]);

  const fetch = useCallback(
    async (file, source) => {
      try {
        setLoading(true);
        setIsUploadingOrDownloading(true);
        setShowAlertRefresh(true);
        dispatch(setDataCancelUploadMoM({ meeting_id: idMeeting, file_name: file.name }));
        const body = {
          meeting_id: idMeeting,
          file,
        };
        const response = await meetingApi.uploadMoMForMeeting(body, source);
        if (response && response.data.result) {
          callUpdateFilesCb(response.data.result.url);
          dispatch(clearDataCancelUploadMoM());
        }
        handleSetFileError(true, false, false, false);
      } catch (error) {
        if (axios.isCancel(error)) {
          const bodyCancelled = {
            meeting_id: idMeeting,
            status: STATUS_UPLOAD.CANCEL,
            file_name: file.name,
          };
          meetingApi.cancelUploadMoM(bodyCancelled);
        }
        dispatch(clearDataCancelUploadMoM());
        handleSetFileError(false, true, false, false);
      } finally {
        setLoading(false);
        setIsUploadingOrDownloading(false);
        setShowAlertRefresh(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [idMeeting]
  );

  useEffect(() => {
    const fileUpload = files[0];
    if (!fileUpload) {
      return;
    }
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    fetch(fileUpload, source);
    return () => {
      source.cancel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idMeeting, files]);

  useEffect(() => {
    if (meetingDetail) {
      setFileUrl(meetingDetail?.file_url);
      updateFilesCb(meetingDetail?.file_url);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meetingDetail]);
  return (
    <>
      {isLoading ? (
        <FileUploadContainer>
          <div className="center-loading">
            <div className="spinner-border text-primary" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        </FileUploadContainer>
      ) : (
        <>
          {!fileUrl ? (
            <FileUploadContainer error={isClickedSave}>
              <UploadIcon src={upload_icon} alt=""></UploadIcon>
              <DragDropText>Drag and drop your file here</DragDropText>
              <DragDropTextLimit>
                Limit {`200`}MB per file <br />
                e.g. : .pdf <br />
                <strong>OR</strong>
              </DragDropTextLimit>
              <UploadFileBtn
                type="button"
                onClick={handleUploadBtnClick}
                data-testid="fileUploadBtn"
              >
                <span>Browse File</span>
              </UploadFileBtn>
              <FormField
                type="file"
                ref={fileInputField}
                onChange={handleNewFileUpload}
                title=""
                value=""
                data-testid="fileUploadInput"
                {...otherProps}
              />
            </FileUploadContainer>
          ) : (
            <FilePreviewContainer>
              <ProgressContainer>
                <DocumentIcon src={document_icon} alt=""></DocumentIcon>
                <ProgressContent>
                  <FileUploadTitle>
                    <span className={'progress-file-name'}>{`Minute of Meeting.pdf`}</span>
                    <span className={'progress-time'}>
                      Time remaining: <strong>0 sec</strong>
                    </span>
                  </FileUploadTitle>
                  <UploadFileProgress>
                    <span style={{ width: '100%' }}></span>
                  </UploadFileProgress>
                </ProgressContent>
                <TrashIcon
                  src={sp_trash}
                  alt=""
                  onClick={() => setVisibleDeleteMoM(true)}
                  data-testid="deleteMoMBtn"
                ></TrashIcon>
              </ProgressContainer>
            </FilePreviewContainer>
          )}
        </>
      )}

      {/* ModelTC */}
      <ModelTC
        info={{
          type: 'deleteMoM',
          visible: visibleDeleteMoM,
          setVisible: setVisibleDeleteMoM,
          handleSubmit: handleDeleteMoM,
          onClose: () => {},
          loading: isLoading,
        }}
      />
    </>
  );
};

export default FileUpload;
