import { Alert } from 'antd';
import styled, { css } from 'styled-components';
import { color, font, fontSize } from '../../assets/common';
export const AiAlert = styled(Alert)`
  padding: 18px 23.33px 18px 41px;
  opacity: 100;
  transition-property: height, padding, border, opacity;
  transition-duration: 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  .ant-alert-icon {
    position: absolute;
    left: 11px;
    top: 20px;
  }

  ${(props) => {
    if (props.type === 'error') {
      return css`
        color: ${color.monza};
        background-color: #ffebee;
        border: 2px solid #bf0013;
        border-left: 4px solid;
      `;
    }
    if (props.type === 'success') {
      return css`
        color: ${color.camarone};
        background-color: #e5f4e7;
        border: 2px solid #007724;
        border-left: 4px solid;
      `;
    }

    if (props.type === 'warning') {
      return css`
        color: #fc930d;
        background-color: #fff7e5;
        border: 2px solid #fc930d;
        border-left: 4px solid;
      `;
    }

    if (props.type === 'normal') {
      return css`
        color: ${color.darkBlue2};
        background: #e2f1fc;
        border: 1px solid #003f9a;
        border-left: 4px solid;
      `;
    }
  }}
  .ant-alert-message {
    color: inherit;
    font-family: ${font.inter};
    font-weight: 500;
    font-size: ${fontSize.normal} !important;
    width: 100%;
    padding-left: 10px;
    word-break: break-word;
  }

  .ant-alert-description {
    color: inherit;
    font-family: ${font.inter};
    font-weight: 400;
    font-size: 13px;
    width: 100%;
    padding-left: 10px;
  }

  .anticon-close {
    svg {
      fill: ${(props) => {
        if (props.type === 'error') {
          return `${color.monza}`;
        }
        if (props.type === 'success') {
          return `${color.camarone}`;
        }
        if (props.type === 'warning') {
          return `#fc930d`;
        }
        if (props.type === 'normal') {
          return `${color.darkBlue2}`;
        }
      }};
    }
  }
`;
export const AiIconAlert = styled.img`
  .ant-alert-icon {
    width: 16.67px;
    height: 16.67px;
    margin-right: 13.76px;
  }
`;
export const AiAlertWrapper = styled.div`
  position: relative;

  .replace-msg {
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #fc930d;
    display: flex;
    align-items: center;
    position: absolute;
    top: 50%;
    right: 80px;
    transform: translateY(-50%);
    padding: 10px;
    cursor: pointer;
  }

  img {
    margin-left: 10px;
  }
`;
