/* eslint-disable react-hooks/exhaustive-deps */
import { Col, Dropdown, Pagination, Row, Space } from 'antd';
import { useFormik } from 'formik';
import __, {
  cloneDeep,
  concat,
  find,
  get,
  isArray,
  isEmpty,
  isNil,
  isObject,
  map,
  orderBy,
  size,
  unionBy,
} from 'lodash';
import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';

import { AiAlert, AiButton, AiLoading, AiSpin } from '../../../assets/common';
import { genarate_document, tc_meeting_breadcrumb } from '../../../assets/img';
import {
  AlertMessage,
  AsyncMultipleSelect,
  BreadCrumb,
  FieldError,
  ModalAddExistingAgenda,
  ModelTC,
} from '../../../components';
import GlobalAlertMessage from '../../../components/GlobalAlertMessage/GlobalAlertMessage';
import SearchByCategoryForTcMeeting from '../../../components/SearchByCategoryForTcMeeting/SearchByCategoryForTcMeeting';
import SearchBySecretariatForTcMeeting from '../../../components/SearchBySecretariatForTcMeeting/SearchBySecretariatForTcMeeting';
import { useCreateRemarkInMeeting } from '../../../hooks/useCreateRemarkInMeeting';
import { useDeleteMeetingRemark } from '../../../hooks/useDeleteMeetingRemark';
import { useFetch } from '../../../hooks/useFetch';
import { isCheckError } from '../../../hooks/useFormik';
import { useGetMeeting } from '../../../hooks/useGetMeeting';
import { useGetRoleInMeeting } from '../../../hooks/useGetRoleInMeeting';
// import { useSecretarialOptions } from '../../../hooks/useSecretarialOptions';
import { useShowAlertUploadDownloadWhenNavigate } from '../../../hooks/useShowAlertUploadDownloadWhenNavigate';
import { useShowAlertWhenRefresh } from '../../../hooks/useShowAlertWhenRefresh';
import { useShowPopupOtherAgenda } from '../../../hooks/useShowPopupOtherAgenda';
import { useUpdateMeeting } from '../../../hooks/useUpdateMeeting';
import { useUpdateRemarkInMeeting } from '../../../hooks/useUpdateRemarkInMeeting';
import mobilitySimulationApi from '../../../services/mobilitySimulationApi';
import { othersAgendaApi } from '../../../services/othersAgendaApi';
import talentProfileApi from '../../../services/talentProfiles';
import { talentReviewApi } from '../../../services/talentReviewApi';
import { meetingApi } from '../../../services/tcMeeting';
import { pushMessage } from '../../../store/alertMessageSlice';
import { resetAgendaDetail } from '../../../store/spTemplateSlice';
import {
  AGENDA_STATUS,
  DATE_TIME_FORMAT,
  FILL_OUT_REQUIRE_MESSAGE,
  GLOBAL_ALERT_MESSAGE_CONTAINER_ID,
  MEETING_CATEGORY_OPTIONS,
  MEETING_CATEGORY_VALUES,
  MEETING_DETAIL_STATUS,
  MEETING_MESSAGE,
  MEETING_ROLE_TYPES,
  MESSAGE_TYPES,
  NOTIFICATION_TYPE,
  PERMISSION,
  ROLE,
  ROUTE_BACK_HISTORY,
  USER_ACCESS_MESSAGE,
  USER_ID,
} from '../../../utils/constants';
import {
  convertDataOtherAgendaToUpdate,
  convertMobilitySimulationAgenda,
  convertMobilitySimulationToViewAgenda,
  convertTalentReviewAgendaToPost,
  getAsyncOptionAdvocatorsHrPartners,
  getBusinessIdToPopulateForAdditionalView,
  getDescForWithoutModalMeetingDetails,
  getDevelopmentPlanningData,
  getFieldValueForTCMeetingInFirstTime,
  getLabelNameForSecretariatOfTcMeeting,
  isDifferentFieldMeetingDetails,
  isExistDataNotSave,
} from '../../../utils/helper';
import { showNotification } from '../../../utils/notification';
import { AGENDA_TYPES, MOBILITY_SIMULATION } from '../../MobilitySimulation/constants';
import { AiContainerAgendas, AiTitleFlex } from '../Agendas/Styled';
import AgendaItem from '../components/AgendaItem/AgendaItem';
import FileUpload from '../components/FileUpload/FileUpload';
import MeetingStatus from '../components/MeetingStatus/MeetingStatus';
import ViewMoMFile from '../components/ViewMoMFile/ViewMoMFile';
import {
  clearAgendaState,
  removeAgenda,
  removeCreatedOrExistedAgenda,
  setInitialStateAgenda,
  updateRemoveAgendas,
} from '../redux/agendaSlice';
import {
  addOrUpdateMeeting,
  changeMeetingStatus,
  clearMeeting,
  initialStateMeeting,
  updateSubmittedFormChanges,
} from '../redux/meetingDetailSlice';
import { meetingStatusSelector } from '../selector';
import AddRemarkOtherAgenda from './components/AddRemark/AddRemarkOtherAgenda';
import EditRemarkOtherAgenda from './components/EditRemark/EditRemarkOtherAgenda';
import MeetingCategory from './components/MeetingCategory';
import TopButtonMeetingDetail from './components/TopButtonMeetingDetail/TopButtonMeetingDetail';
import ViewRemarks from './components/ViewRemarks/ViewRemarks';
import styles from './meeting-details.module.scss';
import { validationSchema } from './meeting-validations';
import {
  AiContainerForm,
  AiContainerGrBtn,
  AiDatePicker,
  AiGroupButton,
  AiH2,
  AiH3,
  AiInput,
  AiLabel,
  AiTimePickerField,
  AiTitleWrapper,
  CutomBtnWrapper,
  MeetingTitle,
  TopBtnWrapper,
} from './Styled';
import SearchByAdditionalViewAccessForTcMeeting from '../../../components/SearchByAdditionalViewAccessForTcMeeting/SearchByAdditionalViewAccessForTcMeeting';
import { useDectectNavigate } from '../../../hooks/useDetectNavigate';

// import search_category_data from './searchData.json';

const numEachPage = 6;

const initCompareData = {
  meeting_name: '',
  start_time: '',
  end_time: '',
  date: '',
  category: {},
  location: '',
  chairman: [],
  meeting_committee: [],
  meeting_facilitator: [],
  meeting_secretariats: [],
  file_url: '',
  remarks: [],
  remark_fields: {
    remarkType: null,
    title: '',
    staff: null,
    remark: '',
    category: null,
    agenda: null,
  },
};

const ViewMeetingDetail = () => {
  const user = useSelector((state) => state.user);
  const isEdittingMeetingSelector = useSelector(meetingStatusSelector);
  const [visibleUpdateMeetingModal, setVisibleUpdateMeetingModal] = useState(false);
  const [visibleCancelMeetingModal, setVisibleCancelMeetingModal] = useState(false);
  const [visibleStartMeetingModal, setVisibleStartMeetingModal] = useState(false);
  const [resendNewInvite, setResendNewInvite] = useState(false);
  const [visibleRemoveAgenda, setVisibleRemoveAgenda] = useState(false);
  const [currentAgendaId, setCurrentAgendaId] = useState();
  const [visibleRemoveMeeting, setVisibleRemoveMeeting] = useState(false);
  const [loadingDeleteMeeting, setLoadingDeleteMeeting] = useState(false);
  const [isDislayEditPopup, setIsDislayEditPopup] = useState(false);
  const [loadingEditFromCancel] = useState(false);
  const [visibleCompleteMeetingModal, setVisibleCompleteMeetingModal] = useState(false);
  const [visibleSaveTheMeeting, setVisibleSaveTheMeeting] = useState(false);
  const [visibleNotifyFacilitator, setVisibleNotifyFacilitator] = useState(false);
  const [loadingConcludeMeeting, setLoadingConcludeMeeting] = useState(false);
  const [visibleConcludeMeetingModal, setVisibleConcludeMeetingModal] = useState(false);
  const [isLoadingGenerateMoM, setLoadingGenerateMoM] = useState(false);
  const [isClickedSaveBtn, setIsClickedSaveBtn] = useState(false);

  // Init state to conpare data changes
  const [compareData, setCompareData] = useState({
    initData: initCompareData,
    currentData: initCompareData,
  });

  const meeting = useSelector((state) => state.app.meetingModule.preMeeting.meeting);
  const agendaIsEdit = useSelector((state) => state.app.meetingModule.preMeeting.isEdittingMeeting);
  const roleActive = useSelector((state) => state.user.roleActive);
  const { externalToken } = useSelector((state) => state.user.tokenImage);
  const [isSubmitToResendInvatation, setIsSubmitToResendInvatation] = useState(false);
  const [page, setPage] = useState(1);
  const [remarkMsg, setRemarkMsg] = useState(null);

  const [momResponse, setMomResponse] = useState({ isGenerated: false });
  const { isUploadingOrDownloading, setIsUploadingOrDownloading } =
    useShowAlertUploadDownloadWhenNavigate();
  const { setShowAlertRefresh } = useShowAlertWhenRefresh();

  // Upload file state
  const [fileUrl, setFileUrl] = useState(null);
  const [fileSizeUpload, setFileSizeUpload] = useState(0);
  const [fileUploadStatus, setFileUploadStatus] = useState({
    isSuccess: false,
    hasError: false,
    notPdfFile: false,
    limit200MB: false,
    isDeletedMoM: false,
    isExistFile: false,
  });

  // Category Value
  const [isLoadAddtionalDataInFirstTime, setIsLoadAddtionalDataInFirstTime] = useState(true);
  const [isChangedMeetingCategory, setIsChangedMeetingCategory] = useState(false);

  const isAdminRole = useMemo(() => {
    return roleActive?.roleName ? roleActive?.roleName.includes(ROLE.ADMIN) : false;
  }, [roleActive]);

  const [breadCrumbList, setBreadCrumbList] = useState([
    { name: 'Dashboard', icon: tc_meeting_breadcrumb },
    { name: 'Board Management Meeting' },
  ]);
  const dispatch = useDispatch();
  const history = useHistory();
  const { idMeeting } = useParams();
  const [isEditting, setIsEditing] = useState(false);
  const [deleteMSIds, setDeleteMSIds] = useState([]);

  // State form changes
  const [submittedRemarkForm, setSubmittedRemarkForm] = useState({
    isSubmitted: false,
    hasError: false,
    currentRemarkItem: null,
  });
  const [isBack, setIsBack] = useState(false);
  const [isOpenCategoryDropdown, setIsOpenCategoryDropdown] = useState();
  const [meetingSecretariatValue, setMeetingSecretariatValue] = useState([]);
  const [additionalViewAccessValue, setAdditionalViewAccessValue] = useState([]);

  // Set Meeting Secretariats
  useEffect(() => {
    formik.setFieldValue('meeting_secretariats', meetingSecretariatValue || []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meetingSecretariatValue]);

  // Set Meeting Additional View Access
  useEffect(() => {
    formik.setFieldValue('meeting_additional_view_accesses', additionalViewAccessValue || []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [additionalViewAccessValue]);

  useEffect(() => {
    setBreadCrumbList([
      { name: 'Dashboard', icon: tc_meeting_breadcrumb },
      { name: meeting.meeting_name },
    ]);
  }, [meeting.meeting_name]);

  const {
    data: meetingDetail,
    fetch: fetchMetting,
    loading: loadingGetMeeting,
  } = useGetMeeting({ idMeeting });
  const { data: meetingRole } = useGetRoleInMeeting({ idMeeting });

  const isOnlyViewMeeting = useMemo(() => {
    if (!idMeeting) return false;

    return (
      !meetingRole?.is_secretariat_or_facilitator &&
      !meetingRole?.is_chairman &&
      !meetingRole?.is_commitee &&
      !isAdminRole &&
      meetingRole?.is_additional_view_access
    );
  }, [meetingRole, idMeeting]);

  useEffect(() => {
    if (meetingRole || user) {
      fetchMetting();
    }
  }, [fetchMetting, meetingRole, user]);

  const UPLOAD_FILE_ERROR_MESSAGE = useMemo(
    () => ({
      notPdfFile: 'Please ensure the uploaded file is a PDF file',
      limit200MB: `Error uploading file. File is too big (${Math.round(
        fileSizeUpload / 1024 / 1024
      )}MB). Max filesize: 200MB`,
      isSuccess: 'MoM uploaded successfully',
      hasError: 'MoM has not been able to upload. Please retry',
      isDeletedMoM: 'MoM deleted successfully',
      isExistFile: 'You have one file already uploaded. Do you want to replace the existing file?',
    }),
    [fileSizeUpload]
  );

  const showMessageUploadFile = useCallback(
    (state, name, type) => {
      if (state[name]) {
        dispatch(
          pushMessage({
            type,
            timeShow: 0,
            message: UPLOAD_FILE_ERROR_MESSAGE[name],
          })
        );
        setFileUploadStatus((prev) => ({ ...prev, [name]: false }));
      }
    },
    [UPLOAD_FILE_ERROR_MESSAGE]
  );

  useEffect(() => {
    const { notPdfFile, limit200MB, hasError, isExistFile, isDeletedMoM, isSuccess } =
      fileUploadStatus;
    if (!(notPdfFile || limit200MB || hasError || isExistFile || isDeletedMoM || isSuccess)) return;

    Object.keys(fileUploadStatus).forEach((key) => {
      let messageType = MESSAGE_TYPES.ERROR;
      if (['isSuccess', 'isDeletedMoM'].includes(key)) messageType = MESSAGE_TYPES.SUCCESS;
      if (key === 'isExistFile') messageType = MESSAGE_TYPES.WARNING;
      showMessageUploadFile(fileUploadStatus, key, messageType);
    });
  }, [fileUploadStatus]);

  // const { secretarialOptions } = useSecretarialOptions('add_meeting');
  // const { getAsyncOptionsForSecretarial, secretarialOptions, businessOption, opuDivisionOption } = useSecretarialOptions('add_meeting');

  const resetMeetingDetails = () => {
    formik.setValues({
      ...initialStateMeeting,
      meeting_id: idMeeting || null,
    });
    dispatch(clearMeeting());
    setMeetingSecretariatValue([]);
    setAdditionalViewAccessValue([]);
    handleResetAgendaDetail();
  };

  useEffect(() => {
    if (meetingDetail?.meeting_id) {
      dispatch(
        addOrUpdateMeeting({
          ...meetingDetail,
          category: find(MEETING_CATEGORY_OPTIONS, {
            name: meetingDetail.category,
          }),
          category_value: {
            name: meetingDetail.category_value,
            category_label: meetingDetail.category_label,
          },
          meeting_secretariats: getFieldValueForTCMeetingInFirstTime(
            meetingDetail?.meeting_secretariats
          ),
          meeting_additional_view_accesses: getFieldValueForTCMeetingInFirstTime(
            meetingDetail?.meeting_additional_view_accesses
          ),
        })
      );
      setMeetingSecretariatValue(
        getFieldValueForTCMeetingInFirstTime(meetingDetail?.meeting_secretariats)
      );
      setAdditionalViewAccessValue(
        getFieldValueForTCMeetingInFirstTime(meetingDetail?.meeting_additional_view_accesses)
      );
      setCompareData((prev) => ({
        ...prev,
        initData: {
          ...prev.initData,
          meeting_secretariats: getFieldValueForTCMeetingInFirstTime(
            meetingDetail?.meeting_secretariats
          ),
          meeting_additional_view_accesses: getFieldValueForTCMeetingInFirstTime(
            meetingDetail?.meeting_additional_view_accesses
          ),
        },
      }));
    } else {
      resetMeetingDetails();
    }
  }, [meetingDetail, dispatch]);

  const convertMeetingFromStore = useCallback((meeting) => {
    const {
      category,
      chairman,
      meeting_committee,
      meeting_facilitator,
      meeting_secretariats,
      meeting_additional_view_accesses,
      end_time,
      start_time,
      date,
      category_value,
      ...rest
    } = meeting;
    const dateWithFormat = moment(date).format('YYYY-MM-DD');
    return {
      ...rest,
      meeting_category: category?.name,
      chairman_id: __(chairman).castArray().head()?.value,
      meeting_committee_ids: map(meeting_committee, 'value'),
      meeting_facilitator_ids: map(meeting_facilitator, 'value'),
      meeting_secretariats: meeting_secretariats,
      meeting_additional_view_accesses,
      end_time: moment(`${dateWithFormat} ${end_time}`, DATE_TIME_FORMAT.DATE_TIME).toDate(),
      start_time: moment(`${dateWithFormat} ${start_time}`, DATE_TIME_FORMAT.DATE_TIME).toDate(),
      on_step: 1,
      category_value: category_value?.name || null,
      category_label:
        category_value?.category_label || formik.values.category_value?.category_label || null,
    };
  }, []);

  const {
    updateMeeting,
    isSuccess: isUpdateMeetingSuccess,
    error: updateMeetingError,
  } = useUpdateMeeting({
    onSuccess: fetchMetting,
  });

  const createOrEditMeeting = useCallback(
    (meeting) => {
      const data = convertMeetingFromStore(meeting);
      return updateMeeting({ idMeeting, ...data });
    },
    [isEditting, convertMeetingFromStore]
  );

  const handleSubmit = async (values) => {
    setRemarkMsg(null);
    dispatch(addOrUpdateMeeting(values));
    const response = await createOrEditMeeting(values);
    if (!response?.result?.is_secretariat_or_facilitator && !isAdminRole) {
      return resetMeetingDetails();
    }
    return response;
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: meeting,
    validationSchema: validationSchema,
    onSubmit: handleSubmit,
    initialTouched: Object.keys(meeting).reduce((result, key) => ({ ...result, [key]: true }), {}),
  });

  const onChangeInputData = (value, key) => {
    formik.setFieldValue(key, value);
  };

  const onChange = (date, dateString) => {
    formik.setFieldValue('date', dateString);
  };

  const onChangeSelectData = (event, key) => {
    formik.setFieldValue(key, event);
  };

  const handleCheckValidateForm = async () => {
    setRemarkMsg(null);
    handleSetFormTouched();

    if (
      size(formik.errors) > 0 &&
      formik.errors['meeting_secretariats'] &&
      isEmpty(formik.values.meeting_secretariats)
    ) {
      dispatch(
        pushMessage({
          message: FILL_OUT_REQUIRE_MESSAGE,
          type: MESSAGE_TYPES.ERROR,
          timeShow: 7000,
        })
      );
      return;
    }

    setVisibleUpdateMeetingModal(true);
  };

  const exitCreateOrEditMeeting = () => {
    setRemarkMsg(null);
    setIsEditing(false);
    dispatch(changeMeetingStatus(false));
    formik.setValues(meeting);
    history.push(`/meeting/detail/${idMeeting}`);
  };

  const exitDeleteMeeting = () => {
    setIsEditing(false);
    history.push(`/calendar`);
  };

  const exitResendNewInvite = () => {
    setIsEditing(false);
    handleSubmitForm();
    createOrUpdateAgendas();
    setVisibleUpdateMeetingModal(false);
    setIsSubmitToResendInvatation(false);
    dispatch(changeMeetingStatus(false));
  };

  const exitCancelMeeting = () => {
    setIsEditing(false);
    setVisibleCancelMeetingModal(false);
  };

  const exitStartMeeting = () => {
    setIsEditing(false);
    setVisibleStartMeetingModal(false);
  };

  const handleSubmitForm = async () => {
    setVisibleSaveTheMeeting(false);
    if (
      [MEETING_DETAIL_STATUS.IN_REVIEW].includes(meetingDetail?.status) &&
      !fileUrl &&
      !isMeetingInReviewWithoutRole &&
      !isBack
    ) {
      return;
    }

    setRemarkMsg(null);
    const values = formik.values;
    const result = await formik.validateForm(values);

    if (isEmpty(result)) {
      await handleSubmit(values);
      setIsEditing(false);
    } else {
      dispatch(addOrUpdateMeeting(formik.values));
      setIsEditing(false);
    }
    setIsBack(false);
    return true;
  };

  const resendNewInviteByMeetingId = async () => {
    try {
      let response = await meetingApi.resendInvitationsByMeetingId(idMeeting);
      if (response.status === 200) {
        return response;
      }
    } catch (error) {
      return console.error(error);
    }
  };

  const handleCancelMeetingById = async () => {
    try {
      let response = await meetingApi.cancelMeetingById(idMeeting);
      if (response.status === 200) {
        fetchMetting();
        dispatch(
          pushMessage({
            message: `${meeting.meeting_name} has been cancelled successfully`,
            timeShow: 0,
            type: MESSAGE_TYPES.SUCCESS,
          })
        );
        return response;
      }
    } catch (error) {
      dispatch(
        pushMessage({
          message: USER_ACCESS_MESSAGE.AN_UNEXPECTED_ERROR,
          timeShow: 0,
          type: MESSAGE_TYPES.ERROR,
        })
      );
    }
  };

  const handleStartMeetingById = async () => {
    try {
      let response = await meetingApi.startMeetingById(idMeeting);
      if (response.status === 200) {
        fetchMetting();
        dispatch(
          pushMessage({
            type: MESSAGE_TYPES.SUCCESS,
            message: `${meeting.meeting_name} is started`,
            timeShow: 0,
          })
        );
        return response;
      }
    } catch (error) {
      dispatch(
        pushMessage({
          type: MESSAGE_TYPES.ERROR,
          message: USER_ACCESS_MESSAGE.AN_UNEXPECTED_ERROR,
          timeShow: 0,
        })
      );
    }
  };

  // Add Agendas
  const { createdAgenda, agendas, removeList, existedAgenda, changeStatusList } = useSelector(
    (state) => state.app.meetingModule.agenda
  );
  const [loadingGetAgendas, setLoadingGetAgendas] = useState(false);
  const { date } = useSelector((state) => state.app.meetingModule.preMeeting.meeting);
  const [visibleExistingAgenda, setVisibleExistingAgenda] = useState(false);
  const [listAgendas, setListAgendas] = useState([]);
  const [visibleWithOutSave, setVisibleWithOutSave] = useState(false);
  const [minValue, setMinValue] = useState(0);
  const [maxValue, setMaxValue] = useState(6);
  const [currentRemarkToUpdate, setCurrentRemarkToUpdate] = useState();

  // Paper forward
  const [agendaIdPaperForward, setAgendaIdPaperForward] = useState({});
  const [visibleAddAgendaPaperForward, setVisibleAddAgendaPaperForward] = useState(false);
  const [loadingAddAgendaPaperForward, setLoadingAddAgendaPaperForward] = useState(false);
  const [loadingCreateOrUpdateAgendas, setLoadingCreateOrUpdateAgendas] = useState(false);

  const createAgenda = () => {
    handleResetAgendaDetail();
    history.push(
      `/meeting/${idMeeting}/agenda/create?history=${ROUTE_BACK_HISTORY.MEETING_DETAIL}`
    );
  };

  const createMobilityAgenda = () => {
    handleResetAgendaDetail();
    history.push(
      `/meeting/${idMeeting}/mobility-agenda/create?history=${ROUTE_BACK_HISTORY.MEETING_DETAIL}`
    );
  };

  const createTalentReviewAgenda = () => {
    handleResetAgendaDetail();
    history.push(
      `/meeting/${idMeeting}/talent-review-agenda/create?history=${ROUTE_BACK_HISTORY.MEETING_DETAIL}`
    );
  };

  const handleResetAgendaDetail = () => {
    dispatch(setInitialStateAgenda());
    dispatch(resetAgendaDetail());
  };

  const isMeetingInReviewOrConcluded = useMemo(() => {
    return [MEETING_DETAIL_STATUS.IN_REVIEW, MEETING_DETAIL_STATUS.CONCLUDED].includes(
      meeting.status
    );
  }, [meeting]);

  const isMeetingInReviewWithRole = useMemo(() => {
    return (
      meeting?.status === MEETING_DETAIL_STATUS.IN_REVIEW &&
      (meeting.is_secretariat_or_facilitator || isAdminRole)
    );
  }, [meeting, isAdminRole]);

  const isMeetingInReviewWithoutRole = useMemo(() => {
    return (
      meeting?.status === MEETING_DETAIL_STATUS.IN_REVIEW &&
      (!meeting.is_secretariat_or_facilitator || isOnlyViewMeeting)
    );
  }, [meeting, isOnlyViewMeeting]);

  const isMeetingInReviewFacilitator = useMemo(() => {
    return isMeetingInReviewWithRole && meeting?.types?.includes(MEETING_ROLE_TYPES.FACILITATOR);
  }, [meeting, isMeetingInReviewWithRole]);

  const isMeetingInReviewSecretariat = useMemo(() => {
    return isMeetingInReviewWithRole && meeting?.types?.includes(MEETING_ROLE_TYPES.SECRETARIAT);
  }, [meeting, isMeetingInReviewWithRole]);

  const isViewMOMFileSection = useMemo(() => {
    return (
      meetingDetail &&
      fileUrl &&
      (meetingRole?.is_secretariat_or_facilitator ||
        meetingRole?.is_chairman ||
        meetingRole?.is_commitee ||
        meetingRole?.is_additional_view_access ||
        isAdminRole) &&
      [MEETING_DETAIL_STATUS.CONCLUDED].includes(meetingDetail?.status)
    );
  }, [meetingDetail, meetingRole, fileUrl, isAdminRole]);

  const handleConvertData = (agendas) => {
    const newArr = [];
    agendas.forEach((item) => {
      if (item.filter_type === 'SP') {
        const dateWithFormat = moment(date).format('YYYY-MM-DD');
        let itemConvert = {
          succession_planning_name: item.succession_planning_name,
          advocator_ids: item.advocators.map((advocator) => advocator.value),
          cc_ids: item.cc_list.map((cc) => cc.value),
          first_line_ids: item.first_line.map((item, idx) => ({
            staff_id: item.staff_id,
            ranking: idx + 1,
            is_talent_review: item.is_talent_review,
            agenda_as_main: item.agenda_as_main,
          })),
          second_line_ids: item.second_line.map((item, idx) => ({
            staff_id: item.staff_id,
            ranking: idx + 1,
            is_talent_review: item.is_talent_review,
            agenda_as_main: item.agenda_as_main,
          })),
          third_line_ids: item.third_line.map((item, idx) => ({
            staff_id: item.staff_id,
            ranking: idx + 1,
            is_talent_review: item.is_talent_review,
            agenda_as_main: item.agenda_as_main,
          })),
          position_codes: item.positions.map((item) => item.position_code),
          meeting_id: idMeeting,
          version: '0',
          end_time: moment(
            `${dateWithFormat} ${item.end_time}`,
            DATE_TIME_FORMAT.DATE_TIME
          ).toDate(),
          start_time: moment(
            `${dateWithFormat} ${item.start_time}`,
            DATE_TIME_FORMAT.DATE_TIME
          ).toDate(),
          remarks: item.remarks,
          action_type: item.action_type,
          saved_card_view: [
            {
              line: 'first_line',
              view: item.cardSettings.first_line.map((m) => ({ ...m, checked: true })),
            },
            {
              line: 'second_line',
              view: item.cardSettings.second_line.map((m) => ({ ...m, checked: true })),
            },
            {
              line: 'third_line',
              view: item.cardSettings.third_line.map((m) => ({ ...m, checked: true })),
            },
          ],
        };
        if (isNaN(Number(item.agenda_id))) {
          newArr.push({
            agenda_id: null,
            ...itemConvert,
          });
        } else {
          newArr.push({
            agenda_id: item.agenda_id,
            ...itemConvert,
          });
        }
      }
    });
    return newArr;
  };

  const createOrUpdateAgendas = async () => {
    setRemarkMsg(null);
    setLoadingCreateOrUpdateAgendas(true);
    if (size(createdAgenda) > 0) {
      const sucessionList = handleConvertData(createdAgenda);
      const mobilityList = convertMobilitySimulationAgenda(createdAgenda, date, idMeeting);
      const talentReviewList = convertTalentReviewAgendaToPost(createdAgenda, date, idMeeting);
      const othersList = createdAgenda
        .filter((agenda) => agenda.type_agenda === AGENDA_TYPES.OTHERS)
        .map((item) => convertDataOtherAgendaToUpdate(item, idMeeting));
      if (!isEmpty(talentReviewList)) {
        talentReviewList.forEach((item) => {
          if (!isEmpty(item?.succession_planning)) {
            item.succession_planning = getDevelopmentPlanningData(item.succession_planning);
          }
        });
      }
      try {
        const remarksTalent = createdAgenda
          .filter((item) => !isEmpty(item.remarks_from_pending_action))
          .map((item) => ({
            ...item,
            remarks_from_pending_action: item.remarks_from_pending_action.map((v) => ({
              ...v,
              flag:
                item.advocators.map((cc) => cc.value).join() +
                (item.cc_list ? item.cc_list.map((cc) => cc.value).join() : '') +
                (item.hr_partners ? item.hr_partners.map((cc) => cc.value).join() : '') +
                (item.succession_planning_name || item.title),
            })),
          }))
          .map((item) => item.remarks_from_pending_action)
          .flat();

        if (size(sucessionList) > 0) {
          const rs = await meetingApi.createOrUpdateAgenda({
            body: {
              business: 'Business',
              agendas: sucessionList,
            },
            roleUser: user,
            meetingID: idMeeting,
          });
          const agendaCreated = get(rs, 'data.results') || [];
          if (!isEmpty(agendaCreated)) {
            agendaCreated.forEach(async (ac) => {
              const remarkBelongAgenda = remarksTalent.filter(
                (c) =>
                  ac.advocators.map((ad) => ad.user_id).join() +
                    ac.cc_list.map((ad) => ad.user_id).join() +
                    ac.agenda_name ===
                  c.flag
              );
              if (!isEmpty(remarkBelongAgenda)) {
                const headers = { role: roleActive };
                const remarkList = remarkBelongAgenda.map((item) => ({
                  staff_id: item?.staffId,
                  remark: item?.remarks,
                  category: item?.remarksCategory,
                  version: item?.version || 0,
                  remark_type: item?.remarkType,
                }));
                await meetingApi.addRemarks(
                  headers,
                  ac.agenda_id,
                  remarkList[0].staff_id,
                  remarkList
                );
              }
            });
          }
        }
        if (size(mobilityList) > 0) {
          await meetingApi.createAgendaMS({
            body: mobilityList,
            roleUser: user,
          });
        }
        if (size(talentReviewList) > 0) {
          const rs = await talentReviewApi.createTalentReviewAgenda({
            body: talentReviewList,
            roleUser: user,
          });
          const agendaCreated = get(rs, 'data.result') || [];
          if (!isEmpty(agendaCreated)) {
            agendaCreated.forEach(async (ac) => {
              const remarkBelongAgenda = remarksTalent.filter(
                (c) =>
                  ac.advocators.map((ad) => ad.user_id).join() +
                    ac.hr_partners.map((ad) => ad.user_id).join() +
                    ac.agenda_name ===
                  c.flag
              );
              if (!isEmpty(remarkBelongAgenda)) {
                const headers = { role: roleActive };
                const remarkList = remarkBelongAgenda.map((item) => ({
                  staff_id: item?.staffId,
                  remark: item?.remarks,
                  category: item?.remarksCategory,
                  version: item?.version || 0,
                  remark_type: item?.remarkType,
                }));
                await meetingApi.addRemarks(
                  headers,
                  ac.agenda_id,
                  remarkList[0].staff_id,
                  remarkList
                );
              }
            });
          }
        }
        if (size(othersList) > 0) {
          await othersAgendaApi.createAgenda(othersList, user.roleActive.roleId);
        }
      } catch (error) {
        const errorMessage = get(error, 'response.data.errors');
        setRemarkMsg({
          type: 'error',
          message: errorMessage || 'An occur error',
          onClose: () => setRemarkMsg(null),
        });
      }
    }
    if (size(removeList) > 0) {
      try {
        await meetingApi.deleteAgendas({
          roleUser: user,
          agenda_ids: removeList.join(','),
        });
      } catch (error) {
        dispatch(
          pushMessage({
            type: MESSAGE_TYPES.ERROR,
            timeShow: 0,
            message: USER_ACCESS_MESSAGE.AN_UNEXPECTED_ERROR,
          })
        );
      }
    }
    if (size(existedAgenda) > 0) {
      try {
        await meetingApi.addExistedAgendas({
          roleUser: user,
          body: {
            agenda_ids: existedAgenda.map((item) => item.agenda_id),
          },
          idMeeting,
        });
      } catch (error) {
        dispatch(
          pushMessage({
            type: MESSAGE_TYPES.ERROR,
            timeShow: 0,
            message: USER_ACCESS_MESSAGE.AN_UNEXPECTED_ERROR,
          })
        );
      }
    }
    setLoadingCreateOrUpdateAgendas(false);
    dispatch(clearAgendaState());
    await getAgendas();
  };

  const getAgendas = async () => {
    setLoadingGetAgendas(true);
    try {
      const response = await meetingApi.getAgendas({
        idMeeting,
        roleUser: user,
      });
      if (response.data.result) {
        setListAgendas(unionBy(response.data.result, 'agenda_id'));
      }

      setLoadingGetAgendas(false);
    } catch (error) {
      setLoadingGetAgendas(false);
    }
  };

  const handleDeleteAgenda = (agenda_id) => {
    setRemarkMsg(null);
    setVisibleRemoveAgenda(true);
    setCurrentAgendaId(agenda_id);
  };

  useEffect(() => {
    if (!idMeeting) return;
    getAgendas();
  }, [idMeeting, user]);

  ///Implement Update Exised Agenda
  const handeUpdateAgendaExisted = async (agendaId, type) => {
    setRemarkMsg(null);
    setAgendaIdPaperForward({ agendaId, type });
    setVisibleAddAgendaPaperForward(true);
  };
  ///Implement Show List Agenda
  const handleShowListAgenda = () => {
    let clonelistAgendas = cloneDeep(listAgendas);
    // let clonelistAgendas = unionBy(cloneDeep(listAgendas), 'agenda_id');
    ///Case remove agenda get from db
    if (size(removeList) > 0) {
      removeList.forEach((ele) => {
        clonelistAgendas = listAgendas.filter((item) => item.agenda_id !== ele);
      });
    }
    ///Case edit agenda that get from db
    if (size(createdAgenda) > 0) {
      createdAgenda.forEach((ele) => {
        let idx = clonelistAgendas.findIndex((item) => ele.agenda_id === item.agenda_id);
        if (idx !== -1) {
          clonelistAgendas[idx] = ele;
        }
      });
      if (size(agendas) > 0) {
        agendas.forEach((ele) => {
          clonelistAgendas = clonelistAgendas.filter((item) => ele.agenda_id !== item.agenda_id);
        });
      }
    }
    if (loadingGetAgendas) {
      return {
        header: '',
        content: (
          <AiSpin tip="Loading...">
            <AiAlert />
          </AiSpin>
        ),
      };
    }

    if (size(concat(agendas, clonelistAgendas)) > 0) {
      let tempAgendas = concat(agendas, clonelistAgendas).map((item) => {
        if (moment(item?.start_time).isValid() && moment(item?.end_time).isValid()) {
          return {
            ...item,
            start_time: moment(item?.start_time).format(DATE_TIME_FORMAT.TIME),
            end_time: moment(item?.end_time).format(DATE_TIME_FORMAT.TIME),
          };
        }
        return item;
      });

      tempAgendas = orderBy(
        tempAgendas,
        [(item) => moment(item.start_time, 'h:mm A').format('HH:mm')],
        ['esc']
      );
      if (isMeetingInReviewOrConcluded) {
        tempAgendas = tempAgendas.filter((item) =>
          [AGENDA_STATUS.PAPER_FINALIZED].includes(item.status)
        );
      } else {
        tempAgendas =
          !isEditting && !isEdittingMeetingSelector
            ? tempAgendas.filter((item) =>
                [AGENDA_STATUS.PAPER_READY, AGENDA_STATUS.PAPER_FINALIZED].includes(item.status)
              )
            : tempAgendas;
      }

      return {
        header: 'Agenda',
        content: tempAgendas.slice(minValue, maxValue).map((item) => {
          return (
            <>
              <div key={item.agenda_id} style={{ cursor: 'pointer' }}>
                <AgendaItem
                  item={item}
                  key={item.agenda_id}
                  avatar={false}
                  isViewDetail={
                    (!isEditting && !isEdittingMeetingSelector) ||
                    isMeetingInReviewOrConcluded ||
                    isOnlyViewMeeting
                  }
                  handleDeleteAgenda={handleDeleteAgenda}
                  handeUpdateAgendaExisted={handeUpdateAgendaExisted}
                  prevPageName={ROUTE_BACK_HISTORY.MEETING_DETAIL}
                  remarkMsg={remarkMsg}
                />
              </div>
            </>
          );
        }),
        totalPage: tempAgendas.length,
        tempAgendas,
        idsAgenda: tempAgendas.map((item) => item.agenda_id),
      };
    }
    return {
      header: 'Add Agenda in Meeting',
      content: '',
    };
  };

  let { header, content: agendaList, totalPage, tempAgendas, idsAgenda } = handleShowListAgenda();
  const exitAgenda = () => {
    dispatch(clearMeeting());
    dispatch(clearAgendaState());
    history.push('/calendar');
  };

  const handlePageChange = (value) => {
    setMinValue((value - 1) * numEachPage);
    setMaxValue(value * numEachPage);
    setPage(value);
  };
  //IMPLEMENT CHECK PERMISSION ADD AGENDA
  const isAddAgenda = useMemo(() => {
    if (
      meetingRole?.is_secretariat_or_facilitator &&
      user?.permissions.includes(PERMISSION.CREATE_MEETING)
    ) {
      return true;
    }
  }, [meetingRole, user]);

  const handleEditMeeting = () => {
    if (isEditting === true && meeting.status === MEETING_DETAIL_STATUS.IN_PROGRESS) {
      setCompareData((prev) => ({
        ...prev,
        initData: { ...prev.initData, remark_fields: initCompareData.remark_fields },
        currentData: { ...prev.currentData, remark_fields: initCompareData.remark_fields },
      }));
      createOrUpdateAgendas();
      setMinValue(0);
      setMaxValue(6);
      setPage(1);
    }
    setIsEditing(!isEditting);
    dispatch(changeMeetingStatus(!isEditting));
  };

  useEffect(() => {
    setIsEditing(agendaIsEdit || isMeetingInReviewWithRole);
  }, [agendaIsEdit, meeting]);
  const { createRemark, isLoading: isCreateRemarkLoading } = useCreateRemarkInMeeting();
  const { updateRemark } = useUpdateRemarkInMeeting();

  const [triggerFetchRemarks, setTriggerFetchRemarks] = useState(0);

  const handleAddRemark = useCallback(
    async ({ agenda, staff, remark, category, remarkType }, { resetForm }) => {
      setRemarkMsg(null);
      const data = {
        agenda_id: agenda,
        remark,
        category: category,
        remark_type: remarkType,
        version: 0,
        staff_id: staff,
      };
      const remarkSubject = remarkType === 'Talent' ? 'Talent Remarks' : 'General Remarks';
      await createRemark(data, {
        onSuccess: () => {
          resetForm();
          const message = `${remarkSubject} added Successfully!`;
          setRemarkMsg({ type: 'success', message });
          dispatch(
            pushMessage({
              message,
              type: MESSAGE_TYPES.SUCCESS,
              timeShow: 0,
            })
          );
          setTriggerFetchRemarks((old) => old + 1);
        },
        onError: () => {
          const message = `Add ${remarkSubject} failed!`;
          setRemarkMsg({ type: 'error', message });
          dispatch(
            pushMessage({
              message,
              type: MESSAGE_TYPES.ERROR,
              timeShow: 0,
            })
          );
        },
      });
    },
    [createRemark]
  );

  const handleEditRemark = useCallback(
    async (remarkValue) => {
      if (!remarkValue) {
        setCompareData((prev) => ({
          ...prev,
          initData: { ...prev.initData, remark_fields: initCompareData.remark_fields },
        }));
        setCurrentRemarkToUpdate(null);
        return;
      }

      setRemarkMsg(null);
      const body = {
        meeting_id: idMeeting,
        agenda_id: remarkValue?.agenda?.value,
        remark: remarkValue?.remark,
        category: remarkValue?.category?.value,
        remark_type: remarkValue?.remarkType?.value,
        version: 0,
        staffId: isObject(remarkValue?.staff?.value)
          ? remarkValue?.staff?.value?.staffId
          : remarkValue?.staff?.value,
      };
      const dataToUpdate = {
        remarkId: remarkValue.remarkId,
        body,
        role: roleActive?.roleId,
      };
      await updateRemark(dataToUpdate, {
        onSuccess: () => {
          const message = `Remark Successfully Edited!`;
          setRemarkMsg({ type: 'success', message });
          setCurrentRemarkToUpdate(null);
          fetchMeetingRemark({ meetingId: idMeeting });
        },
        onError: () => {
          const message = `Update Remark failed!`;
          setRemarkMsg({ type: 'error', message });
        },
      });
    },
    [updateRemark]
  );

  const isDisplayRemark = useMemo(() => {
    return (
      ([MEETING_DETAIL_STATUS.IN_PROGRESS, MEETING_DETAIL_STATUS.IN_REVIEW].includes(
        meeting?.status
      ) &&
        meetingRole?.is_secretariat_or_facilitator &&
        (isEditting || isEdittingMeetingSelector)) ||
      ([MEETING_DETAIL_STATUS.CONCLUDED].includes(meeting?.status) &&
        meetingRole?.is_secretariat_or_facilitator &&
        !(isEditting || isEdittingMeetingSelector))
    );
  }, [meeting, meetingRole, isEditting, isEdittingMeetingSelector]);

  const isShowAllRemark = useMemo(() => {
    return (
      [MEETING_DETAIL_STATUS.CONCLUDED].includes(meeting?.status) &&
      meetingRole?.is_secretariat_or_facilitator
    );
  }, [meeting, meetingRole]);

  const {
    data: remarksData,
    fetch: fetchMeetingRemark,
    isLoading: isGetMeetingLoading,
  } = useFetch(talentProfileApi.getMeetingRemarks);

  const { data, fetch: fetchAgendas } = useFetch(talentProfileApi.getAgendasInMeeting);

  useEffect(() => {
    if (isDisplayRemark) {
      fetchAgendas({ meetingId: idMeeting, status: 'FOR_REMARK' });
    }
  }, [fetchAgendas, idMeeting, isDisplayRemark, user]);

  const agendaOptions = useMemo(() => {
    if (!data) {
      return [];
    }
    const newData = __.uniqBy(data, 'agenda_id');
    return map(newData, ({ agenda_name, agenda_id, type_agenda }) => ({
      label: agenda_name,
      value: agenda_id,
      type: type_agenda,
    }));
  }, [data]);

  const [remarkFilters, setRemarkFilters] = useState(null);

  useEffect(() => {
    if (isDisplayRemark && remarkFilters) {
      const data = {
        meetingId: idMeeting,
      };
      if (remarkFilters.remarkType) {
        data.remark_type = remarkFilters.remarkType;
      }

      if (!isEmpty(remarkFilters.agendas)) {
        data.list_agendas_id = encodeURIComponent(JSON.stringify(remarkFilters.agendas));
      }
      fetchMeetingRemark(data);
    }
  }, [remarkFilters, isDisplayRemark, idMeeting, fetchMeetingRemark, triggerFetchRemarks]);

  const remarks = useMemo(
    () =>
      map(
        remarksData,
        ({
          agenda_id,
          agenda_name,
          type_agenda,
          category,
          remark_type,
          remarks,
          talent_name,
          remark_id,
          staffId,
        }) => ({
          agendaId: agenda_id,
          agendaName: agenda_name,
          agendaType: type_agenda,
          category,
          remarkType: remark_type,
          remarks,
          talentName: talent_name,
          remarkId: remark_id,
          staffId,
        })
      ),
    [remarksData]
  );

  const { deleteRemark, isLoading: isDeleteRemarkLoading } = useDeleteMeetingRemark();

  const handleDeleteRemark = useCallback(
    async ({ remarkId, agendaId, remarkType }, { closeDeletePopup }) => {
      setRemarkMsg(null);
      const remarkSubject = remarkType === 'Talent' ? 'Talent Remarks' : 'General Remarks';
      await deleteRemark(
        { remark_id: remarkId, agenda_id: agendaId, id: USER_ID, role: roleActive },
        {
          onSuccess: () => {
            setRemarkMsg({
              message: `${remarkSubject} has been deleted Successfully!`,
              type: 'success',
            });
            setTriggerFetchRemarks((old) => old + 1);
            setCurrentRemarkToUpdate(null);
          },

          onError: () => {
            setRemarkMsg({
              message: `Delete ${remarkSubject} Failed!`,
              type: 'error',
            });
          },
        }
      );
      closeDeletePopup();
    },
    [deleteRemark, roleActive]
  );

  const handleClickEditRemarkBtn = useCallback(
    (remark) => {
      setCurrentRemarkToUpdate(remark);
    },
    [setCurrentRemarkToUpdate]
  );

  const checkCancelAndDeleteBtn = () => {
    if (meetingDetail.status === MEETING_DETAIL_STATUS.CANCELED) {
      return 'Delete Meeting';
    }
    if (
      (meetingDetail.status === MEETING_DETAIL_STATUS.IN_PROGRESS ||
        meetingDetail.status === MEETING_DETAIL_STATUS.PUBLISHED) &&
      (meetingRole.is_secretariat_or_facilitator || isAdminRole)
    ) {
      return 'Cancel Meeting';
    } else {
      return '';
    }
  };

  const handleCancelAndDeleteBtn = () => {
    if (meetingDetail.status === MEETING_DETAIL_STATUS.CANCELED) {
      setVisibleRemoveMeeting(true);
    }
    if (
      (meetingDetail.status === MEETING_DETAIL_STATUS.IN_PROGRESS ||
        meetingDetail.status === MEETING_DETAIL_STATUS.PUBLISHED) &&
      (meetingRole.is_secretariat_or_facilitator || isAdminRole)
    ) {
      setVisibleCancelMeetingModal(true);
    }
  };

  const updateUploadedFile = (url) => {
    setFileUrl(url);
  };

  useEffect(() => {
    if (!meetingDetail?.file_url) return;
    setFileUrl(meetingDetail.file_url);
  }, [meetingDetail?.file_url]);

  const handleViewMoMFile = () => {
    if (fileUrl) {
      window.open(`${fileUrl}?${externalToken}`, '_blank', 'noopener, noreferrer');
    }
  };

  useEffect(() => {
    if (!isUploadingOrDownloading) return;
    const fetchGenerateMoM = async () => {
      setLoadingGenerateMoM(true);
      const time_zone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      const data = { meeting_id: Number(idMeeting), time_zone };
      try {
        const response = await meetingApi.generateMoM(data, roleActive?.roleId);
        if (response.status === 200 && response?.data?.result) {
          setMomResponse((prev) => ({ ...prev, ...response.data.result }));
        }
      } catch (error) {
        setLoadingGenerateMoM(false);
        setShowAlertRefresh(false);
        setIsUploadingOrDownloading(false);
        console.error(error);
        return false;
      }
    };
    fetchGenerateMoM();
  }, [isUploadingOrDownloading]);

  useEffect(() => {
    if (!momResponse.block_blob || momResponse.isGenerated) return;
    let status = {
      data: { code: 0 },
    };
    let count = 0;
    // eslint-disable-next-line
    const getStatusGenerate = setInterval(() => {
      meetingApi.fetchGenerateStatus(momResponse.block_blob).then((rs) => {
        status = { ...status, ...rs };
        count += 1;
        if (Number(status?.data?.code) === 200 && status?.data?.result && count > 2) {
          setLoadingGenerateMoM(false);
          setMomResponse((prev) => ({ ...prev, isGenerated: true }));
          clearInterval(getStatusGenerate);
        }
      });
    }, 5000);

    return () => {
      clearInterval(getStatusGenerate);
    };
  }, [momResponse]);

  useEffect(() => {
    if (!momResponse.isGenerated) return;

    const { url, block_blob } = momResponse;
    const dateFormat = 'DD_MM_YYYY';
    const fileName = `${meetingDetail?.meeting_name}_${meetingDetail?.category}_${moment(
      meetingDetail?.date
    ).format(dateFormat)}.docx`;
    const handleGenerateMoMFile = async () => {
      try {
        const res = await fetch(`${url}`);
        const blob = await res.blob();
        if (blob) {
          const href = window.URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.setAttribute('download', fileName);
          link.href = href;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          await meetingApi.confirmGenerate(block_blob);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setShowAlertRefresh(false);
        setIsUploadingOrDownloading(false);
        setMomResponse({ isGenerated: false });
      }
    };
    handleGenerateMoMFile();
  }, [momResponse.isGenerated]);

  const handleGenerateMoMFile = async () => {
    setShowAlertRefresh(true);
    setIsUploadingOrDownloading(true);
  };

  const handleCompleteMeeting = () => {
    if (isEditting === true) {
      dispatch(
        pushMessage({
          type: MESSAGE_TYPES.WARNING,
          message: MEETING_MESSAGE.WARNING_MODE_CURRENTLY_IS_EDITTING,
        })
      );
      return;
    }

    if (isEmpty(tempAgendas)) return setVisibleCompleteMeetingModal(true);

    const paperReadyExist = tempAgendas.find((item) => item.status === AGENDA_STATUS.PAPER_READY);
    if (!isEmpty(paperReadyExist)) {
      dispatch(
        pushMessage({
          type: MESSAGE_TYPES.WARNING,
          message: MEETING_MESSAGE.WARNING_EXISTS_PAPER_READY,
        })
      );
      return;
    }

    return setVisibleCompleteMeetingModal(true);
  };

  const handleConfirmToCompleteMeeting = async () => {
    setVisibleCompleteMeetingModal(false);
    if ([MEETING_DETAIL_STATUS.IN_PROGRESS].includes(meetingDetail?.status)) {
      await handleToDeleteMS();
    }

    try {
      const res = await meetingApi.completeMeeting(idMeeting);
      if (res.status === 200) {
        history.push(`/meeting-in-review/${idMeeting}`);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleToDeleteMS = async () => {
    if (isEmpty(deleteMSIds)) return;

    let ids = [];
    deleteMSIds.forEach((item) => {
      ids.push(item?.mbsm_id);
    });
    try {
      const res = await mobilitySimulationApi.delete(ids, MOBILITY_SIMULATION.CREATED_FROM_AGENDA);
      if (res.status === 200) {
        return true;
      }
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  useEffect(() => {
    const finalizedAgendas = !isEmpty(listAgendas)
      ? listAgendas.filter(
          (item) =>
            item?.status === AGENDA_STATUS.PAPER_FINALIZED && item?.type_agenda === AGENDA_TYPES.MS
        )
      : [];
    if (isEmpty(finalizedAgendas)) return;

    finalizedAgendas.forEach((item) => {
      if (!isNaN(Number(item?.agenda_id))) {
        fetchMSAgenda(item);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listAgendas]);

  const fetchMSAgenda = async (data) => {
    try {
      const response = await meetingApi.getAgendaMS(data?.agenda_id, roleActive?.roleId);
      if (response.status === 200) {
        const revertData = convertMobilitySimulationToViewAgenda(response.data.result);
        const msIds = !isEmpty(revertData?.mobility_simulations)
          ? revertData?.mobility_simulations.filter((item) => !item.is_main)
          : [];
        setDeleteMSIds((prevState) => {
          return [...prevState, ...msIds];
        });
      }
    } catch (error) {
      return console.error(error);
    }
  };

  const checkFileUpload = () => {
    if (!fileUrl) {
      dispatch(
        pushMessage({
          type: MESSAGE_TYPES.ERROR,
          message:
            'Please upload the final approved MoM for this meeting before proceeding to conclude the meeting.',
          timeShow: 0,
        })
      );
    } else {
      setVisibleConcludeMeetingModal(true);
    }
  };

  const exitConcludeMeeting = () => {
    setVisibleConcludeMeetingModal(false);
  };

  const handleCheckValidSaveBtn = async () => {
    handleSetFormTouched();
    setIsClickedSaveBtn(true);
    if (
      size(formik.errors) > 0 &&
      formik.errors['meeting_secretariats'] &&
      isEmpty(formik.values.meeting_secretariats)
    ) {
      dispatch(
        pushMessage({
          message: FILL_OUT_REQUIRE_MESSAGE,
          type: MESSAGE_TYPES.ERROR,
          timeShow: 7000,
          containerId: GLOBAL_ALERT_MESSAGE_CONTAINER_ID.TC_IN_REVIEW_MEETING,
          isOnly: true,
        })
      );
      return;
    }
    if (!fileUrl) {
      // comment us-1237574
      // dispatch(
      //   pushMessage({
      //     message:
      //       'Please upload the final approved MoM and supporting document for this meeting before proceeding to conclude the meeting',
      //     type: MESSAGE_TYPES.ERROR,
      //     timeShow: 0,
      //     containerId: GLOBAL_ALERT_MESSAGE_CONTAINER_ID.TC_IN_REVIEW_MEETING,
      //     isOnly: true,
      //   })
      // );
      dispatch(
        pushMessage({
          message: FILL_OUT_REQUIRE_MESSAGE,
          type: MESSAGE_TYPES.ERROR,
          timeShow: 0,
          containerId: GLOBAL_ALERT_MESSAGE_CONTAINER_ID.TC_IN_REVIEW_MEETING,
          isOnly: true,
        })
      );
      return;
    }
    setVisibleSaveTheMeeting(true);
  };

  const handleSetFormTouched = () => {
    if (formik.values) {
      formik.setTouched(
        Object.keys(formik.values).reduce((result, key) => ({ ...result, [key]: true }), {})
      );
    }
  };

  const handleSaveTheMeeting = async () => {
    setRemarkMsg(null);
    setVisibleSaveTheMeeting(false);
    handleSubmitForm();
  };

  const handleNotifyFacilitator = async () => {
    if (idMeeting) {
      try {
        let response = await meetingApi.notifyFacilitator(idMeeting, roleActive?.roleId);
        if (response.status === 200) {
          setVisibleNotifyFacilitator(false);
          dispatch(
            pushMessage({
              type: MESSAGE_TYPES.SUCCESS,
              message: 'Meeting successfully send to Notify Facilitator',
              timeShow: 0,
            })
          );
          fetchMetting();
          return response;
        }
      } catch (error) {
        setVisibleNotifyFacilitator(false);
        dispatch(
          pushMessage({
            type: MESSAGE_TYPES.ERROR,
            message: USER_ACCESS_MESSAGE.AN_UNEXPECTED_ERROR,
            timeShow: 0,
          })
        );
        return console.error(error);
      }
    }
  };

  const blockNavigateByData = isExistDataNotSave({
    existedAgenda,
    createdAgenda,
    removeList,
    changeStatusList,
    editAgenda: !isEmpty(agendas) && agendas.find((x) => x.isEdit === true),
  });
  const { showPopup, setShowPopup, handleKeepNavigate } = useDectectNavigate({
    blockNavigateByData,
  });

  useEffect(() => {
    formik.setFieldValue(
      'meeting_secretariats',
      getFieldValueForTCMeetingInFirstTime(
        meeting?.meeting_secretariats ||
          meeting?.roles?.meeting_secretariats ||
          meeting?.roles?.secretariat
      )
    );
    formik.setFieldValue(
      'meeting_additional_view_accesses',
      getFieldValueForTCMeetingInFirstTime(
        meeting?.meeting_additional_view_accesses ||
          meeting?.roles?.meeting_additional_view_accesses ||
          meeting?.roles?.additional_view_access
      )
    );
    setMeetingSecretariatValue(
      getFieldValueForTCMeetingInFirstTime(
        meeting?.meeting_secretariats ||
          meeting?.roles?.meeting_secretariats ||
          meeting?.roles?.secretariat
      )
    );
    setAdditionalViewAccessValue(
      getFieldValueForTCMeetingInFirstTime(
        meeting?.meeting_additional_view_accesses ||
          meeting?.roles?.meeting_additional_view_accesses ||
          meeting?.roles?.additional_view_access
      )
    );
    setCompareData((prev) => ({
      ...prev,
      initData: {
        ...prev.initData,
        ...meeting,
        meeting_secretariats: getFieldValueForTCMeetingInFirstTime(
          meeting?.meeting_secretariats ||
            meeting?.roles?.meeting_secretariats ||
            meeting?.roles?.secretariat
        ),
        meeting_additional_view_accesses: getFieldValueForTCMeetingInFirstTime(
          meeting?.meeting_additional_view_accesses ||
            meeting?.roles?.meeting_additional_view_accesses ||
            meeting?.roles?.additional_view_access
        ),
      },
    }));
  }, [meeting]);

  useEffect(() => {
    setCompareData((prev) => ({
      ...prev,
      currentData: {
        ...prev.currentData,
        ...formik.values,
      },
    }));
  }, [formik.values]);

  const handleSubmitFormChanges = async () => {
    if (submittedRemarkForm.hasError) {
      setIsShowPopupSaveChangesMeeting(false);
    } else {
      setIsShowPopupSaveChangesMeeting(false);
      handleSubmitForm();
      keepNavigateWhenFieldChanges(navigatingPathname ? navigatingPathname : '/calendar');
    }
    dispatch(updateSubmittedFormChanges(false));
    setSubmittedRemarkForm({
      isSubmitted: false,
      hasError: false,
      currentRemarkItem: null,
    });
  };

  useEffect(() => {
    if (submittedRemarkForm.isSubmitted) {
      handleSubmitFormChanges();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submittedRemarkForm.isSubmitted]);

  const onClickBack = () => {
    if (!isEmpty(isDifferentFieldMeetingDetails(compareData))) {
      setIsBack(true);
      setIsShowPopupSaveChangesMeeting(true);
    } else {
      dispatch(changeMeetingStatus(false));
      history.push(`/calendar`);
    }
  };

  const {
    isShowPopupSaveAgenda: isShowPopupSaveChangesMeeting,
    handleKeepNavigate: keepNavigateWhenFieldChanges,
    setIsShowPopupSaveAgenda: setIsShowPopupSaveChangesMeeting,
    navigatingPathname,
  } = useShowPopupOtherAgenda(
    blockNavigateByData ? [] : isDifferentFieldMeetingDetails(compareData)
  );

  const handleSetMeetingSecretariatValue = (val) => {
    const isCheck =
      meetingSecretariatValue.filter((item) => item?.label_name === val?.label_name).length > 0;
    if (!isCheck) {
      setMeetingSecretariatValue((prev) => {
        return [...prev, val];
      });
    }
    setIsLoadAddtionalDataInFirstTime(false);
  };

  const handleSetAdditionalViewAccess = (val) => {
    const isCheck =
      additionalViewAccessValue.filter((item) => item?.label_name === val?.label_name).length > 0;
    if (!isCheck) {
      setAdditionalViewAccessValue((prev) => {
        return [...prev, val];
      });
    }
  };

  useEffect(() => {
    if (
      !roleActive?.roleId ||
      isEmpty(formik.values.category?.name) ||
      (isLoadAddtionalDataInFirstTime && !isEmpty(formik.values.meeting_secretariats))
    )
      return;

    if (
      isEmpty(formik.values.meeting_secretariats) &&
      ![MEETING_CATEGORY_VALUES.SKG, MEETING_CATEGORY_VALUES.JOB_FAMILY].includes(
        formik.values.category?.name
      )
    ) {
      setAdditionalViewAccessValue([]);
      formik.setFieldValue('meeting_additional_view_accesses', []);
      return;
    }
    const getDataToPopulateForAdditionalView = async (firstItem) => {
      try {
        const params = {
          role_id: firstItem?.role_id,
          meeting_category: formik.values.category?.name,
          business_id: getBusinessIdToPopulateForAdditionalView(
            formik.values.category?.name,
            firstItem
          ),
        };
        const res = await meetingApi.getDataToPopulateForAdditionalView(params, roleActive?.roleId);
        if (res.status === 200 && res.data.result.length > 0 && isArray(res.data.result)) {
          res.data.result.forEach((item) => {
            const tempVal = {
              ...item,
              label_name: getLabelNameForSecretariatOfTcMeeting(formik.values.category?.name, item),
              search_type: formik.values.category?.name,
            };
            handleSetAdditionalViewAccess(tempVal);
          });
        }
      } catch (error) {
        console.error(error);
      }
    };

    if (
      ![MEETING_CATEGORY_VALUES.SKG, MEETING_CATEGORY_VALUES.JOB_FAMILY].includes(
        formik.values.category?.name
      ) &&
      formik.values.meeting_secretariats.length === 1 &&
      isChangedMeetingCategory
    ) {
      getDataToPopulateForAdditionalView(formik.values.meeting_secretariats[0]);
      setIsChangedMeetingCategory(false);
    }
  }, [formik.values.meeting_secretariats, isLoadAddtionalDataInFirstTime]);

  // const isDisabledAdditionalViewAccess = useMemo(() => {
  //   return (
  //     [MEETING_CATEGORY_VALUES.PETRONAS].includes(formik.values.category?.name) ||
  //     isEmpty(formik.values.meeting_secretariats) ||
  //     meeting?.status === MEETING_DETAIL_STATUS.IN_PROGRESS ||
  //     (!isEditting && !isEdittingMeetingSelector)
  //   );
  // }, [formik.values.category, formik.values.meeting_secretariats, meeting, isEditting, isEdittingMeetingSelector]);

  const isDisabledMeetingSecretariatField = useMemo(() => {
    return (
      [MEETING_CATEGORY_VALUES.PETRONAS].includes(formik.values.category?.name) ||
      [MEETING_DETAIL_STATUS.IN_PROGRESS].includes(meeting?.status) ||
      (!isEditting && !isEdittingMeetingSelector)
    );
  }, [formik.values.category, meeting, isEditting, isEdittingMeetingSelector]);

  const handleSelectMeetingCategory = (val) => {
    formik.setFieldValue('category', val);
    formik.setFieldValue('category_value', null);
    setIsLoadAddtionalDataInFirstTime(false);

    const tempVal = {
      role: ROLE.HR_COE,
      role_id: 7,
      label_name: getLabelNameForSecretariatOfTcMeeting(MEETING_CATEGORY_VALUES.PETRONAS, {
        role: ROLE.HR_COE,
        role_id: 7,
      }),
      search_type: MEETING_CATEGORY_VALUES.PETRONAS,
    };
    if (val.name === MEETING_CATEGORY_VALUES.PETRONAS) {
      setMeetingSecretariatValue([tempVal]);
      setAdditionalViewAccessValue([tempVal]);
    } else if (
      [MEETING_CATEGORY_VALUES.SKG, MEETING_CATEGORY_VALUES.JOB_FAMILY].includes(val.name)
    ) {
      setAdditionalViewAccessValue([tempVal]);
      setMeetingSecretariatValue([]);
    } else {
      setMeetingSecretariatValue([]);
      setAdditionalViewAccessValue([]);
    }
  };

  return (
    <>
      <BreadCrumb level={2} breadCrumbList={breadCrumbList} />
      <GlobalAlertMessage
        style={{ margin: '14px 0' }}
        id={GLOBAL_ALERT_MESSAGE_CONTAINER_ID.TC_IN_REVIEW_MEETING}
      />
      <GlobalAlertMessage style={{ margin: '14px 0' }} />
      {isUpdateMeetingSuccess && !isSubmitToResendInvatation && (
        <div style={{ margin: '14px 0' }}>
          <AlertMessage message="Your changes has been saved successfully." type="success" />
        </div>
      )}
      {isUpdateMeetingSuccess && isSubmitToResendInvatation && (
        <div style={{ margin: '14px 0' }}>
          <AlertMessage
            message="Your changes have been saved successfully and the new invite have been sent."
            type="success"
          />
        </div>
      )}
      {!isNil(updateMeetingError) && !isEmpty(updateMeetingError) && (
        <div style={{ margin: '14px 0' }}>
          <AlertMessage message="Your changes hasn't been updated." type="error" />
        </div>
      )}
      {!isMeetingInReviewOrConcluded && (
        <MeetingTitle>
          <AiH2>{meeting.meeting_name}</AiH2>
          <MeetingStatus status={meeting.status} />
        </MeetingTitle>
      )}
      {loadingGetMeeting && (
        <AiLoading>
          <AiSpin size="large"></AiSpin>
        </AiLoading>
      )}
      <form onSubmit={formik.handleSubmit}>
        {isOnlyViewMeeting && (
          <TopBtnWrapper>
            <AiButton onKeyDown={() => {}} onClick={onClickBack}>
              <span data-testid="back-btn">Back</span>
            </AiButton>
          </TopBtnWrapper>
        )}
        {!isMeetingInReviewOrConcluded &&
          !!meeting?.is_secretariat_or_facilitator &&
          !isOnlyViewMeeting && (
            <TopBtnWrapper>
              {!isEditting && !isEdittingMeetingSelector ? (
                <div>
                  <AiButton onKeyDown={() => {}} onClick={onClickBack}>
                    <span data-testid="back-btn">Back</span>
                  </AiButton>
                  {meeting.status !== MEETING_DETAIL_STATUS.IN_PROGRESS && (
                    <>
                      <AiButton
                        onKeyDown={() => {}}
                        onClick={() =>
                          meeting.status === MEETING_DETAIL_STATUS.CANCELED
                            ? setIsDislayEditPopup(true)
                            : handleEditMeeting()
                        }
                      >
                        Edit Meeting
                      </AiButton>
                      {meeting.status !== MEETING_DETAIL_STATUS.CANCELED && (
                        <AiButton
                          mode={'teal'}
                          onKeyDown={() => {}}
                          onClick={() => setVisibleStartMeetingModal(true)}
                        >
                          Start Meeting
                        </AiButton>
                      )}
                    </>
                  )}

                  {meeting.status === MEETING_DETAIL_STATUS.IN_PROGRESS && (
                    <AiButton
                      data-testid="complete-meeting"
                      mode={'teal'}
                      onKeyDown={() => {}}
                      onClick={() => handleCompleteMeeting()}
                    >
                      Complete Meeting
                    </AiButton>
                  )}
                </div>
              ) : (
                <>
                  {meeting.status === MEETING_DETAIL_STATUS.IN_PROGRESS ? (
                    <>
                      <AiButton onKeyDown={() => {}} onClick={onClickBack}>
                        <span data-testid="back-btn">Back</span>
                      </AiButton>
                      <AiButton
                        data-testid="complete-meeting"
                        mode={'teal'}
                        onKeyDown={() => {}}
                        onClick={() => handleCompleteMeeting()}
                      >
                        Complete Meeting
                      </AiButton>
                    </>
                  ) : (
                    <div>
                      <AiButton
                        onKeyDown={() => {}}
                        onClick={() => {
                          setIsEditing(false);
                          dispatch(changeMeetingStatus(false));
                        }}
                      >
                        Cancel
                      </AiButton>
                      <AiButton onKeyDown={() => {}} onClick={handleCheckValidateForm}>
                        Update Meeting
                      </AiButton>
                    </div>
                  )}
                </>
              )}
            </TopBtnWrapper>
          )}

        {isMeetingInReviewOrConcluded && !isOnlyViewMeeting && (
          <TopButtonMeetingDetail
            saveBtn={{
              show: isMeetingInReviewWithRole,
              onClick: () => handleCheckValidSaveBtn(),
            }}
            backBtn={{ show: true, onClick: onClickBack }}
            concludeBtn={{
              show: isMeetingInReviewFacilitator,
              onClick: checkFileUpload,
            }}
            notifyBtn={{
              show: isMeetingInReviewSecretariat && !isMeetingInReviewFacilitator,
              onClick: () => setVisibleNotifyFacilitator(true),
            }}
          />
        )}

        {meetingDetail?.status === MEETING_DETAIL_STATUS.IN_REVIEW &&
          (!isMeetingInReviewWithoutRole || isAdminRole) &&
          !isOnlyViewMeeting && (
            <AiContainerForm style={{ marginBottom: '29px', paddingBottom: '29px' }}>
              <AiTitleWrapper>
                <MeetingTitle>
                  <AiH2 style={{ fontSize: '18px' }}>
                    {'Upload approved Minutes of Meeting (MoM)'}
                  </AiH2>
                  <MeetingStatus status={MEETING_DETAIL_STATUS.IN_REVIEW} />
                </MeetingTitle>
                <AiGroupButton>
                  {fileUrl && (
                    <AiButton
                      border={true}
                      borderColor={'#00A19C'}
                      onKeyDown={() => {}}
                      onClick={() => handleViewMoMFile()}
                    >
                      View MoM
                    </AiButton>
                  )}
                  {isLoadingGenerateMoM ? (
                    <AiButton style={{ width: '152px' }} border={true} borderColor={'#00A19C'}>
                      <div
                        style={{ width: '20px', height: '20px' }}
                        className="spinner-border text-primary"
                        role="status"
                      >
                        <span className="sr-only">Loading...</span>
                      </div>
                    </AiButton>
                  ) : (
                    <AiButton
                      border={true}
                      borderColor={'#00A19C'}
                      onKeyDown={() => {}}
                      onClick={() => handleGenerateMoMFile()}
                    >
                      <img src={genarate_document} alt="" style={{ marginRight: '14px' }} />
                      Generate MoM
                    </AiButton>
                  )}
                </AiGroupButton>
              </AiTitleWrapper>
              <FileUpload
                accept=".pdf"
                updateFilesCb={updateUploadedFile}
                setFileUploadStatus={setFileUploadStatus}
                setFileSizeUpload={setFileSizeUpload}
                // comment us-1237574
                // isClickedSave={isClickedSaveBtn}
              />
            </AiContainerForm>
          )}

        {(isViewMOMFileSection ||
          (isOnlyViewMeeting &&
            [MEETING_DETAIL_STATUS.IN_REVIEW, MEETING_DETAIL_STATUS.CONCLUDED].includes(
              meetingDetail?.status
            ))) && (
          <AiContainerForm style={{ marginBottom: '29px', paddingBottom: '29px' }}>
            <AiTitleWrapper>
              <MeetingTitle>
                <AiH2 style={{ fontSize: '18px' }}>{'Approved Minute of Meeting'}</AiH2>
              </MeetingTitle>
            </AiTitleWrapper>
            {meetingDetail?.file_url && meetingDetail?.file_size && (
              <ViewMoMFile meetingDetail={meetingDetail} />
            )}
          </AiContainerForm>
        )}
        <AiContainerForm>
          <MeetingTitle>
            <AiH3>Meeting Details</AiH3>
            {(meeting?.status === MEETING_DETAIL_STATUS.CONCLUDED ||
              (meeting?.status === MEETING_DETAIL_STATUS.IN_REVIEW &&
                isMeetingInReviewWithoutRole)) && <MeetingStatus status={meeting.status} />}
          </MeetingTitle>
          <Row className="mb-3">
            <Col span={24}>
              <AiLabel>Meeting Name</AiLabel>
              <AiInput
                placeholder="Meeting Name"
                name="meeting_name"
                value={formik.values.meeting_name}
                onChange={formik.handleChange}
                status={isCheckError(formik, 'meeting_name') ? 'error' : ''}
                disabled={
                  meeting?.status === MEETING_DETAIL_STATUS.IN_PROGRESS ||
                  (!isEditting && !isEdittingMeetingSelector) ||
                  isOnlyViewMeeting
                }
              />
              <FieldError name="meeting_name" {...formik} />
            </Col>
          </Row>
          <div className={styles.rowInput}>
            <div className={styles.inputItem}>
              <AiLabel>Date</AiLabel>
              <Space direction="vertical" style={{ width: '100%' }}>
                <AiDatePicker
                  onChange={onChange}
                  picker="Date"
                  format={DATE_TIME_FORMAT.DATE_SPACE}
                  status={isCheckError(formik, 'date') ? 'error' : ''}
                  value={
                    formik.values.date && moment(formik.values.date, DATE_TIME_FORMAT.DATE_SPACE)
                  }
                  disabled={
                    meeting?.status === MEETING_DETAIL_STATUS.IN_PROGRESS ||
                    isMeetingInReviewOrConcluded ||
                    (!isEditting && !isEdittingMeetingSelector) ||
                    isOnlyViewMeeting
                  }
                />
                <FieldError name="date" {...formik} />
              </Space>
            </div>
            <div className={styles.inputItem}>
              <AiLabel>Meeting Category</AiLabel>
              <MeetingCategory
                selected={formik.values.category}
                setSelected={(event) => {
                  handleSelectMeetingCategory(event);
                  setIsChangedMeetingCategory(true);
                }}
                options={MEETING_CATEGORY_OPTIONS}
                placeholder="Select Meeting Category"
                status={isCheckError(formik, 'category') ? 'error' : ''}
                disabled={
                  meeting?.status === MEETING_DETAIL_STATUS.IN_PROGRESS ||
                  (!isEditting && !isEdittingMeetingSelector) ||
                  isOnlyViewMeeting
                }
              />
              <FieldError name="category" {...formik} />
            </div>
            {!isEmpty(formik.values.category) &&
              ['Business', 'OPU', 'Sector', 'Division', 'SKG', 'Job Family'].includes(
                formik.values.category?.name
              ) && (
                <div className={styles.inputItem}>
                  <AiLabel>{formik.values.category?.name}</AiLabel>
                  <SearchByCategoryForTcMeeting
                    openDropdown={isOpenCategoryDropdown}
                    setOpenDropdown={setIsOpenCategoryDropdown}
                    setValue={({ val }) => formik.setFieldValue('category_value', val)}
                    searchName={formik.values.category?.name}
                    fieldValue={formik.values.category_value}
                    status={isCheckError(formik, 'category_value') ? 'error' : ''}
                    disabled={
                      meeting?.status === MEETING_DETAIL_STATUS.IN_PROGRESS ||
                      (!isEditting && !isEdittingMeetingSelector) ||
                      isOnlyViewMeeting
                    }
                  />
                  <FieldError name="category_value" {...formik} />
                </div>
              )}
            <div className={styles.inputItem}>
              <AiLabel>Start time</AiLabel>
              <AiTimePickerField
                placeholder={'Select Time'}
                value={
                  formik.values.start_time
                    ? moment(formik.values.start_time, DATE_TIME_FORMAT.TIME).local()
                    : null
                }
                style={{ width: '100%' }}
                onChange={(_event, value) => onChangeInputData(value, 'start_time')}
                status={isCheckError(formik, 'start_time') ? 'error' : ''}
                disabled={
                  meeting?.status === MEETING_DETAIL_STATUS.IN_PROGRESS ||
                  (!isEditting && !isEdittingMeetingSelector) ||
                  isOnlyViewMeeting
                }
                showTime={{ format: DATE_TIME_FORMAT.TIME, use12Hours: true }}
                format={DATE_TIME_FORMAT.TIME}
                showNow={false}
                inputReadOnly={true}
                allowClear={false}
              />
              <FieldError name="start_time" {...formik} />
            </div>
            <div className={styles.inputItem}>
              <AiLabel>End time</AiLabel>
              <AiTimePickerField
                placeholder={'Select Time'}
                value={
                  formik.values.end_time
                    ? moment(formik.values.end_time, DATE_TIME_FORMAT.TIME).local()
                    : null
                }
                style={{ width: '100%' }}
                onChange={(_event, value) => onChangeInputData(value, 'end_time')}
                status={isCheckError(formik, 'end_time') ? 'error' : ''}
                disabled={
                  meeting?.status === MEETING_DETAIL_STATUS.IN_PROGRESS ||
                  (!isEditting && !isEdittingMeetingSelector) ||
                  isOnlyViewMeeting
                }
                showTime={{ format: DATE_TIME_FORMAT.TIME, use12Hours: true }}
                format={DATE_TIME_FORMAT.TIME}
                showNow={false}
                inputReadOnly={true}
                allowClear={false}
              />
              <FieldError name="end_time" {...formik} />
            </div>
          </div>

          <Row className="mb-3">
            <Col span={24}>
              <AiLabel>Location</AiLabel>
              <AiInput
                placeholder="Enter Location"
                name="location"
                value={formik.values.location}
                onChange={formik.handleChange}
                disabled={
                  meeting?.status === MEETING_DETAIL_STATUS.IN_PROGRESS ||
                  (!isEditting && !isEdittingMeetingSelector) ||
                  isOnlyViewMeeting
                }
              />
            </Col>
          </Row>
          <Row className="mb-3">
            <Col span={24}>
              <AiLabel>Chairman</AiLabel>
              <span data-testid="select">
                <AsyncMultipleSelect
                  value={formik.values.chairman}
                  placeholder="Enter Chairman Name"
                  loadOptions={(e) => getAsyncOptionAdvocatorsHrPartners(e, roleActive.roleId)}
                  onChange={(e) => onChangeSelectData(e, 'chairman')}
                  status={isCheckError(formik, 'chairman') ? 'error' : ''}
                  isDisabled={
                    meeting?.status === MEETING_DETAIL_STATUS.IN_PROGRESS ||
                    (!isEditting && !isEdittingMeetingSelector) ||
                    isOnlyViewMeeting
                  }
                />
              </span>

              <FieldError name="chairman" {...formik} />
            </Col>
          </Row>
          <Row className="mb-3">
            <Col span={24}>
              <AiLabel>Committee Members</AiLabel>
              <AsyncMultipleSelect
                placeholder={'Enter Committee Members Name'}
                loadOptions={(e) => getAsyncOptionAdvocatorsHrPartners(e, roleActive.roleId)}
                onChange={(e) => onChangeSelectData(e, 'meeting_committee')}
                isDisabled={
                  meeting?.status === MEETING_DETAIL_STATUS.IN_PROGRESS ||
                  (!isEditting && !isEdittingMeetingSelector) ||
                  isOnlyViewMeeting
                }
                value={formik.values.meeting_committee}
              />
            </Col>
          </Row>
          <Row className="mb-3">
            <Col span={24}>
              <AiLabel>Meeting Facilitators</AiLabel>
              <AsyncMultipleSelect
                placeholder={'Enter Meeting Facilitators Name'}
                onChange={(e) => onChangeSelectData(e, 'meeting_facilitator')}
                loadOptions={(e) => getAsyncOptionAdvocatorsHrPartners(e, roleActive.roleId)}
                isDisabled={
                  meeting?.status === MEETING_DETAIL_STATUS.IN_PROGRESS ||
                  (!isEditting && !isEdittingMeetingSelector) ||
                  isOnlyViewMeeting
                }
                value={formik.values.meeting_facilitator}
              />
            </Col>
          </Row>
          <Row className="mb-3">
            <Col span={24}>
              <AiLabel>Meeting Secretariat</AiLabel>
              <SearchBySecretariatForTcMeeting
                searchName={formik.values.category?.name}
                setValue={({ val }) => {
                  handleSetMeetingSecretariatValue(val);
                  if (formik.values.meeting_secretariats.length === 0) {
                    setIsChangedMeetingCategory(true);
                  }
                }}
                fieldValues={meetingSecretariatValue}
                setMeetingSecretariatValue={setMeetingSecretariatValue}
                status={isCheckError(formik, 'meeting_secretariats') ? 'error' : ''}
                isDisabled={isDisabledMeetingSecretariatField || isOnlyViewMeeting}
              />
              <FieldError name="meeting_secretariats" {...{ formik, forceTouched: true }} />
            </Col>
          </Row>
          <Row className="mb-3">
            <Col span={24}>
              <AiLabel noRequire={true}>Additional View Access</AiLabel>
              <SearchByAdditionalViewAccessForTcMeeting
                searchName={formik.values.category?.name}
                setValue={({ val }) => handleSetAdditionalViewAccess(val)}
                fieldValues={additionalViewAccessValue}
                setAdditionalViewAccessValue={setAdditionalViewAccessValue}
                isDisabled={
                  meeting?.status === MEETING_DETAIL_STATUS.IN_PROGRESS ||
                  (!isEditting && !isEdittingMeetingSelector) ||
                  isOnlyViewMeeting
                }
                // status={isCheckError(formik, 'meeting_additional_view_accesses') ? 'error' : ''}
                // isDisabled={isDisabledAdditionalViewAccess}
              />
              {/* <FieldError name="meeting_additional_view_accesses" {...{ formik, forceTouched: true }} /> */}
            </Col>
          </Row>
        </AiContainerForm>
        {!isOnlyViewMeeting && (
          <CutomBtnWrapper>
            {meeting.status === MEETING_DETAIL_STATUS.IN_PROGRESS &&
              meeting.is_secretariat_or_facilitator && (
                <AiButton onKeyDown={() => {}} onClick={handleEditMeeting}>
                  {isEditting ? 'Done Edit Meeting' : 'Edit Meeting'}
                </AiButton>
              )}
          </CutomBtnWrapper>
        )}
        {visibleExistingAgenda && (
          <ModalAddExistingAgenda
            show={visibleExistingAgenda}
            setShow={setVisibleExistingAgenda}
            idsAgenda={idsAgenda}
          />
        )}
        <AiContainerAgendas>
          {!isOnlyViewMeeting && (
            <AiContainerGrBtn style={{ paddingTop: 0, paddingBottom: 15 }}>
              <AiH3>{header}</AiH3>
              {(isAddAgenda || isAdminRole) && isEditting && !isMeetingInReviewOrConcluded && (
                <AiTitleFlex>
                  <AiButton
                    border={'true'}
                    onKeyDown={() => {}}
                    onClick={() => setVisibleExistingAgenda(true)}
                  >
                    Add Existing Agenda
                  </AiButton>
                  <div>
                    <Dropdown
                      getPopupContainer={(trigger) => trigger.parentElement}
                      menu={{
                        items: [
                          {
                            key: '1',
                            label: (
                              <div onKeyDown={() => {}} onClick={() => createTalentReviewAgenda()}>
                                Talent Review
                              </div>
                            ),
                          },
                          {
                            key: '2',
                            label: (
                              <div onKeyDown={() => {}} onClick={() => createAgenda()}>
                                Succession Planning
                              </div>
                            ),
                          },
                          {
                            key: '3',
                            label: (
                              <div onKeyDown={() => {}} onClick={() => createMobilityAgenda()}>
                                Mobility Discussion
                              </div>
                            ),
                          },
                          {
                            key: '4',
                            label: <div>Others</div>,
                            disabled: true,
                          },
                        ],
                      }}
                      trigger={['click']}
                      placement="bottomLeft"
                    >
                      <AiButton mode="teal" className="ml-3">
                        Add New Agenda
                      </AiButton>
                    </Dropdown>
                  </div>
                </AiTitleFlex>
              )}
            </AiContainerGrBtn>
          )}
          {/* Get Agendas */}
          {agendaList}
          {totalPage > 0 && (
            <Pagination
              total={totalPage}
              showTotal={(total, range) => (
                <>
                  Showing <b>{range[0]}</b> to <b>{range[1]}</b> of <b>{total}</b> results
                </>
              )}
              current={page}
              defaultPageSize={numEachPage}
              showSizeChanger={false}
              onChange={handlePageChange}
            />
          )}
        </AiContainerAgendas>
        {(isDisplayRemark || isShowAllRemark) && (
          <AiContainerForm>
            <Space direction="vertical" size={38} className="w-100">
              {isMeetingInReviewWithoutRole ||
              meeting?.status === MEETING_DETAIL_STATUS.CONCLUDED ||
              isOnlyViewMeeting ? null : (
                <>
                  {currentRemarkToUpdate ? (
                    <EditRemarkOtherAgenda
                      agendaOptions={agendaOptions}
                      currentRemarkToUpdate={currentRemarkToUpdate}
                      onEditRemark={handleEditRemark}
                      setCompareData={setCompareData}
                      setSubmittedRemarkForm={setSubmittedRemarkForm}
                      setCurrentRemarkToUpdate={setCurrentRemarkToUpdate}
                    />
                  ) : (
                    <AddRemarkOtherAgenda
                      agendaOptions={agendaOptions}
                      onAddRemark={handleAddRemark}
                      loading={isCreateRemarkLoading}
                      remarks={remarks}
                      compareData={compareData}
                      setCompareData={setCompareData}
                      setSubmittedRemarkForm={setSubmittedRemarkForm}
                    />
                  )}
                </>
              )}
              <ViewRemarks
                agendaOptions={agendaOptions}
                onFilters={setRemarkFilters}
                isLoading={isGetMeetingLoading}
                remarks={remarks}
                onDeleteRemark={handleDeleteRemark}
                isDeleteRemarkLoading={isDeleteRemarkLoading}
                onEditRemark={handleClickEditRemarkBtn}
                isHiddenAction={
                  [MEETING_DETAIL_STATUS.CONCLUDED].includes(meetingDetail?.status) ||
                  isOnlyViewMeeting
                }
              />
            </Space>
          </AiContainerForm>
        )}

        {!isMeetingInReviewOrConcluded && !isOnlyViewMeeting && (
          <AiContainerGrBtn style={{ paddingTop: '0' }}>
            <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
              {meetingDetail?.is_secretariat_or_facilitator ? (
                <AiButton
                  data-testid="cancel-delete-btn"
                  mode={'danger'}
                  onKeyDown={() => {}}
                  onClick={handleCancelAndDeleteBtn}
                >
                  {checkCancelAndDeleteBtn()}
                </AiButton>
              ) : (
                <AiButton onKeyDown={() => {}} onClick={onClickBack}>
                  <span data-testid="back-btn">Back</span>
                </AiButton>
              )}
            </div>
          </AiContainerGrBtn>
        )}
      </form>

      {/*
       * IMPLEMENT MODEL
       */}
      <ModelTC
        info={{
          type: 'editFromCancel',
          visible: isDislayEditPopup,
          setVisible: setIsDislayEditPopup,
          onClose: () => setIsDislayEditPopup(false),
          handleSubmit: async () => {
            try {
              let res = await meetingApi.editMeetingFromCancel(roleActive, meeting);
              if (res.status === 200) {
                history.push(`/meeting/${meeting.meeting_id}`);
              }
            } catch (error) {
              console.error(error);
            }
            setIsDislayEditPopup(false);
          },
          loading: loadingEditFromCancel,
        }}
      />

      <ModelTC
        info={{
          type: 'updateMeeting',
          visible: visibleUpdateMeetingModal,
          setVisible: setVisibleUpdateMeetingModal,
          onClose: exitCreateOrEditMeeting,
          handleSubmit: async () => {
            setVisibleUpdateMeetingModal(false);
            setResendNewInvite(true);
          },
        }}
      />
      <ModelTC
        info={{
          type: 'resendNewInvite',
          visible: resendNewInvite,
          setVisible: setResendNewInvite,
          onClose: exitResendNewInvite,
          handleSubmit: async () => {
            setResendNewInvite(false);
            setIsSubmitToResendInvatation(true);
            handleSubmitForm();
            createOrUpdateAgendas();
            resendNewInviteByMeetingId();
            dispatch(changeMeetingStatus(false));
          },
        }}
      />

      <ModelTC
        info={{
          type: 'paperForward',
          visible: visibleAddAgendaPaperForward,
          setVisible: setVisibleAddAgendaPaperForward,
          handleSubmit: async () => {
            setLoadingAddAgendaPaperForward(true);
            try {
              await meetingApi.updateAgendaExisted({
                roleUser: user,
                agendaId: agendaIdPaperForward.agendaId,
                type: agendaIdPaperForward.type,
              });
              await getAgendas();
              setAgendaIdPaperForward(false);
              setLoadingAddAgendaPaperForward(false);
              setVisibleAddAgendaPaperForward(false);
            } catch (error) {
              showNotification(`Add Agenda failed`, NOTIFICATION_TYPE.FAILED);
              setAgendaIdPaperForward(false);
              setLoadingAddAgendaPaperForward(false);
              setVisibleAddAgendaPaperForward(false);
            }
          },
          onClose: () => {},
          loading: loadingAddAgendaPaperForward,
        }}
      />
      <ModelTC
        info={{
          type: 'withoutSaving',
          visible: visibleWithOutSave,
          setVisible: setVisibleWithOutSave,
          onClose: exitAgenda,
          handleSubmit: async () => {
            await createOrUpdateAgendas();
            setVisibleWithOutSave(false);
          },
        }}
      />
      <ModelTC
        info={{
          type: 'deleteAgenda',
          visible: visibleRemoveAgenda,
          setVisible: setVisibleRemoveAgenda,
          handleSubmit: async () => {
            let idxEleAgendaHasCreated = listAgendas.findIndex(
              (item) => item.agenda_id === currentAgendaId
            );
            if (idxEleAgendaHasCreated !== -1) {
              setListAgendas((preState) => {
                return preState.filter((item) => item.agenda_id !== currentAgendaId);
              });
              dispatch(updateRemoveAgendas(currentAgendaId));
            } else {
              let idxEle = agendas.findIndex((item) => item.agenda_id === currentAgendaId);
              if (idxEle !== -1) {
                dispatch(removeAgenda(currentAgendaId));
                dispatch(removeCreatedOrExistedAgenda(currentAgendaId));
              }
            }
            dispatch(
              pushMessage({
                type: MESSAGE_TYPES.SUCCESS,
                message: 'Agenda has been deleted successfully.',
                timeShow: 0,
              })
            );
            setVisibleRemoveAgenda(false);
            setPage(1);
            setMinValue(0);
            setMaxValue(6);
          },
          onClose: () => {},
        }}
      />
      <ModelTC
        info={{
          type: 'cancelMeeting',
          visible: visibleCancelMeetingModal,
          setVisible: setVisibleCancelMeetingModal,
          handleSubmit: async () => {
            handleCancelMeetingById();
            setVisibleCancelMeetingModal(false);
          },
          onClose: exitCancelMeeting,
        }}
      />
      <ModelTC
        info={{
          type: 'startMeeting',
          visible: visibleStartMeetingModal,
          setVisible: setVisibleStartMeetingModal,
          handleSubmit: async () => {
            handleStartMeetingById();
            setVisibleStartMeetingModal(false);
          },
          onClose: exitStartMeeting,
        }}
      />
      <ModelTC
        info={{
          type: 'completeMeeting',
          visible: visibleCompleteMeetingModal,
          setVisible: setVisibleCompleteMeetingModal,
          handleSubmit: () => handleConfirmToCompleteMeeting(),
          onClose: () => setVisibleCompleteMeetingModal(false),
        }}
      />
      <ModelTC
        info={{
          type: 'deleteMeeting',
          visible: visibleRemoveMeeting,
          setVisible: setVisibleRemoveMeeting,
          handleSubmit: async () => {
            setLoadingDeleteMeeting(true);
            if (!idMeeting) {
              exitDeleteMeeting();
            } else {
              try {
                let res = await meetingApi.deleteMeeting({ idMeeting });
                if (res.status === 200) {
                  exitDeleteMeeting();
                  setVisibleRemoveMeeting(false);
                  setLoadingDeleteMeeting(false);
                }
              } catch (error) {
                showNotification(`Delete Meeting failed`, NOTIFICATION_TYPE.FAILED);
                setVisibleRemoveMeeting(false);
                setLoadingDeleteMeeting(false);
              }
            }
          },
          onClose: () => {},
          loading: loadingDeleteMeeting,
        }}
      />
      <ModelTC
        info={{
          type: 'saveTheMeeting',
          visible: visibleSaveTheMeeting,
          setVisible: setVisibleSaveTheMeeting,
          handleSubmit: handleSaveTheMeeting,
          onClose: () => {},
          loading: false,
        }}
      />
      <ModelTC
        info={{
          type: 'notifyFacilitator',
          visible: visibleNotifyFacilitator,
          setVisible: setVisibleNotifyFacilitator,
          handleSubmit: handleNotifyFacilitator,
          onClose: () => {},
          loading: false,
        }}
      />
      <ModelTC
        info={{
          type: 'concludeMeeting',
          visible: visibleConcludeMeetingModal,
          setVisible: setVisibleConcludeMeetingModal,
          handleSubmit: async () => {
            setLoadingConcludeMeeting(true);
            try {
              const res = await meetingApi.concludeMeeting(idMeeting, roleActive);
              if (res.status === 200) {
                setLoadingConcludeMeeting(false);
                history.push(`/meeting-conclude`);
              }
            } catch (error) {
              setLoadingConcludeMeeting(false);
              console.error(error);
            }
            setVisibleConcludeMeetingModal(false);
          },
          onClose: exitConcludeMeeting,
          loading: loadingConcludeMeeting,
        }}
      />
      <ModelTC
        info={{
          type: 'leaveWhileAddAgenda',
          visible: showPopup,
          setVisible: setShowPopup,
          handleSubmit: async () => {
            await createOrUpdateAgendas();
            handleKeepNavigate();
            setShowPopup(false);
          },
          onClose: () => {
            dispatch(changeMeetingStatus(false));
            dispatch(clearMeeting());
            dispatch(clearAgendaState());
            setShowPopup(false);
            handleKeepNavigate();
          },
          loading: loadingCreateOrUpdateAgendas || loadingGetAgendas,
        }}
      />
      <ModelTC
        info={{
          type: `withoutSavingAgenda`,
          title: `You are about to leave this page without saving.`,
          desc: getDescForWithoutModalMeetingDetails(
            isDifferentFieldMeetingDetails(compareData),
            idMeeting
          ),
          visible: isShowPopupSaveChangesMeeting,
          setVisible: setIsShowPopupSaveChangesMeeting,
          handleSubmit: async () => {
            const isCheckRemarkField =
              isDifferentFieldMeetingDetails(compareData).filter((item) => item.includes(`remark_`))
                .length > 0;
            if (isCheckRemarkField) {
              dispatch(updateSubmittedFormChanges(true));
            } else {
              const res = await handleSubmitForm();
              if (res) {
                keepNavigateWhenFieldChanges(navigatingPathname ? navigatingPathname : '/calendar');
                setIsShowPopupSaveChangesMeeting(false);
              }
            }
          },
          onClose: () => {
            dispatch(changeMeetingStatus(false));
            dispatch(clearMeeting());
            dispatch(clearAgendaState());
            keepNavigateWhenFieldChanges(navigatingPathname ? navigatingPathname : '/calendar');
            setIsShowPopupSaveChangesMeeting(false);
          },
          loading: false,
        }}
      />
    </>
  );
};
export default ViewMeetingDetail;
