import { Input, List, Spin } from 'antd';
import { get, isEmpty, uniqueId } from 'lodash';
import React, { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { search_dropdown } from '../../assets/img';
import {
  ListAntd,
  SearchInput,
  TitleDiv,
  Wrapper,
  WrapperPopUp,
} from './SearchBusinessMeetingFilterStyled';
import { BasicPopover } from '../../assets/common';
import { meetingApi } from '../../services/tcMeeting';
import { InView } from 'react-intersection-observer';
import { useSelector } from 'react-redux';

const convertType = [
  { key: 'business_unit', business: 'Business' },
  { key: 'sector', business: 'Sector' },
  { key: 'opu_name', business: 'OPU' },
  { key: 'division', business: 'Division' },
  { key: 'name', business: 'Job Family' },
  { key: 'name', business: 'SKG' },
];

const initQuery = {
  keyword: '',
  search: '',
  page: 1,
  limit: 15,
  open: false,
  loading: false,
  data: [],
  hasMore: true,
};
const SearchBusinessMeetingFilter = (props) => {
  const { business, setSelected, columns, uiDisplay } = props;
  const [query, setQuery] = useState(initQuery);
  const roleActive = useSelector((state) => state.user.roleActive);

  const getFilterBusinessMeeting = async () => {
    if (query.loading) {
      return;
    }
    try {
      setQuery((prev) => ({ ...prev, loading: true }));
      const res = await meetingApi.fetchFilterBusinessMeeting(
        {
          search_text: query.search,
          page: query.page,
          limit: query.limit,
          is_full_category_dropdown: true,
          meeting_category: business,
        },
        roleActive.roleId
      );
      const result = get(res, 'data.result.data') || [];
      if (isEmpty(result)) return setQuery((prev) => ({ ...prev, hasMore: false }));
      if (query.page > 1) {
        setQuery((prev) => ({ ...prev, data: [...prev.data, ...result], hasMore: true }));
      } else {
        setQuery((prev) => ({ ...prev, data: !isEmpty(result) ? result : [], hasMore: true }));
      }
    } catch (error) {
      console.error(error);
      setQuery((prev) => ({
        ...prev,
        page: 1,
        search: '',
        data: [],
        hasMore: true,
      }));
    } finally {
      setQuery((prev) => ({ ...prev, loading: false }));
    }
  };

  useEffect(() => {
    if (query.open) getFilterBusinessMeeting();
  }, [query?.search, query?.page, query.open]);

  useEffect(() => {
    if (!query.open) return;
    const delayInputTimeoutId = setTimeout(() => {
      setQuery((prev) => ({
        ...prev,
        page: 1,
        search: prev.keyword,
        data: [],
        hasMore: true,
      }));
    }, 1000);
    return () => clearTimeout(delayInputTimeoutId);
  }, [query.keyword]);

  useEffect(() => {
    setQuery(initQuery);
  }, [business]);

  return (
    <BasicPopover
      getPopupContainer={(trigger) => trigger}
      placement={
        ['Business', 'OPU', 'Division', 'Sector'].includes(business) ? 'bottomRight' : 'bottomLeft'
      }
      trigger="click"
      styles={{
        hideArrow: true,
        minWidth: ['Business', 'OPU', 'Division', 'Sector'].includes(business) ? null : 200,
        w: ['Business', 'OPU', 'Division', 'Sector'].includes(business)
          ? 1000
          : ['Job Family', 'SKG'].includes(business)
          ? 360
          : null,
        px: 0,
        py: 0,
        isWidthNotMaxContent: true,
      }}
      open={query.open}
      onOpenChange={(open) => {
        setQuery((prev) => ({ ...prev, open, keyword: '' }));
      }}
      content={
        <Wrapper>
          <Spin spinning={query.loading}>
            <WrapperPopUp>
              <SearchInput style={{ maxWidth: '400px' }}>
                <Input
                  placeholder="Search by Business, Sector, OPU, Division"
                  value={query.keyword}
                  onChange={(e) => {
                    const val = e.target.value;
                    setQuery((prev) => ({ ...prev, keyword: val }));
                  }}
                  suffix={<img src={search_dropdown} style={{ marginBottom: '10px' }} />}
                />
              </SearchInput>
              <TitleDiv
                width={
                  ['Business', 'OPU', 'Division', 'Sector'].includes(business)
                    ? '245px'
                    : ['Job Family', 'SKG'].includes(business)
                    ? '360px'
                    : null
                }
              >
                {columns.map((m) => (
                  <div
                    style={{ backgroundColor: m === business ? '#C9D5E3' : '' }}
                    key={uniqueId()}
                  >
                    {m}
                  </div>
                ))}
              </TitleDiv>
              <div
                id="scrollableDiv"
                style={{
                  maxHeight: '264px',
                  overflow: 'auto',
                  width: '100%',
                  backgroundColor: 'white',
                }}
              >
                <ListAntd
                  dataSource={query.data}
                  split={false}
                  width={
                    ['Business', 'OPU', 'Division', 'Sector'].includes(business)
                      ? '245px'
                      : ['Job Family', 'SKG'].includes(business)
                      ? '360px'
                      : null
                  }
                  renderItem={(item, index) => (
                    <List.Item
                      key={uuidv4()}
                      onClick={() => {
                        setQuery((prev) => ({ ...prev, open: false }));
                        const value = item[convertType.find((f) => f.business === business)?.key];
                        setSelected(value);
                      }}
                    >
                      {columns.map((m) => (
                        <div className={business === m ? 'function-name' : ''} key={uniqueId()}>
                          <InView
                            as="div"
                            style={{ fontWeight: 400 }}
                            onChange={(inView) => {
                              index === query.data.length - 1 &&
                                query.hasMore &&
                                inView &&
                                setQuery((prev) => ({
                                  ...prev,
                                  page: prev.loading ? prev.page : prev.page + 1,
                                }));
                            }}
                          >
                            {item[convertType.find((f) => f.business === m)?.key]}
                          </InView>
                        </div>
                      ))}
                    </List.Item>
                  )}
                />
              </div>
            </WrapperPopUp>
          </Spin>
        </Wrapper>
      }
    >
      {uiDisplay}
    </BasicPopover>
  );
};
export default SearchBusinessMeetingFilter;
