/* eslint-disable no-case-declarations */
/* eslint-disable react-hooks/exhaustive-deps */
import './recommentdation.scss';

import { DndContext, DragOverlay } from '@dnd-kit/core';
import { cloneDeep, find, findIndex, isEmpty } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import {
  orderTalents,
  updateMatchingPercentLineCard,
  updateTalents,
} from '../../../redux/agendaSlice';
import Box from './Box/Box';
import RecommendationTable from './RecommendationTable/RecommendationTable';
import CardItem from './wrappers/CardItem';
import { meetingApi } from '../../../../../services/tcMeeting';
import { staffIdMap } from '../../../../../utils/helper';

const Recommendations = ({
  positions,
  isReadOnly,
  setLineRe,
  isViewModeAgenda,
  showMessageToDragFromRecommendation,
  meetingDetail,
  ...otherProps
}) => {
  const dispatch = useDispatch();
  const [line1, setLine1] = useState([]);
  const [line2, setLine2] = useState([]);
  const [line3, setLine3] = useState([]);
  const [table, setTable] = useState({
    tableA: [],
    tableB: [],
  });
  const [draggingItem, setDraggingItem] = useState(null);
  const [dragItem, setDragItem] = useState({});
  const [draggingOver, setDraggingOver] = useState(null);
  const [useWorkaround] = useState(false);

  const [optionSets, setOptionSets] = useState([]);
  const [selectedOptionSet, setSelectedOptionSet] = useState({});
  const [isFetchMatchingPercentLine, setIsFetchMatchingPercentLine] = useState(false);

  ///position_code for recommendation
  const { position_code, agendaIncumbent } = useSelector((state) => state.app.meetingModule.agenda);
  const { agendaId } = useParams();

  const lineStaffIds = useMemo(() => {
    return [
      ...staffIdMap(agendaIncumbent.first_line),
      ...staffIdMap(agendaIncumbent.second_line),
      ...staffIdMap(agendaIncumbent.third_line),
    ];
  }, [agendaIncumbent.first_line, agendaIncumbent.second_line, agendaIncumbent.third_line]);

  useEffect(() => {
    const getDataForAiTab = async () => {
      try {
        const responseSetLists = await meetingApi.getSetListSPMatchingCriteria(position_code[0]);
        const resultSets = responseSetLists?.data?.result || [];
        if (!resultSets || resultSets.length === 0) return;

        setOptionSets(resultSets);
        const mainSet = find(resultSets, (set) => set && set.is_main);
        setSelectedOptionSet(mainSet ? mainSet : resultSets[0]);
      } catch (error) {
        console.error(error);
      }
    };
    if (!position_code || !position_code?.length) return;
    getDataForAiTab();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(position_code)]);

  const fetchLineMatchingPercent = async () => {
    try {
      const body = {
        staffIds: lineStaffIds,
        setId: selectedOptionSet.set_id,
        positionCode: position_code[0],
      };
      const res = await meetingApi.getMatchingPercentageForLine(body);
      if (res.status === 200) {
        dispatch(updateMatchingPercentLineCard(res.data.result));
      }
    } catch (error) {
      console.error(error);
    }
  };

  //handle reset Matching Percent when change setId
  useEffect(() => {
    if (isEmpty(selectedOptionSet) || isEmpty(lineStaffIds)) return;
    setIsFetchMatchingPercentLine(true);
  }, [selectedOptionSet, Boolean(lineStaffIds.length)]);

  useEffect(() => {
    if (!isFetchMatchingPercentLine) return;
    fetchLineMatchingPercent();
    setIsFetchMatchingPercentLine(false);
  }, [isFetchMatchingPercentLine]);

  function addItem(item, text) {
    if (!item.staff_id) return;
    switch (text) {
      case '1st':
        //add new
        const newLines1 = [...line1];
        const newItem1 = newLines1.find((d) => d.staff_id === item.staff_id);
        if (newItem1) return;
        newLines1.push(item);

        //remove old line
        const first1 = [...line2].filter((d) => d.staff_id !== item.staff_id);
        const second1 = [...line3].filter((d) => d.staff_id !== item.staff_id);

        //update redux
        dispatch(
          updateTalents({ first_line: newLines1, second_line: first1, third_line: second1 })
        );
        break;
      case '2nd':
        //add new
        const newLines2 = [...line2];
        const newItem2 = newLines2.find((d) => d.staff_id === item.staff_id);
        if (newItem2) return;
        newLines2.push(item);

        //remove old line
        const first2 = [...line1].filter((d) => d.staff_id !== item.staff_id);
        const second2 = [...line3].filter((d) => d.staff_id !== item.staff_id);

        //update redux
        dispatch(
          updateTalents({ first_line: first2, second_line: newLines2, third_line: second2 })
        );

        break;
      case '3rd':
        //add new
        const newLines3 = [...line3];
        const newItem3 = newLines3.find((d) => d.staff_id === item.staff_id);
        if (newItem3) return;
        newLines3.push(item);

        //remove old line
        const first3 = [...line1].filter((d) => d.staff_id !== item.staff_id);
        const second3 = [...line2].filter((d) => d.staff_id !== item.staff_id);

        //update redux
        dispatch(
          updateTalents({ first_line: first3, second_line: second3, third_line: newLines3 })
        );

        break;
      default:
        break;
    }
    setIsFetchMatchingPercentLine(true);
  }

  const updatePrevAgenda = ({ first_line, second_line, third_line }) => {
    otherProps?.setIsPositionChanges && otherProps.setIsPositionChanges(true);
    otherProps?.setPrevAgendaData &&
      otherProps.setPrevAgendaData((prev) => {
        return {
          ...prev,
          first_line:
            JSON.stringify(prev?.first_line) !== JSON.stringify(first_line)
              ? prev?.first_line
              : first_line,
          second_line:
            JSON.stringify(prev?.second_line) !== JSON.stringify(second_line)
              ? prev?.second_line
              : second_line,
          third_line:
            JSON.stringify(prev?.third_line) !== JSON.stringify(third_line)
              ? prev?.third_line
              : third_line,
        };
      });
  };

  useEffect(() => {
    setLineRe(() => {
      return {
        line1: line1,
        line2: line2,
        line3: line3,
      };
    });
    updatePrevAgenda({
      first_line: line1,
      second_line: line2,
      third_line: line3,
    });
  }, [line2, line1, line3, setLineRe]);

  useEffect(() => {
    setLine1(agendaIncumbent.first_line || []);
    setLine2(agendaIncumbent.second_line || []);
    setLine3(agendaIncumbent.third_line || []);
    updatePrevAgenda({
      first_line: agendaIncumbent?.first_line || [],
      second_line: agendaIncumbent?.second_line || [],
      third_line: agendaIncumbent?.third_line || [],
    });
  }, [
    agendaId,
    JSON.stringify(agendaIncumbent.first_line),
    JSON.stringify(agendaIncumbent.second_line),
    JSON.stringify(agendaIncumbent.third_line),
  ]);

  function handleDragStart(event) {
    setDraggingItem(event.active.id);
    if (event.active.data.current) {
      setDragItem(event.active.data.current);
    }
  }
  function handleDragOver(event) {
    setDraggingOver(event.over?.id || null);
  }
  const handeDefineTypeAndIndex = (data, item) => {
    const arrayType = data.split('-');
    let arrayLine = [];
    if (arrayType[0] === '1st') {
      arrayLine = [...line1];
    }
    if (arrayType[0] === '2nd') {
      arrayLine = [...line2];
    }
    if (arrayType[0] === '3rd') {
      arrayLine = [...line3];
    }
    if (arrayType[0] === 'recommendation') {
      arrayLine = [...cloneDeep(table.tableA)];
    }
    if (arrayType[0] === 'filtered') {
      arrayLine = [...cloneDeep(table.tableB)];
    }
    return {
      name: arrayType[0],
      index: arrayType[2],
      arrayLine,
      item,
    };
  };
  function swap(array, indexA, indexB) {
    const item = array[indexA];
    array = cloneDeep(array).map((val) => {
      if (val.staff_id === item.staff_id) val.staff_id = 'delete';
      return val;
    });
    if (indexB === 0) {
      array.unshift(item);
    } else if (indexB === array.length - 1 && indexA === array.length - 2) {
      array.push(item);
    } else {
      array.splice(indexB, 0, item);
    }
    return array.filter((item) => item.staff_id !== 'delete');
  }
  function handleDragEnd(event) {
    const idActive = event.active.id;

    if (!event.over) return;
    const idOver = event.over.id;
    const itemActive = event.active.data.current;
    const itemOver = event.over.data.current;
    const activeInfo = handeDefineTypeAndIndex(idActive, itemActive);
    const overInfo = handeDefineTypeAndIndex(idOver, itemOver);

    if (activeInfo.name === overInfo.name) {
      if (!overInfo.index && activeInfo.index) {
        return;
      }
      const newArr = swap(activeInfo.arrayLine, activeInfo.index, overInfo.index);

      if (activeInfo.name === '1st') {
        dispatch(orderTalents({ first_line: newArr, second_line: line2, third_line: line3 }));
      }
      if (activeInfo.name === '2nd') {
        dispatch(orderTalents({ first_line: line1, second_line: newArr, third_line: line3 }));
      }
      if (activeInfo.name === '3rd') {
        dispatch(orderTalents({ first_line: line1, second_line: line2, third_line: newArr }));
      }
    }

    const allLines = {
      first_line: line1,
      second_line: line2,
      third_line: line3,
    };

    if (activeInfo.name !== 'recommendation' && activeInfo.name !== 'filtered') {
      if (activeInfo.name !== overInfo.name) {
        activeInfo.arrayLine.splice(activeInfo.index, 1);

        if (activeInfo.name === '1st') {
          allLines.first_line = activeInfo.arrayLine;
        }
        if (activeInfo.name === '2nd') {
          allLines.second_line = activeInfo.arrayLine;
        }
        if (activeInfo.name === '3rd') {
          allLines.third_line = activeInfo.arrayLine;
        }
        if (!overInfo.index) overInfo.arrayLine.push(activeInfo.item);
        if (overInfo.index) overInfo.arrayLine.splice(overInfo.index, 0, activeInfo.item);

        if (overInfo.name === '1st') {
          allLines.first_line = overInfo.arrayLine;
        }
        if (overInfo.name === '2nd') {
          allLines.second_line = overInfo.arrayLine;
        }
        if (overInfo.name === '3rd') {
          allLines.third_line = overInfo.arrayLine;
        }
        dispatch(orderTalents(allLines));
      }
    }
    //add from recommendation and filtered table
    if (activeInfo.name === 'recommendation' || activeInfo.name === 'filtered') {
      const totalArray = [...line1, ...line2, ...line3];
      let isCheck = findIndex(totalArray, (item) => item.staff_id === activeInfo.item.staff_id);
      if (isCheck !== -1) {
        if (findIndex(line1, (item) => item.staff_id === activeInfo.item.staff_id) !== -1) {
          showMessageToDragFromRecommendation('1st Line');
          return;
        }
        if (findIndex(line2, (item) => item.staff_id === activeInfo.item.staff_id) !== -1) {
          showMessageToDragFromRecommendation('2nd Line');
          return;
        }
        if (findIndex(line3, (item) => item.staff_id === activeInfo.item.staff_id) !== -1) {
          showMessageToDragFromRecommendation('3rd Line');
          return;
        }
        return;
      }

      if (!overInfo.index) overInfo.arrayLine.push(activeInfo.item);
      if (overInfo.index) overInfo.arrayLine.splice(overInfo.index, 0, activeInfo.item);

      if (overInfo.name === '1st') {
        allLines.first_line = overInfo.arrayLine;
      }
      if (overInfo.name === '2nd') {
        allLines.second_line = overInfo.arrayLine;
      }
      if (overInfo.name === '3rd') {
        allLines.third_line = overInfo.arrayLine;
      }
      dispatch(orderTalents(allLines));
    }

    //// Swap item
    setDraggingItem(null);
    setDraggingOver(null);
  }

  return (
    <DndContext
      onDragStart={handleDragStart}
      onDragOver={handleDragOver}
      onDragEnd={handleDragEnd}
      autoScroll={{ enabled: useWorkaround ? !!draggingOver : true }}
    >
      <div className="d-flex justify-content-between align-items-start no-gutters">
        <div className={`${!isReadOnly ? 'col-7' : 'col-12'} mr-4`}>
          <Box
            color={'#66B2C4'}
            text={'1st'}
            line={line1}
            positionsCode={position_code}
            addItem={addItem}
            isReadOnly={isReadOnly || isViewModeAgenda}
            code={'first_line'}
            lines={(!line1 ? [] : line1).concat(!line2 ? [] : line2).concat(!line3 ? [] : line3)}
            meetingDetail={meetingDetail}
          />
          <Box
            color={'#BDB6EA'}
            text={'2nd'}
            line={line2}
            positionsCode={position_code}
            addItem={addItem}
            isReadOnly={isReadOnly || isViewModeAgenda}
            code={'second_line'}
            lines={(!line1 ? [] : line1).concat(!line2 ? [] : line2).concat(!line3 ? [] : line3)}
            meetingDetail={meetingDetail}
          />
          <Box
            color={'#BCC6E1'}
            text={'3rd'}
            line={line3}
            positionsCode={position_code}
            addItem={addItem}
            isReadOnly={isReadOnly || isViewModeAgenda}
            code={'third_line'}
            lines={(!line1 ? [] : line1).concat(!line2 ? [] : line2).concat(!line3 ? [] : line3)}
            meetingDetail={meetingDetail}
          />
          <DragOverlay
            dropAnimation={{
              duration: 500,
            }}
          >
            {draggingItem ? <CardItem item={dragItem} index={dragItem.index} /> : null}
          </DragOverlay>
        </div>

        {!isReadOnly && (
          <div className="col">
            <RecommendationTable
              dataTableA={table.tableA}
              dataTableB={table.tableB}
              setTable={setTable}
              positionsCode={position_code}
              positionName={positions?.[0]?.position_name || ''}
              isViewModeAgenda={isViewModeAgenda}
              setIsDisabledPrintBtn={otherProps?.setIsDisabledPrintBtn}
              optionSets={optionSets}
              selectedOptionSet={selectedOptionSet}
              setSelectedOptionSet={setSelectedOptionSet}
            />
          </div>
        )}
      </div>
    </DndContext>
  );
};

export default Recommendations;
