/* eslint-disable react-hooks/exhaustive-deps */
import { Col, Row, Tooltip } from 'antd';

import moment from 'moment';
import React, { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { hr_info } from '../../../../assets/img';
import { AsyncMultipleSelect, SingleSelect } from '../../../../components';
import { isCheckError, onChangeInputData, showError } from '../../../../hooks/useFormik';
import { useGetRoleInMeeting } from '../../../../hooks/useGetRoleInMeeting';
import {
  AGENDA_STATUS,
  DATE_TIME_FORMAT,
  MEETING_DETAIL_STATUS,
  ROLE,
} from '../../../../utils/constants';
import {
  getAsyncOptionAdvocatorsHrPartners,
  isDisabledFiledInAgendaForm,
} from '../../../../utils/helper';
import {
  AiAgendaForm,
  AiInput,
  AiLabel,
  AiTimePickerField,
  AiTitle,
} from '../../../Meeting/AgendaDetails/Styled';
import { font } from '../../../../assets/common';

const TalentReviewAgendaForm = (props) => {
  const {
    agendaDetail,
    isShowTalentDetail,
    formik,
    addTalent,
    setAddTalent,
    handleChangeActionType,
    messageActionType,
  } = props;
  const roleActive = useSelector((state) => state.user.roleActive);
  const { idMeeting, agendaId } = useParams();
  const { data: meetingRole } = useGetRoleInMeeting({ idMeeting });

  const isAdminRole = useMemo(() => {
    return roleActive?.roleName ? roleActive?.roleName.includes(ROLE.ADMIN) : false;
  }, [roleActive]);

  const isAdditionalViewAccess = useMemo(() => {
    if (!agendaId) return false;

    return (
      !meetingRole?.is_secretariat_or_facilitator &&
      !meetingRole?.is_chairman &&
      !meetingRole?.is_commitee &&
      !isAdminRole &&
      meetingRole?.is_additional_view_access
    );
  }, [meetingRole, agendaId, isAdminRole]);

  const isDisabledField = useMemo(() => {
    return (
      isDisabledFiledInAgendaForm(agendaId, agendaDetail, meetingRole, isAdminRole) ||
      isAdditionalViewAccess
    );
  }, [agendaDetail, agendaId, meetingRole, isAdminRole, isAdditionalViewAccess]);

  useEffect(() => {
    formik.setFieldValue('is_has_staff', isShowTalentDetail ? true : false);
    if (formik.values?.action_type === null) formik.setFieldValue('action_type', '');
  }, [isShowTalentDetail]);

  const isDisableActionType = useMemo(() => {
    if (isAdditionalViewAccess) {
      return true;
    }
    if (
      !isNaN(Number(agendaId)) &&
      [MEETING_DETAIL_STATUS.IN_PLANNING, MEETING_DETAIL_STATUS.PUBLISHED].includes(
        agendaDetail?.meeting_status
      ) &&
      agendaDetail?.status !== AGENDA_STATUS.PAPER_IN_PROGRESS
    ) {
      return true;
    }
    if (
      !isNaN(Number(agendaId)) &&
      MEETING_DETAIL_STATUS.IN_PROGRESS === agendaDetail?.meeting_status &&
      ![AGENDA_STATUS.PAPER_IN_PROGRESS, AGENDA_STATUS.PAPER_READY].includes(agendaDetail?.status)
    ) {
      return true;
    }
    if (
      !isNaN(Number(agendaId)) &&
      MEETING_DETAIL_STATUS.IN_REVIEW === agendaDetail?.meeting_status &&
      agendaDetail?.status !== AGENDA_STATUS.PAPER_FINALIZED
    ) {
      return true;
    }

    if (!isNaN(Number(agendaId)) && !meetingRole?.is_secretariat_or_facilitator) {
      return true;
    }
    if (
      !isNaN(Number(agendaId)) &&
      [
        MEETING_DETAIL_STATUS.PENDING_APPROVAL,
        MEETING_DETAIL_STATUS.MOM_REJECTED,
        MEETING_DETAIL_STATUS.CANCELED,
        MEETING_DETAIL_STATUS.MEETING_COMPLETED,
        MEETING_DETAIL_STATUS.CONCLUDED,
      ].includes(agendaDetail?.meeting_status)
    ) {
      return true;
    }
    return false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agendaDetail?.status, agendaId, agendaDetail?.meeting_status, isAdditionalViewAccess]);

  const actionTypeOptions = [
    { label: 'To Review', value: 'Reviewed' },
    { label: 'To Endorse', value: 'Endorsed' },
    { label: 'To Approve', value: 'Approved' },
  ];

  const actionType =
    actionTypeOptions.find((item) => item.value === formik.values.action_type) || '';

  useEffect(() => {
    if (addTalent?.top_talent?.is_updated) {
      formik.setFieldValue('action_type', actionType.value || '');
      setAddTalent((prev) => ({
        ...prev,
        top_talent: { ...prev.top_talent, is_updated: false },
      }));
    }
  }, [addTalent?.top_talent?.is_updated]);

  return (
    <AiAgendaForm>
      <AiTitle>Talent Review Session Details</AiTitle>
      <Row gutter={16} className="mb-3">
        <Col span={16} className="gutter-row">
          <AiLabel>
            Talent Review Name <span style={{ color: '#FF787F' }}>*</span>
          </AiLabel>
          <AiInput
            placeholder="Enter Talent Review Name"
            name="title"
            value={formik.values.title}
            onChange={formik.handleChange}
            status={isCheckError(formik, 'title') ? 'error' : ''}
            disabled={isDisabledField}
          />
          {showError(formik, 'title')}
        </Col>

        <Col span={4} className="gutter-row">
          <AiLabel>
            Start time <span style={{ color: '#FF787F' }}>*</span>
          </AiLabel>
          <AiTimePickerField
            placeholder={'Select Time'}
            value={
              formik.values.start_time
                ? moment(formik.values.start_time, DATE_TIME_FORMAT.TIME).local()
                : null
            }
            style={{ width: '100%' }}
            onChange={(_event, value) => onChangeInputData(formik, value, 'start_time')}
            status={isCheckError(formik, 'start_time') ? 'error' : ''}
            showTime={{ format: DATE_TIME_FORMAT.TIME, use12Hours: true }}
            format={DATE_TIME_FORMAT.TIME}
            showNow={false}
            inputReadOnly={true}
            allowClear={false}
            getPopupContainer={(trigger) => trigger.parentElement}
            disabled={isDisabledField}
          />
          {showError(formik, 'start_time')}
        </Col>
        <Col span={4} className="gutter-row">
          <AiLabel>
            End time <span style={{ color: '#FF787F' }}>*</span>
          </AiLabel>
          <AiTimePickerField
            placeholder={'Select Time'}
            value={
              formik.values.end_time
                ? moment(formik.values.end_time, DATE_TIME_FORMAT.TIME).local()
                : null
            }
            style={{ width: '100%' }}
            onChange={(_event, value) => onChangeInputData(formik, value, 'end_time')}
            status={isCheckError(formik, 'end_time') ? 'error' : ''}
            showTime={{ format: DATE_TIME_FORMAT.TIME, use12Hours: true }}
            format={DATE_TIME_FORMAT.TIME}
            showNow={false}
            inputReadOnly={true}
            allowClear={false}
            getPopupContainer={(trigger) => trigger.parentElement}
            disabled={isDisabledField}
          />
          {showError(formik, 'end_time')}
        </Col>
      </Row>
      <Row className="mb-3">
        <Col span={24}>
          <AiLabel>
            Advocators <span style={{ color: '#FF787F' }}>*</span>
          </AiLabel>
          <AsyncMultipleSelect
            value={formik.values.advocators}
            placeholder="Enter Advocators Name"
            loadOptions={(e) => getAsyncOptionAdvocatorsHrPartners(e, roleActive.roleId)}
            onChange={(e) => onChangeInputData(formik, e, 'advocators')}
            status={isCheckError(formik, 'advocators') ? 'error' : ''}
            isDisabled={isDisabledField}
          />
          {showError(formik, 'advocators')}
        </Col>
      </Row>
      <Row className="mb-3">
        <Col span={24}>
          <AiLabel>
            HR Partners
            <Tooltip
              placement="top"
              title="Please include HR personnel involved during review, endorsement and approval"
              overlayClassName="custom-tooltip"
              {...{
                overlayInnerStyle: {
                  fontFamily: font.inter,
                  width: '260px',
                  fontSize: '12px',
                  lineHeight: '18px',
                  gap: '24px',
                  padding: '12px',
                  borderRadius: '4px',
                  backgroundColor: '#000',
                },
              }}
            >
              <span style={{ marginLeft: '5px' }}>
                <img src={hr_info} alt="" />
              </span>
            </Tooltip>
          </AiLabel>
          <AsyncMultipleSelect
            name="hr_partners"
            value={formik.values.hr_partners}
            placeholder="Enter HR Partners Name"
            loadOptions={(e) => getAsyncOptionAdvocatorsHrPartners(e, roleActive.roleId)}
            onChange={(e) => onChangeInputData(formik, e, 'hr_partners')}
            status={isCheckError(formik, 'hr_partners') ? 'error' : ''}
            isDisabled={isDisabledField}
          />
          {showError(formik, 'hr_partners')}
        </Col>
      </Row>
      <Row gutter={16} className="mb-3 mt-3">
        <Col span={6} className="gutter-row">
          <AiLabel>
            Action Type
            <Tooltip
              placement="top"
              title={
                <div
                  style={{ display: 'grid', rowGap: '10px', fontWeight: '400', fontSize: '12px' }}
                >
                  <div>
                    <span style={{ fontWeight: '600' }}>Approval</span> - Final talent council
                    decision according to LOA. Will be reflected accordingly in myTalentX.
                  </div>
                  <div>
                    <span style={{ fontWeight: '600' }}>Endorsed</span> - Endorsed talent council
                    decision prior to final level talent council (n-1). Will be saved in TR pending
                    action in Meeting Agenda.
                  </div>
                  <div>
                    <span style={{ fontWeight: '600' }}>Reviewed</span> - Reviewed talent council
                    decision prior to final level talent council (n-2 or n-3). Will be saved in TR
                    pending action in Meeting Agenda.
                  </div>
                </div>
              }
              {...{
                overlayInnerStyle: {
                  fontFamily: font.inter,
                  width: '340px',
                  backgroundColor: '#000',
                  fontSize: '12px',
                  lineHeight: '18px',
                  gap: '24px',
                  padding: '12px',
                  borderRadius: '4px',
                },
              }}
            >
              <span style={{ marginLeft: '5px' }}>
                <img src={hr_info} alt="" />
              </span>
            </Tooltip>
            {formik.values.is_has_staff && <span style={{ color: '#FF787F' }}>*</span>}
          </AiLabel>
          <div data-testid="select">
            <SingleSelect
              value={actionType}
              placeholder="Select Action Type"
              options={actionTypeOptions}
              onChange={(e) => {
                onChangeInputData(formik, e?.value, 'action_type');
                handleChangeActionType({ action_type: e?.value });
              }}
              status={isCheckError(formik, 'action_type') || messageActionType ? 'error' : ''}
              isDisabled={isDisableActionType}
            />
            {showError(formik, 'action_type')}
          </div>
        </Col>
      </Row>
    </AiAgendaForm>
  );
};

export default TalentReviewAgendaForm;
