import _ from 'lodash';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { resetCardSettingDSP } from '../../../../store/pageSlice';
import BoxDSP from '../BoxDSP/BoxDSP';

const RecommendationsDSP = (props) => {
  const { positions, incumbents } = props;
  const dispatch = useDispatch();

  const getFirstLine = positions?.filter((item) => item?.successorType === '1st Line');
  const getSecondLine = positions?.filter((item) => item?.successorType === '2nd Line');
  const getThirdLine = positions?.filter((item) => item?.successorType === '3rd Line');

  const firstLine = _.unionBy(getFirstLine, 'staff_id');
  const secondLine = _.unionBy(getSecondLine, 'staff_id');
  const thirdLine = _.unionBy(getThirdLine, 'staff_id');

  const getRowOfLine = (numItem) => {
    if (!numItem) return 0;
    const itemPerRow = 3;
    return Math.ceil(numItem / itemPerRow);
  };

  useEffect(() => {
    return () => {
      dispatch(resetCardSettingDSP());
    };
  }, []);

  return (
    <div
      data-testid="view-box-line"
      className="d-flex justify-content-between align-items-start no-gutters"
    >
      <div className="col-12">
        <BoxDSP color={'#66B2C4'} text={'1st Line'} line={firstLine} incumbents={incumbents} />
        <BoxDSP
          color={'#BDB6EA'}
          text={'2nd Line'}
          line={secondLine}
          incumbents={incumbents}
          numRows1={getRowOfLine(firstLine.length)}
        />
        <BoxDSP
          color={'#BCC6E1'}
          text={'3rd Line'}
          line={thirdLine}
          incumbents={incumbents}
          numRows1={getRowOfLine(firstLine.length)}
          numRows2={getRowOfLine(secondLine.length)}
        />
      </div>
    </div>
  );
};

export default RecommendationsDSP;
