import { arrayMove as dndKitArrayMove } from '@dnd-kit/sortable';
import { jwtDecode } from 'jwt-decode';
import _, {
  cloneDeep,
  includes,
  inRange,
  isArray,
  isEmpty,
  isNumber,
  isObject,
  isSafeInteger,
  join,
  negate,
  orderBy,
  size,
  uniq,
  uniqueId,
} from 'lodash';
import moment from 'moment';
import React from 'react';
import { v4 as uuidv4 } from 'uuid';

import {
  active_icon,
  avatar,
  board_pack,
  dashboard_breadcrumb,
  homepage_icon,
  in_active_icon,
  suspended_icon,
} from '../assets/img';

import { AGENDA_TYPES, MOBILITY_MODAL_ADD_TYPE } from '../pages/MobilitySimulation/constants';
import { TooltipText } from './commom';
import {
  ACTION_TYPE_AGENDA,
  ACTION_TYPE_TALENT_REVIEW_AGENDA,
  AGENDA_STATUS,
  ASSIGNMENT_STATUS_TYPES,
  BUSINESS_NAME_TIME_LINE,
  BUSINESS_SHORT_NAME,
  CRITERIA_TYPE,
  DASHBOARD_TOBE_REVIEW,
  DATE_TIME_FORMAT,
  FILE_EXTENSION,
  JOB_GRADE_VALUES,
  LEADERSHIP_DESIRE_ORDER,
  MAPPING_DATA_TYPES,
  MEETING_DETAIL_STATUS,
  MOBILITY_PLAN_LATEST_KEYS,
  NAME_FOLDER_BLOB,
  PERMISSION,
  ROLE,
  ROLE_SG_MAPPING,
  ROLE_SHORT_NAME,
  ROLE_SP,
  AGENDA_FIELD_CHANGES,
  SQL_INTERGER_LIMIT,
  MS_FIELD,
  OT_FIELD,
  AGENDA_SHORT_TYPES,
  SP_AGENDA_FIELDS,
  MEETING_DETAILS_FIELDS,
  COMPETENCIES_CATEGORY,
  MATRIX_SP_AGENDA,
  MATRIX_TR_AGENDA,
  MEETING_CATEGORY_VALUES,
  ADMIN_ROLE,
  PDC_MEETING_FIELDS,
  TYPE_BKP,
  TYPE_EKP,
} from './constants';
import { history } from './history';
import userApi from '../services/userApi';

export const handleShowCT = (item) => {
  if (!item) return '';

  if (item === 'Corporate Talent') return `(CT) `;
  if (item === 'Business Talent') return `(BT) `;
  if (item === 'Other Talent') return `(OT) `;
  return `(${item}) `;
};

export const hideOtherTalentLabel = (assignmentStatus, role, label) => {
  // const LABEL_OT_TEXT = ['Other Talent', 'OT', 'Others'];
  return (
    assignmentStatus?.toUpperCase() === ASSIGNMENT_STATUS_TYPES.ACTIVE &&
    role === ROLE_SG_MAPPING.VICE_PRESIDENT
  );
};

function renderSPCounter(lineSettings, item, type) {
  const arr = item?.positions.filter((fil) => fil.role === type);
  if (isEmpty(arr)) return null;
  const newArr = [];
  arr.forEach((a) => {
    if (lineSettings && lineSettings.find((fi) => fi === a.readiness)) newArr.push(a);
    if (!lineSettings) newArr.push(a);
  });
  if (isEmpty(newArr)) return null;
  return (
    <div style={{ display: 'grid', gap: '5px', fontWeight: '400', fontSize: '11px' }}>
      {newArr.map((a) => (
        <div key={uniqueId()} style={{ display: 'flex', gap: '5px', alignItems: 'center' }}>
          <span
            style={{
              height: '4px',
              width: '4px',
              backgroundColor: '#FFFFFF',
              borderRadius: '50%',
              display: 'inline-block',
            }}
          ></span>{' '}
          <span>
            {a.position_name} - {a.readiness}
          </span>
        </div>
      ))}
    </div>
  );
}

function calculateSPCounter(lineSettings, item, type) {
  return (
    item?.positions?.reduce((accumulator, currentValue) => {
      if (
        lineSettings &&
        lineSettings.find((fi) => fi === currentValue.readiness) &&
        currentValue.role === type
      ) {
        accumulator += 1;
      }
      if (!lineSettings && currentValue.role === type) {
        accumulator += 1;
      }
      return accumulator;
    }, 0) || 0
  );
}

export const handleShowTextTooltip = (item, positions, lineSettings) => {
  const lineSettingsValue = lineSettings
    ? lineSettings.map((i) => i.value)
    : ['1st Line', '2nd Line', '3rd Line'];
  if (!size(item.positions)) return null;
  if (size(positions) === 0) return null;
  let positionsRole = positions.map((eleP) => {
    return eleP.role;
  });
  let positionFilterRole = positionsRole.filter((eleR) => eleR !== null || eleR !== undefined);
  let uniqPositionsRole = uniq(positionFilterRole);
  if (size(uniqPositionsRole) === 0) {
    return null;
  }

  const renderVP = () => renderSPCounter(lineSettingsValue, item, 'VP');
  const renderSGM = () => renderSPCounter(lineSettingsValue, item, 'SGM');
  const renderGM = () => renderSPCounter(lineSettingsValue, item, 'GM');

  let uniqPositionsRoleConvert = uniq(
    uniqPositionsRole.map((ele2) => {
      if (ROLE_SP.VP.includes(ele2) || ROLE_SP.SGM.includes(ele2)) {
        return ROLE_SP.VP[0];
      }
      if (ROLE_SP.GM.includes(ele2)) {
        return ROLE_SP.GM[0];
      }
      return null;
    })
  );

  if (size(uniqPositionsRoleConvert) === 2) {
    if (isEmpty(renderVP()) && isEmpty(renderSGM()) && isEmpty(renderGM())) return null;
    return (
      <div style={{ display: 'grid', gap: '20px', fontSize: '13px', lineHeight: '16px' }}>
        {!isEmpty(renderVP()) && (
          <div>
            <div style={{ fontWeight: '700' }}>VP</div>
            <div style={{ fontWeight: '600' }}>{renderVP()}</div>
          </div>
        )}
        {!isEmpty(renderSGM()) && (
          <div>
            <div style={{ fontWeight: '700' }}>SGM</div>
            <div style={{ fontWeight: '600' }}>{renderSGM()}</div>
          </div>
        )}
        {!isEmpty(renderGM()) && (
          <div>
            <div style={{ fontWeight: '700' }}>GM</div>
            <div style={{ fontWeight: '600' }}>{renderGM()}</div>
          </div>
        )}
      </div>
    );
  }
  if (size(uniqPositionsRoleConvert) === 1) {
    if (ROLE_SP.VP.includes(uniqPositionsRoleConvert[0])) {
      if (isEmpty(renderVP()) && isEmpty(renderSGM())) return null;
      return (
        <div style={{ display: 'grid', gap: '20px', fontSize: '13px', lineHeight: '16px' }}>
          {!isEmpty(renderVP()) && (
            <div>
              <div style={{ fontWeight: '700' }}>VP</div>
              <div style={{ fontWeight: '600' }}>{renderVP()}</div>
            </div>
          )}
          {!isEmpty(renderSGM()) && (
            <div>
              <div style={{ fontWeight: '700' }}>SGM</div>
              <div style={{ fontWeight: '600' }}>{renderSGM()}</div>
            </div>
          )}
        </div>
      );
    } else if (ROLE_SP.GM.includes(uniqPositionsRoleConvert[0])) {
      if (isEmpty(renderSGM()) && isEmpty(renderGM())) return null;
      return (
        <div style={{ display: 'grid', gap: '20px', fontSize: '13px', lineHeight: '16px' }}>
          {!isEmpty(renderSGM()) && (
            <div>
              <div style={{ fontWeight: '700' }}>SGM</div>
              <div style={{ fontWeight: '600' }}>{renderSGM()}</div>
            </div>
          )}
          {!isEmpty(renderGM()) && (
            <div>
              <div style={{ fontWeight: '700' }}>GM</div>
              <div style={{ fontWeight: '600' }}>{renderGM()}</div>
            </div>
          )}
        </div>
      );
    } else {
      return;
    }
  }
};

export const infoPosition = (position_name, department, division, opu, business) => {
  return (
    <div
      style={{
        fontSize: '16px',
      }}
    >
      {position_name && (
        <div
          style={{
            color: '#fff',
            padding: '10px 10px 0 10px',
            fontSize: '16px',
            marginBottom: '10px',
          }}
        >
          {position_name}
        </div>
      )}

      <ul
        style={{
          color: '#999a9c',
        }}
      >
        {department && <li>Department : {department}</li>}
        {division && <li>Divison : {division}</li>}
        {opu && <li>OPU : {opu}</li>}
        {business && <li>Business : {business}</li>}
      </ul>
    </div>
  );
};

export const handleShowTooltip = (item, positions, lineSettings) => {
  const lineSettingsValue = lineSettings
    ? lineSettings.map((i) => i.value)
    : ['1st Line', '2nd Line', '3rd Line'];

  if (size(positions) === 0) return null;

  let positionsRole = positions.map((eleP) => {
    return eleP.role;
  });
  let positionFilterRole = positionsRole.filter((eleR) => eleR != null || eleR !== undefined);
  let uniqPositionsRole = uniq(positionFilterRole);

  if (size(uniqPositionsRole) === 0) {
    return null;
  }

  let uniqPositionsRoleConvert = uniq(
    uniqPositionsRole.map((ele2) => {
      if (ROLE_SP.VP.includes(ele2) || ROLE_SP.SGM.includes(ele2)) {
        return ROLE_SP.VP[0];
      }
      if (ROLE_SP.GM.includes(ele2)) {
        return ROLE_SP.GM[0];
      }
      return null;
    })
  );

  if (size(uniqPositionsRoleConvert) === 2) {
    return (
      <>
        <TooltipText hadColor={calculateSPCounter(lineSettingsValue, item, 'VP') > 2}>
          VP:
          <span style={{ fontWeight: 'bold' }}>
            {calculateSPCounter(lineSettingsValue, item, 'VP')}
          </span>
        </TooltipText>
        <TooltipText hadColor={calculateSPCounter(lineSettingsValue, item, 'SGM') > 4}>
          SGM:
          <span style={{ fontWeight: 'bold' }}>
            {calculateSPCounter(lineSettingsValue, item, 'SGM')}
          </span>
        </TooltipText>
        <TooltipText hadColor={calculateSPCounter(lineSettingsValue, item, 'GM') > 4}>
          GM:
          <span style={{ fontWeight: 'bold' }}>
            {calculateSPCounter(lineSettingsValue, item, 'GM')}
          </span>
        </TooltipText>
      </>
    );
  }
  if (size(uniqPositionsRoleConvert) === 1) {
    if (ROLE_SP.VP.includes(uniqPositionsRoleConvert[0])) {
      return (
        <>
          <TooltipText hadColor={calculateSPCounter(lineSettingsValue, item, 'VP') > 2}>
            VP:
            <span style={{ fontWeight: 'bold' }}>
              {calculateSPCounter(lineSettingsValue, item, 'VP')}
            </span>
          </TooltipText>
          <TooltipText hadColor={calculateSPCounter(lineSettingsValue, item, 'SGM') > 4}>
            SGM:
            <span style={{ fontWeight: 'bold' }}>
              {calculateSPCounter(lineSettingsValue, item, 'SGM')}
            </span>
          </TooltipText>
        </>
      );
    } else if (ROLE_SP.GM.includes(uniqPositionsRoleConvert[0])) {
      return (
        <>
          <TooltipText hadColor={calculateSPCounter(lineSettingsValue, item, 'SGM') > 4}>
            SGM:
            <span style={{ fontWeight: 'bold' }}>
              {calculateSPCounter(lineSettingsValue, item, 'SGM')}
            </span>
          </TooltipText>
          <TooltipText hadColor={calculateSPCounter(lineSettingsValue, item, 'GM') > 4}>
            GM:
            <span style={{ fontWeight: 'bold' }}>
              {calculateSPCounter(lineSettingsValue, item, 'GM')}
            </span>
          </TooltipText>
        </>
      );
    } else {
      return;
    }
  }
};

export const formatTime = (date) => {
  if (!date) return '';
  return moment(date).format('h:mm:ss a');
};

export const formatTimeWithoutSecond = (date) => {
  if (!date) return '';
  return moment(date).format('h:mm a');
};

export const formatDate = (date) => {
  if (!date) return '';

  return moment(date).format(DATE_TIME_FORMAT.DATE_SPACE);
};

export const formatDateWithMonthAndYear = (date) => {
  if (!date) return '';
  return moment(date).format(DATE_TIME_FORMAT.ONLY_MONTH_YEAR);
};

export const capitalizeFirstLetter = (string) => {
  if (!string) return '';

  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const calculateAge = (date) => {
  if (!date) return '';
  const currentDate = moment().year();
  const convertDate = Number(moment(date).format(DATE_TIME_FORMAT.ONLY_YEAR));
  const calc = currentDate - convertDate;
  return `${calc} year${calc === 1 ? '' : 's'}`;
};

export const calculateTime = (date) => {
  if (!date) return '0';
  let a = moment(date);
  let b = moment();
  let total = b.diff(a, 'months');
  if (total === 0) return '0';

  let month = total % 12;
  let year = Math.floor(total / 12);
  if (year && !month) {
    return `${year} year${year === 1 ? '' : 's'}`;
  }
  if (!year && month) {
    return `${month} month${month === 1 ? '' : 's'}`;
  }
  if (year && month) {
    return `${year} year${year === 1 ? '' : 's'} ${month} month${month === 1 ? '' : 's'}`;
  }
};

export const decodeBase64ToImage = (image) => {
  if (!image) return avatar;

  const persistData = localStorage.getItem('persist:role');
  if (!persistData) return;

  const parsedData = JSON.parse(persistData);
  const isExternal = image.includes(NAME_FOLDER_BLOB.EXTERNAL);
  const imageToken = JSON.parse(parsedData?.user)?.tokenImage;
  const token = isExternal ? imageToken.externalToken : imageToken.oracleToken;

  return token ? `${image}?${token}` : `${image}`;
};

export const handleShowPosition = (item) => {
  let text = item.position_name;
  if (item.business_unit) {
    text += ', ' + item.business_unit;
  }
  if (item.position_code) {
    text += '(' + item.position_code + ')';
  }
  if (item.role) {
    text += ', Role:' + item.role;
  }
  return text;
};

export const handleShowTalent = (item) => {
  let text = `Staff ID: ${item.staffId}`;
  if (item.birthName) {
    text += ', Name: ' + item.birthName;
  }

  return text;
};

export const getPositionAndStaffId = (item) => {
  return {
    staff_id: item?.staff_id || item?.staffId,
    position_code: item?.position_code || item?.positionId,
  };
};

export const getPermissionForViewTalentProfileByRole = (role) => {
  if (!role || !role.roleName) return 0;
  return PERMISSION.VIEW_TALENT_PROFILE;
};
export const getPermissionByRole = ({ role, part }) => {
  if (!role || !role.roleName) return 0;
  if (part === 'ROLE') {
    return PERMISSION.AD_ROLE_MANAGEMENT;
  }
};
export const getPermissionForHiddenBtnByRole = (role) => {
  if (!role || !role.roleName) return;
  return role.roleName === ROLE.HR_BP ||
    role.roleName === ROLE.HR_BP_SECRETARIAT ||
    role.roleName === ROLE.HR_COE ||
    role.roleName === ROLE.HR_BP_LEADERS
    ? true
    : false;
};

export const isPermittedItem = (permission, permissionsList) => {
  if (permission === undefined) return true;

  if (!permissionsList || permissionsList.length === 0) return false;
  if (typeof permission === 'number') {
    const permittedPermission = permissionsList.includes(permission);
    return permittedPermission;
  } else {
    let hasPermission = false;
    permission.forEach((ele) => {
      const permittedPermission = permissionsList.includes(ele);
      if (permittedPermission) {
        hasPermission = true;
      }
    });
    return hasPermission;
  }
};
export const getPermissionsByRole = ({ permissions, part }) => {
  if (part === 'VIEW_TALENT_PROFILE') {
    const hasExistWith6 = includes(permissions, 6);
    if (hasExistWith6) {
      return PERMISSION.VIEW_TALENT_PROFILE;
    }
    return 0;
  }
  if (part === 'EDIT_TALENT_PROFILE') {
    const hasExistWith3 = includes(permissions, 3);
    if (hasExistWith3) {
      return PERMISSION.EDIT_TALENT_PROFILE;
    }
    return 0;
  }
  if (part === 'ADD_TALENT_COMPARISION') {
    const hasExistWith9 = includes(permissions, 9);
    if (hasExistWith9) {
      return PERMISSION.ADD_TALENT_COMPARISION;
    }
    return 0;
  }
  if (part === 'TP_HIDE_UNHIDE_TALENT_PROFILE') {
    const hasExistWith10 = includes(permissions, 10);
    if (hasExistWith10) {
      return PERMISSION.TP_HIDE_UNHIDE_TALENT_PROFILE;
    }
    return 0;
  }
  if (part === 'LINK_TO_TALENT_COMPARISION') {
    const hasExistWith11 = includes(permissions, 11);
    if (hasExistWith11) {
      return PERMISSION.LINK_TO_TALENT_COMPARISION;
    }
    return 0;
  }
  if (part === 'SEARCH_VIEW_SP_DASHBOARD') {
    const hasExistWith12 = includes(permissions, 12);
    if (hasExistWith12) {
      return PERMISSION.SEARCH_VIEW_SP_DASHBOARD;
    }
    return 0;
  }
};

export const findPermission = (permissions, permission) => {
  return permissions.find((item) => item === permission) || 0;
};

export function getPermissionForEditTalentProfileByRole(role) {
  if (!role || !role.roleName) return;

  return role.roleName === ROLE.HR_BP ||
    role.roleName === ROLE.HR_BP_SECRETARIAT ||
    role.roleName === ROLE.HR_COE ||
    role.roleName === ROLE.HR_BP_LEADERS
    ? PERMISSION.EDIT_TALENT_PROFILE
    : 0;
}

export function mapRoleInUserAccess(accessLevelList) {
  if (isEmpty(accessLevelList)) return [];
  const newArr = [];
  accessLevelList.forEach((item) => {
    switch (item) {
      case 'BU':
        newArr.push({
          accessLevel: 'Own Business',
          businessAccess: null,
          businessType: null,
        });
        break;
      case 'OPU/Div':
        newArr.push({
          accessLevel: 'Own OPU/Div',
          businessAccess: null,
          businessType: null,
        });
        break;
      default:
        break;
    }
  });
  return newArr;
}

export const urlStringBuilder = (companyLabel, buLabel, label, labelSPS) => {
  const urlSearchBuilder = new URLSearchParams();
  _([companyLabel || '', buLabel || '', label || '', labelSPS])
    .filter(negate(isEmpty))
    .forEach((lb) => {
      urlSearchBuilder.append('labels', lb);
    });

  return urlSearchBuilder.toString();
};

export function convertNotifications(notificationList) {
  if (isEmpty(notificationList)) return [];
  const groups = notificationList.reduce((groups, game) => {
    const date = game.sent_at?.split('T')[0];
    if (!groups[date]) {
      groups[date] = [];
    }
    groups[date].push(game);
    return groups;
  }, {});

  const groupArrays = Object.keys(groups).map((date) => {
    return {
      date,
      notis: groups[date],
    };
  });
  groupArrays.sort(function (a, b) {
    return new Date(b.date) - new Date(a.date);
  });
  groupArrays.forEach((d) => d.notis.sort((a, b) => moment(b.sent_at) - moment(a.sent_at)));
  return groupArrays;
}

export function displayDate(date) {
  if (!date) return '';
  if (moment(date).isSame(moment(new Date()).format('YYYY-MM-DD'))) return 'Today';
  if (moment(date).isSame(moment(new Date()).subtract(1, 'day').format('YYYY-MM-DD')))
    return 'Yesterday';
  return moment(date).format('ddd DD MMM YYYY');
}

export const handleShowRetirement = (data) => {
  if (_.isEmpty(data)) return 'None';

  if (_.size(data) === 1) return data[0] ? formatDate(new Date(data[0])) : 'None';

  if (_.size(data) > 1) {
    let result = data.map((item) => {
      if (!item) return 'None';
      return formatDate(new Date(item));
    });
    return _.join(result, ' / ');
  }
};

export const handleShowRetirementDate = (retirementDate) => {
  return retirementDate ? moment(retirementDate).format('DD/MM/YYYY') : 'N/A';
};

export const retiredAge = (retirementDate, birthDate) => {
  if (!retirementDate || !birthDate) return '';
  const retiredAge =
    moment(retirementDate).format(DATE_TIME_FORMAT.ONLY_YEAR) -
    moment(birthDate).format(DATE_TIME_FORMAT.ONLY_YEAR);
  return isNaN(retiredAge) ? '' : `(${retiredAge})`;
};

export const releaseChecklistColor = (releaseChecklist) => {
  if (isEmpty(releaseChecklist)) return '';
  const arr = Object.values(releaseChecklist);
  if (isEmpty(arr)) return '';
  const number = arr.filter((v) => v === true).length;
  if (number <= 1) return 'red';
  if (number <= 2) return 'orange';
  if (number <= 4) return 'green';
};

export const releaseChecklistNumber = (releaseChecklist) => {
  if (isEmpty(releaseChecklist)) return 'N/A';
  const arr = Object.values(releaseChecklist);
  if (isEmpty(arr)) return 'N/A';
  const number = arr.filter((v) => v === true).length;
  return `${number}/4`;
};

export const convertPositionSimulation = (objPosition) => {
  return {
    key: objPosition.staff_id,
    position_name: objPosition.position_name || 'N/A',
    business: objPosition.business || 'N/A',
    current_incumbent: objPosition.birth_name || 'N/A',
    years_in_position: objPosition.years_in_position ?? 'N/A',
    years_in_sg: objPosition.years_in_sg ?? 'N/A',
    performance_rating: objPosition.performance_rating || 'N/A',
    retirement_date: objPosition.retirement_date || 'N/A',
    age: objPosition.age || 'N/A',
    position_code: objPosition.position_code,
    type: MOBILITY_MODAL_ADD_TYPE.POSITION,
    staff_id: objPosition.staff_id,
    birth_date: objPosition.birth_date || 'N/A',
    date_in_sg: objPosition.date_in_sg || 'N/A',
    date_in_position: objPosition.date_in_position || 'N/A',
    salary_grade: objPosition.salary_grade || 'N/A',
  };
};

export const convertTalentSimulation = (objTalent) => {
  return {
    key: objTalent.staffId,
    gm: objTalent.GM,
    sgm: objTalent.SGM,
    vp: objTalent.VP,
    staff_id: objTalent.staffId,
    category: objTalent.category,
    is_unsearchable: objTalent.isUnsearchable,
    positions: objTalent.positions,
    release_checklist: objTalent.release_checklist,
    compared_positions: objTalent.compared_positions,
    position_code: objTalent.position_code,
    position_name: objTalent.position_name || 'N/A',
    years_in_position: objTalent.years_in_position ?? objTalent.year_in_position ?? 'N/A',
    years_in_sg: objTalent.years_in_sg ?? objTalent.year_in_sg ?? 'N/A',
    performance_rating: objTalent.performance_rating || 'N/A',
    retirement_date: objTalent.retirement_date || 'N/A',
    age: objTalent.age || 'N/A',
    type: MOBILITY_MODAL_ADD_TYPE.TALENT,
    salary_grade: objTalent.salary_grade || 'N/A',
    birth_name: objTalent.birth_name || 'N/A',
    birth_date: objTalent.birth_date || 'N/A',
    image: objTalent.image,
    matching_percentage: objTalent.matching_percentage
      ? `${objTalent.matching_percentage}%`
      : 'N/A',
    sp_readiness: objTalent.sp_readiness || 'N/A',
    retirement_year_countdown: objTalent.retirement_year_countdown || 'N/A',
    business: objTalent.business_unit || 'N/A',
    date_in_sg: objTalent.date_in_sg || 'N/A',
    date_in_position: objTalent.date_in_position || 'N/A',
    ct: objTalent.ct || 'N/A',
    ranking: objTalent.ranking || 'N/A',
    role: objTalent.role || 'N/A',
    manager_releasability: objTalent.manager_releasability || 'N/A',
    willing_to_relocate: objTalent.willing_to_relocate || 'N/A',
    new_in_position: objTalent.new_in_position || 'N/A',
    cross_business: objTalent.cross_business || 'N/A',
    job_grade: objTalent.job_grade || 'N/A',
    mobility_plan: objTalent.mobility_plan || 'N/A',
  };
};

export const convertMobilitySimulationAgenda = (arr, date, idMeeting) => {
  const newArr = [];
  arr.forEach((item) => {
    const dateWithFormat = moment(date).format('YYYY-MM-DD');
    if (item.type_agenda === 'MS') {
      newArr.push({
        title: item.title,
        start_time: moment(
          `${dateWithFormat} ${item.start_time}`,
          DATE_TIME_FORMAT.DATE_TIME
        ).toDate(),
        end_time: moment(`${dateWithFormat} ${item.end_time}`, DATE_TIME_FORMAT.DATE_TIME).toDate(),
        advocators: isEmpty(item.advocators)
          ? []
          : item.advocators.map((item) => {
              return item.value;
            }),
        hr_partners: isEmpty(item.hr_partners)
          ? []
          : item.hr_partners.map((item) => {
              return item.value;
            }),
        remarks: item.remarks,
        version: item.version || 0,
        meeting_id: idMeeting,
        type_agenda: item.type_agenda,
        ms_ids: item.mobilitySimulationIds,
        user_id: item.user_id || '',
        set_main_at: item.set_main_at || '',
        version_name: item.version_name || '',
        approved_status: item.approved_status || '',
        business: item.business || '',
        status: item.status || AGENDA_STATUS.PAPER_IN_PROGRESS,
      });
    }
  });

  return newArr;
};

export const convertMobilitySimulationToViewAgenda = (data) => {
  if (isEmpty(data)) return {};
  return {
    date: data.start_time,
    title: data.agenda_name || data.title,
    start_time:
      moment(data.start_time).format(DATE_TIME_FORMAT.TIME) === 'Invalid date'
        ? data.start_time
        : moment(data.start_time).format(DATE_TIME_FORMAT.TIME),
    end_time:
      moment(data.end_time).format(DATE_TIME_FORMAT.TIME) === 'Invalid date'
        ? data.end_time
        : moment(data.end_time).format(DATE_TIME_FORMAT.TIME),
    advocators: isEmpty(data.advocators)
      ? []
      : data.advocators.map((item) => {
          return {
            label: item.name || item.label,
            value: item.user_id || item.value,
          };
        }),
    hr_partners: isEmpty(data.hr_partners)
      ? []
      : data.hr_partners.map((item) => {
          return {
            label: item.name || item.label,
            value: item.user_id || item.value,
          };
        }),
    remarks: data.remarks,
    version: data.version === 0 ? data.version : data.version || null,
    type_agenda: data.type_agenda,
    ms_ids: data.mobilitySimulationIds || data.ms_ids,
    user_id: data.user_id || '',
    set_main_at: data.set_main_at || '',
    version_name: data.version_name || '',
    approved_status: data.approved_status || '',
    business: data.business || '',
    status: data.agenda_status || '',
    meeting_id: data.meeting_id || null,
    meeting_status: data.meeting_status || '',
    meeting_name: data.meeting_name || '',
    mobility_simulations: data.mobility_simulations || [],
    agenda_id: data.agenda_id || null,
  };
};

export const convertDataToUpdateMSAgenda = (data) => {
  const dateWithFormat = moment(data?.date).format('YYYY-MM-DD');
  return {
    title: data.title || '',
    start_time: moment(`${dateWithFormat} ${data.start_time}`, DATE_TIME_FORMAT.DATE_TIME).toDate(),
    end_time: moment(`${dateWithFormat} ${data.end_time}`, DATE_TIME_FORMAT.DATE_TIME).toDate(),
    advocators: isEmpty(data.advocators)
      ? []
      : data.advocators.map((item) => {
          return item.value;
        }),
    hr_partners: isEmpty(data.hr_partners)
      ? []
      : data.hr_partners.map((item) => {
          return item.value;
        }),
    remarks: data.remarks || '',
    version: data.version || 0,
    ms_ids: data.mobilitySimulationIds || [],
  };
};

export function convertDataChain(arr) {
  if (isEmpty(arr)) return [];
  const talents = arr.slice(1, arr.length);
  if (isEmpty(talents)) return [];
  return talents.map((item, index) => {
    return {
      staff_id: item.staff_id,
      order_no: index,
      from_category: item.category,
    };
  });
}

export const handleShowMessageSuccessMS = (message, setCreatedMobilityState, timeShow = 5000) => {
  return setCreatedMobilityState((prev) => {
    return {
      ...prev,
      isShowMessage: {
        type: 'success',
        message,
        onClose: () =>
          setCreatedMobilityState((prev) => {
            return {
              ...prev,
              isShowMessage: {},
            };
          }),
        timeShow,
      },
    };
  });
};

export const convertDataToAddNewApprovedSp = (data) => {
  return {
    plan_name: data?.plan_name || '',
    position_code: data?.position_code || '',
    role: data?.role || '',
    position_label: data?.position_label?.id || '',
    business_unit: data?.business_unit?.id || '',
    group_code: data?.group_code?.id || null,
    platform: data?.platform || '',
    approved_date: data?.approved_date,
    included_in_ratio: data?.included_in_ratio?.id || '',
    successor: convertSuccessorToAddNewApprovedSp(data?.successor),
    delete_successor: data?.delete_successor ? data?.delete_successor : [],
  };
};

export const convertSuccessorToAddNewApprovedSp = (data) => {
  const newData = !isEmpty(data)
    ? data.map((item) => {
        return {
          id: !isNaN(item?.key) ? item?.key : null,
          candidate_name: item?.candidate_name || '',
          staff_id: item?.staff_id || '',
          readiness: item?.readiness?.id || '',
          ranking: item?.ranking?.id || '',
          active_approved_successor: item?.active_approved_successor?.id || '',
          talent_review_date: item?.talent_review_date,
        };
      })
    : [];
  return newData;
};

export const convertAuditLogData = (data) => {
  return !isEmpty(data)
    ? data.map((item, index) => {
        return {
          no: index + 1,
          module: item?.feature_name ? `${item?.feature_name}` : `${item?.module_name}`,
          action: item?.action || '',
          old_value: item?.old_value || '',
          new_value: item?.new_value || '',
          action_at: item?.action_at || '',
          action_by: item?.action_by || '',
        };
      })
    : [];
};

export const convertUserActivitytLogData = (data) => {
  return !isEmpty(data)
    ? data.map((item, index) => {
        return {
          no: index + 1,
          email: item?.email || '',
          type: item?.type || '',
          url: item?.url || '',
          time: item?.time || '',
        };
      })
    : [];
};

export const convertTalentReviewAgendaToPost = (arr, date, idMeeting) => {
  const tempArr = [];
  arr.forEach((item) => {
    if (item.type_agenda === AGENDA_TYPES.TALENT) {
      const tempData = convertDataToUpdateTalentReviewAgenda(item, date, idMeeting);
      tempArr.push(tempData);
    }
  });

  return tempArr;
};

export const convertDataToViewTalentReviewAgenda = (data) => {
  if (isEmpty(data)) return {};
  return {
    date: data?.start_time || '',
    title: data?.agenda_name || data?.title || '',
    start_time:
      moment(data.start_time).format(DATE_TIME_FORMAT.TIME) === 'Invalid date'
        ? data.start_time
        : moment(data.start_time).format(DATE_TIME_FORMAT.TIME),
    end_time:
      moment(data.end_time).format(DATE_TIME_FORMAT.TIME) === 'Invalid date'
        ? data.end_time
        : moment(data.end_time).format(DATE_TIME_FORMAT.TIME),
    advocators: isEmpty(data?.advocators)
      ? []
      : data?.advocators.map((item) => {
          return {
            label: item.name || item.label,
            value: item.id || item.value,
          };
        }),
    hr_partners: isEmpty(data?.hr_partners)
      ? []
      : data?.hr_partners.map((item) => {
          return {
            label: item.name || item.label,
            value: item.id || item.value,
          };
        }),
    version: data?.version === 0 ? data?.version : data?.version || null,
    type_agenda: data?.type_agenda,
    user_id: data?.user_id || '',
    set_main_at: data?.set_main_at || '',
    version_name: data?.version_name || '',
    approved_status: data?.approved_status || '',
    business: data?.business || '',
    status: data?.status || '',
    meeting_id: data?.meeting_id || null,
    meeting_status: data?.meeting_status || '',
    meeting_name: data?.meeting_name || '',
    agenda_id: data?.agenda_id || null,
    top_talent: data?.top_talent || {},
    succession_planning: {
      first_line: isEmpty(data?.succession_planning?.first_line)
        ? []
        : orderBy(
            data?.succession_planning?.first_line,
            [
              (v) => {
                return v.order || '';
              },
              (v) => {
                return v.rank || '';
              },
              (v) => {
                return v.position_code || '';
              },
            ],
            ['asc', 'desc', 'asc']
          ),
      second_line: isEmpty(data?.succession_planning?.second_line)
        ? []
        : orderBy(
            data?.succession_planning?.second_line,
            [
              (v) => {
                return v.order || '';
              },
              (v) => {
                return v.rank || '';
              },
              (v) => {
                return v.position_code || '';
              },
            ],
            ['asc', 'desc', 'asc']
          ),
      third_line: isEmpty(data?.succession_planning?.third_line)
        ? []
        : orderBy(
            data?.succession_planning?.third_line,
            [
              (v) => {
                return v.order || '';
              },
              (v) => {
                return v.rank || '';
              },
              (v) => {
                return v.position_code || '';
              },
            ],
            ['asc', 'desc', 'asc']
          ),
      agreed_to_drop: isEmpty(data?.succession_planning?.agreed_to_drop)
        ? []
        : orderBy(
            data?.succession_planning?.agreed_to_drop,
            [
              (v) => {
                return v.order || '';
              },
              (v) => {
                return v.rank || '';
              },
              (v) => {
                return v.position_code || '';
              },
            ],
            ['asc', 'desc', 'asc']
          ),
    },
    mobility_plan: !isEmpty(data?.mobility_plan)
      ? data.mobility_plan.map((item) => {
          return {
            ...item,
            idx: uuidv4(),
          };
        })
      : [
          {
            next_mobility: { manual: '', selection: [] },
            next_2_mobility: { manual: '', selection: [] },
            end_state: { manual: '', selection: [] },
            idx: uuidv4(),
          },
        ],
    development_plan: data?.development_plan || [],
    staff_id: data?.staff_id,
    agenda_as_main: data?.agenda_as_main || null,
    action_type: data?.action_type || '',
    agenda_as_main_type: data?.agenda_as_main_type,
    agenda_as_main_platform: data?.agenda_as_main_platform,
    talent_review_date: data?.talent_review_date,
  };
};

export const convertDataToSetAgendaAsMain = (data) => {
  if (isEmpty(data)) return {};
  return {
    staff_id: data?.staff_id,
    type_agenda: data?.type_agenda,
    top_talent: data?.top_talent || {},
    succession_planning: data?.succession_planning || {},
    mobility_plan: !isEmpty(data?.mobility_plan)
      ? data.mobility_plan.map((item) => {
          return {
            ...item,
            idx: uuidv4(),
          };
        })
      : [
          {
            next_mobility: { manual: '', selection: [] },
            next_2_mobility: { manual: '', selection: [] },
            end_state: { manual: '', selection: [] },
            idx: uuidv4(),
          },
        ],
    development_plan: data?.development_plan || [],
    version: data?.version === 0 ? data?.version : data?.version || null,
    set_main_at: data?.set_main_at || '',
    version_name: data?.version_name || '',
    talent_review_date: data?.talent_review_date,
  };
};

export const convertDataToUpdateTalentReviewAgenda = (data, date, idMeeting) => {
  const dateWithFormat = moment(data?.date || date).format('YYYY-MM-DD');
  return {
    agenda_id: !isNaN(Number(data?.agenda_id)) ? data?.agenda_id : null,
    title: data?.title || data?.agenda_name,
    start_time: data?.start_time
      ? moment(`${dateWithFormat} ${data?.start_time}`, DATE_TIME_FORMAT.DATE_TIME).toDate()
      : '',
    end_time: data?.end_time
      ? moment(`${dateWithFormat} ${data?.end_time}`, DATE_TIME_FORMAT.DATE_TIME).toDate()
      : '',
    advocators: isEmpty(data?.advocators)
      ? []
      : data?.advocators.map((item) => {
          return item?.value;
        }),
    hr_partners: isEmpty(data?.hr_partners)
      ? []
      : data?.hr_partners.map((item) => {
          return item?.value;
        }),
    version: data?.version || 0,
    meeting_id: data?.meeting_id || idMeeting,
    type_agenda: data?.type_agenda,
    user_id: data?.user_id || '',
    set_main_at: data?.set_main_at || '',
    version_name: data?.version_name || '',
    approved_status: data?.approved_status || '',
    business: data?.business || '',
    status: data?.status || AGENDA_STATUS.PAPER_IN_PROGRESS,
    top_talent: !isEmpty(data?.top_talent) ? { ...data?.top_talent, image: '' } : {},
    succession_planning: data?.succession_planning || {},
    mobility_plan:
      data?.mobility_plan?.map((item) => ({
        next_mobility: {
          ...item?.next_mobility,
          selection:
            item?.next_mobility?.selection?.map((p, idx) => ({
              position_code: p.position_code,
              priority: p.priority,
              sort_order: idx,
            })) || [],
        },
        next_2_mobility: {
          ...item?.next_2_mobility,
          selection:
            item?.next_2_mobility?.selection?.map((p, idx) => ({
              position_code: p.position_code,
              priority: p.priority,
              sort_order: idx,
            })) || [],
        },
        end_state: {
          ...item?.end_state,
          selection:
            item?.end_state?.selection?.map((p, idx) => ({
              position_code: p.position_code,
              priority: p.priority,
              sort_order: idx,
            })) || [],
        },
      })) || [],
    development_plan: data?.development_plan || [],
    staff_id: data?.staff_id,
    agenda_as_main: data?.agenda_as_main || null,
    action_type: data?.action_type || '',
    agenda_as_main_type: data?.agenda_as_main_type,
    agenda_as_main_platform: data?.agenda_as_main_platform,
    talent_review_date: data?.talent_review_date,
  };
};

export const convertDataOtherAgendaToUpdate = (data, meeting_id, agenda_id) => {
  return {
    ...data,
    agenda_id,
    meeting_id,
    hr_partners: data.hr_partners.map((i) => i.value),
    advocators: data.advocators.map((i) => i.value),
    remarks: !isEmpty(data?.remarks)
      ? data.remarks.map((item) => {
          if (!isNumber(item?.remark_id)) {
            return {
              remark: item?.remark,
              remark_type: item?.remark_type,
              category: isObject(item?.category) ? item?.category?.value : item?.category,
              staff_id: item?.staff_id,
            };
          } else {
            return {
              ...item,
              remark_id: item?.remark_id,
              category: isObject(item?.category) ? item?.category?.value : item?.category,
            };
          }
        })
      : [],
    version: 0,
    attachment_ids: !isEmpty(data?.fileUpload) ? data.fileUpload.map((item) => item.id) : null,
  };
};

export const convertDataOtherAgendaToView = (data) => {
  return {
    ...data,
    title: data.agenda_name,
    hr_partners: data.hr_partners.map((i) => ({ value: i.id, label: i.name })),
    advocators: data.advocators.map((i) => ({ value: i.id, label: i.name })),
  };
};

export const getDevelopmentPlanningData = (data) => {
  let temObject = {
    first_line: [],
    second_line: [],
    third_line: [],
    agreed_to_drop: [],
  };

  if (!isEmpty(data)) {
    temObject.first_line = !isEmpty(data.first_line)
      ? data.first_line.map((item) => {
          return item.position_code;
        })
      : [];
    temObject.second_line = !isEmpty(data.second_line)
      ? data.second_line.map((item) => {
          return item.position_code;
        })
      : [];
    temObject.third_line = !isEmpty(data.third_line)
      ? data.third_line.map((item) => {
          return item.position_code;
        })
      : [];
    temObject.agreed_to_drop = !isEmpty(data.agreed_to_drop)
      ? data.agreed_to_drop.map((item) => {
          return {
            position_code: item.position_code,
            remark: item.remark,
            revert_line: item.revert_line,
          };
        })
      : [];
  }

  return temObject;
};

export function getListExcludePositions(data) {
  const fristLine = data.succession_planning.first_line || [];
  const secondLine = data.succession_planning.second_line || [];
  const thirdLine = data.succession_planning.third_line || [];
  const agreedToDrop = data.succession_planning.agreed_to_drop || [];
  const newArr = [...fristLine, ...secondLine, ...thirdLine, ...agreedToDrop];
  return newArr.map((item) => item.position_code);
}

export function downloadFile(blob, fileName) {
  const link = document.createElement('a');
  // create a blobURL pointing to our Blob
  link.href = URL.createObjectURL(blob);
  link.download = fileName;
  // some browser needs the anchor to be in the doc
  document.body.append(link);
  link.click();
  link.remove();
  // in case the Blob uses a lot of memory
  setTimeout(() => URL.revokeObjectURL(link.href), 7000);
}

export const getBusinessShortName = (name) => {
  let shortName = '';
  switch (name) {
    case BUSINESS_NAME_TIME_LINE.UPSTREAM:
      shortName = BUSINESS_SHORT_NAME.UPSTREAM;
      break;
    case BUSINESS_NAME_TIME_LINE.DOWNSTREAM:
      shortName = BUSINESS_SHORT_NAME.DOWNSTREAM;
      break;
    case BUSINESS_NAME_TIME_LINE.CORPORATE:
      shortName = BUSINESS_SHORT_NAME.CORPORATE;
      break;
    case BUSINESS_NAME_TIME_LINE.GAS_NEW_ENERGY:
      shortName = BUSINESS_SHORT_NAME.GAS_NEW_ENERGY;
      break;
    case BUSINESS_NAME_TIME_LINE.PDT:
      shortName = BUSINESS_SHORT_NAME.PDT;
      break;
    case BUSINESS_NAME_TIME_LINE.NEW_Energy:
      shortName = BUSINESS_SHORT_NAME.NEW_Energy;
      break;
    case BUSINESS_NAME_TIME_LINE.HR:
      shortName = BUSINESS_SHORT_NAME.HR;
      break;
    case BUSINESS_NAME_TIME_LINE.PETRONAS_GAS:
      shortName = BUSINESS_SHORT_NAME.PETRONAS_GAS;
      break;
    case BUSINESS_NAME_TIME_LINE.HOLDING_COMPANY:
    case BUSINESS_NAME_TIME_LINE.HC:
    case BUSINESS_NAME_TIME_LINE.HCU:
      shortName = BUSINESS_SHORT_NAME.CORPORATE;
      break;
    default:
      break;
  }
  return shortName;
};

export const mockApiReturn = (url, resValue, isSuccess = true, timeout = 500) => {
  if (url) {
    console.log('call api', url);
  }
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      if (isSuccess) {
        resolve({
          status: 200,
          data: {
            code: 200,
            message: 'OK',
            result: resValue,
          },
        });
      } else {
        reject(new Error({ message: 'Error', code: 403 }));
      }
    }, timeout);
  });
};

export const getRoleShortName = (name) => {
  let shortName = '';
  switch (name) {
    case ROLE_SG_MAPPING.EXECUTIVE:
      shortName = ROLE_SHORT_NAME.EXECUTIVE;
      break;
    case ROLE_SG_MAPPING.GENERAL_MANAGER:
      shortName = ROLE_SHORT_NAME.GENERAL_MANAGER;
      break;
    case ROLE_SG_MAPPING.MANAGER:
      shortName = ROLE_SHORT_NAME.MANAGER;
      break;
    case ROLE_SG_MAPPING.SENIOR_GENERAL_MANAGER:
      shortName = ROLE_SHORT_NAME.SENIOR_GENERAL_MANAGER;
      break;
    case ROLE_SG_MAPPING.SENIOR_MANAGER:
      shortName = ROLE_SHORT_NAME.SENIOR_MANAGER;
      break;
    case ROLE_SG_MAPPING.VICE_PRESIDENT:
      shortName = ROLE_SHORT_NAME.VICE_PRESIDENT;
      break;
    default:
      break;
  }
  return shortName;
};

export const getMappingData = (groups, data, typeMapping) => {
  let cloneDeepData = cloneDeep(groups);
  let newData = [];

  if (!isEmpty(data)) {
    data.forEach((item) => {
      Object.keys(cloneDeepData).forEach((key, value) => {
        if (item.role_level === key && typeMapping === MAPPING_DATA_TYPES.SG_JG_MAPPING) {
          cloneDeepData[key] = getSGJGMappingItem(key, item.children);
        }
        if (item.business_unit === key && typeMapping === MAPPING_DATA_TYPES.BU_MAPPING) {
          cloneDeepData[key] = getBuMappingItem(key, item.children);
        }
        if (item?.job_family?.value === key && typeMapping === MAPPING_DATA_TYPES.FUNCTION_LIST) {
          cloneDeepData[key] = getFunctionListItem(key, item.children);
        }
      });
    });

    Object.entries(cloneDeepData).forEach((item) => {
      newData.push(...item[1]);
    });
  }

  return newData;
};

export const getSGJGMappingItem = (roleName, data) => {
  return !isEmpty(data)
    ? data.map((item, index) => {
        return {
          id: item.id,
          only_one_item: data.length === 1 ? 'only_one_item' : '',
          first_item: data.length > 1 && index === 0 ? 'first_item' : '',
          last_item: data.length && data.length === index + 1 ? 'last_item' : '',
          role_level: roleName,
          sg_in_oracle: item?.sg_in_oracle?.label || '',
          jg_in_oracle: item?.jg_in_oracle?.label || '',
          jg_in_p_series: item?.jg_in_p_series?.label || '',
        };
      })
    : [];
};

export const getBuMappingItem = (buLabel, data) => {
  return !isEmpty(data)
    ? data.map((item, index) => {
        return {
          id: item.id,
          only_one_item: data.length === 1 ? 'only_one_item' : '',
          first_item: data.length > 1 && index === 0 ? 'first_item' : '',
          last_item: data.length && data.length === index + 1 ? 'last_item' : '',
          business_unit: buLabel,
          data_field_in_oracle: item?.data_field_in_oracle?.label || '',
          data_content_in_oracle: item?.data_content_in_oracle?.label || '',
        };
      })
    : [];
};

export const getFunctionListItem = (jobFamilyName, data) => {
  return !isEmpty(data)
    ? data.map((item, index) => {
        return {
          id: item.id,
          only_one_item: data.length === 1 ? 'only_one_item' : '',
          first_item: data.length > 1 && index === 0 ? 'first_item' : '',
          last_item: data.length && data.length === index + 1 ? 'last_item' : '',
          job_family: jobFamilyName,
          sub_job_family: item?.sub_job_family?.label || '',
          function: item?.function?.label || '',
        };
      })
    : [];
};

export function getOriginalColor(line) {
  if (isEmpty(line)) return '';
  let color = '';
  switch (line) {
    case '1st Line':
      color = '#66B2C4';
      break;
    case '2nd Line':
      color = '#BDB6EA';
      break;
    case '3rd Line':
      color = '#BCC6E1';
      break;

    default:
      break;
  }
  return color;
}

export const paginationConfig = (total, currentPage, onChange, limitPage = 15) => {
  return {
    position: ['bottomRight'],
    total,
    current: currentPage,
    showSizeChanger: false,
    defaultPageSize: limitPage,
    onChange: onChange,
    showTotal: (total, range) => (
      <div>
        Showing <b>{range[0]}</b> to <b>{range[1]}</b> of <b>{total}</b> results
      </div>
    ),
  };
};

export const formatMainMobilityPlan = (data) => {
  const endState = data[MOBILITY_PLAN_LATEST_KEYS.END_STATE];
  const mobilityPlan = data[MOBILITY_PLAN_LATEST_KEYS.NEXT_MOBILITY];
  const mobility2Plan = data[MOBILITY_PLAN_LATEST_KEYS.NEXT_2_MOBILITY];
  const maxLength = Math.max(endState.length, mobilityPlan.length, mobility2Plan.length);
  const rows = [];

  for (let i = 0; i < maxLength; i++) {
    rows.push({
      [MOBILITY_PLAN_LATEST_KEYS.NEXT_MOBILITY]: mobilityPlan[i] || null,
      [MOBILITY_PLAN_LATEST_KEYS.NEXT_2_MOBILITY]: mobility2Plan[i] || null,
      [MOBILITY_PLAN_LATEST_KEYS.END_STATE]: endState[i] || null,
    });
  }
  return rows;
};

export const sortCollectionByField = (arr, fieldName, orderArr) => {
  const compareArr = { others: [] };
  const result = [];
  const orderSet = new Set(orderArr);

  orderSet.forEach((item) => {
    compareArr[item] = [];
  });

  arr.forEach((item) => {
    const value = item[fieldName];
    if (orderSet.has(value)) {
      compareArr[value].push(item);
    } else {
      compareArr.others.push(item);
    }
  });

  orderSet.forEach((item) => {
    result.push(...compareArr[item]);
  });
  result.push(...compareArr.others);
  return result;
};

export const checkDeleteResetPage = (
  arrDeleted,
  arrData,
  total,
  currentPage,
  groupRow = 1,
  limit = 15
) => {
  return arrDeleted.length * groupRow === arrData.length && total < limit * currentPage;
};

export const getContentTypeByExtension = (fileName) => {
  if (!fileName) return;

  let contentType;
  let responseType;
  let dataType;
  let extension = getFileExtension(fileName) || '';
  extension = extension.toLowerCase();
  switch (extension) {
    case FILE_EXTENSION.PDF:
      contentType = 'application/pdf';
      responseType = 'arraybuffer';
      dataType = 'blob';
      break;
    case FILE_EXTENSION.MP4:
      responseType = 'blob';
      contentType = 'video/mp4';
      break;
    case FILE_EXTENSION.DOCX:
      break;
    default:
      contentType = 'application/octet-stream';
      break;
  }

  return { contentType, responseType, dataType, extension };
};

export const getFileExtension = (fileName) => {
  if (!fileName || fileName.startsWith('.')) return '';

  // Use a more efficient regex to capture the file extension
  const match = /(?:\.([^.]+))$/.exec(fileName);

  // Return the captured group if it exists, otherwise return an empty string
  return match ? match[1]?.toLowerCase() : '';
};

export const handleShowYIPAndYISG = (yearIn, dateIn) => {
  const yearInString = `${yearIn}`;
  if (!_.isEmpty(yearInString) && Number(yearInString) === 0) return '0 Months';

  if (
    !_.isEmpty(yearInString) &&
    Number(yearInString) > 0 &&
    moment(dateIn, 'YYYY/MM/DD', true).isValid()
  ) {
    return calculateTime(dateIn);
  }
  return 'N/A';
};

export const generateFilterUrl = (searchParams) => {
  let result = '?';

  Object.keys(searchParams).forEach((fieldName) => {
    const value = searchParams[fieldName];
    switch (typeof value) {
      case 'number':
      case 'boolean':
      case 'string':
        result += `${fieldName}=${value}&`;
        break;
      case 'object':
        result += !isEmpty(value)
          ? value
              ?.map((item) => {
                if (typeof item === 'string') {
                  return `${fieldName}[]=${item?.replace('&', '%26')}&`;
                } else {
                  return `${fieldName}[]=${item}&`;
                }
              })
              .join('')
          : '';
        break;
      default:
        break;
    }
  });
  result = result.substring(0, result.length - 1);
  return result;
};

export const getCurrentTab = (tabs, pathname) => {
  let tab = null;
  tabs.forEach((item) => {
    const path = pathname.split('/');
    if (path.includes(item.path)) {
      tab = item;
    } else if (!isEmpty(item.subTab)) {
      item.subTab.forEach((i) => {
        if (path.includes(i.path)) tab = i;
      });
    }
  });
  return tab;
};

export const convertExternalTalents = (currentObj) => {
  return {
    ...currentObj,
    assessment: {
      performance_rating: [
        {
          year: moment().year() - 5,
          description:
            currentObj.assessment.performance_rating.find(
              (item) => Number(item.year) === moment().year() - 5
            )?.description || '',
        },
        {
          year: moment().year() - 4,
          description:
            currentObj.assessment.performance_rating.find(
              (item) => Number(item.year) === moment().year() - 4
            )?.description || '',
        },
        {
          year: moment().year() - 3,
          description:
            currentObj.assessment.performance_rating.find(
              (item) => Number(item.year) === moment().year() - 3
            )?.description || '',
        },
        {
          year: moment().year() - 2,
          description:
            currentObj.assessment.performance_rating.find(
              (item) => Number(item.year) === moment().year() - 2
            )?.description || '',
        },
        {
          year: moment().year() - 1,
          description:
            currentObj.assessment.performance_rating.find(
              (item) => Number(item.year) === moment().year() - 1
            )?.description || '',
        },
      ],
      be_pcb: [
        {
          year: moment().year() - 5,
          description:
            currentObj.assessment.be_pcb.find((item) => Number(item.year) === moment().year() - 5)
              ?.description || '',
        },
        {
          year: moment().year() - 4,
          description:
            currentObj.assessment.be_pcb.find((item) => Number(item.year) === moment().year() - 4)
              ?.description || '',
        },
        {
          year: moment().year() - 3,
          description:
            currentObj.assessment.be_pcb.find((item) => Number(item.year) === moment().year() - 3)
              ?.description || '',
        },
        {
          year: moment().year() - 2,
          description:
            currentObj.assessment.be_pcb.find((item) => Number(item.year) === moment().year() - 2)
              ?.description || '',
        },
        {
          year: moment().year() - 1,
          description:
            currentObj.assessment.be_pcb.find((item) => Number(item.year) === moment().year() - 1)
              ?.description || '',
        },
      ],
      external_link: currentObj.assessment.external_link || [],
    },
    working_experience: !isEmpty(currentObj.working_experience)
      ? currentObj.working_experience
      : [],
  };
};

export const compareArrays = (arr1, arr2, fieldName) => {
  const result = [];
  const longerArr = arr1.length > arr2.length ? arr1 : arr2;
  const shorterArr = arr1.length > arr2.length ? arr2 : arr1;
  longerArr.forEach((item) => {
    if (!shorterArr.some((otherItem) => otherItem[fieldName] === item[fieldName])) {
      result.push(item);
    }
  });
  if (arr1.length === arr2.length) {
    shorterArr.forEach((item) => {
      if (!longerArr.some((otherItem) => otherItem[fieldName] === item[fieldName])) {
        result.push(item);
      }
    });
  }
  return result;
};

export const convertRoleAccessLevels = (data) => {
  if (!isArray(data)) return '';

  let tempRoleAccessLevel = '';
  data.forEach((roleName) => {
    switch (roleName) {
      case ROLE_SHORT_NAME.VICE_PRESIDENT:
        tempRoleAccessLevel = ROLE_SG_MAPPING.VICE_PRESIDENT;
        break;
      case ROLE_SHORT_NAME.SENIOR_GENERAL_MANAGER:
        tempRoleAccessLevel =
          (tempRoleAccessLevel && tempRoleAccessLevel + ', ') +
          ROLE_SG_MAPPING.SENIOR_GENERAL_MANAGER;
        break;
      case ROLE_SHORT_NAME.GENERAL_MANAGER:
        tempRoleAccessLevel =
          (tempRoleAccessLevel && tempRoleAccessLevel + ', ') + ROLE_SG_MAPPING.GENERAL_MANAGER;
        break;
      case ROLE_SHORT_NAME.SENIOR_MANAGER:
        tempRoleAccessLevel =
          (tempRoleAccessLevel && tempRoleAccessLevel + ', ') + ROLE_SG_MAPPING.SENIOR_MANAGER;
        break;
      case ROLE_SHORT_NAME.ROLE_EXECUTIVE:
        tempRoleAccessLevel =
          (tempRoleAccessLevel && tempRoleAccessLevel + ', ') + ROLE_SG_MAPPING.EXECUTIVE;
        break;
      case ROLE_SHORT_NAME.ROLE_MANAGER:
        tempRoleAccessLevel =
          (tempRoleAccessLevel && tempRoleAccessLevel + ', ') + ROLE_SG_MAPPING.MANAGER;
        break;
      default:
        break;
    }
  });
  return tempRoleAccessLevel;
};

export const getNoOf2NestedArr = (parentIndex, childIndex, startNum = 1) => {
  return 2 * parentIndex + childIndex + startNum;
};

export const convertLeadershipChartData = (data) => {
  if (isEmpty(data)) return [];
  const outputArray = data.edges.map((item) => ({
    ...item,
    edge: LEADERSHIP_DESIRE_ORDER.find((i) => item.edge.includes(i)),
  }));

  outputArray.sort(
    (a, b) => LEADERSHIP_DESIRE_ORDER.indexOf(a.edge) - LEADERSHIP_DESIRE_ORDER.indexOf(b.edge)
  );
  return outputArray;
};

export function convertHistoricalSuccessionPlanning(data, roles) {
  let arr = data.map((item) => {
    return {
      ...item,
      value: item.value.map((val) => {
        return {
          ...val,
          order:
            val.role === 'overall'
              ? 1
              : val.role === 'Vice President'
              ? 2
              : val.role === 'Senior General Manager'
              ? 3
              : val.role === 'General Manager'
              ? 4
              : val.role === 'Senior Manager'
              ? 5
              : 6,
        };
      }),
    };
  });

  arr = arr.map((item) => {
    const newValue = orderBy(item?.value, ['order'], ['asc']);
    return {
      ...item,
      value: newValue,
    };
  });

  return arr.map((item) => {
    let obj = {};
    const value = item?.value;

    obj = {
      overall_ratio: value.find((item) => item.role === 'overall')?.ratio ?? 'N/A',
      overall_position_deliberated:
        value.find((item) => item.role === 'overall')?.position_deliberated ?? 'N/A',
      overall_key_position: value.find((item) => item.role === 'overall')?.key_position ?? 'N/A',
    };
    obj[`${roles[0]?.abbreviation}_ratio`] =
      value.find((item) => item.role === roles[0]?.name)?.ratio ?? 'N/A';
    obj[`${roles[0]?.abbreviation}_position_deliberated`] =
      value.find((item) => item.role === roles[0]?.name)?.position_deliberated ?? 'N/A';
    obj[`${roles[0]?.abbreviation}_key_position`] =
      value.find((item) => item.role === roles[0]?.name)?.key_position ?? 'N/A';
    obj[`${roles[1]?.abbreviation}_ratio`] =
      value.find((item) => item.role === roles[1]?.name)?.ratio ?? 'N/A';
    obj[`${roles[1]?.abbreviation}_position_deliberated`] =
      value.find((item) => item.role === roles[1]?.name)?.position_deliberated ?? 'N/A';
    obj[`${roles[1]?.abbreviation}_key_position`] =
      value.find((item) => item.role === roles[1]?.name)?.key_position ?? 'N/A';
    obj[`${roles[2]?.abbreviation}_ratio`] =
      value.find((item) => item.role === roles[2]?.name)?.ratio ?? 'N/A';
    obj[`${roles[2]?.abbreviation}_position_deliberated`] =
      value.find((item) => item.role === roles[2]?.name)?.position_deliberated ?? 'N/A';
    obj[`${roles[2]?.abbreviation}_key_position`] =
      value.find((item) => item.role === roles[2]?.name)?.key_position ?? 'N/A';

    return {
      ...item,
      ...obj,
      conducted: item.conducted ?? 'N/A',
    };
  });
}

export const checkIntegerNumber = (number) => {
  const regex = new RegExp(/^\d+$/);
  if (!regex.test(number)) return false;

  number = parseInt(number);

  return (
    isSafeInteger(number) &&
    inRange(number, SQL_INTERGER_LIMIT.MIN_VALUE, SQL_INTERGER_LIMIT.MAX_VALUE)
  );
};

export const returnDataToExportViewResult = (data) => {
  return data.map((item) => {
    return [
      {
        value: item?.staff_id ?? 'N/A',
      },
      {
        value: item?.staff_name ?? 'N/A',
      },
      {
        value: item?.position_code ?? 'N/A',
      },
      {
        value: item?.set_name ?? 'N/A',
      },
      {
        value: item?.years_in_position ?? 'N/A',
      },
      {
        value: item?.years_in_sg ?? 'N/A',
      },
      {
        value: item?.age ?? 'N/A',
      },
      {
        value: item?.retirement_date ?? 'N/A',
      },
      {
        value: item?.current_position ?? 'N/A',
      },
      {
        value: item?.talent_level ?? 'N/A',
      },
      {
        value: item?.sg ?? 'N/A',
      },
      {
        value: item?.matching_percentage ?? 'N/A',
      },
      {
        value: item?.sp_readiness ?? 'N/A',
      },
      {
        value: item?.edge_match ?? 'N/A',
      },
      {
        value: item?.edge_spike_match ?? 'N/A',
      },
      {
        value: item?.attr27_match ?? 'N/A',
      },
      {
        value: item?.attr27_spike_match ?? 'N/A',
      },
      {
        value: item?.function_score ?? 'N/A',
      },
      {
        value: item?.business_score ?? 'N/A',
      },
      {
        value: item?.function_spike_score ?? 'N/A',
      },
      {
        value: item?.business_spike_score ?? 'N/A',
      },
      {
        value: item?.ppa_score ?? 'N/A',
      },
      {
        value: item?.vp_score ?? 'N/A',
      },
      {
        value: item?.sgm_score ?? 'N/A',
      },
      {
        value: item?.edge ?? 'N/A',
      },
      {
        value: item?.edge_spike ?? 'N/A',
      },
      {
        value: item?.attr27 ?? 'N/A',
      },
      {
        value: item?.attr27_spike ?? 'N/A',
      },
      {
        value: item?.functional ?? 'N/A',
      },
      {
        value: item?.business ?? 'N/A',
      },
      {
        value: item?.functional_spike ?? 'N/A',
      },
      {
        value: item?.business_spike ?? 'N/A',
      },
      {
        value: item?.ppa ?? 'N/A',
      },
      {
        value: item?.vp ?? 'N/A',
      },
      {
        value: item?.sgm ?? 'N/A',
      },
      {
        value: item?.filter_incumbent ?? 'N/A',
      },
      {
        value: item?.top_talent_experience_optional ?? 'N/A',
      },
      {
        value: item?.personality_archetype_optional ?? 'N/A',
      },
    ];
  });
};

export const returnDataToExportHistoricalDetails = (data) => {
  return data.length
    ? data.map((item, index) => {
        return [
          {
            value: index + 1,
          },
          {
            value: item?.staffName || 'N/A',
          },
          {
            value: item?.staffId || 'N/A',
          },
          {
            value: item?.topTalentStatus || item?.top_talent_status || 'N/A',
          },
          {
            value: item?.salaryGrade || item?.sg || 'N/A',
          },
          {
            value: item?.role_level || 'N/A',
          },
          {
            value: item?.business || 'N/A',
          },
        ];
      })
    : [];
};

export const calculateYearMonthBetween = (start, end, onlyYear) => {
  if (!start || !end) return 'N/A';
  const startDate = new Date(start);
  const endDate = new Date(end);

  let years = endDate.getFullYear() - startDate.getFullYear();
  let months = endDate.getMonth() - startDate.getMonth();

  if (months < 0 || (months === 0 && endDate.getDate() < startDate.getDate())) {
    years--;
    months += 12;
  }

  let result = '';
  if (years > 0) {
    result += `${years} ${years > 1 ? 'Years' : 'Year'} `;
  }
  if (onlyYear) {
    return result;
  }
  if (months > 0) {
    result += `${months} ${months > 1 ? 'Months' : 'Month'}`;
  }

  return result.trim();
};

export const optionSelectStatus = (group, positions) => {
  if (isEmpty(positions))
    return {
      positionSelected: {},
      options: [
        { label: 'To Review', value: 'Reviewed' },
        { label: 'To Endorse', value: 'Endorsed' },
        { label: 'To Approve', value: 'Approved' },
      ],
    };
  if (!group)
    return {
      positionSelected: {},
      options: [],
    };
  const role = positions.filter((item) => item.role)[0]?.role;
  if (!role)
    return {
      positionSelected: {},
      options: [],
    };
  const positionSelected = positions.find((item) => item.role === role);
  const type = positionSelected?.position_label;
  if (!type)
    return {
      positionSelected: {},
      options: [],
    };
  //2024139 sgm bcp
  // Review: dropdown: To Review
  // Endorse: dropdown: To Review and To Endorse
  // Approval: dropdown: To Review, To Endorse and To Approve
  const isApprove = ACTION_TYPE_AGENDA.APPROVE.find(
    (item) => item.group === group && item.type.includes(type) && item.role.includes(role)
  );
  if (isApprove)
    return {
      positionSelected,
      options: [
        { label: 'To Review', value: 'Reviewed' },
        { label: 'To Endorse', value: 'Endorsed' },
        { label: 'To Approve', value: 'Approved' },
      ],
    };

  const isEndorse = ACTION_TYPE_AGENDA.ENDORSE.find(
    (item) => item.group === group && item.type.includes(type) && item.role.includes(role)
  );
  if (isEndorse)
    return {
      positionSelected,
      options: [
        { label: 'To Review', value: 'Reviewed' },
        { label: 'To Endorse', value: 'Endorsed' },
      ],
    };
  const isReview = ACTION_TYPE_AGENDA.REVIEW.find(
    (item) => item.group === group && item.type.includes(type) && item.role.includes(role)
  );
  if (isReview)
    return {
      positionSelected,
      options: [{ label: 'To Review', value: 'Reviewed' }],
    };
  return {
    positionSelected: {},
    options: [],
  };
};

export const optionSelectStatusTalentReview = (isShowTalentDetail, group, type, line, role) => {
  if (!isShowTalentDetail)
    return [
      { label: 'To Review', value: 'Reviewed' },
      { label: 'To Endorse', value: 'Endorsed' },
      { label: 'To Approve', value: 'Approved' },
    ];

  if (!group || !type || !role) return [];
  let typeCheck = type;
  if ((group === 'HR BP' || group === 'HR COE') && type === 'Corporate Talent' && !line) return [];
  if ((group === 'HR BP' || group === 'HR COE') && type === 'Corporate Talent' && line)
    typeCheck = type + ' ' + line;

  const isApprove = ACTION_TYPE_TALENT_REVIEW_AGENDA.APPROVE.find(
    (item) => item.group === group && item.type.includes(typeCheck) && item.role.includes(role)
  );

  if (isApprove)
    return [
      { label: 'To Review', value: 'Reviewed' },
      { label: 'To Endorse', value: 'Endorsed' },
      { label: 'To Approve', value: 'Approved' },
    ];

  const isEndorse = ACTION_TYPE_TALENT_REVIEW_AGENDA.ENDORSE.find(
    (item) => item.group === group && item.type.includes(typeCheck) && item.role.includes(role)
  );
  if (isEndorse)
    return [
      { label: 'To Review', value: 'Reviewed' },
      { label: 'To Endorse', value: 'Endorsed' },
    ];
  const isReview = ACTION_TYPE_TALENT_REVIEW_AGENDA.REVIEW.find(
    (item) => item.group === group && item.type.includes(typeCheck) && item.role.includes(role)
  );
  if (isReview) return [{ label: 'To Review', value: 'Reviewed' }];

  return [];
};

export const sortByField = (array, fieldName, ascending = true) => {
  return array.sort((a, b) => {
    if (ascending) {
      return a[fieldName] - b[fieldName];
    } else {
      return b[fieldName] - a[fieldName];
    }
  });
};

export const getTalentStatusIcon = (assignment_status_type) => {
  switch (assignment_status_type) {
    case ASSIGNMENT_STATUS_TYPES.ACTIVE:
      return active_icon;
    case ASSIGNMENT_STATUS_TYPES.INACTIVE:
      return in_active_icon;
    case ASSIGNMENT_STATUS_TYPES.SUSPENDED:
      return suspended_icon;
    default:
      return null;
  }
};

export const handleReturnValueOfSetMatching = (data) => {
  let tempData = [];
  switch (data.criteria) {
    case CRITERIA_TYPE.EDGE:
      tempData = data.content.map((item) => ({
        content: item,
        proficiency: null,
        spike: false,
      }));
      break;
    case CRITERIA_TYPE.SMA_RESULT:
      tempData = data.content.map((item) => ({
        content: item,
        proficiency: null,
      }));
      break;
    case CRITERIA_TYPE.PERSONALITY_ARCHETYPE:
    case CRITERIA_TYPE.PERSONALITY_ARCHETYPE_OPTIONAL:
    case CRITERIA_TYPE.EXTERNAL_RESULT:
    case CRITERIA_TYPE.EXTERNAL_RESULT_OPTIONAL:
      tempData = data.content.map((item) => ({
        content: item,
        spike: null,
      }));
      break;
    case CRITERIA_TYPE.BUSINESS_EXPOSURE:
      tempData.push({
        content: null,
        year: '',
      });
      break;
    // case CRITERIA_TYPE.TOP_TALENT_EXPERIENCE:
    // case CRITERIA_TYPE.TOP_TALENT_EXPERIENCE_OPTIONAL:
    case CRITERIA_TYPE.BUSINESS_EXPOSURE_OPTIONAL:
    case CRITERIA_TYPE.ATTRIBUTES:
      tempData.push({
        content: null,
        spike: false,
      });
      break;
    case CRITERIA_TYPE.SUCCESSOR_TO_VP_POSITION:
    case CRITERIA_TYPE.SUCCESSOR_TO_SGM_POSITION:
    case CRITERIA_TYPE.PERFORMANCE_RATING:
      tempData.push({
        content: null,
      });
      break;
    case CRITERIA_TYPE.FUNCTIONAL:
      tempData.push({
        function: null,
        content: null,
        year: '',
        // complementary: false,
      });
      break;
    case CRITERIA_TYPE.FUNCTIONAL_OPTIONAL:
    case CRITERIA_TYPE.FUNCTIONAL_SPIKE:
      tempData.push({
        function: null,
        content: null,
        // complementary: false,
      });
      break;
    default:
      tempData.push({
        function: null,
        content: null,
        spike: false,
      });
      break;
  }
  return tempData;
};

export const getNewColumnCriteria = (data) => {
  let newColumn = {};
  switch (data) {
    case CRITERIA_TYPE.BUSINESS_EXPOSURE:
      newColumn = {
        content: null,
        year: '',
      };
      break;
    case CRITERIA_TYPE.ATTRIBUTES:
    case CRITERIA_TYPE.BUSINESS_EXPOSURE_OPTIONAL:
      newColumn = {
        content: null,
        spike: false,
      };
      break;
    // case CRITERIA_TYPE.TOP_TALENT_EXPERIENCE:
    // case CRITERIA_TYPE.TOP_TALENT_EXPERIENCE_OPTIONAL:
    //   newColumn = {
    //     content: null,
    //   };
    //   break;
    case CRITERIA_TYPE.FUNCTIONAL:
      newColumn = {
        function: null,
        content: null,
        year: '',
        // complementary: false,
      };
      break;
    case CRITERIA_TYPE.FUNCTIONAL_OPTIONAL:
    case CRITERIA_TYPE.FUNCTIONAL_SPIKE:
      newColumn = {
        function: null,
        content: null,
        // complementary: false,
      };
      break;
    default:
      newColumn = {
        function: null,
        content: null,
        spike: false,
      };
      break;
  }
  return newColumn;
};

export const confirmAutoLogout = () => {
  localStorage.clear();
  sessionStorage.clear();
  let cookies = document.cookie;
  if (cookies.split(';').length > 0) {
    for (let i = 0; i < cookies.split(';').length; ++i) {
      let name = cookies[i];
      document.cookie = name + '=;expired';
    }
  }
  history.push('/');
};

export const isDisabledFiledInAgendaForm = (agendaId, agendaDetail, meetingRole) => {
  return (
    (!isNaN(Number(agendaId)) &&
      [
        MEETING_DETAIL_STATUS.PUBLISHED,
        MEETING_DETAIL_STATUS.IN_PROGRESS,
        MEETING_DETAIL_STATUS.IN_REVIEW,
        MEETING_DETAIL_STATUS.CONCLUDED,
        MEETING_DETAIL_STATUS.IN_PLANNING,
      ].includes(agendaDetail?.meeting_status) &&
      ![AGENDA_STATUS.PAPER_IN_PROGRESS].includes(agendaDetail?.status) &&
      meetingRole?.is_secretariat_or_facilitator) ||
    !meetingRole?.is_secretariat_or_facilitator
  );
};

export const isDisabledRemarkFieldInAgenda = (agendaId, agendaDetail, meetingRole) => {
  return (
    (!isNaN(Number(agendaId)) &&
      (([
        MEETING_DETAIL_STATUS.PUBLISHED,
        MEETING_DETAIL_STATUS.CONCLUDED,
        MEETING_DETAIL_STATUS.IN_PLANNING,
      ].includes(agendaDetail?.meeting_status) &&
        ![AGENDA_STATUS.PAPER_IN_PROGRESS].includes(agendaDetail?.status)) ||
        ([MEETING_DETAIL_STATUS.IN_PROGRESS].includes(agendaDetail?.meeting_status) &&
          [AGENDA_STATUS.PAPER_FINALIZED].includes(agendaDetail?.status)) ||
        [AGENDA_STATUS.PAPER_BRING].includes(agendaDetail?.status)) &&
      meetingRole?.is_secretariat_or_facilitator) ||
    !meetingRole?.is_secretariat_or_facilitator
  );
};

export const returnDataToUpdateMobility = (data) => {
  return data.map((item, index) => {
    return {
      key: item.staffId,
      gm: item.GM,
      sgm: item.SGM,
      vp: item.VP,
      staff_id: item.staffId,
      category: item.category,
      is_unsearchable: item.isUnsearchable,
      positions: item.positions,
      release_checklist: item.release_checklist,
      compared_positions: item.compared_positions,
      position_code: item.position_code,
      position_name: item.position_name || 'N/A',
      years_in_position: item.years_in_position || item.year_in_position || 'N/A',
      years_in_sg: item.years_in_sg || item.year_in_sg || 'N/A',
      performance_rating: item.performance_rating || 'N/A',
      retirement_date: item.retirement_date || 'N/A',
      age: item.age || 'N/A',
      type: MOBILITY_MODAL_ADD_TYPE.TALENT,
      salary_grade: item.salary_grade || 'N/A',
      birth_name: item.birth_name || 'N/A',
      birth_date: item.birth_date || 'N/A',
      image: item.image,
      matching_percentage: item.matching_percentage ? `${item.matching_percentage}%` : 'N/A',
      sp_readiness: item.sp_readiness || 'N/A',
      retirement_year_countdown: item.retirement_year_countdown || 'N/A',
      business: item.business_unit || 'N/A',
      date_in_sg: item.date_in_sg || 'N/A',
      date_in_position: item.date_in_position || 'N/A',
      ct: item.ct || 'N/A',
      ranking: item.ranking || 'N/A',
      role: item.role || 'N/A',
      manager_releasability: item.manager_releasability || 'N/A',
      willing_to_relocate: item.willing_to_relocate || 'N/A',
      new_in_position: item.new_in_position || 'N/A',
      cross_business: item.cross_business || 'N/A',
      job_grade: item.job_grade || 'N/A',
      mobility_plan: item.mobility_plan || 'N/A',
      index: index + 1,
      approved_date: item?.approved_date || '',
    };
  });
};

export const handleRenderListPersonality = (data) => {
  return data.map((item, index) => {
    return (
      <tr key={index}>
        <td>
          <p>{item.personality_archetype}</p>
        </td>
        <td>
          <p> {item.details}</p>
        </td>
      </tr>
    );
  });
};

export const isTokenExpired = (token) => {
  const decodedToken = jwtDecode(token);
  const currentTime = Date.now() / 1000;
  return decodedToken.exp < currentTime;
};

export const getMaxLengthFiledInArrayMobilityPlan = (data) => {
  return !isEmpty(data)
    ? Math.max(
        ...data.map(
          (el) =>
            (
              (el.position_name || '') +
              (el.position_code ? ` (${el.position_code}) ` : '') +
              (el.job_grade ? ` (${el.job_grade}) ` : '')
            )?.length || 0
        )
      )
    : 0;
};

export const groupComplementary = (arr) => {
  const convertData = [];
  let isFirstItem = {};
  [...arr].forEach((item, index) => {
    if (item?.sub_statement !== isFirstItem.sub_statement || index % 10 === 0) {
      isFirstItem = item;
      convertData.push({ ...item, isFirstRow: true, totalRowSpan: 1 });
    } else {
      const firstItem = convertData.find((f) => f.id === isFirstItem.id);
      if (firstItem?.totalRowSpan) {
        firstItem.totalRowSpan += 1;
      }
      convertData.push({ ...item, idFirstRow: firstItem?.id });
    }
  });
  return convertData;
};

export const convertToAddEditComplementary = (data, staffId) => {
  const body = [];
  [...data]
    .filter((item) => (item.isSearching || item.isEditing) && item.job_function)
    // eslint-disable-next-line no-unused-vars
    .forEach(({ id, idFirstRow, isSearching, isEditing, tempVal, confidence_score, ...item }) => {
      if (isSearching || (isEditing && isNaN(id))) {
        body.push({
          staff_id: staffId,
          ...item,
        });
        return;
      }
      if (isEditing) {
        body.push({
          id,
          job_function: item.job_function,
          sub_statement: item.sub_statement,
          original_job_function: item.original_job_function,
          is_hide: item.is_hide,
          edit_type: item.edit_type || '',
        });
      }
    });

  return body;
};

export const groupAspirationMatching = (arr) => {
  const convertData = [];
  let isFirstItem = {};
  [...arr].forEach((item, index) => {
    if (
      (item?.original_minilmjobfunction !== isFirstItem.job_function &&
        item?.aspiration_statement !== isFirstItem.aspiration_statement) ||
      item.is_updated === false ||
      index % 10 === 0
    ) {
      isFirstItem = item;
      convertData.push({ ...item, isFirstRow: true, totalRowSpan: 1 });
    } else {
      const firstItem = convertData.find((f) => f.ids === isFirstItem.ids);
      if (firstItem?.totalRowSpan) {
        firstItem.totalRowSpan += 1;
      }
      convertData.push({ ...item, idFirstRow: firstItem?.ids });
    }
  });
  return convertData;
};

export const convertToAddEditAspirationMatching = (data, staffId) => {
  const body = [];
  [...data]
    .filter((item) => (item.isSearching || item.isEditing) && item.job_function)
    // eslint-disable-next-line no-unused-vars
    .forEach(
      ({
        ids,
        idFirstRow,
        isSearching,
        isEditing,
        tempVal,
        confidence_score,
        list_of_position,
        ...item
      }) => {
        if (isSearching || (isEditing && ids.includes('tempId'))) {
          body.push({
            staff_id: staffId,
            list_of_position:
              !isEmpty(list_of_position) && list_of_position.map((elem) => elem.position_code),
            is_updated: true,
            ...item,
          });
          return;
        }
        if (isEditing) {
          body.push({
            ids,
            job_function: item.job_function,
            list_of_position:
              !isEmpty(list_of_position) && list_of_position.map((elem) => elem.position_code),
            is_hide: item.is_hide,
            edit_type: item.edit_type || '',
          });
        }
      }
    );

  return body;
};

export const removeAtIndex = (array, index) => {
  return [...array.slice(0, index), ...array.slice(index + 1)];
};

export const insertAtIndex = (array, index, item) => {
  return [...array.slice(0, index), item, ...array.slice(index)];
};

export const arrayMove = (array, oldIndex, newIndex) => {
  return dndKitArrayMove(array, oldIndex, newIndex);
};

export const handleShowInformation = (data, type) => {
  if (isEmpty(data)) return '';

  if (type === 'time') {
    if (size(data) === 1) return data[0] ? formatDate(new Date(data[0])) : 'None';

    if (size(data) > 1) {
      let result = data.map((item) => {
        if (item) {
          return formatDate(new Date(item));
        }
        return 'None';
      });
      return join(result, ' / ');
    }
  } else {
    if (size(data) === 1) return data[0] ? data[0] : 'None';

    if (size(data) > 1) {
      let result = data.map((item) => {
        if (item) {
          return item;
        }
        return 'None';
      });
      return join(result, ' / ');
    }
  }
};

export const handleShowRetirementToView = (item) => {
  let total =
    Number(moment(item?.retirement_date || item?.contract_end_date).format('YYYY')) -
    Number(moment(item?.date_of_birth).format('YYYY'));
  if (!isNaN(total)) {
    return total;
  }
};

// Dashboard functions
export const convertDataSPS = (
  statisticSuccess,
  keyPositionData,
  type,
  positionDeliberated,
  roles
) => {
  let arrOfSPS = !isEmpty(roles)
    ? roles.map((role) => {
        return {
          roleName: role.name,
          ratio: 0,
          positionDeliberated: 0,
          keyPosition: 0,
          type: role.abbreviation,
          tooltipRatio: 'Ratio = Total of  Unique Successors ' + role.abbreviation + ' positions',
        };
      })
    : [];
  arrOfSPS = [
    { roleName: 'Overall', ratio: 0, positionDeliberated: 0, keyPosition: 0, type },
    ...arrOfSPS,
  ];

  if (!isEmpty(statisticSuccess) && !isEmpty(positionDeliberated) && !isEmpty(keyPositionData)) {
    arrOfSPS[0] = {
      ...arrOfSPS[0],
      ratio: statisticSuccess?.overallRatio || 0,
      positionDeliberated: positionDeliberated?.total || 0,
      keyPosition: keyPositionData?.total || 0,
    };

    arrOfSPS = arrOfSPS.map((role, index) => {
      if (index === 0) return arrOfSPS[0];

      const itemStatisticSuccess =
        !isEmpty(statisticSuccess.firstLineSuccessors) &&
        statisticSuccess.firstLineSuccessors.find((d) => d.role === role.type);
      const ratio = itemStatisticSuccess?.individualRatio
        ? itemStatisticSuccess?.individualRatio
        : Number.parseFloat(0);
      const itemPositionDelibrated =
        !isEmpty(positionDeliberated.total_role) &&
        positionDeliberated.total_role.filter((d) => d.role === role.type)[0];

      const itemKeyPosition =
        !isEmpty(keyPositionData.total_role) &&
        keyPositionData.total_role.find((d) => d.role === role.type);
      const keyPosition = itemKeyPosition?.number || 0;

      return {
        ...role,
        ratio: ratio,
        positionDeliberated: itemPositionDelibrated?.number || 0,
        keyPosition,
      };
    });
  }
  return arrOfSPS;
};

export const convertDataTobeReview = (totalRetirement) => {
  const newData = [];
  DASHBOARD_TOBE_REVIEW.forEach((item) => {
    const total = totalRetirement[item.keyApi]?.total;
    const totalRoles = totalRetirement[item.keyApi]?.total_role;
    if (total === 0 || total) {
      const newItem = { ...item, total: total, totalRoles };
      newData.push(newItem);
    }
  });
  return newData;
};

export const getAsyncOptionAdvocatorsHrPartners = async (inputValue, roleId) => {
  let res = [];
  try {
    const params = { keyword: inputValue, limit: 5 };
    let response = await userApi.getUsers(roleId, params);
    if (response.status === 200 && response.data.result.data.length > 0) {
      res = response.data.result.data.map((item) => ({
        label: item.name,
        value: item.id,
      }));
      return res;
    }
    return res;
  } catch (error) {
    console.error(error);
    return res;
  }
};

export const byteToMB = (fileSize) => {
  const MB_SIZE = 1024 * 1024;
  const fileMB = fileSize / MB_SIZE;
  return Math.round(fileMB * 10) / 10;
};

export const getDescForWithoutModal = (fields, agendaId, agendaType) => {
  return (
    <>
      {agendaId && (
        <>
          You have made changes on:
          <ul
            style={{
              color: `#9F9EAE`,
              fontSize: `13px`,
              fontStyle: `normal`,
              fontWeight: 500,
              lineHeight: `20px`,
              padding: `10px 20px 0`,
            }}
          >
            {fields.map((item) => {
              let tempVal = '';
              if (agendaType === AGENDA_SHORT_TYPES.SUCCESSION_PLANNING) {
                Object.keys(SP_AGENDA_FIELDS).forEach((key) => {
                  if (item === key) {
                    tempVal = `${SP_AGENDA_FIELDS[key]}`;
                  }
                });
              } else {
                Object.keys(AGENDA_FIELD_CHANGES).forEach((key) => {
                  if (item === key) {
                    tempVal = AGENDA_FIELD_CHANGES[key];
                  }
                });
              }
              return <li>{tempVal}</li>;
            })}
          </ul>
        </>
      )}
      {agendaId
        ? 'Please ensure the changes are saved before leaving the page.'
        : 'Please ensure the agenda is saved before leaving the page.'}
    </>
  );
};

export const isExistDataNotSave = (data) => {
  const { existedAgenda, createdAgenda, removeList, changeStatusList, editAgenda } = data;
  if (
    isEmpty(existedAgenda) &&
    isEmpty(createdAgenda) &&
    isEmpty(removeList) &&
    isEmpty(changeStatusList) &&
    isEmpty(editAgenda)
  )
    return false;

  return true;
};

export const isDifferentFieldMS = (data, agendaId) => {
  const { initData, currentData } = data;
  const { all_remarks, ...rest_field } = MS_FIELD;
  const fieldName = !isNaN(Number(agendaId)) ? MS_FIELD : rest_field;
  const fields = [];
  if (isEmpty(initData) && isEmpty(currentData)) return fields;
  Object.keys(fieldName).forEach((item) => {
    if (JSON.stringify(initData[item]) !== JSON.stringify(currentData[item])) {
      fields.push(item);
    }
  });
  return fields;
};

export const getDescForWithoutModalMS = (fields, agendaId) => {
  return (
    <>
      {agendaId && (
        <>
          You have made changes on:
          <ul
            style={{
              color: `#9F9EAE`,
              fontSize: `13px`,
              fontStyle: `normal`,
              fontWeight: 500,
              lineHeight: `20px`,
              padding: `10px 20px 0`,
            }}
          >
            {fields.map((item) => {
              let tempVal = '';
              Object.keys(MS_FIELD).forEach((key) => {
                if (item === key) {
                  tempVal = MS_FIELD[key];
                }
              });
              return <li>{tempVal}</li>;
            })}
          </ul>
        </>
      )}

      {agendaId
        ? 'Please ensure the changes are saved before leaving the page.'
        : 'Please ensure the agenda is saved before leaving the page.'}
    </>
  );
};

export const convertAgendaIncumbentData = (result) => {
  return {
    ...result,
    succession_planning_name: result?.agenda_name || result?.succession_planning_name,
    start_time: result.start_time ? moment(result.start_time).format(DATE_TIME_FORMAT.TIME) : '',
    end_time: result.end_time ? moment(result.end_time).format(DATE_TIME_FORMAT.TIME) : '',
    business: result?.business ?? 'CCP Succession Planning',
    cc_list: result.ccList.map((item) => ({
      value: item.id,
      label: item.name,
    })),
    advocators: result.advocators.map((item) => ({
      value: item.id,
      label: item.name,
    })),
    agenda_as_main_positions: result.agenda_as_main_positions?.split(',') || [],
  };
};

export const isDifferentFieldOT = (data) => {
  const { initData, currentData } = data;
  const fields = [];
  if (isEmpty(initData) && isEmpty(currentData)) return fields;
  Object.keys(OT_FIELD).forEach((item) => {
    if (JSON.stringify(initData[item]) !== JSON.stringify(currentData[item])) {
      fields.push(item);
    }
  });
  return fields;
};

export const getDescForWithoutModalOT = (fields, agendaId) => {
  return (
    <>
      {agendaId && (
        <>
          You have made changes on:
          <ul
            style={{
              color: `#9F9EAE`,
              fontSize: `13px`,
              fontStyle: `normal`,
              fontWeight: 500,
              lineHeight: `20px`,
              padding: `10px 20px 0`,
            }}
          >
            {fields.map((item) => {
              let tempVal = '';
              Object.keys(OT_FIELD).forEach((key) => {
                if (item === key) {
                  tempVal = OT_FIELD[key];
                }
              });
              return <li>{tempVal}</li>;
            })}
          </ul>{' '}
        </>
      )}
      {agendaId
        ? 'Please ensure the changes are saved before leaving the page.'
        : 'Please ensure the agenda is saved before leaving the page.'}
    </>
  );
};

export const orderBySuccessionPlanning = (data) => {
  return orderBy(
    data,
    [
      (v) => {
        return v.rank || '';
      },
      (v) => {
        return v.position_code || '';
      },
    ],
    ['desc', 'asc']
  );
};

export const getBreadCrumbList = (pageList) => {
  let tempData = [];
  pageList.forEach((item) => {
    if (item === `Dashboard`) {
      tempData.push({
        name: item,
        icon: dashboard_breadcrumb,
        url: `/dashboard`,
      });
    } else if (['Home', 'Homepage'].includes(item)) {
      tempData.push({
        name: item,
        icon: homepage_icon,
        url: `/homepage`,
      });
    } else if (item === `TC Meeting`) {
      tempData.push({
        name: item,
        icon: board_pack,
      });
    } else {
      tempData.push({ name: item });
    }
  });

  return tempData;
};

export const handleShowRetirementForSP = (item) => {
  let total =
    Number(moment(item?.retirement_date).format('YYYY')) -
    Number(moment(item?.birth_date, 'DD-MM-YYYY').format('YYYY'));
  if (!isNaN(total)) {
    return '(' + total + ')';
  }
};

export const HIDE_STATUS_COMPLEMENTARY = {
  DUE_TO_THRESHOLD: `(Due to threshold)`,
  INPUT_BY_USER: `(Input by user)`,
  BY_DEFAULT: `(By default)`,
};

export const returnContentHideStatus = (record, thresholdVal) => {
  let tempContent;
  if (record?.edit_type === 'hide') {
    tempContent = HIDE_STATUS_COMPLEMENTARY.INPUT_BY_USER;
  } else if (
    !record.is_updated &&
    record.is_hide &&
    record.confidence_score &&
    Number.parseFloat(record.confidence_score).toFixed(2) < thresholdVal
  ) {
    tempContent = HIDE_STATUS_COMPLEMENTARY.DUE_TO_THRESHOLD;
  } else if ((!record.is_updated && !record.is_hide) || (record.is_updated && !record?.edit_type)) {
    tempContent = HIDE_STATUS_COMPLEMENTARY.BY_DEFAULT;
  } else {
    tempContent = '';
  }
  return tempContent;
};

export const getDescForWithoutModalTalentReview = (fields, agendaId) => {
  return (
    <>
      {agendaId && (
        <>
          You have made changes on:
          <ul
            style={{
              color: `#9F9EAE`,
              fontSize: `13px`,
              fontStyle: `normal`,
              fontWeight: 500,
              lineHeight: `20px`,
              padding: `10px 20px 0`,
            }}
          >
            {fields.map((item) => (
              <li key={item}>{item}</li>
            ))}
          </ul>
        </>
      )}
      {agendaId
        ? 'Please ensure the changes are saved before leaving the page.'
        : 'Please ensure the agenda is saved before leaving the page.'}
    </>
  );
};

export function isIdsArray(arr) {
  if (isEmpty(arr)) return false;
  return arr.every((item) => item && !isNaN(Number(item)));
}

export const isDifferentFieldMeetingDetails = (data) => {
  const { initData, currentData } = data;
  const fields = [];
  if (isEmpty(initData) && isEmpty(currentData)) return fields;
  Object.keys(MEETING_DETAILS_FIELDS).forEach((item) => {
    if (
      JSON.stringify(initData[item]) !== JSON.stringify(currentData[item]) &&
      !item.includes(`remark_`)
    ) {
      fields.push(item);
    }

    if (item === `remark_fields`) {
      Object.keys(initData[item]).forEach((key) => {
        if (
          JSON.stringify(initData[item][key]) !== JSON.stringify(currentData[item][key]) &&
          ![`title`].includes(key)
        ) {
          fields.push(`remark_${key}`);
        }
      });
    }
  });
  return fields;
};

export const getDescForWithoutModalMeetingDetails = (fields, idMeeting) => {
  return (
    <>
      {idMeeting && (
        <>
          You have made changes on:
          <ul
            style={{
              color: `#9F9EAE`,
              fontSize: `13px`,
              fontStyle: `normal`,
              fontWeight: 500,
              lineHeight: `20px`,
              padding: `10px 20px 0`,
            }}
          >
            {fields.map((item) => {
              let tempVal = '';
              Object.keys(MEETING_DETAILS_FIELDS).forEach((key) => {
                if (item === key) {
                  tempVal = MEETING_DETAILS_FIELDS[key];
                }
              });
              return <li>{tempVal}</li>;
            })}
          </ul>{' '}
        </>
      )}
      {idMeeting
        ? 'Please ensure the changes are saved before leaving the page.'
        : 'Please ensure the agenda is saved before leaving the page.'}
    </>
  );
};

export const getRetirementContractEndLabel = (data) => {
  const contractVal = `CONTRACT`;
  if (!isEmpty(data)) {
    const isCheckContract =
      data.filter((item) =>
        !isEmpty(item?.sg) ? item.sg.includes(contractVal) : item?.sg === contractVal
      ).length > 0;
    const isCheckNoContract =
      data.filter((item) =>
        !isEmpty(item?.sg) ? !item.sg.includes(contractVal) : item?.sg !== contractVal
      ).length > 0;
    if (isCheckContract && isCheckNoContract) {
      return `Retirement / Contract End Date:`;
    } else if (isCheckContract) {
      return `Contract End Date:`;
    } else {
      return `Retirement Date:`;
    }
  }
};

export function convertDataCompentenciesExternalAssessment(data) {
  const newData = [];
  Object.values(COMPETENCIES_CATEGORY).forEach((item) => {
    if (data?.type === 'COMPETENCIES') {
      const val = data?.data?.find((f) => f.competency === item);
      newData.push({
        competency: item,
        popup_content: [
          {
            header: 'Definition',
            content: val?.popupContent?.find((p) => p.header === 'Definition')?.content ?? '-',
          },
          {
            header: 'High Score',
            content: val?.popupContent?.find((p) => p.header === 'High Score')?.content ?? '-',
          },
          {
            header: 'Low Score',
            content: val?.popupContent?.find((p) => p.header === 'Low Score')?.content ?? '-',
          },
        ],
        score: val?.score,
      });
    } else {
      newData.push({
        competency: item,
        popup_content: [
          {
            header: 'Definition',
            content: '-',
          },
          {
            header: 'High Score',
            content: '-',
          },
          {
            header: 'Low Score',
            content: '-',
          },
        ],
        score: '-',
      });
    }
  });

  return newData;
}

export const getSPAgendaDetailError = () => {
  return {
    agenda_id: null,
    start_time: '',
    end_time: '',
    status: '',
    meeting_id: null,
    type_agenda: '',
    remarks: '',
    version: null,
    created_at: null,
    agenda_name: '',
    user_id: null,
    set_main_at: null,
    version_name: null,
    approved_status: null,
    business: '',
    action_type: '',
    forward_from_meeting_id: null,
    agenda_as_main: null,
    agenda_as_main_positions: null,
    permissions: {},
    advocators: [],
    ccList: [],
    positions: [],
    first_line: [],
    second_line: [],
    third_line: [],
    succession_planning_name: '',
    cc_list: [],
    talent_remarks: [],
  };
};

export const returnBgdColorByScoreVal = (record) => {
  const bgdColor = {
    yellow: `#FBE769`,
    grey: `#D6D6D6`,
    lightBlue: `#8CC7F8`,
    darkBlue: `#005CB9`,
    strongYellow: `#FDB924`,
  };
  if (!record?.score) return bgdColor.grey;

  if (record.score <= 3) {
    return bgdColor.yellow;
  }

  if (record.score >= 3.1 && record.score <= 7.0) {
    return bgdColor.grey;
  }

  if (record.score >= 7.1 && record.score <= 9.4) {
    return bgdColor.lightBlue;
  }

  if (record.score >= 9.5) {
    return bgdColor.darkBlue;
  }
};

export const removeQueryString = (url) => {
  const index = url.indexOf('?');
  return index !== -1 ? url.substring(0, index) : url;
};

export const checkInvalidContentBusiness = (value) => {
  const format = new RegExp(/[¬`£~!@#$%^*_+={};':"\\|?]+/);
  return format.test(value);
};

export const convertToCompareComplementary = (data, staffId) => {
  const body = [];
  [...data]
    .filter((item) => (item.isSearching || item.isEditing) && item.job_function)
    // eslint-disable-next-line no-unused-vars
    .forEach(({ id, idFirstRow, isSearching, isEditing, tempVal, confidence_score, ...item }) => {
      if (isSearching || (isEditing && isNaN(id))) {
        body.push({
          isSearching,
          isEditing,
          staff_id: staffId,
          ...item,
        });
        return;
      }
      if (isEditing) {
        body.push({
          id,
          job_function: item.job_function,
          sub_statement: item.sub_statement,
          original_job_function: item.original_job_function,
          is_hide: item.is_hide,
          edit_type: item.edit_type || '',
          isSearching,
          isEditing,
        });
      }
    });

  return body;
};

export const formatDisplayTime = (seconds) => {
  const minDisplay = Math.floor(seconds / 60);
  const secDisplay = Math.floor(seconds % 60);
  return minDisplay ? `${minDisplay} min ${secDisplay} sec` : `${secDisplay} sec`;
};

export const handleShowInformationPosition = (data, type) => {
  if (isEmpty(data)) return 'None';

  if (type === 'time') {
    return formatDate(new Date(data)) || 'None';
  } else {
    return data;
  }
};

export const convertRoleForPosition = (value) => {
  if (isEmpty(value)) return '';

  let tempRole = '';
  switch (value) {
    case ROLE_SHORT_NAME.VICE_PRESIDENT:
      tempRole = ROLE_SG_MAPPING.VICE_PRESIDENT;
      break;
    case ROLE_SHORT_NAME.SENIOR_GENERAL_MANAGER:
      tempRole = ROLE_SG_MAPPING.SENIOR_GENERAL_MANAGER;
      break;
    case ROLE_SHORT_NAME.GENERAL_MANAGER:
      tempRole = ROLE_SG_MAPPING.GENERAL_MANAGER;
      break;
    case ROLE_SHORT_NAME.SENIOR_MANAGER:
      tempRole = ROLE_SG_MAPPING.SENIOR_MANAGER;
      break;
    case ROLE_SHORT_NAME.ROLE_EXECUTIVE:
      tempRole = ROLE_SG_MAPPING.EXECUTIVE;
      break;
    case ROLE_SHORT_NAME.ROLE_MANAGER:
      tempRole = ROLE_SG_MAPPING.MANAGER;
      break;
    default:
      tempRole = value;
      break;
  }
  return tempRole;
};

export function checkSubset(parent, child) {
  // Convert parent array to a Set for efficient lookup
  const parentSet = new Set(parent);

  // Check if every element in parentSet is in the child array
  return [...parentSet].some((item) => child.includes(item));
}

export const handleShowPositionForSP = (data) => {
  let text = data.position_name;
  if (data.business_unit) {
    text += ', ' + data.business_unit;
  }
  if (data.position_code) {
    text += ' (Position ID: ' + data.position_code + ')';
  }
  return text;
};

export const handleShowRoleForSP = (data) => {
  if (!data.role) return ', Role:';
  return ', Role: ' + convertRoleForPosition(data?.role);
};

export const getRetirementContractEndDateLabelForSP = (incumbent) => {
  const contractVal = `CONTRACT`;
  if (!isEmpty(incumbent?.sg) && incumbent?.sg.includes(contractVal)) {
    return `Contract End Date:`;
  } else {
    return `Retirement Date:`;
  }
};

export const getLableClassForSP = (data) => {
  let labelClass = `other`;
  if (data?.position_label === TYPE_BKP) {
    labelClass = `BCP`;
  } else if (data?.position_label === TYPE_EKP) {
    labelClass = `CCP`;
  }
  return labelClass;
};

export const validateActionType = (props) => {
  const { user_role, action_type, position_category, position_role } = props;
  if (!position_category || !position_role || !action_type) return null;
  if (action_type === 'Reviewed') return null;
  if (position_category === 'OCP') return null;
  let isMatch = false;
  MATRIX_SP_AGENDA.forEach((f) => {
    if (isMatch === true) return;
    if (
      f.user_role === user_role &&
      f.action_type === action_type &&
      f.position_category.includes(position_category) &&
      f.position_role.includes(position_role)
    )
      isMatch = true;
  });

  return isMatch === true ? null : { user_role, position_category };
};

export const validateActionTypeTR = (props) => {
  const { user_role, action_type, talent_type, position_role } = props;
  if (!talent_type || !position_role || !action_type) return null;
  if (action_type === 'Reviewed') return null;
  let isMatch = false;
  MATRIX_TR_AGENDA.forEach((f) => {
    if (isMatch === true) return;
    if (
      f.user_role === user_role &&
      f.action_type === action_type &&
      f.talent_type.includes(talent_type) &&
      f.position_role.includes(position_role)
    )
      isMatch = true;
  });

  return isMatch === true ? null : { user_role, talent_type };
};

export const handleShowActionTypeCT = (item) => {
  if (!item) return '';
  if (item === 'Corporate Talent') return `CT`;
  if (item === 'Business Talent') return `BT`;
  if (item === 'Other Talent') return `OT`;
  return item;
};

export const compareCard = (oldCard, newCard, isSame = true) => {
  if (isSame) {
    return oldCard.staff_id === newCard.staff_id && oldCard.position_code === newCard.position_code;
  }
  return oldCard.staff_id !== newCard.staff_id && oldCard.position_code !== newCard.position_code;
};

export const isDifferentFieldPdcMeeting = (data) => {
  const { initData, currentData } = data;
  const fields = [];
  if (isEmpty(initData) && isEmpty(currentData)) return fields;
  Object.keys(PDC_MEETING_FIELDS).forEach((item) => {
    if (JSON.stringify(initData[item]) !== JSON.stringify(currentData[item])) {
      fields.push(item);
    }
  });
  return fields;
};

export const staffIdMap = (arr) => {
  if (!arr) return [];
  return arr.map((i) => i.staff_id);
};

export const getLabelNameForSecretariatOfTcMeeting = (searchName, record) => {
  if (isEmpty(record)) return '';

  let tempVal = '';
  switch (searchName) {
    case MEETING_CATEGORY_VALUES.PETRONAS:
      tempVal = `${record?.role || record?.name || record?.role_name}`;
      break;
    case MEETING_CATEGORY_VALUES.BUSINESS:
      tempVal = `${record?.role || record?.name || record?.role_name} ${
        record?.business || record?.business_unit
          ? `- ${record?.business || record?.business_unit}`
          : ''
      }`;
      break;
    case MEETING_CATEGORY_VALUES.SECTOR:
      tempVal = `${record?.role || record?.name || record?.role_name} ${
        record.sector ? `(${record.sector})` : ''
      }`;
      break;
    case MEETING_CATEGORY_VALUES.OPU:
      tempVal = `${record?.role || record?.name || record?.role_name} ${
        record?.opu || record?.opu_name ? `(${record?.opu || record?.opu_name})` : ''
      }`;
      break;
    case MEETING_CATEGORY_VALUES.DIVISION:
      tempVal = `${record?.role || record?.name || record?.role_name} ${
        record.division ? `(${record.division})` : ''
      }`;
      break;
    case MEETING_CATEGORY_VALUES.SKG:
      tempVal = `${record?.role} ${record?.skg_text ? `(${record?.skg_text})` : ''}`;
      break;
    case MEETING_CATEGORY_VALUES.JOB_FAMILY:
      tempVal = `${record?.role} ${record?.job_family ? `(${record?.job_family})` : ''}`;
      break;

    default:
      break;
  }
  return tempVal;
};

export const getBusinessIdToPopulateForAdditionalView = (searchName, record) => {
  if (isEmpty(record)) return '';

  let tempVal = '';
  switch (searchName) {
    case MEETING_CATEGORY_VALUES.BUSINESS:
      tempVal = record?.dev_bu_unit_id;
      break;
    case MEETING_CATEGORY_VALUES.SECTOR:
      tempVal = record?.sector_id;
      break;
    case MEETING_CATEGORY_VALUES.OPU:
      tempVal = record?.dev_opu_id;
      break;
    case MEETING_CATEGORY_VALUES.DIVISION:
      tempVal = record?.dev_div_id;
      break;
    case MEETING_CATEGORY_VALUES.SKG:
    case MEETING_CATEGORY_VALUES.JOB_FAMILY:
      tempVal = record?.id;
      break;
    default:
      break;
  }
  return tempVal;
};

export const getFieldValueForTCMeetingInFirstTime = (data) => {
  return !isEmpty(data)
    ? data.map((item) => ({
        ...item,
        role: item?.role,
        label_name: getLabelNameForSecretariatOfTcMeeting(item?.search_type, {
          ...item,
          role: item?.role,
        }),
      }))
    : [];
};
