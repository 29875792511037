import { PERMISSION } from '../utils/constants';
import { findPermission, generateFilterUrl, getPermissionsByRole } from '../utils/helper';
import axiosClient from './axiosClient';
import { isEmpty } from 'lodash';

export const getSecondSectionProfiles = (id, role, permissions) => {
  const permission = getPermissionsByRole({ permissions, part: 'VIEW_TALENT_PROFILE' });
  return axiosClient.get(`/talent-profiles/second-sections/${id}`, {
    headers: {
      permission,
      role: role.roleId,
    },
  });
};

export const fetchThirdSectionProfiles = (id, role, permissions) => {
  const permission = getPermissionsByRole({ permissions, part: 'VIEW_TALENT_PROFILE' });
  return axiosClient.get(`/talent-profiles/third-sections/${id}`, {
    headers: {
      permission,
      role: role.roleId,
    },
  });
};

export const fetchFirstSectionProfiles = (id, role, permissions) => {
  const permission = getPermissionsByRole({ permissions, part: 'VIEW_TALENT_PROFILE' });
  return axiosClient.get(`/talent-profiles/first-sections/${id}`, {
    headers: {
      permission,
      role: role.roleId,
    },
  });
};

export const fetchDevelopmentPlan = (id, roleUser, body) => {
  const { roleActive, permissions } = roleUser;
  const permission = getPermissionsByRole({ permissions, part: 'VIEW_TALENT_PROFILE' });
  return axiosClient.get(`/talent-profiles/development-plan/${id}`, {
    headers: {
      permission,
      role: roleActive.roleId,
    },
    params: body,
  });
};
export const addDevelopmentPlan = (body, roleUser) => {
  const { roleActive, permissions } = roleUser;
  const permission = findPermission(permissions, PERMISSION.EDIT_TALENT_PROFILE);
  return axiosClient.post(`/talent-profiles/development-plan/`, body, {
    headers: {
      permission,
      role: roleActive.roleId,
    },
  });
};
export const editDevelopmentPlan = (id, body, roleUser) => {
  const { roleActive, permissions } = roleUser;
  const permission = findPermission(permissions, PERMISSION.EDIT_TALENT_PROFILE);
  return axiosClient.put(`/talent-profiles/development-plan/${id}`, body, {
    headers: {
      permission,
      role: roleActive.roleId,
    },
  });
};
export const deleteDevelopmentPlan = (id, roleUser) => {
  const { roleActive, permissions } = roleUser;
  const permission = findPermission(permissions, PERMISSION.EDIT_TALENT_PROFILE);
  return axiosClient.delete(`/talent-profiles/development-plan/${id}`, {
    headers: {
      permission,
      role: roleActive.roleId,
    },
  });
};

export const fetchContractDetails = (id, roleUser, body, tokenTalentProfile) => {
  const { roleActive, permissions } = roleUser;
  const permission = getPermissionsByRole({ permissions, part: 'VIEW_TALENT_PROFILE' });
  return axiosClient.get(`/talent-profiles/contract-details/${id}`, {
    headers: {
      permission,
      role: roleActive.roleId,
      'staff-permission-token': tokenTalentProfile,
    },
    params: body,
  });
};

export const getMobilities = ({ roleUser, id, isPrintPdf, limit, page }) => {
  const { roleActive, permissions } = roleUser;
  const permission = getPermissionsByRole({ permissions, part: 'VIEW_TALENT_PROFILE' });
  return axiosClient.get(`/mobilities/${id}`, {
    headers: {
      role: roleActive.roleId,
      permission,
    },
    params: {
      limit: limit,
      page: page,
      isPrintPdf,
    },
  });
};

export const getMainMobilities = (roleUser, staff_id, page, limit) => {
  const { roleActive, permissions } = roleUser;
  const permission = getPermissionsByRole({ permissions, part: 'VIEW_TALENT_PROFILE' });
  return axiosClient.get(`/mobilities/${staff_id}/latest?page=${page}&limit=${limit}`, {
    headers: {
      role: roleActive.roleId,
      permission,
    },
  });
};

export const fetchOtherExperiences = (
  params,
  roleId,
  permissions,
  profileId,
  tokenTalentProfile
) => {
  const permission = getPermissionsByRole({ permissions, part: 'VIEW_TALENT_PROFILE' });
  return axiosClient.get(`/talent-profiles/other-experiences/${profileId}`, {
    headers: {
      permission,
      role: roleId,
      'staff-permission-token': tokenTalentProfile,
    },
    params,
  });
};

export const addOtherExperience = (body, roleUser) => {
  const { roleActive, permissions } = roleUser;
  const permission = findPermission(permissions, PERMISSION.EDIT_TALENT_PROFILE);
  return axiosClient.post(`/talent-profiles/other-experiences`, body, {
    headers: {
      permission,
      role: roleActive.roleId,
    },
  });
};

export const editOtherExperience = (id, body, roleUser) => {
  const { roleActive, permissions } = roleUser;
  const permission = findPermission(permissions, PERMISSION.EDIT_TALENT_PROFILE);
  return axiosClient.put(`/talent-profiles/other-experiences/${id}`, body, {
    headers: {
      permission,
      role: roleActive.roleId,
    },
  });
};

export const deleteOtherExperience = (id, roleUser) => {
  const { roleActive, permissions } = roleUser;
  const permission = findPermission(permissions, PERMISSION.EDIT_TALENT_PROFILE);
  return axiosClient.delete(`/talent-profiles/other-experiences/${id}`, {
    headers: {
      permission,
      role: roleActive.roleId,
    },
  });
};

export const fetchOtherContribution = ({ params, roleUser, profileId, tokenTalentProfile }) => {
  const { roleActive, permissions } = roleUser;
  const permission = getPermissionsByRole({ permissions, part: 'VIEW_TALENT_PROFILE' });
  return axiosClient.get(`/talent-profiles/order-contribution/${profileId}`, {
    headers: {
      permission,
      role: roleActive.roleId,
      'staff-permission-token': tokenTalentProfile,
    },
    params,
  });
};

export const fetchApprovedSP = ({ id, selectFilter, roleUser, tokenTalentProfile }) => {
  const { roleActive, permissions } = roleUser;
  const permission = getPermissionsByRole({ permissions, part: 'VIEW_TALENT_PROFILE' });
  return axiosClient.get(`/talent-profiles/approved-successon-planning/${id}`, {
    headers: {
      permission,
      role: roleActive.roleId,
      'staff-permission-token': tokenTalentProfile,
    },
    params: {
      role: selectFilter,
    },
  });
};

export const fetchKeystrengthsDevDetails = ({ pagination, roleUser, id, tokenTalentProfile }) => {
  const { roleActive, permissions } = roleUser;
  const permission = getPermissionsByRole({ permissions, part: 'VIEW_TALENT_PROFILE' });
  return axiosClient.get(`/talent-profiles/keystrengths-dev-details/${id}`, {
    headers: {
      permission,
      role: roleActive.roleId,
      'staff-permission-token': tokenTalentProfile,
    },
    params: {
      page: pagination.page,
      limit: pagination.limit,
      // search: selectFilter,
    },
  });
};

export const fetchKeystrengthsDev = (id, role) => {
  const permission = getPermissionForViewTalentProfileByRole(role);
  return axiosClient.get(`/talent-profiles/keystrengths-dev/${id}`, {
    headers: {
      permission,
      role: role.roleId,
    },
  });
};

export const getNotes = (staffId, roleUser, params, tokenTalentProfile) => {
  const { roleActive, permissions } = roleUser;
  const permission = getPermissionsByRole({ permissions, part: 'VIEW_TALENT_PROFILE' });
  return axiosClient.get(`/talent-profiles/notes-section/${staffId}`, {
    params,
    headers: {
      permission,
      role: roleActive.roleId,
      'staff-permission-token': tokenTalentProfile,
    },
  });
};

export const getExperiences = ({ id, roleId, permissions, tokenTalentProfile }) => {
  const permission = getPermissionsByRole({ permissions, part: 'VIEW_TALENT_PROFILE' });
  return axiosClient.get(`/talent-profiles/experiences/${id}`, {
    headers: {
      permission,
      role: roleId,
      'staff-permission-token': tokenTalentProfile,
    },
    params: {
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    },
  });
};

export const getExperiencesEditHistorical = (params) => {
  const url = `/talent-profiles/history-edit-experiences`;
  return axiosClient.get(url, {
    params: {
      ...params,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    },
  });
};

export const fetchLeaderShips = ({ roleUser, params, id, tokenTalentProfile }) => {
  const { roleActive, permissions } = roleUser;
  const permission = getPermissionsByRole({ permissions, part: 'VIEW_TALENT_PROFILE' });
  return axiosClient.get(`/talent-profiles/leaderships/effective-role/edges/${id}`, {
    headers: {
      permission,
      role: roleActive.roleId,
      'staff-permission-token': tokenTalentProfile,
    },
    params,
  });
};

export const fetchLeaderShipsFeedback = ({ roleUser, params, id, body, tokenTalentProfile }) => {
  const { roleActive, permissions } = roleUser;
  const permission = getPermissionsByRole({ permissions, part: 'VIEW_TALENT_PROFILE' });
  return axiosClient.post(`/talent-profiles/leaderships/feedbacks/${id}`, body, {
    headers: {
      permission,
      role: roleActive.roleId,
      'staff-permission-token': tokenTalentProfile,
    },
    params,
  });
};

export const fetchTalentProfileSummary = ({ roleUser, id }) => {
  const { roleActive, permissions } = roleUser;
  const permission = getPermissionsByRole({ permissions, part: 'VIEW_TALENT_PROFILE' });
  return axiosClient.get(`/talent-profiles/summary/${id}`, {
    headers: {
      permission,
      role: roleActive.roleId,
    },
  });
};

export const fetchTalentProfilePersonality = ({ roleUser, id, tokenTalentProfile }) => {
  const { roleActive, permissions } = roleUser;
  const permission = getPermissionsByRole({ permissions, part: 'VIEW_TALENT_PROFILE' });
  return axiosClient.get(`/talent-profiles/personality/${id}`, {
    headers: {
      permission,
      role: roleActive.roleId,
      'staff-permission-token': tokenTalentProfile,
    },
  });
};

export const fetchFunctionExperiences = ({ roleId, permissions, search, page, limit }) => {
  const permission = getPermissionsByRole({ permissions, part: 'EDIT_TALENT_PROFILE' });
  return axiosClient.get(`/functions`, {
    headers: {
      permission,
      role: roleId,
    },
    params: {
      search,
      page,
      limit,
    },
  });
};

export const fetchBusinessExperiences = () => {
  return axiosClient.get(`/business-units`);
};

export const fetchBusinessExperiencesLazy = (params, tokenTalentProfile) => {
  return axiosClient.get(`/business-unit/lazy-load-tp`, {
    params,
    headers: {
      'staff-permission-token': tokenTalentProfile,
    },
  });
};

export const fetchCheckPermittedStaff = ({ roleUser, id, tokenTalentProfile }) => {
  const { roleActive, permissions } = roleUser;
  const permission = getPermissionsByRole({ permissions, part: 'EDIT_TALENT_PROFILE' });
  return axiosClient.get(`/check-permitted-staff/${id}`, {
    headers: {
      permission,
      role: roleActive.roleId,
      'staff-permission-token': tokenTalentProfile,
    },
  });
};

export const fetchUpdateExperiences = ({ roleId, permissions, params }) => {
  const { body, staffId, positionCode } = params;
  const permission = getPermissionsByRole({ permissions, part: 'EDIT_TALENT_PROFILE' });
  return axiosClient.put(`/talent-profiles/${staffId}/experience/${positionCode}`, body, {
    headers: {
      permission,
      role: roleId,
    },
  });
};

export const fetchCareerJourner = ({ roleUser, id }) => {
  const { roleActive, permissions } = roleUser;
  const permission = getPermissionsByRole({ permissions, part: 'VIEW_TALENT_PROFILE' });
  return axiosClient.get(`/talent-profiles/career-journer/${id}`, {
    headers: {
      permission,
      role: roleActive.roleId,
    },
  });
};

export const getTalentReview = ({ roleUser, id, tokenTalentProfile }) => {
  const { roleActive, permissions } = roleUser;
  const permission = getPermissionsByRole({ permissions, part: 'VIEW_TALENT_PROFILE' });
  return axiosClient.get(`/talent-profiles/talent-reviews/${id}`, {
    headers: {
      permission,
      role: roleActive.roleId,
      'staff-permission-token': tokenTalentProfile,
    },
  });
};

export const fetchBestFitRecommendation = ({ profileId, roleUser, body, tokenTalentProfile }) => {
  const { roleActive, permissions } = roleUser;
  const permission = getPermissionsByRole({ permissions, part: 'VIEW_TALENT_PROFILE' });
  return axiosClient.get(`/talent-profiles/best-fit-recommendation/${profileId}`, {
    headers: {
      permission,
      role: roleActive.roleId,
      'staff-permission-token': tokenTalentProfile,
    },
    params: body,
  });
};

export const fetchFeedbacksSummaryEditTag = ({ id, roleUser, params }) => {
  const { roleActive, permissions } = roleUser;
  const permission = getPermissionsByRole({ permissions, part: 'EDIT_TALENT_PROFILE' });
  return axiosClient.get(`/talent-profiles/leaderships/feedbacks-sumarry/edit-tag/${id}`, {
    headers: {
      permission,
      role: roleActive.roleId,
    },
    params: params || {},
  });
};

export const updateFeedbacksSummary = ({ id, roleUser, body }) => {
  const { roleActive, permissions } = roleUser;
  const permission = getPermissionsByRole({ permissions, part: 'EDIT_TALENT_PROFILE' });
  return axiosClient.put(`talent-profiles/leaderships/update-feedbacks/${id}`, body, {
    headers: {
      permission,
      role: roleActive.roleId,
    },
  });
};

export const updateOtherOtherExperience = ({ id, roleUser, body }) => {
  const { roleActive, permissions } = roleUser;
  const permission = getPermissionsByRole({ permissions, part: 'EDIT_TALENT_PROFILE' });
  return axiosClient.put(`talent-profiles/update-experience/${id}`, body, {
    headers: {
      permission,
      role: roleActive.roleId,
    },
  });
};

export const addNotes = ({ profileId, roleUser, data, isPrivate }) => {
  const { roleActive, permissions } = roleUser;
  const permission = isPrivate
    ? findPermission(permissions, PERMISSION.VIEW_EDIT_PRIVACY_NOTES)
    : findPermission(permissions, PERMISSION.EDIT_TALENT_PROFILE);
  return axiosClient.post(`talent-profiles/${profileId}/notes/add`, data, {
    headers: {
      permission,
      role: roleActive.roleId,
    },
  });
};

export const updateNotes = ({ profileId, noteId, roleUser, body, isPrivate }) => {
  const { roleActive, permissions } = roleUser;
  const permission = isPrivate
    ? findPermission(permissions, PERMISSION.VIEW_EDIT_PRIVACY_NOTES)
    : findPermission(permissions, PERMISSION.EDIT_TALENT_PROFILE);
  return axiosClient.put(`talent-profiles/${profileId}/notes/${noteId}/update`, body, {
    headers: {
      permission,
      role: roleActive.roleId,
    },
  });
};

export const deleteNotes = ({ profileId, noteId, roleUser, isPrivate }) => {
  const { roleActive, permissions } = roleUser;
  const permission = isPrivate
    ? findPermission(permissions, PERMISSION.VIEW_EDIT_PRIVACY_NOTES)
    : findPermission(permissions, PERMISSION.EDIT_TALENT_PROFILE);
  return axiosClient.delete(`talent-profiles/${profileId}/notes/${noteId}/delete`, {
    headers: {
      permission,
      role: roleActive.roleId,
      is_private: isPrivate,
    },
  });
};

export const getDocuments = ({ profileId, roleUser, tokenTalentProfile }) => {
  const { roleActive, permissions } = roleUser;
  const permission = getPermissionsByRole({ permissions, part: 'VIEW_TALENT_PROFILE' });
  return axiosClient.get(`talent-profiles/${profileId}/documents/get-list`, {
    headers: {
      permission,
      role: roleActive.roleId,
      'staff-permission-token': tokenTalentProfile,
    },
  });
};

export const cancelUploadDocument = (body) => {
  return axiosClient.post('/talent-profile-document/create-file-document', body);
};

export const addDocument = ({ profileId, roleUser, file }, source) => {
  const { roleActive, permissions } = roleUser;
  const formData = new FormData();
  formData.append('file', file);
  const permission = getPermissionsByRole({ permissions, part: 'EDIT_TALENT_PROFILE' });
  return axiosClient.post(`talent-profile-document/add/${profileId}`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
      permission,
      role: roleActive.roleId,
    },
    cancelToken: source.token,
  });
};

export const deleteDocument = ({ profileId, roleUser, body }) => {
  const { roleActive, permissions } = roleUser;
  const permission = getPermissionsByRole({ permissions, part: 'EDIT_TALENT_PROFILE' });
  return axiosClient.delete(`talent-profiles/${profileId}/documents/delete`, {
    data: body,
    headers: {
      permission,
      role: roleActive.roleId,
    },
  });
};

export const fetchDisclaimerRecords = (tokenTalentProfile) => {
  const url = `/document-types`;
  return axiosClient.get(url, {
    headers: {
      'staff-permission-token': tokenTalentProfile,
    },
  });
};

export const getTalentDetails = ({ staffId, roleUser }) => {
  const { roleActive, permissions } = roleUser;
  const permission = getPermissionsByRole({ permissions, part: 'ADD_TALENT_COMPARISION' });
  return axiosClient.get(`talent-profiles/comparision/get-talent-details/${staffId}`, {
    headers: {
      permission: permission,
      role: roleActive.roleId,
    },
  });
};

export const getStaffAgendaTalentReview = (params) => {
  const { staff_id, agenda_id } = params;
  const url = `/agenda-talent-review/get-staff?staff_id=${staff_id}&agenda_id=${agenda_id}`;
  return axiosClient.get(url);
};

function getPermissionForViewTalentProfileByRole(role) {
  if (!role || !role.roleName) return;
  return PERMISSION.VIEW_TALENT_PROFILE;
}

const talentProfileApi = {
  fetchAssessmentData: ({ profileId, roleUser, body }) => {
    const { roleActive, permissions } = roleUser;
    const permission = findPermission(permissions, PERMISSION.VIEW_TALENT_PROFILE);
    return axiosClient.get(`/talent-profiles/assessment/${profileId}`, {
      headers: {
        permission,
        role: roleActive.roleId,
      },
      params: body,
    });
  },
  addAssessmentRecord: (body, roleUser) => {
    const { roleActive, permissions } = roleUser;
    const permission = findPermission(permissions, PERMISSION.EDIT_TALENT_PROFILE);
    return axiosClient.post(`/talent-profiles/assessment/`, body, {
      headers: {
        permission,
        role: roleActive.roleId,
      },
    });
  },
  editAssessmentRecord: (id, body, roleUser) => {
    const { roleActive, permissions } = roleUser;
    const permission = findPermission(permissions, PERMISSION.EDIT_TALENT_PROFILE);
    return axiosClient.put(`/talent-profiles/assessment/${id}`, body, {
      headers: {
        permission,
        role: roleActive.roleId,
      },
    });
  },
  deleteAssessmentRecord: (id, roleUser) => {
    const { roleActive, permissions } = roleUser;
    const permission = findPermission(permissions, PERMISSION.EDIT_TALENT_PROFILE);
    return axiosClient.delete(`/talent-profiles/assessment/${id}`, {
      headers: {
        permission,
        role: roleActive.roleId,
      },
    });
  },
  fetchHistoricalTalentStatus: ({ profileId, roleUser, tokenTalentProfile }) => {
    const { roleActive, permissions } = roleUser;
    const permission = getPermissionsByRole({ permissions, part: 'VIEW_TALENT_PROFILE' });
    return axiosClient.get(`/talent-profiles/history-status/${profileId}`, {
      headers: {
        permission,
        role: roleActive.roleId,
        'staff-permission-token': tokenTalentProfile,
      },
    });
  },
  fetchWorkPreferenceStatus: ({ profileId, roleUser, tokenTalentProfile }) => {
    const { roleActive, permissions } = roleUser;
    const permission = getPermissionsByRole({ permissions, part: 'VIEW_TALENT_PROFILE' });
    return axiosClient.get(`/talent-profiles/work-preference-status/${profileId}`, {
      headers: {
        permission,
        role: roleActive.roleId,
        'staff-permission-token': tokenTalentProfile,
      },
    });
  },
  fetchOtherContribution: (params, role, profileId, tokenTalentProfile) => {
    const permission = getPermissionForViewTalentProfileByRole(role);
    return axiosClient.get(`/talent-profiles/order-contribution/${profileId}`, {
      headers: {
        permission,
        role: role.roleId,
        'staff-permission-token': tokenTalentProfile,
      },
      params,
    });
  },
  getProjectInvolvement: (staffId, page, role, tokenTalentProfile) => {
    const url = `/talent-profiles/project-involvements/${staffId}`;
    const permission = getPermissionForViewTalentProfileByRole(role);
    const params = { page };
    return axiosClient.get(url, {
      headers: {
        permission,
        role: role.roleId,
        'staff-permission-token': tokenTalentProfile,
      },
      params,
    });
  },
  getExternalExperience: (staffId, role, tokenTalentProfile) => {
    const url = `/talent-profiles/external-experiences/${staffId}`;
    const permission = getPermissionForViewTalentProfileByRole(role);
    return axiosClient.get(url, {
      headers: {
        permission,
        role: role.roleId,
        'staff-permission-token': tokenTalentProfile,
      },
    });
  },
  getFilterFeedbacks: ({ profileId, roleUser, params, tokenTalentProfile }) => {
    const { roleActive, permissions } = roleUser;
    const permission = getPermissionsByRole({ permissions, part: 'VIEW_TALENT_PROFILE' });
    return axiosClient.get(`/talent-profiles/leaderships/feedback-edges/${profileId}`, {
      headers: {
        permission,
        role: roleActive.roleId,
        'staff-permission-token': tokenTalentProfile,
      },
      params,
    });
  },

  getAgendasInMeeting: ({ meetingId, status }) => {
    return axiosClient.get(`/agendas-in-meeting/${meetingId}`, {
      params: { status },
    });
  },

  createMeetingRemark: (data) => {
    return axiosClient.post(`/meetings/in-planning/remarks`, data);
  },

  getMeetingRemarks: ({ meetingId, ...params }) => {
    return axiosClient.get(`/meetings/${meetingId}/remarks`, { params });
  },

  updateMeetingRemarks: ({ remarkId, body, role }) => {
    return axiosClient.put(`/meetings/in-planning/remarks/${remarkId}`, body, {
      headers: {
        role,
      },
    });
  },

  addHistoricalStatus: (role, staff_id, body) => {
    return axiosClient.post(`/historical-talent-status/${staff_id}/add`, body, {
      headers: {
        role: role.roleId,
      },
    });
  },

  editHistoricalStatus: (role, staff_id, status_id, body) => {
    return axiosClient.put(`/historical-talent-status/${staff_id}/edit/${status_id}`, body, {
      headers: {
        role: role.roleId,
      },
    });
  },

  deleteHistoricalStatus: (role, staff_id, status_id) => {
    return axiosClient.delete(`historical-talent-status/${staff_id}/delete/${status_id}`, {
      headers: {
        role: role.roleId,
      },
    });
  },

  addTalentReview: (role, body) => {
    return axiosClient.post(`talent-review/add-talent-review`, body, {
      headers: {
        role: role.roleId,
      },
    });
  },

  editTalentReview: (role, body) => {
    return axiosClient.put(`/talent-review/${body.staff_id}/edit/${body.id}`, body, {
      headers: {
        role: role.roleId,
      },
    });
  },

  addMobilityPlanRecord: (body, role) => {
    return axiosClient.post(`/mobilities/add-record`, body, {
      headers: {
        role: role,
      },
    });
  },

  editMobilityPlan: (id, body, roleUser) => {
    return axiosClient.put(`/mobilities/edit-mobility/${id}`, body, {
      headers: {
        role: roleUser,
      },
    });
  },

  deleteMobilityPlanRecord: (id, roleUser) => {
    return axiosClient.delete(`/mobilities/delete-record/${id}`, {
      headers: {
        role: roleUser,
      },
    });
  },

  deleteTalentReview: (review_id, roleActive) => {
    return axiosClient.delete(`/talent-review/${review_id}`, {
      headers: {
        role: roleActive?.roleId,
      },
    });
  },

  getPersonalityViewMore: (staff_id, roleActive, tokenTalentProfile) => {
    return axiosClient.get(`talent-profiles/personality-detail/${staff_id}`, {
      headers: {
        role: roleActive?.roleId,
        permission: PERMISSION.VIEW_TALENT_PROFILE,
        'staff-permission-token': tokenTalentProfile,
      },
    });
  },

  getAspirationMatching: (staff_id, params, tokenTalentProfile) => {
    const url = `/talent-profiles/aspiration-matching/${staff_id}`;
    return axiosClient.get(url, {
      params,
      'staff-permission-token': tokenTalentProfile,
    });
  },

  getAspirationMatchingToEdit: (staff_id, roleId, tokenTalentProfile) => {
    const url = `/talent-profiles/aspiration-matching/edit-view/${staff_id}`;
    return axiosClient.get(url, {
      headers: {
        role: roleId,
        permission: PERMISSION.EDIT_ASPIRATION_MATCHING,
        'staff-permission-token': tokenTalentProfile,
      },
    });
  },

  updateAspirationMatching: (staff_id, body, roleId) => {
    return axiosClient.post(`/talent-profiles/aspiration-matching/save`, body, {
      headers: {
        role: roleId,
        permission: PERMISSION.EDIT_ASPIRATION_MATCHING,
      },
    });
  },

  deleteAspirationMatchingRecord: (staff_id, ids, roleId) => {
    return axiosClient.delete(`/talent-profiles/aspiration-matching/${staff_id}?ids=${ids}`, {
      headers: {
        role: roleId,
        permission: PERMISSION.EDIT_ASPIRATION_MATCHING,
      },
    });
  },

  viewComplementaryExperience: (staff_id, query, roleId, tokenTalentProfile) => {
    const { limit, page, job_function } = query;
    const url = `/talent-profiles/complementary-experience/${staff_id}`;
    return axiosClient.get(url, {
      params: {
        limit,
        page,
        job_function:
          (!isEmpty(job_function) && job_function.map((item) => `${item}`).join(',')) || '',
      },
      headers: {
        role: roleId,
        'staff-permission-token': tokenTalentProfile,
      },
    });
  },

  saveComplementaryExperience: (body, roleId) => {
    const url = `/talent-profiles/complementary-experience/save`;
    return axiosClient.post(url, body, {
      headers: {
        role: roleId,
        permission: PERMISSION.ADD_COMPLEMENTARY_EXPERIENCE,
      },
    });
  },

  viewEditComplementaryExperience: (params) => {
    const { staffId, roleId, job_function, hide, tokenTalentProfile } = params;
    const url = `/talent-profiles/complementary-experience/view-edit`;
    return axiosClient.get(url, {
      params: {
        staffId,
        job_function:
          (!isEmpty(job_function) && job_function.map((item) => `${item}`).join(',')) || '',
        is_hide: (!isEmpty(hide) && hide.map((item) => `${item}`).join(',')) || '',
      },
      headers: {
        role: roleId,
        permission: PERMISSION.ADD_COMPLEMENTARY_EXPERIENCE,
        'staff-permission-token': tokenTalentProfile,
      },
    });
  },

  getFilterComplementaryExperience: (staffId, roleId, isEdit) => {
    const params = generateFilterUrl({ staffId, isEdit });
    const url = `/talent-profiles/complementary-experience/filter-dropdown${params}`;
    return axiosClient.get(url, {
      headers: {
        role: roleId,
        permission: PERMISSION.EDIT_COMPLEMENTARY_EXPERIENCE,
      },
    });
  },

  deleteDevelopmentPlan: (id, roleId) => {
    return axiosClient.delete(`/talent-profiles/complementary-experience/${id}`, {
      headers: {
        role: roleId,
        permission: PERMISSION.EDIT_COMPLEMENTARY_EXPERIENCE,
      },
    });
  },

  addTalentVideo: (profileId, body, roleId) => {
    const url = `/talent-profiles/${profileId}/add-video`;
    return axiosClient.post(url, body, {
      headers: {
        role: roleId,
        permission: PERMISSION.VIEW_TALENT_PROFILE,
      },
    });
  },

  getTalentVideos: (profileId, roleId, { limit, page }, tokenTalentProfile) => {
    const url = `/talent-profiles/${profileId}/get-videos`;
    return axiosClient.get(url, {
      headers: {
        role: roleId,
        permission: PERMISSION.VIEW_TALENT_PROFILE,
        'staff-permission-token': tokenTalentProfile,
      },
      params: { limit, page },
    });
  },

  deleteTalentVideos: (ids, roleId) => {
    const url = `/talent-profiles/delete-videos`;
    return axiosClient.delete(url, {
      headers: {
        role: roleId,
        permission: PERMISSION.VIEW_TALENT_PROFILE,
      },
      data: { ids },
    });
  },

  getTokenTalentProfile: (staff_id, roleId) => {
    const url = `/users-permission/${staff_id}`;
    return axiosClient.get(url, {
      headers: {
        role: roleId,
        permission: PERMISSION.VIEW_TALENT_PROFILE,
      },
    });
  },
};

export default talentProfileApi;
