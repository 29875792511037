import { Input, List } from 'antd';
import { get } from 'lodash';
import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { search_dropdown } from '../../assets/img';
import { ListAntd, TitleDiv, Wrapper, WrapperPopUp } from './SearchBusinessOptionsStyled';
const convertType = [
  { value: 'business_unit', type: 'Business' },
  { value: 'sector', type: 'Sector' },
  { value: 'opu_name', type: 'OPU' },
  { value: 'division', type: 'Division' },
];
const SearchBusinessOptions = (props) => {
  const { options, setSelected, onClose, type, styles, keyword, setKeyword } = props;
  const data = keyword
    ? options.filter((f) => {
        if (type === 'Business')
          return f.business_unit?.toLowerCase().includes(keyword?.toLowerCase());
        if (type === 'Sector') return f.sector?.toLowerCase().includes(keyword?.toLowerCase());
        if (type === 'OPU') return f.opu_name?.toLowerCase().includes(keyword?.toLowerCase());
        if (type === 'Division') return f.division?.toLowerCase().includes(keyword?.toLowerCase());
      })
    : options;
  return (
    <Wrapper>
      <WrapperPopUp>
        <div className={styles.search_dropdown} style={{ width: '400px' }}>
          <Input
            placeholder="Search by Business, Sector, OPU, Division"
            value={keyword}
            onChange={(e) => setKeyword(e.target.value)}
            suffix={<img src={search_dropdown} style={{ marginBottom: '10px' }} />}
          />
        </div>
        <TitleDiv>
          <div style={{ backgroundColor: type === 'Business' ? '#C9D5E3' : '' }}>Business</div>
          <div style={{ backgroundColor: type === 'Sector' ? '#C9D5E3' : '' }}>Sector</div>
          <div style={{ backgroundColor: type === 'OPU' ? '#C9D5E3' : '' }}>OPU</div>
          <div style={{ backgroundColor: type === 'Division' ? '#C9D5E3' : '' }}>Division</div>
        </TitleDiv>
        <div
          id="scrollableDiv"
          style={{
            maxHeight: '264px',
            overflow: 'auto',
            width: '100%',
            backgroundColor: 'white',
          }}
        >
          <ListAntd
            dataSource={data}
            split={false}
            renderItem={(item) => (
              <List.Item
                key={uuidv4()}
                onClick={() => {
                  setSelected(get(item, convertType.find((f) => f.type === type)?.value));
                  onClose();
                }}
              >
                <div className={type === 'Business' ? 'function-name' : ''}>
                  {item.business_unit}
                </div>
                <div className={type === 'Sector' ? 'function-name' : ''}>{item.sector}</div>
                <div className={type === 'OPU' ? 'function-name' : ''}>{item.opu_name}</div>
                <div className={type === 'Division' ? 'function-name' : ''}>{item.division}</div>
              </List.Item>
            )}
          />
        </div>
      </WrapperPopUp>
    </Wrapper>
  );
};
export default SearchBusinessOptions;
