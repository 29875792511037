import axiosClient from './axiosClient';

export const othersAgendaApi = {
  createAgenda: (body, roleId) => {
    const url = '/agenda-others';
    return axiosClient.post(url, body, {
      headers: {
        role: roleId,
      },
    });
  },

  updateAgenda: (body, roleId) => {
    const url = '/agenda-others';
    return axiosClient.put(url, body, {
      headers: {
        role: roleId,
      },
    });
  },

  getAgendaDetail: (agendaId, roleId) => {
    const url = `/agenda-others/${agendaId}`;
    return axiosClient.get(url, {
      headers: {
        role: roleId,
      },
    });
  },
};
