import React from 'react';
import { useSelector } from 'react-redux';

import SportlightCarousel from '../../components/SportlightCarousel';
import NewsAnnouncements from '../../components/NewsAnnouncements';

function Homepage() {
  const username = useSelector((state) => state.user.username);
  const indexOf = username.indexOf('(');
  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '12px',
          marginBottom: '24px',
        }}
      >
        <div
          style={{ fontWeight: '700', fontSize: '24px', color: '#3F3C4C' }}
        >{`Welcome ${username.substring(0, indexOf === -1 ? username.length : indexOf)},`}</div>
        <div style={{ fontWeight: '500', fontSize: '24px', color: '#3D3D3D' }}>{'Spotlight'}</div>
        <SportlightCarousel />
        <NewsAnnouncements />
      </div>
    </div>
  );
}

export default Homepage;
