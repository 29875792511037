import { Spin, notification } from 'antd';
import { isEmpty } from 'lodash';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { AddNewNote, ViewNote } from '..';
import { ModelTC } from '../../components';
import { addNotes, deleteNotes, getNotes, updateNotes } from '../../services/talentProfiles';
import { PERMISSION } from '../../utils/constants';
import styles from './note.module.scss';

const NoteList = (props) => {
  const { notes } = props;
  if (!notes || notes.length === 0) return <></>;
  const listItems = notes.map((note, index) => {
    return (
      <li className={styles.content} key={index}>
        <span className={styles.platform}>
          {note.platform} ({moment(note.date).format('DD-MMM-YYYY')}),
        </span>
        <span className={styles.text}>{note.notes_content}</span>
      </li>
    );
  });
  return <ul className={styles.menu}>{listItems}</ul>;
};

const Notes = ({ profileId, roleUser, tokenTalentProfile }) => {
  const { permissions } = useSelector((state) => state.user);
  const [allNotes, setAllNotes] = useState({ totalNotes: 6, data: [] });
  const [notes, setNotes] = useState({ totalNotes: 6, data: [] });
  const [isOpen, setIsOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [itemChoose, setItemChoose] = useState({});
  const [isPopupDelete, setIsPopupDelete] = useState(false);
  const [isOpenNote, setIsOpenNote] = useState(false);
  const [isViewToggle, setIsViewToggle] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const isAddEditNonPrivateNote = useMemo(() => {
    if (isEmpty(permissions)) return false;
    return permissions.includes(PERMISSION.TP_EDIT_NON_PRIVATE_NOTE);
  }, [permissions]);

  const isAddEditPrivateNote = useMemo(() => {
    if (isEmpty(permissions)) return false;
    return permissions.includes(PERMISSION.VIEW_EDIT_PRIVACY_NOTES);
  }, [permissions]);

  const fetchNotes = async (isViewOtherNotes, isViewToggle, isAll) => {
    try {
      setIsLoading(true);
      const pagination = isAll ? { first: 0, count: 1000 } : {};
      const res = await getNotes(
        profileId,
        roleUser,
        {
          ...pagination,
          is_view_other: isViewOtherNotes,
          is_toggle: isViewToggle,
        },
        tokenTalentProfile
      );
      if (res.status === 200) {
        const result = res?.data?.result;
        const data = { totalNotes: result?.totalNotes, data: result?.notes };
        if (isAll) {
          setAllNotes(data);
        } else {
          setNotes(data);
        }
      }
    } catch (error) {
      console.error(error);
      setAllNotes({ totalNotes: 0, data: [] });
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    if (!roleUser.roleActive.roleId) return;
    fetchNotes(false, false, false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roleUser.roleActive.roleId, profileId, roleUser.permissions?.length]);

  useEffect(() => {
    if (!roleUser.roleActive.roleId) return;
    fetchNotes(true, isViewToggle, true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roleUser.roleActive.roleId, profileId, roleUser.permissions?.length, isViewToggle]);

  const showViewNote = () => {
    setIsOpen(true);
  };

  const handleNote = async (data, isPrivate) => {
    const reFetchData = async () => {
      fetchNotes(false, false, false);
      fetchNotes(true, isViewToggle, true);
      setIsEdit(false);
      setIsOpenNote(false);
      setIsOpen(false);
    };
    if (!data?.id) {
      try {
        const response = await addNotes({ profileId, roleUser, data, isPrivate });
        if (response && response?.data?.code === 200) {
          reFetchData();
          notification.success({
            message: `Add new note successful.`,
            placement: 'topRight',
            duration: 2,
          });
        }
      } catch (e) {
        notification.error({
          message: `Add new note error.`,
          placement: 'topRight',
          duration: 2,
        });
      }
    } else {
      try {
        const response = await updateNotes({
          profileId,
          noteId: data.id,
          roleUser,
          body: data,
          isPrivate,
        });
        if (response && response?.data?.code === 200) {
          reFetchData();
          notification.success({
            message: `Update note successful.`,
            placement: 'topRight',
            duration: 2,
          });
        }
      } catch (e) {
        notification.error({
          message: `Update note error.`,
          placement: 'topRight',
          duration: 2,
        });
      }
    }
  };

  const onDeleteItem = async (item) => {
    try {
      const response = await deleteNotes({
        profileId,
        noteId: item.id,
        roleUser,
        isPrivate: item.is_private,
      });
      if (response && response?.data?.code === 200) {
        fetchNotes(false, false, false);
        fetchNotes(true, isViewToggle, true);
        setIsPopupDelete(false);
        notification.success({
          message: `Delete note successful.`,
          placement: 'topRight',
          duration: 2,
        });
      }
    } catch (e) {
      notification.error({
        message: `Delete note error.`,
        placement: 'topRight',
        duration: 2,
      });
    }
  };

  return (
    <Spin spinning={isLoading}>
      <div className={styles.notes} data-testid="notes">
        <h1 data-testid="title">Notes</h1>
        <NoteList
          notes={notes.data}
          isAddEditNonPrivateNote={isAddEditNonPrivateNote}
          isAddEditPrivateNote={isAddEditPrivateNote}
        />
        <p
          className={styles.view_more}
          onKeyDown={() => {}}
          onClick={showViewNote}
          data-testid="show-more"
        >
          View more notes
        </p>
        <ViewNote
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          allNotes={allNotes}
          setIsEdit={setIsEdit}
          setIsPopupDelete={setIsPopupDelete}
          setIsOpenNote={setIsOpenNote}
          onChooseItem={(item) => setItemChoose(item)}
          isLoading={isLoading}
          isViewToggle={isViewToggle}
          setIsViewToggle={setIsViewToggle}
        />
        {(isAddEditNonPrivateNote || isAddEditPrivateNote) && (
          <AddNewNote
            isOpen={isOpenNote}
            setIsOpenNote={setIsOpenNote}
            isEdit={isEdit}
            setIsEdit={setIsEdit}
            dataEdit={itemChoose}
            handleNote={handleNote}
            isAddEditNonPrivateNote={isAddEditNonPrivateNote}
            isAddEditPrivateNote={isAddEditPrivateNote}
          />
        )}
        <ModelTC
          info={{
            type: 'deleteTalentProfileNote',
            visible: isPopupDelete,
            setVisible: setIsPopupDelete,
            handleSubmit: () => onDeleteItem(itemChoose),
            onClose: () => setIsPopupDelete(false),
          }}
        />
      </div>
    </Spin>
  );
};

export default Notes;
