import { Spin } from 'antd';
import moment from 'moment';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useHistory, useParams } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';

import { AiButton, BasicButton } from '../../../assets/common';
import { pets_chevron_right } from '../../../assets/img';
import Disclaimer from '../../../components/Disclaimer/Disclaimer';
import { useSearchParams } from '../../../hooks/useSearchQuery.js';
import { getPositionByCode, getSuccessionPlanningViewDetail } from '../../../services/successionplanningratio';
import { SP_NOTES_PERFORMANCE_RATING } from '../../../utils/constants';
import { PositionsDashboardSP, RecommendationsDSP } from '../../Agenda/components';
import styles from './dashboardSp.module.scss';
import { Title, TitleDiv } from './dashboardSpStyled.js';
import { isEmpty } from 'lodash';

const DashboardSP = () => {
  const { positionCode } = useParams();
  const { approved_date, platform, agenda_id } = useSearchParams();
  const [loading, setLoading] = useState(false);
  const [dataPosition, setDataPosition] = useState([]);
  const { roleId } = useSelector((state) => state.user.roleActive);
  const user = useSelector((state) => state.user);
  const [incumbents, setIncumbents] = useState([]);
  const history = useHistory();
  const printRef = useRef(null);
  const [printing, setPrinting] = useState();
  const [isCollapsedRemarks, setIsCollapsedRemarks] = useState(true);

  const positionName = useMemo(() => {
    return incumbents
      .map((d) => {
        return d.position_name;
      })
      .join(' / ');
  }, [incumbents]);

  const dateApproved = useMemo(() => {
    if (!incumbents.length) return null;
    return incumbents[0].last_approved_date;
  }, [incumbents]);

  const reactToPrint = useReactToPrint({
    content: () => {
      const cloneDom = printRef.current.cloneNode(true);
      const ignoreElements = cloneDom.querySelectorAll('.print-ignore, .ant-table-filter-trigger, .ant-pagination');
      const allRemarksElement = cloneDom.querySelector('#all-remarks-approved-print');
      if (!isCollapsedRemarks) {
        allRemarksElement.style.display = 'block';
        allRemarksElement.style.maxHeight = 'none';
      }

      ignoreElements.forEach((el) => {
        el.parentNode.removeChild(el);
      });
      const elements = cloneDom.querySelectorAll('.card-dsp');
      if (!isEmpty(elements)) {
        elements.forEach((el) => {
          el.style.removeProperty('width');
        });
      }
      return cloneDom;
    },
    removeAfterPrint: true,
    onAfterPrint: () => {
      setPrinting(undefined);
    },
    documentTitle: `SP_${positionName}_${dateApproved ? moment(dateApproved).format('YYYYMMDD') : ''}`,
  });

  const handlePrint = () => {
    setPrinting(true);
  };

  useEffect(() => {
    if (printing === undefined) return;
    if (!printing || (printing && isCollapsedRemarks)) reactToPrint();
  }, [isCollapsedRemarks, printing, reactToPrint]);

  const getPositionByCodeAPI = async () => {
    const params = {
      approved_date,
      platform,
    };
    const res = await getPositionByCode({ positionCode: positionCode, roleUser: user, params });
    if (res.status === 200) {
      return res.data.result;
    }
  };

  const fetchPositionsSPDashboardAPI = async () => {
    const res = await getSuccessionPlanningViewDetail({
      position_codes: positionCode,
      roleUser: user,
      check_group: 1,
      historical_sp_approved_date: approved_date,
    });
    if (res.status === 200) {
      return res.data.result;
    }
  };

  useEffect(() => {
    async function fetchAPI() {
      setLoading(true);
      Promise.all([getPositionByCodeAPI(), fetchPositionsSPDashboardAPI()])
        .then((res) => {
          if (!res[0] && !res[1]) return;
          setDataPosition(res[0]);
          const incumbentData =
            approved_date === undefined ? res[1].data : res[1].data.map((item) => ({ ...item, last_approved_date: approved_date }));
          setIncumbents(incumbentData);
        })
        .catch((err) => {
          if (err?.response?.data?.code === 403) {
            history.push('/access-denied');
            return;
          }
          console.error(err);
          setDataPosition([]);
          setIncumbents([]);
        })
        .finally(() => {
          setLoading(false);
        });
    }

    if (positionCode) {
      fetchAPI();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [positionCode, roleId]);

  return (
    <div className={styles.maxWidth} data-testid="view-detail-sp-page">
      <div className={styles.control}>
        <div className={styles.breadcrumb}>
          <Link to="/">
            <p data-testid="link-1">Dashboard</p>
          </Link>
          <img src={pets_chevron_right} alt="pets_chevron_right" />
          <Link to="/succession-planning">
            <p data-testid="link-2">Succession Planning Dashboard</p>
          </Link>
          <img src={pets_chevron_right} alt="pets_chevron_right" />
          <p data-testid="link-active" className={styles.talent_profile}>
            Succession Planning Dashboard
          </p>
        </div>
      </div>

      <div className={styles.control}>
        <div data-testid="title-page" className={styles.title_page}>
          {positionName}
        </div>
      </div>

      <div className="d-flex justify-content-end align-items-center " style={{ gap: '10px', marginBottom: '14px' }}>
        <Spin spinning={Boolean(printing)}>
          <BasicButton onClick={handlePrint}>Print</BasicButton>
        </Spin>
      </div>

      <div ref={printRef}>
        <div className={styles.dashboardSP}>
          <div data-testid="view-position-recommend">
            <PositionsDashboardSP incumbents={incumbents} loading={loading} approved_date={approved_date} platform={platform} printing={printing} />
            <Disclaimer dataContent={[{ id: 1, label: SP_NOTES_PERFORMANCE_RATING }]} customStyle={{ marginBottom: '30px' }} />
            <RecommendationsDSP positions={dataPosition} incumbents={incumbents} />
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-end" style={{ margin: '20px 0' }}>
        {!approved_date && !platform && <AiButton onClick={() => history.push('/succession-planning-page')}>Back</AiButton>}
      </div>
    </div>
  );
};

export default DashboardSP;