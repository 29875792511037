import { Col, Row, Space } from 'antd';
import { useFormik } from 'formik';
import { isArray, isEmpty, size } from 'lodash';
import moment from 'moment';
import React, { memo, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { AiButton } from '../../../assets/common';
import { AlertMessage, AsyncMultipleSelect, FieldError } from '../../../components';
import SearchByCategoryForTcMeeting from '../../../components/SearchByCategoryForTcMeeting/SearchByCategoryForTcMeeting';
import SearchBySecretariatForTcMeeting from '../../../components/SearchBySecretariatForTcMeeting/SearchBySecretariatForTcMeeting';
import { isCheckError, onChangeInputData, onChangeSelectData } from '../../../hooks/useFormik';
import {
  DATE_TIME_FORMAT,
  MEETING_CATEGORY_OPTIONS,
  MEETING_CATEGORY_VALUES,
  ROLE,
} from '../../../utils/constants';
import {
  getAsyncOptionAdvocatorsHrPartners,
  getLabelNameForSecretariatOfTcMeeting,
} from '../../../utils/helper';
import MeetingCategory from './components/MeetingCategory';
import styles from './meeting-details.module.scss';
import {
  AiContainerGrBtnModel,
  AiDatePicker,
  AiDesc,
  AiFormWrap,
  AiInput,
  AiLabel,
  AiModel,
  AiTimePickerField,
  AiTitle,
} from './Styled';
import { meetingApi } from '../../../services/tcMeeting';
import SearchByAdditionalViewAccessForTcMeeting from '../../../components/SearchByAdditionalViewAccessForTcMeeting/SearchByAdditionalViewAccessForTcMeeting';

const ModalMeetingDetails = ({
  setVisible,
  visible,
  initialValues,
  initialErrors,
  validationSchema,
  setValues,
  // getAsyncOptionsForSecretarial,
  // businessOption,
  // opuDivisionOption,
  meetingSecretariatValue,
  setMeetingSecretariatValue,
  additionalViewAccessValue,
  setAdditionalViewAccessValue,
  // isDisabledAdditionalViewAccess,
  setIsLoadAddtionalDataInFirstTime,
  setIsChangedMeetingCategory,
}) => {
  const roleActive = useSelector((state) => state.user.roleActive);
  const [isOpenCategoryDropdown, setIsOpenCategoryDropdown] = useState();

  const formik = useFormik({
    initialValues,
    initialErrors,
    enableReinitialize: true,
    validationSchema,
    onSubmit: (values) => {
      setValues(values);
      setVisible(false);
    },
    initialTouched: Object.keys(initialValues).reduce(
      (result, key) => ({ ...result, [key]: true }),
      {}
    ),
  });

  const [isChangeCategory, setIsChangeCategory] = useState(false);
  const [searchCategoryDropdown, setSearchCategoryDropdown] = useState([]);

  useEffect(() => {
    const fetchMeetingCategoryDropdown = async () => {
      try {
        const res = await meetingApi.getMeetingCategoryDropdown(roleActive.roleId);
        if (res.status === 200) {
          setSearchCategoryDropdown(res.data?.result || []);
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchMeetingCategoryDropdown();
  }, [roleActive.roleId]);

  useEffect(() => {
    if (isChangeCategory) {
      formik.setFieldValue('category_value', null);
      setIsChangeCategory(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isChangeCategory]);

  const onChange = (date, dateString) => {
    formik.setFieldValue('date', dateString);
  };

  const disabledDate = (current) => {
    // Can not select days before today and today
    const weekStart = moment().startOf('day');
    return !weekStart.isSameOrBefore(current);
  };

  const getSearchCategoryData = useMemo(() => {
    if (isEmpty(searchCategoryDropdown) || !isArray(searchCategoryDropdown)) return [];

    const tempData = !isEmpty(searchCategoryDropdown)
      ? searchCategoryDropdown.filter((item) => item.category === formik.values.category?.name)
      : [];
    if (isEmpty(tempData[0]?.label)) return [];

    return tempData[0]?.label;
  }, [searchCategoryDropdown, formik.values.category]);

  const handleSetMeetingSecretariatValue = (val) => {
    const isCheck =
      meetingSecretariatValue.filter((item) => item?.label_name === val?.label_name).length > 0;
    if (!isCheck) {
      setMeetingSecretariatValue((prev) => {
        return [...prev, val];
      });
    }
    setIsLoadAddtionalDataInFirstTime(false);
  };

  const handleSetAdditionalViewAccess = (val) => {
    const isCheck =
      additionalViewAccessValue.filter((item) => item?.label_name === val?.label_name).length > 0;
    if (!isCheck) {
      setAdditionalViewAccessValue((prev) => {
        return [...prev, val];
      });
    }
  };

  // Set meeting secretariats value
  useEffect(() => {
    formik.setFieldValue('meeting_secretariats', meetingSecretariatValue);
  }, [meetingSecretariatValue]);

  const handleSelectMeetingCategory = (val) => {
    formik.setFieldValue('category', val);
    formik.setFieldValue('category_value', null);
    setIsLoadAddtionalDataInFirstTime(false);
    setIsChangeCategory(true);

    const tempVal = {
      role: ROLE.HR_COE,
      role_id: 7,
      label_name: getLabelNameForSecretariatOfTcMeeting(MEETING_CATEGORY_VALUES.PETRONAS, {
        role: ROLE.HR_COE,
        role_id: 7,
      }),
      search_type: MEETING_CATEGORY_VALUES.PETRONAS,
    };
    if (val.name === MEETING_CATEGORY_VALUES.PETRONAS) {
      setMeetingSecretariatValue([tempVal]);
      setAdditionalViewAccessValue([tempVal]);
    } else if (
      [MEETING_CATEGORY_VALUES.SKG, MEETING_CATEGORY_VALUES.JOB_FAMILY].includes(val.name)
    ) {
      setAdditionalViewAccessValue([tempVal]);
      setMeetingSecretariatValue([]);
    } else {
      setMeetingSecretariatValue([]);
      setAdditionalViewAccessValue([]);
    }
  };

  return (
    <AiModel
      open={visible}
      width={1329}
      height={670}
      footer={null}
      onCancel={() => setVisible(false)}
    >
      {size(formik.errors) > 0 && (
        <Row>
          <Col span={24} style={{ marginBottom: '30px' }}>
            <AlertMessage
              message="There are items that require your attention. Please fill out this field."
              type="error"
            />
          </Col>
        </Row>
      )}

      <Row align="middle">
        <Col>
          <AiTitle>Fill in the field below to save as draft</AiTitle>
          <AiDesc>You have left a field empty and a value must be entered.</AiDesc>
        </Col>
      </Row>
      <form onSubmit={formik.handleSubmit}>
        <AiFormWrap>
          <Row className="mb-3">
            <Col span={24}>
              <AiLabel>Meeting Name</AiLabel>
              <AiInput
                placeholder="Meeting Name"
                name="meeting_name"
                value={formik.values.meeting_name}
                onChange={formik.handleChange}
                status={isCheckError(formik, 'meeting_name') ? 'error' : ''}
              />
            </Col>
            <FieldError name="meeting_name" {...formik} />
          </Row>
          <div className={styles.rowInput}>
            <div className={styles.inputItem}>
              <AiLabel>Date</AiLabel>
              <Space direction="vertical" style={{ width: '100%' }}>
                <AiDatePicker
                  disabledDate={disabledDate}
                  onChange={onChange}
                  format={DATE_TIME_FORMAT.DATE_SPACE}
                  status={isCheckError(formik, 'date') ? 'error' : ''}
                  value={
                    formik.values.date && moment(formik.values.date, DATE_TIME_FORMAT.DATE_SPACE)
                  }
                  placeholder="Select Date"
                  getPopupContainer={(trigger) => trigger.parentElement}
                />
                <FieldError name="date" {...formik} />
              </Space>
            </div>
            <div className={styles.inputItem}>
              <AiLabel>Meeting Category</AiLabel>
              <MeetingCategory
                selected={formik.values.category}
                setSelected={(event) => {
                  handleSelectMeetingCategory(event);
                  setIsChangedMeetingCategory(true);
                }}
                options={MEETING_CATEGORY_OPTIONS}
                placeholder="Select Meeting Category"
                status={isCheckError(formik, 'category') ? 'error' : ''}
              />
              <FieldError
                name="category.id"
                {...formik}
                forceTouched={isCheckError(formik, 'category')}
              />
            </div>
            {!isEmpty(formik.values.category) &&
              ['Business', 'OPU', 'Sector', 'Division'].includes(formik.values.category?.name) && (
                <div className={styles.inputItem}>
                  <AiLabel>{formik.values.category?.name}</AiLabel>
                  <SearchByCategoryForTcMeeting
                    openDropdown={isOpenCategoryDropdown}
                    setOpenDropdown={setIsOpenCategoryDropdown}
                    setValue={({ val }) => formik.setFieldValue('category_value', val)}
                    searchName={formik.values.category?.name}
                    fieldValue={formik.values.category_value}
                    data={getSearchCategoryData}
                    status={isCheckError(formik, 'category_value') ? 'error' : ''}
                    disabled={false}
                  />
                  <FieldError name="category_value" {...formik} />
                </div>
              )}

            {!isEmpty(formik.values.category) &&
              ['SKG', 'Job Family'].includes(formik.values.category?.name) && (
                <div className={styles.inputItem}>
                  <AiLabel>{formik.values.category?.name}</AiLabel>
                  <MeetingCategory
                    selected={formik.values.category_value}
                    setSelected={(event) => {
                      formik.setFieldValue('category_value', event);
                    }}
                    options={getSearchCategoryData}
                    placeholder={`Select ${formik.values.category?.name}`}
                    status={isCheckError(formik, 'category_value') ? 'error' : ''}
                  />
                  <FieldError name="category_value" {...formik} />
                </div>
              )}
            <div className={styles.inputItem}>
              <AiLabel>Start time</AiLabel>
              <AiTimePickerField
                placeholder={'Select Time'}
                value={
                  formik.values.start_time
                    ? moment(formik.values.start_time, DATE_TIME_FORMAT.TIME).local()
                    : null
                }
                style={{ width: '100%' }}
                onChange={(_event, value) => onChangeInputData(formik, value, 'start_time')}
                status={isCheckError(formik, 'start_time') ? 'error' : ''}
                showTime={{ format: DATE_TIME_FORMAT.TIME, use12Hours: true }}
                format={DATE_TIME_FORMAT.TIME}
                showNow={false}
                inputReadOnly={true}
                allowClear={false}
                getPopupContainer={(trigger) => trigger.parentElement}
              />
              <FieldError name="start_time" {...formik} />
            </div>
            <div className={styles.inputItem}>
              <AiLabel>End time</AiLabel>
              <AiTimePickerField
                placeholder={'Select Time'}
                value={
                  formik.values.end_time
                    ? moment(formik.values.end_time, DATE_TIME_FORMAT.TIME).local()
                    : null
                }
                style={{ width: '100%' }}
                onChange={(_event, value) => onChangeInputData(formik, value, 'end_time')}
                status={isCheckError(formik, 'end_time') ? 'error' : ''}
                showTime={{ format: DATE_TIME_FORMAT.TIME, use12Hours: true }}
                format={DATE_TIME_FORMAT.TIME}
                showNow={false}
                inputReadOnly={true}
                allowClear={false}
                getPopupContainer={(trigger) => trigger.parentElement}
              />
              <FieldError name="end_time" {...formik} />
            </div>
          </div>
          <Row className="mb-3">
            <Col span={24}>
              <AiLabel>Chairman</AiLabel>
              <AsyncMultipleSelect
                value={formik.values.chairman}
                placeholder="Enter Chairman Name"
                loadOptions={(e) => getAsyncOptionAdvocatorsHrPartners(e, roleActive.roleId)}
                onChange={(e) => onChangeSelectData(formik, e, 'chairman')}
                status={isCheckError(formik, 'chairman') ? 'error' : ''}
                isDisabled={false}
              />
              <FieldError name="chairman" {...formik} />
            </Col>
          </Row>
          <Row className="mb-3">
            <Col span={24}>
              <AiLabel>Meeting Secretariat</AiLabel>
              <SearchBySecretariatForTcMeeting
                searchName={formik.values.category?.name}
                setValue={({ val }) => {
                  handleSetMeetingSecretariatValue(val);
                  if (formik.values.meeting_secretariats.length === 0) {
                    setIsChangedMeetingCategory(true);
                  }
                }}
                fieldValues={meetingSecretariatValue}
                setMeetingSecretariatValue={setMeetingSecretariatValue}
                status={isCheckError(formik, 'meeting_secretariats') ? 'error' : ''}
                isDisabled={false}
              />
              <FieldError name="meeting_secretariats" {...{ formik, forceTouched: true }} />
            </Col>
          </Row>
          <Row className="mb-3">
            <Col span={24}>
              <AiLabel noRequire={true}>Additional View Access</AiLabel>
              <SearchByAdditionalViewAccessForTcMeeting
                searchName={formik.values.category?.name}
                setValue={({ val }) => handleSetAdditionalViewAccess(val)}
                fieldValues={additionalViewAccessValue}
                setAdditionalViewAccessValue={setAdditionalViewAccessValue}
                // status={isCheckError(formik, 'meeting_additional_view_accesses') ? 'error' : ''}
                // isDisabled={isDisabledAdditionalViewAccess}
              />
              {/* <FieldError name="meeting_additional_view_accesses" {...{ formik, forceTouched: true }} /> */}
            </Col>
          </Row>
        </AiFormWrap>
        <AiContainerGrBtnModel>
          <AiButton
            onClick={() => {
              setVisible(false);
              formik.resetForm();
            }}
            border={true}
          >
            Cancel
          </AiButton>
          <AiButton mode="teal" className="ml-2" onClick={formik.handleSubmit}>
            Save as Draft
          </AiButton>
        </AiContainerGrBtnModel>
      </form>
    </AiModel>
  );
};

export default memo(ModalMeetingDetails);
