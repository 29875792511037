import React from 'react';
import { MOBILITY_PLAN_DROP_CARD_INFO } from '../../../../../../utils/constants';

const MobilityItem = React.forwardRef((props, ref) => {
  const { item } = props;
  const color =
    item.typeMobility === MOBILITY_PLAN_DROP_CARD_INFO.NEXT_MOBILITY.type
      ? '#005CB9'
      : item.typeMobility === MOBILITY_PLAN_DROP_CARD_INFO.NEXT_2_MOBILITY.type
      ? '#763F98'
      : '#00A19C';

  const style = {
    background: '#fff',
    border: '1px solid #ebebeb',
    borderRadius: '4px',
    borderLeft: '8px solid #66b2c4',
    borderLeftColor: color === '#00A19C' ? '#66B2C4' : color,
    color,
    fontWeight: 600,
    padding: '8px 10px 8px 12px',
    minHeight: '62px',
  };
  return (
    <div ref={ref} style={style} onKeyDown={() => {}}>
      {item.position_name} <span style={{ fontWeight: 400 }}>({item.position_code})</span>{' '}
      {item.job_grade && <span style={{ fontWeight: 400 }}>({item.job_grade})</span>}
    </div>
  );
});
MobilityItem.displayName = 'MobilityItem';

export default MobilityItem;
