import { isEmpty } from 'lodash';
import React, { memo, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { sp_edit_active, sp_edit_deactive, sp_info_deactive } from '../../../../../assets/img';
import Disclaimer from '../../../../../components/Disclaimer/Disclaimer';
import {
  AGENDA_STATUS,
  MEETING_DETAIL_STATUS,
  SP_AGENDA_DISCLAIMER,
} from '../../../../../utils/constants';
import PositionItem from './PositionItem';
import styles from './positions.module.scss';

// import { getRetirementContractEndLabel, handleShowInformation, handleShowRetirement } from '../../../../../utils/helper';
// import ViewSucessionPlanPopup from '../ViewSucessionPlanPopup/ViewSucessionPlanPopup';
// const INIT_INFO_DATA = {
//   incumbent: [],
//   SG: [],
//   yearsInPosition: [],
//   age: [],
//   retirement: [],
//   approvedDate: [],
//   incumbentList: [],
// };

const Positions = (props) => {
  const {
    positions,
    viewVersion,
    handleDeletePosition,
    isDisabledByAgendaStatus,
    isEnableButtonToEditPosition,
    setPrevAgendaData,
    formik,
    onChangeSelectData,
  } = props;
  const agendaIncumbent = useSelector((state) => state.app.meetingModule.agenda.agendaIncumbent);
  // const [information, setInformation] = useState(INIT_INFO_DATA);
  const [isEdit, setIsEdit] = useState(false);
  // const [isShowSuccessionPlanPopup, setIsShowSuccessionPlanPopup] = useState(false);
  const { agendaId } = useParams();

  const { meetingRole, meeting: meetingDetail } = useSelector(
    (state) => state.app.meetingModule.preMeeting
  );
  const isEnabledRemarkField = useMemo(() => {
    return (
      (([MEETING_DETAIL_STATUS.IN_PLANNING, MEETING_DETAIL_STATUS.PUBLISHED].includes(
        meetingDetail?.status
      ) &&
        agendaIncumbent.status === AGENDA_STATUS.PAPER_IN_PROGRESS) ||
        ([MEETING_DETAIL_STATUS.IN_PROGRESS].includes(meetingDetail?.status) &&
          agendaIncumbent.status === AGENDA_STATUS.PAPER_READY)) &&
      meetingRole?.is_secretariat_or_facilitator
    );
  }, [meetingDetail, agendaIncumbent, meetingRole]);

  const handleEdit = () => {
    setIsEdit(!isEdit);
  };

  // const handleDataInformationStaff = () => {
  //   setInformation(INIT_INFO_DATA);

  //   positions.map((item) => {
  //     if (!isEmpty(item.incumbent)) {
  //       setInformation((preState) => {
  //         return {
  //           ...preState,
  //           approvedDate: [...preState.approvedDate, item.last_approved_date ? item.last_approved_date : item.contract_end_date],
  //           incumbent: [...preState.incumbent, item.incumbent.name],
  //           incumbentList: [...preState.incumbentList, item.incumbent],
  //           SG: [...preState.SG, item.incumbent.salary_grade],
  //           yearsInPosition: [...preState.yearsInPosition, item.incumbent.date_in_position],
  //           age: [...preState.age, item.incumbent.age],
  //           retirement: [...preState.retirement, item.incumbent.retirement_date],
  //         };
  //       });
  //     }
  //     return null;
  //   });
  // };

  const saveRemark = (e) => {
    const val = e?.target?.value;
    onChangeSelectData(formik, val, 'remarks');
    setPrevAgendaData((prev) => ({ ...prev, remarks: agendaIncumbent?.remark ?? '' }));
  };

  // useEffect(() => {
  //   handleDataInformationStaff();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [positions]);

  const isDisabledGeneralRemark = useMemo(() => {
    return (
      !meetingDetail?.is_secretariat_or_facilitator ||
      isNaN(Number(agendaId)) ||
      ([MEETING_DETAIL_STATUS.IN_PROGRESS].includes(meetingDetail?.status) &&
        agendaIncumbent.status === AGENDA_STATUS.PAPER_FINALIZED)
    );
  }, [agendaId, meetingDetail, agendaIncumbent]);

  return (
    <div className={styles.positions} data-testid="component-position">
      {/* Update view position list */}
      <div className={styles.positionListWrapper}>
        <span className={styles.positionTitle}>Current Incumbent Details</span>
        <div style={{ display: 'flex' }} className={styles.listAction}>
          {((!viewVersion && !isDisabledByAgendaStatus) ||
            isEnableButtonToEditPosition ||
            (meetingDetail?.is_secretariat_or_facilitator &&
              meetingDetail?.status === MEETING_DETAIL_STATUS.IN_REVIEW)) && (
            <img
              data-testid="edit-pos-btn"
              src={isEdit ? sp_edit_active : sp_edit_deactive}
              alt="sp-edit"
              onKeyDown={() => {}}
              onClick={() => handleEdit()}
              style={{ cursor: 'pointer', width: 40 }}
            />
          )}
          <img
            src={sp_info_deactive}
            alt={sp_info_deactive}
            style={{ cursor: 'pointer', width: 40 }}
          />
        </div>
      </div>
      <div style={{ marginBottom: '24px' }}>
        {!isEmpty(positions) &&
          positions.map((item, index) => (
            <PositionItem
              key={index}
              data={item}
              isEdit={isEdit}
              handleDeletePosition={handleDeletePosition}
              lastItem={false}
            />
          ))}
      </div>

      {/* <div className={styles.headWrapper} style={{ gap: '15px', marginBottom: '23px' }}>
        <div>
          {!isEmpty(positions) &&
            positions.map((item, index) => (
              <span key={index}>
                <TooltipPoisition item={item} />
                {isEdit && (
                  <img
                    data-testid="delete-pos-btn"
                    style={{ cursor: 'pointer' }}
                    src={sp_delete}
                    alt={sp_delete}
                    onKeyDown={() => {}}
                    onClick={() => handleDeletePosition(item.position_code)}
                  />
                )}
                {index + 1 !== positions.length && ` / `}
              </span>
            ))}
        </div>
        <div style={{ display: 'flex' }}>
          {((!viewVersion && !isDisabledByAgendaStatus) ||
            isEnableButtonToEditPosition ||
            (meetingDetail?.is_secretariat_or_facilitator && meetingDetail?.status === MEETING_DETAIL_STATUS.IN_REVIEW)) && (
            <img
              data-testid="edit-pos-btn"
              src={isEdit ? sp_edit_active : sp_edit_deactive}
              alt="sp-edit"
              onKeyDown={() => {}}
              onClick={() => handleEdit()}
              style={{ cursor: 'pointer' }}
            />
          )}
          <img src={sp_info_deactive} alt={sp_info_deactive} style={{ cursor: 'pointer' }} />
        </div>
        <div className={styles.viewSuccessionPlanButton} onClick={() => setIsShowSuccessionPlanPopup(true)} onKeyDown={() => {}}>
          {`VIEW SUCCESSION PLAN`}
          <img src={right_open_arrow} alt={right_open_arrow} style={{ cursor: 'pointer', paddingLeft: '10px' }} />
        </div>
      </div> */}

      {/* <div className={styles.planning_date}>Succession Planning Approval Date: {handleShowInformation(information.approvedDate, 'time')}</div> */}

      {/* <div className="row no-gutters justify-content-between align-items-stretch" style={{ marginBottom: '20px' }}>
        <div className="col-7">
          <div className={styles.fontMedium}>Current Incumbent Details</div>
          <div className="row no-gutters justify-content-start align-items-start">
            <div className="col-6">
              <div className={styles.fontMinimal}>Current Incumbent: {handleShowInformation(information.incumbent)}</div>
              <div className={styles.fontMinimal}>Salary Grade: {handleShowInformation(information.SG)}</div>
              <div className={styles.fontMinimal}>Date In Position: {handleShowInformation(information.yearsInPosition, 'time')}</div>
            </div>
            <div className="col-6">
              <div className={styles.fontMinimal}>Age: {handleShowInformation(information.age)}</div>
              <div className={styles.fontMinimal}>
                {getRetirementContractEndLabel(information?.incumbentList)} {handleShowRetirement(information.retirement)}
              </div>
            </div>
          </div>
        </div>

        <div className={`${styles.divider}`}></div>

        {checkHideSPRatio() && (
          <div className="col">
            <div className="d-flex align-items-center" style={{ gap: '14px' }}>
              <div className={styles.fontMedium}>SP Ratio</div>
            </div>
            <div className="row no-gutters  justify-content-start align-items-start">
              <div className="col">{displayRatio()}</div>
            </div>
          </div>
        )}
      </div> */}

      {!viewVersion || meetingDetail?.status === MEETING_DETAIL_STATUS.IN_REVIEW ? (
        <>
          <div className={styles.fontMedium}>Description of role(s)/ requirements</div>
          <div className={styles.textArea}>
            <textarea
              data-testid="txt-des-role"
              className={styles.textAreaInput}
              placeholder="Type Description of role(s)/ requirements here"
              onChange={(e) => saveRemark(e)}
              value={formik.values.remarks}
              disabled={
                isDisabledGeneralRemark
                  ? true
                  : (meetingDetail?.status === MEETING_DETAIL_STATUS.IN_REVIEW ||
                      meetingDetail?.status === MEETING_DETAIL_STATUS.IN_PROGRESS) &&
                    meetingDetail?.is_secretariat_or_facilitator
                  ? false
                  : isEnabledRemarkField
                  ? false
                  : true
              }
            ></textarea>
          </div>
        </>
      ) : (
        agendaIncumbent.remarks && (
          <>
            <div className={styles.fontMedium}>Description of role(s)/ requirements</div>
            <div className={styles.textArea}>
              <textarea
                data-testid="txt-des-role"
                className={styles.textAreaInput}
                onChange={(e) => saveRemark(e)}
                value={formik.values.remarks}
                readOnly={viewVersion}
                disabled={!isEnabledRemarkField || isDisabledGeneralRemark}
              ></textarea>
            </div>
          </>
        )
      )}
      <div className={styles.description}>
        <div className={styles.title}>Information</div>
        <div className={styles.content}>
          <div>Talents identified as first line successor shall be limited to:</div>
          <ul>
            <li>2 times only for VP positions</li>
            <li>4 times only for SGM positions</li>
          </ul>
          <div>
            Deliberation of the first line successors shall focus on their development plans in
            order to ensure their readiness to assume the intended positions.
          </div>
        </div>
      </div>
      <Disclaimer dataContent={SP_AGENDA_DISCLAIMER} />
      {/* {isShowSuccessionPlanPopup && (
        <ViewSucessionPlanPopup
          setIsShowSuccessionPlanPopup={setIsShowSuccessionPlanPopup}
          isShowSuccessionPlanPopup={isShowSuccessionPlanPopup}
          positions={positions}
        />
      )} */}
    </div>
  );
};

export default memo(Positions);
