import { MsalAuthenticationTemplate, useMsal } from '@azure/msal-react';
import { notification } from 'antd';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { AiSpin, BasicButton } from '../../assets/common';
import { my_talent_x_logo, petronas_logo } from '../../assets/img';
import { loginRequest } from '../../msalConfig';
import userApi from '../../services/userApi';
import { setData, setRoleActive, setRoles, setUsername } from '../../store/userSlice';
import { ACTION_LOG, ERROR_MESSAGE, USER_NOT_EXIST } from '../../utils/constants';
import * as styles from './system-maintenance.module.scss';
import { useLocation } from 'react-router-dom';
import { isArray, isEmpty } from 'lodash';

const SystemMaintenance = () => {
  const { instance } = useMsal();
  const location = useLocation();
  const currentAccount = instance.getActiveAccount();
  const history = useHistory();
  const dispatch = useDispatch();
  const roleActive = useSelector((state) => state.user.roleActive);
  const username = useSelector((state) => state.user.username);
  const [loading, setLoading] = useState(false);

  const handleClickLogin = async () => {
    instance.loginRedirect(loginRequest);
  };

  async function getEmployee(userId) {
    try {
      setLoading(true);
      const response = await userApi.getUserById(userId);
      // userApi.logAction({ url: '', type: ACTION_LOG.LOGIN });
      const data = response?.data?.result;
      if (response.status === 200 && !isEmpty(data)) {
        if (!roleActive.roleId) {
          dispatch(setRoleActive(isArray(data?.roles) ? data.roles[0] : null));
        }
        dispatch(setRoles(data?.roles));
        dispatch(setData(data));
        dispatch(setUsername(data?.employeeName));
        history.push('/homepage');
      }
    } catch (err) {
      const res = err.response;
      console.log(err);
      if (res?.data?.message === ERROR_MESSAGE || res?.data?.errors === USER_NOT_EXIST) {
        notification.error({
          message: 'User not exist in system',
          duration: 5,
        });
        localStorage.clear();
      } else {
        notification.error({
          message: 'Something went wrong',
          duration: 5,
        });
        localStorage.clear();
      }
    }
    setLoading(false);
  }

  useEffect(() => {
    if (currentAccount?.name) {
      getEmployee();
    }
    if (currentAccount?.name && username && roleActive?.roleId) {
      return history.push('/homepage');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentAccount?.name, roleActive?.roleId, username]);

  return (
    <>
      <MsalAuthenticationTemplate />
      <Row className={styles.wrapper}>
        <Col className={styles.leftSide}>
          <img src={my_talent_x_logo} alt="logo" />
        </Col>
        <Col className={styles.rightSide}>
          <div className={styles.content}>
            <div className={styles.logo}>
              <img src={petronas_logo} alt="logo" />
            </div>
            <div className={styles.title}>We’ll Be Back Soon!</div>
            <div style={{ fontSize: '20px', fontWeight: 400, color: '#575757' }}>
              Sorry for the inconvenience!{' '}
            </div>
            <div className={styles.detail} style={{ marginTop: '40px' }}>
              <span>MyTalentX is currently undergoing major enhancements to ensure you get</span>
              <span style={{ display: 'inline-block' }}> the best experience.</span>
            </div>
            <div className={styles.detail} style={{ marginTop: '40px' }}>
              <span>
                The system is targeted to be fully operational on{' '}
                <span style={{ fontWeight: 700, color: '#3F3C4C' }}>09 December 2024</span>
              </span>
            </div>
            <div
              className={styles.detail}
              style={{ marginTop: '40px', fontSize: '14px', color: '#575757' }}
            >
              For urgent inquiries, reach out via Email or Teams:
            </div>
            <div
              className={styles.detail}
              style={{ marginTop: '5px', fontWeight: 500, fontSize: '14px', color: '#3F3C4C' }}
            >
              Adlil Ahmad Azmy, Nor Idayu Talib
            </div>
            <div style={{ marginTop: '0px', fontSize: '12px', fontWeight: 400, color: '#7A7A7A' }}>
              (Top Talent Solutioning focal persons)
            </div>
            {!loading ? (
              <div>
                <BasicButton
                  mode="teal"
                  className={styles.loginBtn}
                  onClick={handleClickLogin}
                  style={{ visibility: location.pathname === '/admin' ? 'visible' : 'hidden' }}
                >
                  Login
                </BasicButton>
              </div>
            ) : (
              <AiSpin className={styles.loading}></AiSpin>
            )}
            <div className={styles.contact}>
              For inquiries and assistance, please contact ICT Service Desk at <br />
              <span>ict.servicedesk@petronas.com</span>
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};
export default SystemMaintenance;
