import { isEmpty } from 'lodash';
import moment from 'moment';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import { meeting_detail_triangle } from '../../../../assets/img';
import { useComponentVisible } from '../../../../hooks';
import { CALENDAR, DATE_TIME_FORMAT, MEETING_STATUS } from '../../../../utils/constants';
import { changeMeetingStatus } from '../../../Meeting/redux/meetingDetailSlice';
import PopupDetail from '../../components/PopupDetail/PopupDetail';
import { Container } from './styled';

const DetailMonthlyView = (props) => {
  const { day, date, data } = props;
  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);
  const formatDate = moment(date).local().format('YYYY-MM-DD');
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const handleClickMeeting = (meeting) => {
    return meeting.status === CALENDAR.MEETING_STATUS.IN_PLANNING &&
      !meeting.is_secretariat_or_facilitator
      ? null
      : getRedirectUrl(meeting);
  };

  const getRedirectUrl = (meeting) => {
    dispatch(changeMeetingStatus(false));
    return location.pathname === '/pdc'
      ? history.push(`/pdc-meeting/${meeting.meeting_pdc_id}`)
      : history.push(
          meeting.status === CALENDAR.MEETING_STATUS.IN_PLANNING
            ? `/meeting/${meeting.meeting_id}`
            : `/meeting/detail/${meeting.meeting_id}`
        );
  };

  return (
    <Container id={moment(date).format('YYYY-MM-DD') + 'scroll'} data-testid="detail-monthly-view">
      <div className="date">{day < 10 ? `0${day}` : day}</div>
      {!isEmpty(data) &&
        data.map((item, index) => (
          <div className="" style={{}} key={'calendar-date' + index}>
            {!isEmpty(item.meetings) &&
              moment(formatDate).isSame(item.date) &&
              item.meetings.map((d, index) => (
                <div style={{ marginTop: '15px' }} key={'meeting' + index}>
                  <div
                    style={{
                      backgroundColor: MEETING_STATUS.find((v) => v.status === d.status)?.color,
                      borderRadius: '6px',
                      color: 'white',
                      display: 'inline-block',
                      padding: '0px 12px',
                      fontSize: '12px',
                      fontWeight: '500',
                    }}
                  >
                    {d.status}
                  </div>
                  <div
                    className="meeting-name"
                    onKeyDown={() => {}}
                    onClick={() => {
                      handleClickMeeting(d);
                    }}
                    style={
                      d.status === CALENDAR.MEETING_STATUS.IN_PLANNING &&
                      !d.is_secretariat_or_facilitator
                        ? { cursor: 'default' }
                        : null
                    }
                  >
                    {d.name}
                  </div>
                  <div className="meeting-time">
                    {moment(d.start_at).format(DATE_TIME_FORMAT.TIME)} -{' '}
                    {moment(d.end_at).format(DATE_TIME_FORMAT.TIME)}
                  </div>
                </div>
              ))}
            {item.total > 2 && formatDate === item.date && (
              <div className="container-popup" ref={ref} data-testid="detail-monthly-view-popup">
                <div
                  className="add-more"
                  onKeyDown={() => {}}
                  onClick={() => setIsComponentVisible(true)}
                  data-testid="detail-monthly-add-more"
                >
                  And {item.total - 2} more
                </div>
                {isComponentVisible && (
                  <>
                    <PopupDetail
                      setIsComponentVisible={setIsComponentVisible}
                      currentDate={item.date}
                      limit={item.total}
                      params={props.params || {}}
                    />
                    <div className="meeting-path" data-testid="meeting-path">
                      <img src={meeting_detail_triangle} alt="" />
                    </div>
                  </>
                )}
              </div>
            )}
          </div>
        ))}
    </Container>
  );
};

export default DetailMonthlyView;
