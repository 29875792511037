import { Tooltip } from 'antd';
import moment from 'moment';
import React from 'react';
import styled from 'styled-components';

import { deleteIcon, sp_delete } from '../../../../../assets/img';
import {
  decodeBase64ToImage,
  getLableClassForSP,
  getRetirementContractEndDateLabelForSP,
  handleShowCT,
  handleShowInformationPosition,
  handleShowPositionForSP,
  handleShowRetirementDate,
  handleShowRetirementToView,
  handleShowRoleForSP,
  handleShowYIPAndYISG,
} from '../../../../../utils/helper';

export const PositionListWrapper = styled.div`
  .listPositionItem {
    border-radius: 16px;
    border: 1px solid #9fb0c2;
  }

  .positionItemTop {
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    background: var(--color-blue-grey-100, #f0f5fa);
    flex-wrap: wrap;
    padding: 10px 20px;
    position: relative;

    > div {
      width: 100%;
    }

    .deleteBtn {
      position: absolute;
      top: 18px;
      right: 20px;
    }

    .title {
      color: var(--Light-Blue-Grays-700, var(--color-blue-grey-700, #394655));
      font-family: 'Inter', sans-serif;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 28px; /* 155.556% */
      letter-spacing: 0.389px;
    }

    .date {
      color: var(--Grays-500, #525252);
      font-family: 'Inter', sans-serif;
      font-size: 12px;
      font-style: italic;
      font-weight: 400;
      line-height: 20px; /* 166.667% */
    }

    .code {
      color: var(--Light-Primary-700, #007874);
      text-align: center;
      font-family: 'Inter', sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px; /* 150% */
      letter-spacing: 0.1px;
    }
  }

  .positionItemTop,
  .positionItemBot {
    display: flex;
    align-items: flex-start;

    color: #505050;
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
    letter-spacing: 0.235px;
  }

  .positionItemBot {
    padding: 12px 20px;

    .avatar {
      width: 100px;

      img {
        width: 47px;
        height: 47px;
        border-radius: 50%;
        overflow: hidden;
      }
    }

    .itemInfo {
      width: 100%;

      .name {
        color: rgba(0, 0, 0, 0.8);
        font-family: 'Inter', sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px; /* 150% */
        letter-spacing: 0.307px;
        margin-bottom: 5px;
      }
    }

    .listInfo {
      display: flex;
      flex-wrap: wrap;
      list-style: none;
      padding: 0;
      margin-bottom: 0;

      li {
        width: 40%;
        color: #505050;
        font-family: 'Inter', sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px; /* 142.857% */
        letter-spacing: 0.235px;
        margin-bottom: 5px;

        &:nth-child(2n) {
          padding-left: 20px;
        }
      }
    }
  }
`;

export const TooltipWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  .title {
    color: var(--Light-Blue-Grays-700, var(--color-blue-grey-700, #394655));
    font-family: 'Inter', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: 0.389px;
  }
  .JG_hover {
    cursor: pointer;
    font-weight: 600;
    font-family: 'Inter', sans-serif;
    font-size: 18px;
    color: rgb(52, 69, 99);
  }

  .position_label {
    padding: 4px 12px;
    width: 58px;
    height: 32px;
    margin-left: 15px;
    font-family: $inter;
    font-weight: 500;
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    align-items: center;
    border-radius: 3px;
  }

  .BCP {
    color: #5b3075;
    background-color: #ebe1f1;
  }

  .CCP {
    color: #007874;
    background-color: #e0f3f3;
  }

  .other {
    color: #414e5c;
    background-color: #e7edf3;
  }
`;

const PositionItem = (props) => {
  const { data, isEdit, handleDeletePosition, lastItem } = props;

  const content = () => {
    return <span>JG: {data?.job_grade}</span>;
  };

  return (
    <PositionListWrapper>
      <div
        className={'listPositionItem'}
        style={lastItem ? { marginBottom: '5px' } : { marginBottom: '16px' }}
      >
        <div className={'positionItemTop'} style={isEdit ? { paddingRight: '45px' } : {}}>
          <TooltipWrapper>
            <div className={'positionItemName'}>
              <span className={`title`}>{handleShowPositionForSP(data)}</span>
              <Tooltip
                placement="bottom"
                title={content}
                overlayClassName={`customTooltipPosition`}
              >
                <span className={`JG_hover`}> {handleShowRoleForSP(data)}</span>
              </Tooltip>
            </div>
            {data?.position_label && (
              <span className={`${`position_label`} ${getLableClassForSP(data)}`}>
                {data?.position_label}
              </span>
            )}
          </TooltipWrapper>
          <div className="date">
            Succession Planning Approval Date:{' '}
            {handleShowInformationPosition(
              data?.last_approved_date
                ? data?.last_approved_date
                : data?.incumbent?.contract_end_date,
              'time'
            )}
          </div>
          {isEdit && (
            <img
              style={{ cursor: 'pointer' }}
              src={deleteIcon}
              alt={sp_delete}
              onKeyDown={() => {}}
              onClick={() => handleDeletePosition(data.position_code)}
              className={'deleteBtn'}
            />
          )}
        </div>
        <div className={'positionItemBot'}>
          <div className="avatar">
            <img src={decodeBase64ToImage(data?.incumbent?.image)} alt="avatar" />
          </div>
          <div className="itemInfo">
            <div className="name">
              {data?.incumbent?.name || data?.incumbent?.candidate_name}{' '}
              {handleShowCT(data?.incumbent?.ct)}
            </div>
            <ul className="listInfo">
              <li>Age: {data?.incumbent?.age ?? 'None'}</li>
              <li>
                Salary Grade:{' '}
                {handleShowInformationPosition(`${data?.incumbent?.salary_grade || ''}`)}
              </li>
              <li>
                Years In Position:{' '}
                {handleShowInformationPosition(
                  `${handleShowYIPAndYISG(
                    data?.incumbent?.years_in_position,
                    data?.incumbent?.date_in_position
                  )} ${
                    data?.incumbent?.date_in_position
                      ? `(since ${moment(new Date(data?.incumbent?.date_in_position)).format(
                          'DD/MM/YYYY'
                        )})`
                      : ''
                  }`
                )}
              </li>
              <li>
                {getRetirementContractEndDateLabelForSP(data?.incumbent)}{' '}
                {handleShowInformationPosition(
                  `${handleShowRetirementDate(
                    data?.incumbent?.retirement_date || data?.incumbent?.contract_end_date
                  )} (${
                    data?.incumbent?.retirement_date || data?.incumbent?.contract_end_date
                      ? handleShowRetirementToView(data?.incumbent)
                      : 'None'
                  })`
                )}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </PositionListWrapper>
  );
};

export default PositionItem;
