import { size } from 'lodash';
import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

export const useSearchParams = () => {
  const { search } = useLocation();
  return useMemo(() => {
    const urlSearchParam = new URLSearchParams(search);

    return Array.from(urlSearchParam).reduce((result, [key, _value]) => {
      let values = urlSearchParam.getAll(key);
      if (size(values) <= 1) {
        values = values[0];
      }
      return { ...result, [key]: values };
    }, {});
  }, [search]);
};
