import React, { useState } from 'react';
import PropTypes from 'prop-types';
import BasicTreeSelect from '../../../components/BasicTreeSelect/BasicTreeSelect';
import {
  TF_EXPORT_DEFAULT_SELECT_OPTIONS,
  TF_EXPORT_OPTIONS,
  TF_EXPORT_PARENT_OPTIONS,
  TF_EXPORT_SECTION_NAME,
} from './talent-finder-export.constants';
import { BasicButton } from '../../../assets/common';

const TalentFinderExportOption = (props) => {
  const { selectedExportOptions, setSelectedExportOptions, handleOnExport, disabled } = props;
  const [selectedOptions, setSelectedOptions] = useState(TF_EXPORT_DEFAULT_SELECT_OPTIONS);

  const handleApply = () => {
    setSelectedExportOptions(selectedOptions);
    const payloadExport = selectedOptions.filter((i) => !TF_EXPORT_PARENT_OPTIONS.includes(i.value));
    handleOnExport(payloadExport);
  };

  return (
    <BasicTreeSelect
      placement="bottomRight"
      dropdownStyle={{ minWidth: 415, maxWidth: 415 }}
      valueSelectAll={TF_EXPORT_SECTION_NAME.SELECT_ALL}
      onApply={handleApply}
      customApplyText="Export"
      options={TF_EXPORT_OPTIONS}
      selectedOptions={selectedOptions}
      setSelectedOptions={setSelectedOptions}
      prevSelectedOption={selectedExportOptions}
      listHeight={730}
      style={{ height: 30 }}
    >
      <BasicButton mode="teal" disabled={disabled}>
        Export
      </BasicButton>
    </BasicTreeSelect>
  );
};

export default TalentFinderExportOption;

TalentFinderExportOption.propTypes = {
  selectedExportOptions: PropTypes.array,
  setSelectedExportOptions: PropTypes.func,
  handleOnExport: PropTypes.func,
  disabled: PropTypes.bool,
};
