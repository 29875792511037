import React, { useState, useRef, useEffect } from 'react';
import styles from './dropdown-user-access.module.scss';
import Rectangle from '../../../../../assets/img/Rectangle.svg';
import { isEmpty } from 'lodash';
import SearchBusinessOptions from '../../../../../components/SearchBusinessOptions/SearchBusinessOptions';
import { Input } from 'antd';
import { search_dropdown } from '../../../../../assets/img';
import { BasicPopover } from '../../../../../assets/common';

export function DropdownUserAccess({ selected, setSelected, options, type, isSearchable }) {
  const [isActive, setIsActive] = useState(false);
  const [keyword, setKeyword] = useState('');
  const ref = useRef();

  function onClose() {
    setIsActive(false);
    setKeyword('');
  }
  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (isActive && ref.current && !ref.current.contains(e.target)) {
        onClose();
      }
    };
    document.addEventListener('mousedown', checkIfClickedOutside);

    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, [isActive]);

  return (
    <BasicPopover
      getPopupContainer={(trigger) => trigger}
      placement={
        ['Business', 'OPU', 'Division', 'Sector'].includes(type) ? 'bottomRight' : 'bottomLeft'
      }
      trigger="click"
      styles={{
        hideArrow: true,
        minWidth: ['Business', 'OPU', 'Division', 'Sector'].includes(type) ? null : 200,
        w: ['Business', 'OPU', 'Division', 'Sector'].includes(type) ? 1000 : null,
        px: 0,
        py: 0,
        isWidthNotMaxContent: true,
      }}
      open={isActive}
      onOpenChange={(open) => {
        setIsActive(open);
        setKeyword('');
      }}
      content={
        <>
          {['Business', 'OPU', 'Division', 'Sector'].includes(type) ? (
            <SearchBusinessOptions
              options={options}
              setSelected={setSelected}
              setIsActive={setIsActive}
              type={type}
              onClose={onClose}
              styles={styles}
              keyword={keyword}
              setKeyword={setKeyword}
            />
          ) : (
            <>
              {!isEmpty(options) && (
                <>
                  {isSearchable && (
                    <div className={styles.search_dropdown}>
                      <Input
                        placeholder="Search"
                        value={keyword}
                        onChange={(e) => setKeyword(e.target.value)}
                        suffix={<img src={search_dropdown} />}
                      />
                    </div>
                  )}
                  <div style={{ maxHeight: '300px', overflowY: 'auto', maxWidth: '250px' }}>
                    {options
                      .filter((f) => f?.toLowerCase().includes(keyword?.toLowerCase()))
                      .map((option, index) => {
                        return (
                          <div
                            key={index}
                            onKeyDown={() => {}}
                            onClick={() => {
                              setSelected(option);
                              onClose();
                            }}
                            className={styles.dropdown_item}
                            data-testid="click-dropdown-user-access"
                          >
                            {option}
                          </div>
                        );
                      })}
                    {isSearchable &&
                      options.filter((f) => f?.toLowerCase().includes(keyword?.toLowerCase()))
                        ?.length === 0 && (
                        <div key={'no-data-dropdown'} className={styles.dropdown_item_no_data}>
                          No data
                        </div>
                      )}
                  </div>
                </>
              )}
            </>
          )}
        </>
      }
    >
      <div className={styles.dropdown}>
        <div
          className={
            !isEmpty(options)
              ? styles.dropdown_btn
              : `${styles.dropdown_btn} ${styles.dropdown_disabled}`
          }
          onKeyDown={() => {}}
          onClick={
            !isEmpty(options)
              ? () => {
                  setIsActive(!isActive);
                  setKeyword('');
                }
              : null
          }
          data-testid="dropdown-user-access"
        >
          <p className={styles.dropdown_select_label} data-testid="name">
            {selected}
          </p>
          {!isEmpty(options) && <img src={Rectangle} alt="" />}
        </div>
      </div>
    </BasicPopover>
  );
}
