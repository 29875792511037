// import { Select } from 'antd';
import { isEmpty, size } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';

import {
  FILTER_TITLE,
  DEMOGRAPHIC_FILTERS,
  // ADVANCED_FILTER_OPTION,
  SELECT_ALL_LABEL,
  TALENT_FINDER_FILTER_OPTIONS,
  // TALENT_FINDER_FILTERS,
  TALENT_SEARCH_RESULT_OPTIONS,
  EXPERIENCE_FILTERS,
  EMPLOYMENT_INFO_FILTERS,
  SUCCESSION_AND_TOP_TALENT_STATUS_FILTERS,
  EXTERNAL_ASSESSMENT_FILTERS,
  LEADERSHIP_FILTERS,
} from '../../../utils/constants';
import DropdownTreeSelectContainer from '../DropdownTreeSelectContainer/DropdownTreeSelectContainer';
import data from './data.json';
import styles from './filter-finder.module.scss';
import { filter_talent_finder } from '../../../assets/img';

let tempFilter = {
  top_talent_status: [],
  business: [],
  salary_grade: [],
  complementary_experience: [],
  personality: [],
  age: [],
  business_experience: [],
  years_in_position: [],
  nationality: [],
  state_of_birth: [],
  years_to_retire: [],
  gender: [],
  functional_experience: [],
  edge: [],
  ext_edge: [],
  learning_agility: [],
  traits: [],
  drivers: [],
  derailment_risks: [],
  successor: [],
  attr27: [],
  competencies: [],
};

let filterTabActive = [];
let preTalentFilterOptions = {};

const FilterFinder = (props) => {
  // const { Option } = Select;
  const {
    filterOptions,
    setFilterFinderData,
    setIsFilter,
    keySearch,
    setTalentSearchResult,
    setKeyword,
    setPage,
    showFilter,
  } = props;
  const [talentFilterOptions, setTalentFilterOptions] = useState({});
  const [isBlur, setIsBlur] = useState(false);
  const [activeButton, setActiveButton] = useState(null);
  const [showFilters, setShowFilters] = useState({
    DEMOGRAPHIC: false,
    EXPERIENCE: false,
    LEADERSHIP: false,
    EMPLOYMENT_INFO: false,
    SUCCESSION_AND_TOP_TALENT_STATUS: false,
    EXTERNAL_ASSESSMENT: false,
  });
  const [showButtonFilter, setShowButtonFilter] = useState(false);

  const handleButtonClick = (value) => {
    setTalentSearchResult(value);
    setActiveButton(value);
  };

  const isDisableTalentSearchResult = useMemo(() => {
    // reset selection if keysearch is empty
    if (isEmpty(keySearch)) {
      setTalentSearchResult('');
      setActiveButton(null);
    }
    return isEmpty(keySearch);
  }, [keySearch]);

  const cloneState = (state) => {
    return JSON.parse(JSON.stringify(state));
  };

  const updateStateData = (filterName, options, newData) => {
    tempFilter[filterName] = options;
    setTalentFilterOptions(newData);
  };

  const getAllKeys = (tree) => {
    let result = [];
    tree.forEach((item) => {
      let childKeys = [];
      if (item?.children) {
        childKeys = getAllKeys(item.children);
      }
      result.push(...[{ label: item?.label, checked: item?.checked }, ...childKeys]);
    });

    return result;
  };

  const onChange = (currentNode) => {
    const filterName = currentNode.path.split('.')[0];
    let indexArr = getIndexList(currentNode.path);
    const newData = cloneState(talentFilterOptions);
    let options = newData[filterName];
    let selectedOption;
    const optionParentLv2 = options[indexArr[0]];

    const handleCheckedParent = (parentOption) => {
      const optionsChecked = parentOption.children.filter((optionLv2) => {
        return optionLv2.checked === true;
      });

      if (optionsChecked.length > 0) {
        parentOption.checked = true;
      } else {
        parentOption.checked = false;
      }
    };

    switch (indexArr.length) {
      case 1: //onChange lv1 of dropdown select tree
        selectedOption = options[indexArr[0]];
        selectedOption.checked = currentNode.checked;
        selectedOption.expanded = true;

        options.forEach((option) => {
          if (option.label !== currentNode.label) {
            option.expanded = false;

            if (option.children?.length) {
              option.children.forEach((childItem) => {
                childItem.expanded = false;

                if (childItem.children?.length) {
                  childItem.children.forEach((subChildItem) => {
                    subChildItem.expanded = false;
                  });
                }
              });
            }
          }
        });

        if (selectedOption.children?.length) {
          selectedOption.children.forEach((childOption) => {
            childOption.checked = currentNode.checked;
            childOption.expanded = true;

            if (childOption.children?.length) {
              childOption.children.forEach((i) => {
                i.checked = currentNode.checked;
                if (i.children?.length) {
                  i.children.forEach((sub) => {
                    sub.checked = currentNode.checked;
                  });
                }
              });
            }
          });
        }
        break;
      case 2: //onChange lv2 of dropdown select tree
        selectedOption = options[indexArr[0]].children[indexArr[1]];
        selectedOption.checked = currentNode.checked;
        selectedOption.expanded = true;

        if (selectedOption.children?.length) {
          selectedOption.children.forEach((childOption) => {
            childOption.checked = currentNode.checked;
            if (childOption.children?.length) {
              childOption.children.forEach((sub) => {
                sub.checked = currentNode.checked;
              });
            }
          });
        }

        handleCheckedParent(optionParentLv2);
        break;
      case 3: //onChange lv3 of dropdown select tree
        selectedOption = options[indexArr[0]].children[indexArr[1]].children[indexArr[2]];
        selectedOption.checked = currentNode.checked;
        selectedOption.expanded = true;

        if (selectedOption.children?.length) {
          selectedOption.children.forEach((childOption) => {
            childOption.checked = currentNode.checked;
          });
        }

        handleCheckedParent(options[indexArr[0]]?.children[indexArr[1]]);
        handleCheckedParent(optionParentLv2);
        break;
      case 4: //onChange lv4 of dropdown select tree
        selectedOption =
          options[indexArr[0]].children[indexArr[1]].children[indexArr[2]].children[indexArr[3]];
        selectedOption.checked = currentNode.checked;
        handleCheckedParent(options[indexArr[0]]?.children[indexArr[1]].children[indexArr[2]]);
        handleCheckedParent(options[indexArr[0]]?.children[indexArr[1]]);
        handleCheckedParent(optionParentLv2);
        break;
      default:
    }

    //handle checked (Select All)
    const allKeys = getAllKeys(options);
    const tempUncheckedItem = allKeys.filter(
      (item) => !item.checked && item.label !== SELECT_ALL_LABEL
    );
    if (tempUncheckedItem.length > 0) {
      options[0].checked = false;
    } else {
      options[0].checked = true;
    }

    updateStateData(filterName, options, newData);
  };

  const onClickSelectAll = (filterName, isChecked) => {
    const newData = cloneState(talentFilterOptions);
    assignObjectPaths(newData[filterName], filterName, isChecked);
    updateStateData(filterName, newData[filterName], newData);
  };

  const onNodeToggle = (currentNode) => {
    const filterName = currentNode.path.split('.')[0];
    let indexArr = getIndexList(currentNode.path);
    const newData = cloneState(talentFilterOptions);
    const options = newData[filterName];
    let currentOption;

    const toggleExpand = (node, isExpanded) => {
      node.expanded = isExpanded;

      if (node.children?.length) {
        node.children.forEach((child) => toggleExpand(child, isExpanded));
      }
    };

    switch (indexArr.length) {
      case 1: // Expand level 1
        currentOption = options[indexArr[0]];
        toggleExpand(currentOption, currentNode.expanded);

        // Collapse all other level 1 nodes
        options.forEach((option) => {
          if (option.label !== currentNode.label) {
            toggleExpand(option, false);
          }
        });
        break;

      case 2: // Expand level 2
        currentOption = options[indexArr[0]].children[indexArr[1]];
        toggleExpand(currentOption, currentNode.expanded);
        break;

      case 3: // Expand level 3
        currentOption = options[indexArr[0]].children[indexArr[1]].children[indexArr[2]];
        toggleExpand(currentOption, currentNode.expanded);
        break;

      default:
        break;
    }

    updateStateData(filterName, options, newData);
  };

  const getIndexList = (path) => {
    if (!path.includes('.')) return [];

    let tempArr = path.match(/\.\d+/g);
    tempArr =
      size(tempArr) > 0
        ? tempArr.map((item) => {
            return `${item.replace('.', '')}`;
          })
        : [];
    return tempArr;
  };

  const assignObjectPaths = (obj, stack, isChecked) => {
    Object.keys(obj).forEach((k) => {
      const node = obj[k];
      if (typeof node === 'object') {
        if (!stack && node[0]?.label !== SELECT_ALL_LABEL) {
          node.unshift({
            label: SELECT_ALL_LABEL,
            checked: isChecked === undefined ? true : isChecked,
          });
        }
        node.path = stack ? `${stack}.${k}` : k;
        node.checked = isChecked === undefined ? true : isChecked;
        assignObjectPaths(node, node.path, isChecked);
      }
    });
  };

  useEffect(() => {
    if (!isEmpty(filterOptions)) {
      assignObjectPaths(data);
      assignObjectPaths(filterOptions);
      setTalentFilterOptions({ ...filterOptions, ...data });
      preTalentFilterOptions = { ...filterOptions, ...data };
    }

    //  Handle clean the state
    return () => {
      handleClearFilter();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterOptions]);

  const handleClickApplyFilter = (item, key) => {
    if (size(tempFilter[key]) <= 0) {
      tempFilter[key] = item;
    }

    if (!filterTabActive.includes(key)) {
      filterTabActive.push(key);
    }

    const dataToPost = getDataToPost();
    setKeyword(keySearch);
    setFilterFinderData(dataToPost);
    preTalentFilterOptions = cloneState(talentFilterOptions);
    setIsFilter(true);
    setPage(1);
  };

  const getDataToPost = () => {
    const top_talent_status = handleGetFilterData(TALENT_FINDER_FILTER_OPTIONS.TOP_TALENT_STATUS);
    const business_unit = handleGetFilterData(TALENT_FINDER_FILTER_OPTIONS.BUSINESS);
    const sg = tempFilter[TALENT_FINDER_FILTER_OPTIONS.SALARY_GRADE];
    const complementary_experience = handleGetFilterData(
      TALENT_FINDER_FILTER_OPTIONS.COMPLEMENTARY_EXPERIENCE
    );
    const personality_archetype = tempFilter[TALENT_FINDER_FILTER_OPTIONS.PERSONALITY];
    const age_range = handleGetFilterData(TALENT_FINDER_FILTER_OPTIONS.AGE);
    const business = tempFilter[TALENT_FINDER_FILTER_OPTIONS.BUSINESS_EXPERIENCE];
    const previous_business_experience =
      tempFilter[TALENT_FINDER_FILTER_OPTIONS.PREVIOUS_BUSINESS_EXPERIENCE];
    const years_in_position = handleGetFilterData(TALENT_FINDER_FILTER_OPTIONS.YEARS_IN_POSITION);
    const years_to_retirement = handleGetFilterData(TALENT_FINDER_FILTER_OPTIONS.YEARS_TO_RETIRE);
    const nationality = handleGetFilterData(TALENT_FINDER_FILTER_OPTIONS.NATIONALITY);
    const state_of_birth = handleGetFilterData(TALENT_FINDER_FILTER_OPTIONS.STATE_OF_BIRTH);
    const gender = handleGetFilterData(TALENT_FINDER_FILTER_OPTIONS.GENDER);
    const successor = tempFilter[TALENT_FINDER_FILTER_OPTIONS.SUCCESSOR];
    const functional_experience = tempFilter[TALENT_FINDER_FILTER_OPTIONS.FUNCTIONAL_EXPERIENCE];
    const previous_functional_experience =
      tempFilter[TALENT_FINDER_FILTER_OPTIONS.PREVIOUS_FUNCTIONAL_EXPERIENCE];
    const edge = tempFilter[TALENT_FINDER_FILTER_OPTIONS.EDGE];
    const ext_edge = tempFilter[TALENT_FINDER_FILTER_OPTIONS.EXT_EDGE];
    const learning_agility = tempFilter[TALENT_FINDER_FILTER_OPTIONS.LEARNING_AGILITY];
    const traits = tempFilter[TALENT_FINDER_FILTER_OPTIONS.TRAITS];
    const drivers = tempFilter[TALENT_FINDER_FILTER_OPTIONS.DRIVERS];
    const derailment_risks = tempFilter[TALENT_FINDER_FILTER_OPTIONS.DERAILMENT_RISKS];
    const attr27 = tempFilter[TALENT_FINDER_FILTER_OPTIONS.ATTRIBUTES];
    const competencies = tempFilter[TALENT_FINDER_FILTER_OPTIONS.COMPETENCIES];
    return {
      top_talent_status,
      business_unit,
      sg,
      complementary_experience,
      personality_archetype,
      age_range,
      business,
      previous_business_experience,
      years_in_position,
      years_to_retirement,
      nationality,
      state_of_birth,
      gender,
      successor,
      functional_experience,
      previous_functional_experience,
      edge,
      ext_edge,
      learning_agility,
      traits,
      drivers,
      derailment_risks,
      attr27,
      competencies,
    };
  };

  const handleGetFilterData = (filterName) => {
    return tempFilter[filterName]
      ? tempFilter[filterName].filter((d) => d.checked).map((item) => item.value)
      : [];
  };

  const onBlur = (item, key) => {
    talentFilterOptions[key] = item;
    setTalentFilterOptions(preTalentFilterOptions);
    setIsBlur(true);
  };

  const handleClearFilter = () => {
    Object.keys(tempFilter).forEach((key) => {
      tempFilter[key] = [];
    });
    filterTabActive = [];
    setIsFilter(false);
    setFilterFinderData({});
    assignObjectPaths(data);
    assignObjectPaths(filterOptions);
    setTalentFilterOptions({ ...filterOptions, ...data });
    preTalentFilterOptions = { ...filterOptions, ...data };
    setPage(1);
  };

  // const handleReturnNumberColumns = (key) => {
  //   if (
  //     [
  //       TALENT_FINDER_FILTER_OPTIONS.EDGE,
  //       TALENT_FINDER_FILTER_OPTIONS.BUSINESS_EXPERIENCE,
  //       TALENT_FINDER_FILTER_OPTIONS.PREVIOUS_BUSINESS_EXPERIENCE,
  //       TALENT_FINDER_FILTER_OPTIONS.FUNCTIONAL_EXPERIENCE,
  //       TALENT_FINDER_FILTER_OPTIONS.PREVIOUS_FUNCTIONAL_EXPERIENCE,
  //       TALENT_FINDER_FILTER_OPTIONS.COMPLEMENTARY_EXPERIENCE,
  //     ].includes(key)
  //   ) {
  //     return 4;
  //   } else if (
  //     [
  //       TALENT_FINDER_FILTER_OPTIONS.LEARNING_AGILITY,
  //       TALENT_FINDER_FILTER_OPTIONS.TRAITS,
  //       TALENT_FINDER_FILTER_OPTIONS.DRIVERS,
  //       TALENT_FINDER_FILTER_OPTIONS.DERAILMENT_RISKS,
  //       TALENT_FINDER_FILTER_OPTIONS.COMPETENCIES,
  //     ].includes(key)
  //   ) {
  //     return 2.5;
  //   } else {
  //     return 2;
  //   }
  // };

  const dropDownGenerator = (item) => {
    return Object.keys(talentFilterOptions).map((key, index) => {
      if (item.value === key) {
        return (
          <DropdownTreeSelectContainer
            key={`${key}.${index}`}
            keyWord={key}
            filterTabActive={filterTabActive}
            label={item.label}
            placeholder={item.label}
            data={talentFilterOptions[key]}
            onChange={onChange}
            onNodeToggle={onNodeToggle}
            onBlur={() => onBlur(talentFilterOptions[key], key)}
            onClickSelectAll={(isChecked) => onClickSelectAll(key, isChecked)}
            isBlur={isBlur}
            onApply={() => handleClickApplyFilter(talentFilterOptions[key], key)}
            filterName={key}
            // columns={handleReturnNumberColumns(key)}
          />
        );
      }
      return null;
    });
  };

  const toggleFilter = (filterName) => {
    setShowButtonFilter(true);
    setShowFilters((prevFilters) => ({
      ...prevFilters,
      [filterName]: !prevFilters[filterName],
    }));
  };
  useEffect(() => {
    if (showFilter) {
      setShowButtonFilter(true);
      setShowFilters({
        DEMOGRAPHIC: true,
        EXPERIENCE: true,
        LEADERSHIP: true,
        EMPLOYMENT_INFO: true,
        SUCCESSION_AND_TOP_TALENT_STATUS: true,
        EXTERNAL_ASSESSMENT: true,
      });
    } else {
      setShowButtonFilter(false);
      setShowFilters({
        DEMOGRAPHIC: false,
        EXPERIENCE: false,
        LEADERSHIP: false,
        EMPLOYMENT_INFO: false,
        SUCCESSION_AND_TOP_TALENT_STATUS: false,
        EXTERNAL_ASSESSMENT: false,
      });
    }
  }, [showFilter]);
  return (
    <>
      <div className={styles.btn_container}>
        {showButtonFilter &&
          TALENT_SEARCH_RESULT_OPTIONS.map((item) => (
            <button
              key={item.value}
              className={`${styles.btn_filter} ${
                activeButton === item.value ? styles.active : ''
              } ${isDisableTalentSearchResult ? styles.btn_filter_disabled : ''}`}
              onClick={() => handleButtonClick(item.value)}
              disabled={isDisableTalentSearchResult}
              onKeyDown={() => {}}
              tabIndex={0}
            >
              {item.label}
            </button>
          ))}
      </div>
      <div className={`filter_section row p-4 ${styles.container}`}>
        {
          <>
            <div className={styles.filter_list_wrapper}>
              <div className={styles.filter_title} onClick={() => toggleFilter('DEMOGRAPHIC')}>
                {FILTER_TITLE.DEMOGRAPHIC}
                <img src={filter_talent_finder}></img>
              </div>
              {!isEmpty(talentFilterOptions) &&
                showFilters.DEMOGRAPHIC &&
                DEMOGRAPHIC_FILTERS.map((item) => dropDownGenerator(item))}
            </div>
            <div className={styles.filter_list_wrapper}>
              <div className={styles.filter_title} onClick={() => toggleFilter('EXPERIENCE')}>
                {FILTER_TITLE.EXPERIENCE}
                <img src={filter_talent_finder}></img>
              </div>
              {!isEmpty(talentFilterOptions) &&
                showFilters.EXPERIENCE &&
                EXPERIENCE_FILTERS.map((item) => dropDownGenerator(item))}
            </div>
            <div className={styles.filter_list_wrapper}>
              <div className={styles.filter_title} onClick={() => toggleFilter('LEADERSHIP')}>
                {FILTER_TITLE.LEADERSHIP}
                <img src={filter_talent_finder}></img>
              </div>
              {!isEmpty(talentFilterOptions) &&
                showFilters.LEADERSHIP &&
                LEADERSHIP_FILTERS.map((item) => dropDownGenerator(item))}
            </div>
            <div className={styles.filter_list_wrapper}>
              <div className={styles.filter_title} onClick={() => toggleFilter('EMPLOYMENT_INFO')}>
                {FILTER_TITLE.EMPLOYMENT_INFO}
                <img src={filter_talent_finder}></img>
              </div>
              {!isEmpty(talentFilterOptions) &&
                showFilters.EMPLOYMENT_INFO &&
                EMPLOYMENT_INFO_FILTERS.map((item) => dropDownGenerator(item))}
            </div>
            <div className={styles.filter_list_wrapper}>
              <div
                className={styles.filter_title}
                onClick={() => toggleFilter('SUCCESSION_AND_TOP_TALENT_STATUS')}
              >
                {FILTER_TITLE.SUCCESSION_AND_TOP_TALENT_STATUS}
                <img src={filter_talent_finder}></img>
              </div>
              {!isEmpty(talentFilterOptions) &&
                showFilters.SUCCESSION_AND_TOP_TALENT_STATUS &&
                SUCCESSION_AND_TOP_TALENT_STATUS_FILTERS.map((item) => dropDownGenerator(item))}
            </div>
            <div className={styles.filter_list_wrapper}>
              <div
                className={styles.filter_title}
                onClick={() => toggleFilter('EXTERNAL_ASSESSMENT')}
              >
                {FILTER_TITLE.EXTERNAL_ASSESSMENT}
                <img src={filter_talent_finder}></img>
              </div>
              {!isEmpty(talentFilterOptions) &&
                showFilters.EXTERNAL_ASSESSMENT &&
                EXTERNAL_ASSESSMENT_FILTERS.map((item) => dropDownGenerator(item))}
            </div>
          </>
        }
        {size(filterTabActive) > 0 && (
          <div className={'col-12 px-0'}>
            <button className={'clear_filter_button'} onClick={handleClearFilter}>
              Clear Filters
            </button>
          </div>
        )}
      </div>
    </>
  );
};

export default FilterFinder;
