import { isEmpty } from 'lodash';
import React, { useEffect, useMemo } from 'react';

import { BasicButton } from '../../../assets/common';
import {
  grid_view_disable_tf,
  grid_view_enable_tf,
  list_view_disable_tf,
  list_view_enable_tf,
  talent_finder_export,
  talent_finder_export_inactive,
} from '../../../assets/img';
import TalentExport from '../TalentExport/TalentExport';
import styles from './menu-bar.module.scss';

const VIEW_TYPE = {
  CARD: 'card',
  LIST: 'list',
};

const MenuBar = (props) => {
  const {
    showFilter,
    setShowFilter,
    mode,
    setMode,
    setKeyword,
    keyword,
    data,
    setShowError,
    setIsSearch,
    setPage,
    filterFinderData,
    role,
    totalData,
    setShowExporting,
    isFilter,
    setIsFilter,
    setKeySearch,
    keySearch,
    talentSearchResult,
  } = props;

  const viewMode = useMemo(() => {
    if (isEmpty(data)) return '';
    if (mode) return VIEW_TYPE.CARD;
    return VIEW_TYPE.LIST;
  }, [data, mode]);

  function handleSearch() {
    if (!keySearch && isEmpty(filterFinderData)) {
      setShowError(true);
      setIsSearch(false);
    } else {
      setIsSearch(true);
      setPage(1);
    }
    setKeyword(keySearch);
  }
  useEffect(() => {
    setKeySearch(keyword);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setKeyword]);

  // Update key search field value
  useEffect(() => {
    if (isFilter && isEmpty(keySearch)) {
      setKeySearch(keyword);
      setIsFilter(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFilter, keyword]);

  return (
    <div className={styles.search_section}>
      <input
        type="text"
        placeholder="Search Talent"
        className={styles.search}
        onChange={(e) => setKeySearch(e.target.value)}
        onKeyPress={(e) => {
          if (e.key === 'Enter') handleSearch();
        }}
        value={keySearch}
        data-testid="talent-finder-input"
      />
      <div className="d-flex justify-content-between align-items-end w-100">
        <div className="d-flex justify-content-between align-items-end w-100">
          <div className="d-flex">
            <img
              src={showFilter ? talent_finder_export : talent_finder_export_inactive}
              className={`${styles.filterBtn} ${!showFilter ? styles.inActive : ''}`}
              alt="Filter"
              onKeyDown={() => {}}
              onClick={() => {
                setShowFilter(!showFilter);
              }}
            />
            <BasicButton
              className={styles.button}
              height={40}
              mode="teal"
              onKeyDown={() => {}}
              onClick={handleSearch}
              data-testid="talent-finder-btn-search"
            >
              Search
            </BasicButton>
          </div>
        </div>
        <div className={styles.btnRow}>
          <TalentExport
            role={role}
            filter={filterFinderData}
            keyword={keyword}
            totalData={totalData}
            setShowExporting={setShowExporting}
            talentSearchResult={talentSearchResult}
          />

          <div className={styles.icons}>
            <img
              src={viewMode === VIEW_TYPE.CARD ? grid_view_enable_tf : grid_view_disable_tf}
              alt="Grid"
              data-testid="talent-finder-btn-grid"
              className={styles.img}
              style={viewMode === VIEW_TYPE.CARD ? { borderColor: '#00a19c' } : {}}
              onKeyDown={() => {}}
              onClick={() => {
                setMode(true);
              }}
            />
            <div className={styles.betweenBorder} style={viewMode ? { borderColor: '#00a19c' } : {}} />
            <img
              src={viewMode === VIEW_TYPE.LIST ? list_view_enable_tf : list_view_disable_tf}
              alt="List"
              className={styles.img}
              style={viewMode === VIEW_TYPE.LIST ? { borderColor: '#00a19c' } : {}}
              data-testid="talent-finder-btn-list"
              onKeyDown={() => {}}
              onClick={() => {
                setMode(false);
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MenuBar;
