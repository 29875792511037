import axiosClient from './axiosClient';
import queryString from 'query-string';
import { PERMISSION } from '../utils/constants';

export const calendarApi = {
  getMeetings: (query, role) => {
    const stringified = queryString.stringify(query);
    const url = stringified ? `/meetings?${stringified}` : '/meetings';
    return axiosClient.get(url, {
      headers: {
        role: role.roleId,
      },
    });
  },
  getBusinessUnit: (type, role) => {
    const url = '/list-bu-or-opu-by-type';
    return axiosClient.get(url, {
      params: {
        type,
      },
      headers: {
        permission: PERMISSION.VIEW_TALENT_DASHBOARD,
        role: role.roleId,
      },
    });
  },

  getPdcMeetings: (query, role) => {
    const stringified = queryString.stringify(query);
    const url = stringified ? `/meetings-pdc/get-view?${stringified}` : '/meetings-pdc/get-view';
    return axiosClient.get(url, {
      headers: {
        role: role.roleId,
      },
    });
  },
};
