import React from 'react';
import { FieldGroupStyled, Label } from './styled';

// eslint-disable-next-line react/display-name
export default React.memo(({ className, width, label, children }) => {
  return (
    <FieldGroupStyled width={width} className={className}>
      <Label>{label}</Label>
      {children}
    </FieldGroupStyled>
  );
});
