import { List, Spin } from 'antd';
import { isEmpty } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { v4 as uuidv4 } from 'uuid';

import { lookup, Rectangle } from '../../assets/img';
import useCallbackClickOutside from '../../hooks/useCallbackClickOutside';
import { InputStyle, ListAntd, TitleDiv, Wrapper, WrapperPopUp } from './SearchByCategoryForTcMeetingStyled';
import { meetingApi } from '../../services/tcMeeting';
import { useSelector } from 'react-redux';
import { MEETING_CATEGORY_VALUES } from '../../utils/constants';

const titleHeaderList = [
  {
    id: '1',
    name: 'Business',
  },
  {
    id: '2',
    name: 'Sector',
  },
  {
    id: '3',
    name: 'OPU',
  },
  {
    id: '4',
    name: 'Division',
  },
];

const titleSKGHeaderList = [
  {
    id: '1',
    name: 'SKG',
  },
];

const titleJobFamilyHeaderList = [
  {
    id: '1',
    name: 'Job Family',
  },
];

const SearchByCategoryForTcMeeting = (props) => {
  const { setValue, openDropdown, setOpenDropdown, searchName, fieldValue, status, disabled } = props;
  const { roleId } = useSelector((state) => state.user.roleActive);

  const [loading, setLoading] = useState(false);
  const [query, setQuery] = useState({ search: '', page: 1, data: [] });
  const [inputValue, setInputValue] = useState(fieldValue || null);
  const [debouncedInputValue, setDebouncedInputValue] = useState('');
  const [isMount, setIsMount] = useState(false);
  const [hasMore, setHasmore] = useState(true);

  const { ref, isComponentVisible, setIsComponentVisible } = useCallbackClickOutside({
    initialIsVisible: openDropdown,
    cbFunction: () => {
      setOpenDropdown(false);
    },
  });

  useEffect(() => {
    setInputValue(null);
  }, [searchName]);

  // Init data
  useEffect(() => {
    setInputValue(fieldValue?.name || null);
    setIsComponentVisible(false);
  }, [fieldValue]);

  useEffect(() => {
    const delayInputTimeoutId = setTimeout(() => {
      setDebouncedInputValue(inputValue);
    }, 1000);
    return () => clearTimeout(delayInputTimeoutId);
  }, [inputValue]);

  useEffect(() => {
    const element = document.getElementById('scrollableDiv');
    if (element) {
      element.scrollTo({
        top: 0,
      });
      setQuery(() => ({ page: 1, search: debouncedInputValue, data: [] }));
    }
  }, [debouncedInputValue]);

  const loadMoreData = async (page, keyword) => {
    if (loading) {
      return;
    }

    try {
      setLoading(true);
      const params = {
        is_include_roles: false,
        is_full_category_dropdown: true,
        search_text: keyword,
        meeting_category: searchName,
        page,
        limit: 15,
      };
      const res = await meetingApi.getMeetingSearchByCategory(params, roleId);
      const result = res.data.result?.data;
      if (page > 1) {
        setHasmore(true);
        setQuery((prev) => ({ ...prev, data: [...prev.data, ...result] }));
      } else {
        setQuery(() => ({ page: 1, search: keyword, data: !isEmpty(result) ? result : [] }));
      }
    } catch (error) {
      console.error(error);
      setQuery(() => ({ page: 1, search: '', data: [] }));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isComponentVisible && isMount) {
      loadMoreData(query.page, query.search);
    }
  }, [query.page, query.search, isComponentVisible]);

  useEffect(() => {
    if (isComponentVisible && !isMount) {
      loadMoreData(1, '');
      setIsMount(true);
    }
  }, [isMount, isComponentVisible]);

  const getClassActiveColumn = (value) => {
    return searchName === value ? 'function-name' : '';
  };

  const handleReturnValue = (record, isEmit) => {
    let tempVal = '';
    const tempCategoryName = `${record?.business_unit}__${record?.sector}__${record?.opu_name}__${record?.division}`;
    switch (searchName) {
      case MEETING_CATEGORY_VALUES.BUSINESS:
        tempVal = !isEmit
          ? record.business_unit || '-'
          : { id: record?.dev_bu_unit_id, name: record?.business || record?.business_unit || '-', category_label: tempCategoryName };
        break;
      case MEETING_CATEGORY_VALUES.SECTOR:
        tempVal = !isEmit ? record.sector || '-' : { id: record?.sector_id, name: record?.sector || '-', category_label: tempCategoryName };
        break;
      case MEETING_CATEGORY_VALUES.OPU:
        tempVal = !isEmit
          ? record.opu_name || '-'
          : { id: record?.dev_opu_id, name: record?.opu || record?.opu_name || '-', category_label: tempCategoryName };
        break;
      case MEETING_CATEGORY_VALUES.DIVISION:
        tempVal = !isEmit ? record.division || '-' : { id: record?.dev_opu_id, name: record?.division || '-', category_label: tempCategoryName };
        break;
      case MEETING_CATEGORY_VALUES.SKG:
      case MEETING_CATEGORY_VALUES.JOB_FAMILY:
        tempVal = !isEmit ? record?.name : { id: record?.id, name: record?.name };
        break;
      default:
        break;
    }
    return tempVal;
  };

  const getColumnNumber = useMemo(() => {
    if (![MEETING_CATEGORY_VALUES.PETRONAS, MEETING_CATEGORY_VALUES.SKG, MEETING_CATEGORY_VALUES.JOB_FAMILY].includes(searchName)) {
      return 4;
    } else {
      return 1;
    }
  }, [query.data, searchName]);

  const handleReturnTableContent = (record) => {
    let tempHTML;
    switch (searchName) {
      case MEETING_CATEGORY_VALUES.PETRONAS:
        tempHTML = <div className={getClassActiveColumn('Role')}>{record.role || '-'}</div>;
        break;
      case MEETING_CATEGORY_VALUES.BUSINESS:
      case MEETING_CATEGORY_VALUES.OPU:
      case MEETING_CATEGORY_VALUES.DIVISION:
      case MEETING_CATEGORY_VALUES.SECTOR:
        tempHTML = (
          <>
            <div className={getClassActiveColumn('Business')}>{record.business || record?.business_unit || '-'}</div>
            <div className={getClassActiveColumn('Sector')}>{record.sector || '-'}</div>
            <div className={getClassActiveColumn('OPU')}>{record.opu || record?.opu_name || '-'}</div>
            <div className={getClassActiveColumn('Division')}>{record.division || '-'}</div>
          </>
        );
        break;
      case MEETING_CATEGORY_VALUES.SKG:
        tempHTML = <div className={getClassActiveColumn('SKG')}>{record.name || '-'}</div>;
        break;
      case MEETING_CATEGORY_VALUES.JOB_FAMILY:
        tempHTML = <div className={getClassActiveColumn('Job Family')}>{record.name || '-'}</div>;
        break;
      default:
        break;
    }
    return tempHTML;
  };

  const handleOpenSearchDropdown = () => {
    setIsComponentVisible(true);
    setQuery(() => ({ page: 1, search: '', data: [] }));
  };

  return (
    <Wrapper ref={ref}>
      <InputStyle
        onClick={() => handleOpenSearchDropdown()}
        isError={false}
        prefix={
          !fieldValue ? (
            <img
              src={lookup}
              style={{
                filter: 'brightness(0) saturate(100%) invert(56%) sepia(1%) saturate(0%) hue-rotate(229deg) brightness(99%) contrast(98%)',
                pointerEvents: 'all',
                cursor: 'pointer',
                width: '100%',
                height: '100%',
                padding: '0 5px',
              }}
              alt="lookup"
              onClick={() => handleOpenSearchDropdown()}
              value={inputValue}
            />
          ) : (
            <></>
          )
        }
        suffix={
          <img
            src={Rectangle}
            style={{
              filter: 'brightness(0) saturate(100%) invert(56%) sepia(1%) saturate(0%) hue-rotate(229deg) brightness(99%) contrast(98%)',
              pointerEvents: 'all',
              cursor: 'pointer',
              width: '100%',
              height: '100%',
              padding: '0 5px',
            }}
            alt="Rectangle"
            onClick={() => handleOpenSearchDropdown()}
          />
        }
        placeholder={`Search by ${searchName}`}
        onChange={(e) => setInputValue(e.target.value)}
        value={inputValue}
        status={status}
        disabled={disabled}
      />
      {isComponentVisible && (
        <WrapperPopUp>
          <TitleDiv columnNumber={getColumnNumber}>
            {[
              MEETING_CATEGORY_VALUES.BUSINESS,
              MEETING_CATEGORY_VALUES.OPU,
              MEETING_CATEGORY_VALUES.DIVISION,
              MEETING_CATEGORY_VALUES.SECTOR,
            ].includes(searchName) &&
              titleHeaderList.map((item, index) => (
                <div key={index} style={item.name === searchName ? { backgroundColor: '#C9D5E3' } : {}}>
                  {item.name}
                </div>
              ))}
            {[MEETING_CATEGORY_VALUES.SKG].includes(searchName) &&
              titleSKGHeaderList.map((item, index) => (
                <div key={index} style={item.name === searchName ? { backgroundColor: '#C9D5E3' } : {}}>
                  {item.name}
                </div>
              ))}
            {[MEETING_CATEGORY_VALUES.JOB_FAMILY].includes(searchName) &&
              titleJobFamilyHeaderList.map((item, index) => (
                <div key={index} style={item.name === searchName ? { backgroundColor: '#C9D5E3' } : {}}>
                  {item.name}
                </div>
              ))}
          </TitleDiv>
          <div
            id="scrollableDiv"
            style={{
              maxHeight: '264px',
              overflow: 'auto',
              width: '100%',
              backgroundColor: 'white',
            }}
          >
            <InfiniteScroll
              dataLength={query.data.length}
              next={() => {
                setQuery((prev) => ({ ...prev, page: prev.page + 1 }));
              }}
              hasMore={hasMore}
              loader={
                loading && (
                  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50px' }}>
                    <Spin loading={loading} />
                  </div>
                )
              }
              scrollableTarget="scrollableDiv"
            >
              <ListAntd
                columnNumber={getColumnNumber}
                dataSource={query.data}
                split={false}
                renderItem={(item) => (
                  <List.Item
                    key={uuidv4()}
                    onClick={() => {
                      setIsComponentVisible(false);
                      setValue({ val: handleReturnValue(item, true) });
                      setInputValue(handleReturnValue(item, false));
                    }}
                  >
                    {handleReturnTableContent(item)}
                  </List.Item>
                )}
              />
            </InfiniteScroll>
          </div>
        </WrapperPopUp>
      )}
    </Wrapper>
  );
};
export default SearchByCategoryForTcMeeting;
