import { Checkbox } from 'antd';
import React, { useLayoutEffect, useState } from 'react';

import * as SC from './styled';

const CheckBoxList = (props) => {
  const { checkedList, setCheckedList, options, label } = props;
  const [checkAll, setCheckAll] = useState(false);

  const onChange = (isChecked, val) => {
    setCheckedList([val]);
  };

  const onCheckAllChange = (e) => {
    setCheckedList(e.target.checked ? options : []);
    setCheckAll(e.target.checked);
  };

  useLayoutEffect(() => {
    setCheckAll(checkedList.length === options.length);
  }, [checkedList, options]);

  return (
    <SC.Dropdown>
      <div>{label}</div>
      <Checkbox onChange={onCheckAllChange} checked={checkAll}>
        All
      </Checkbox>
      {options.map((m) => (
        <Checkbox
          key={m}
          onChange={(e) => onChange(e.target.checked, m)}
          checked={checkedList.includes(m)}
        >
          {m}
        </Checkbox>
      ))}
      {/* <CheckboxGroup options={options} value={checkedList} onChange={onChange} /> */}
    </SC.Dropdown>
  );
};

export default CheckBoxList;
