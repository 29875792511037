import React from 'react';
import { CALENDAR } from '../../../../utils/constants';
import { Container } from './styled';
import { useLocation } from 'react-router-dom';

const MeetingStatus = (props) => {
  const { selected, setSelected } = props;
  const location = useLocation();
  const navbar = location.pathname === '/pdc' ? CALENDAR.PDC_MEETING_TYPE : CALENDAR.MEETING_TYPE;

  return (
    <Container>
      <div className="meeting-status" data-testid="meeting-status">
        {navbar.map((d, index) => (
          <div
            className={d.param.includes(selected) ? 'active' : null}
            onKeyDown={() => {}}
            onClick={() => setSelected(d.param)}
            key={index}
            data-testid="meeting-status-click"
          >
            {d.display}
          </div>
        ))}
      </div>
    </Container>
  );
};

export default MeetingStatus;
