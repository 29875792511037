import React from 'react';
import styles from './check-box.module.scss';

const Checkbox = (props) => {
  const { item, select, setSelect, filterName } = props;

  const handleChange = (e) => {
    const { name, checked } = e.target;
    if (name === 'all') {
      let tempSelect = select.map((ele) => {
        return { ...ele, isChecked: checked };
      });
      setSelect(tempSelect);
    } else {
      let tempSelect = select.map((ele) => {
        return ele.value === name ? { ...ele, isChecked: checked } : ele;
      });
      setSelect(tempSelect);
    }
  };

  const handleChecked = () => {
    if (item.value === 'all') {
      return !select.some((ele) => ele?.isChecked !== true);
    } else {
      return item?.isChecked || false;
    }
  };

  return (
    <div
      className={`${props?.customStyle ? `${styles.customCheckbox}` : styles.checkbox} ${
        item?.disabled ? styles.disabled : ''
      }`}
    >
      <input
        type="checkbox"
        id={`${item.value}_${filterName || ''}`}
        className={styles.checkbox__input}
        name={item.value}
        onChange={(e) => handleChange(e)}
        checked={handleChecked()}
        disabled={item?.disabled}
      />
      <label
        htmlFor={`${item.value}_${filterName || ''}`}
        className={`${styles.checkbox__label} ${!item.label ? styles.checkbox__labelEmpty : ''}`}
      >
        {item.label}
      </label>
    </div>
  );
};
export default Checkbox;
