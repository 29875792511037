import styled from 'styled-components';

export const Wrapper = styled.div`
  margin-bottom: 30px;
  .ant-collapse-header {
    padding: 0 !important;
    background: white !important;
  }
  .ant-collapse-content-box {
    padding: 0 !important;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #00a19c;
    border-color: #00a19c;
  }
  .custom-range-picker {
    height: 100%;
    border-radius: 6px;
  }
  .ant-picker-input {
    padding-left: 20px !important;
  }
  .checked {
    position: absolute;
    left: 10px;
    top: 11px;
    z-index: 10;
  }
  .ant-picker-header-view {
    white-space: nowrap;
    color: var(--emphasis-body-n-800, #3f3c4c);
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 150% */
  }
  .image-custom {
    padding: 8px;
    background-color: #fff;
    border-radius: 50%;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  }

  .ant-picker-header,
  .ant-picker-body {
    border: none;
  }

  .footer {
    margin: 10px;
    display: flex;
    justify-content: flex-end;
  }

  .ant-picker-cell-in-view.ant-picker-cell-in-range::before {
    background: rgba(52, 69, 99, 0.2);
  }
  .ant-picker-cell-in-view.ant-picker-cell-range-end::before,
  .ant-picker-cell-in-view.ant-picker-cell-range-start::before {
    background: rgba(52, 69, 99, 0.2);
  }

  .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single):not(
      .ant-picker-cell-range-start
    )
    .ant-picker-cell-inner,
  .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single):not(
      .ant-picker-cell-range-end
    )
    .ant-picker-cell-inner,
  .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
  .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
  .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
    border-radius: 50%;
    background: #00a19c;
  }
`;
