import styled from 'styled-components';
import { font } from '../../assets/common';

export const ProposalTitle = styled.div`
  font-family: ${font.inter};
  color: #3f3c4c;
  font-size: 16px;
  font-weight: 700;
  line-height: 19.36px;
  letter-spacing: 0.30000001192092896px;
`;
export const PoposalTypeWrapper = styled.div`
  .ant-select-selector {
    border: 1px solid #d9d9d9 !important;
    border-color: ${(props) => props?.hasError && '#da1e28 !important'};
  }
`;
export const PoposalWrapper = styled.div`
  text-align: left;
  padding: 20px;
  .ant-select-tree {
    padding: 12px 0;
  }
`;
export const ProposalListWrapper = styled.div`
  padding-top: 20px;
`;
