import React, { useEffect, useRef, useState } from 'react';
import DropdownTreeSelect from 'react-dropdown-tree-select';

import { circle_checked } from '../../../assets/img';
import { SELECT_ALL_LABEL, TALENT_FINDER_FILTER_OPTIONS } from '../../../utils/constants';
import { BasicButton } from '../../../assets/common';

const DropdownTreeSelectContainer = (props) => {
  const {
    data,
    onChange,
    onBlur,
    onApply,
    label,
    placeholder,
    filterTabActive,
    keyWord,
    onNodeToggle,
    filterName,
    onClickSelectAll,
    minWidth,
    applyClass,
    // columns = 2,
  } = props;
  const [showApply, setShowApply] = useState(false);
  const [applyStyle, setApplyStyle] = useState({
    width: 0,
    top: 0,
  });
  const dropdownRef = useRef(null);

  const handleOnAction = (_node, _action) => {
    document.querySelector('.dropdown').click();
    onApply();
  };

  const handleOnChange = (currentNode) => {
    if (currentNode.label === SELECT_ALL_LABEL) {
      onClickSelectAll(currentNode.checked);
      return;
    }
    onChange(currentNode);
  };

  const getClassToAlignWidth = () => {
    return minWidth ? `min-width-${minWidth}` : ``;
  };

  useEffect(() => {
    const checkDropdownContent = () => {
      const dropdownContent = dropdownRef.current.querySelector('.dropdown-content');
      setShowApply(dropdownContent !== null);
      if (dropdownContent) {
        const HEIGHT_APPLY = 52;
        const WIDTH_SCROLL = 2;
        const heightInput = dropdownRef.current.querySelector('.filter_button').offsetHeight;
        setApplyStyle({
          width: Math.floor(dropdownContent.offsetWidth - WIDTH_SCROLL),
          top: Math.floor(dropdownContent.offsetHeight - HEIGHT_APPLY + heightInput),
        });
      }
    };

    checkDropdownContent();

    const observer = new MutationObserver(checkDropdownContent);
    observer.observe(dropdownRef.current, { childList: true, subtree: true });

    return () => observer.disconnect();
  }, [dropdownRef]);

  return (
    <div className={`filter_wrapper ${applyClass}`} ref={dropdownRef} style={{ minWidth: `${minWidth}px` }}>
      <button
        className={`filter_button ${filterTabActive.includes(keyWord) ? 'active' : ''}`}
        style={{
          paddingTop: `${minWidth ? '11px' : '9px'}`,
          paddingBottom: `${minWidth ? '11px' : '9px'}`,
        }}
      >
        {filterTabActive.includes(keyWord) && <img src={circle_checked} alt="nofication" />}
        {!filterTabActive.includes(keyWord) ? placeholder : label}
      </button>
      <DropdownTreeSelect
        data={data}
        onNodeToggle={onNodeToggle}
        onAction={handleOnAction}
        onChange={handleOnChange}
        onBlur={onBlur}
        expanded={false}
        checked={false}
        className={
          filterName === TALENT_FINDER_FILTER_OPTIONS.AGE || filterName === TALENT_FINDER_FILTER_OPTIONS.GENDER
            ? 'mdl-demo fit-content'
            : `mdl-demo ${getClassToAlignWidth()}`
        }
      />
      {showApply && (
        <div className="btn-apply" style={{ width: `${applyStyle.width}px`, top: `${applyStyle.top}px` }}>
          <BasicButton onClick={handleOnAction} mode="teal">
            Apply
          </BasicButton>
        </div>
      )}
    </div>
  );
};

export default DropdownTreeSelectContainer;
