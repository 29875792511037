import { Pagination, Row } from 'antd';
import React from 'react';
import styles from './talent-finder-pagination.module.scss';

// eslint-disable-next-line react/display-name
export default React.memo(({ currentPage, totalResult, limit, startRecordNumber, endRecordNumber, onPageChange }) => (
  <Row justify="space-between" align="middle" style={{ width: '100%' }}>
    <Row className={styles.label}>
      Showing <b>&nbsp;{startRecordNumber}&nbsp;</b> to {endRecordNumber} of {totalResult} results
    </Row>
    <Pagination showSizeChanger={false} pageSize={limit} current={currentPage} total={totalResult} onChange={onPageChange} />
  </Row>
));
