/* eslint-disable react-hooks/exhaustive-deps */
import { cloneDeep, isEmpty, isEqual, uniqueId } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { editNote, ellipsis, redClose } from '../../../../../assets/img';
import { useComponentVisible } from '../../../../../hooks';
import { adminApi } from '../../../../../services/admin';
import { ACCESS_LEVEL, PERMISSION, USER_ID } from '../../../../../utils/constants';
import { DropdownUserAccess } from '../DropdownUserAccess/DropdownUserAccess';
import styles from './role-table.module.scss';

const AccessLevel = (props) => {
  const { accessList, setAccessList, userInfo } = props;
  const updateAccessLevel = (value, id) => {
    setAccessList(() => {
      const accessLevelList = cloneDeep(accessList);
      return accessLevelList.map((m) => ({
        ...m,
        accessLevel: m.id === id ? value : m.accessLevel,
        businessAccessName:
          m.id === id && m.accessLevel !== value
            ? getBusinessAccessNameDefault(value)
            : m.businessAccessName,
        businessType: m.id === id ? value : m.accessLevel,
      }));
    });
  };

  const onDeleteAccess = (id) => {
    const cloneAccessList = cloneDeep(accessList);
    setAccessList(cloneAccessList.filter((f) => f.id !== id));
  };

  const getBusinessAccessNameDefault = (accessLevel) => {
    if (accessLevel === 'PETRONAS') return 'PETRONAS';
    if (accessLevel === 'Business') return userInfo.businessUnit;
    if (accessLevel === 'OPU') return userInfo.opu;
    if (accessLevel === 'Division') return userInfo.division;
    if (accessLevel === 'Sector') return userInfo.sector;
    if (accessLevel === 'SKG') return userInfo.skg;
    if (accessLevel === 'Job Family') return userInfo.jobFamily;
  };

  const listItems = accessList?.map((item) => (
    <li
      key={`access-list-${item?.id}`}
      className="mb-2 d-flex justify-content-start"
      style={{ gap: '10px' }}
    >
      <DropdownUserAccess
        selected={item?.accessLevel}
        setSelected={(e) => updateAccessLevel(e, item?.id)}
        options={ACCESS_LEVEL.map((m) => m.name)}
        id={item?.id}
      />
      {accessList?.length > 1 && (
        <>
          <div
            className={styles.btn_remove}
            onKeyDown={() => {}}
            onClick={() => onDeleteAccess(item?.id)}
          >
            <img src={redClose} alt="remove access level" />
          </div>
        </>
      )}
    </li>
  ));
  return <ul className={styles.menu_ul}>{listItems}</ul>;
};

const BusinessAccess = (props) => {
  const { accessList, setAccessList, businessAccessOptions } = props;
  const updateBusinessAccess = (value, id) => {
    setAccessList(() => {
      const accessLevelList = cloneDeep(accessList);
      return accessLevelList.map((m) => ({
        ...m,
        businessAccessName: m.id === id ? value : m.businessAccessName,
      }));
    });
  };

  const onDeleteAccess = (id) => {
    const cloneAccessList = cloneDeep(accessList);
    setAccessList(cloneAccessList.filter((f) => f.id !== id));
  };

  const getBusinessAccessOptions = (accessLevel) => {
    if (accessLevel === 'PETRONAS') return [];
    const businessAccess = businessAccessOptions.find((f) => isEqual(f.category, accessLevel));
    if (accessLevel === 'Business') return businessAccess.label;
    if (accessLevel === 'OPU') return businessAccess.label;
    if (accessLevel === 'Division') return businessAccess.label;
    if (accessLevel === 'Sector') return businessAccess.label;
    if (accessLevel === 'SKG') return businessAccess.label.map((m) => m.name);
    if (accessLevel === 'Job Family') return businessAccess.label.map((m) => m.name);
  };

  const listItems = accessList?.map((item) => (
    <li
      key={`business-list-${item?.id}`}
      className="mb-2 d-flex justify-content-start"
      style={{ gap: '10px' }}
    >
      <DropdownUserAccess
        selected={item?.businessAccessName || ''}
        setSelected={(e) => updateBusinessAccess(e, item?.id)}
        options={getBusinessAccessOptions(item?.accessLevel) || []}
        type={item.accessLevel}
        isSearchable={['SKG', 'Job Family'].includes(item.accessLevel)}
        id={item?.id}
      />
      {accessList?.length > 1 && (
        <>
          <div
            className={styles.btn_remove}
            onKeyDown={() => {}}
            onClick={() => onDeleteAccess(item?.id)}
          >
            <img src={redClose} alt="remove access level" />
          </div>
        </>
      )}
    </li>
  ));
  return <ul className={styles.menu_ul}>{listItems}</ul>;
};

const Roles = (props) => {
  const {
    item,
    index,
    length,
    isSelected,
    handleSelected,
    rolesEdit,
    userInfo,
    businessAccessOptions,
    updateRoleList,
  } = props;

  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);

  const [accessList, setAccessList] = useState(() => {
    if (isEmpty(rolesEdit) && isEmpty(item.accessLevels)) {
      return [
        {
          id: uniqueId(),
          accessLevel: ACCESS_LEVEL[0].name,
          businessAccessName: ACCESS_LEVEL[0].name,
          businessType: ACCESS_LEVEL[0].name,
        },
      ];
    }
    if (isEmpty(rolesEdit) && !isEmpty(item.accessLevels)) {
      return item.accessLevels.map((item) => {
        if (item === ACCESS_LEVEL[0].name)
          return {
            id: uniqueId(),
            accessLevel: item,
            businessAccessName: ACCESS_LEVEL[0].name,
            businessType: ACCESS_LEVEL[0].name,
          };
        return { id: uniqueId(), accessLevel: item, businessAccessName: '', businessType: item };
      });
    }
    if (!isEmpty(rolesEdit)) {
      const currentRole = rolesEdit.find((f) => f.id === item.id)?.userAccessLevels;
      if (!isEmpty(currentRole))
        return (item.accessLevels = currentRole.map((item) => {
          return { id: uniqueId(), ...item };
        }));
      if (isEmpty(currentRole))
        if (isEmpty(item.accessLevels))
          return [
            {
              id: uniqueId(),
              accessLevel: ACCESS_LEVEL[0].name,
              businessAccessName: ACCESS_LEVEL[0].name,
              businessType: ACCESS_LEVEL[0].name,
            },
          ];
      return item.accessLevels.map((item) => {
        if (item === ACCESS_LEVEL[0].name)
          return {
            id: uniqueId(),
            accessLevel: item,
            businessAccessName: ACCESS_LEVEL[0].name,
            businessType: ACCESS_LEVEL[0].name,
          };
        return { id: uniqueId(), accessLevel: item, businessAccessName: '', businessType: item };
      });
    }
  });

  useEffect(() => {
    // eslint-disable-next-line no-unused-vars
    updateRoleList({ id: item.id, userAccessLevels: accessList.map(({ id, ...m }) => m) });
  }, [accessList]);
  return (
    <tr className={isSelected ? `${styles.table_body} ${styles.isSelected}` : styles.table_body}>
      <td className="d-flex justify-content-start align-items-center" style={{ width: '10%' }}>
        <input
          id={`role_${index}`}
          type="checkbox"
          onChange={() => handleSelected(item.id)}
          value={isSelected}
          checked={isSelected}
        />
      </td>
      <td className={styles.nameBody} style={{ width: '30%' }}>
        {item?.fullName}
      </td>
      <td className="d-flex justify-content-center" style={{ width: '30%' }}>
        <AccessLevel accessList={accessList} setAccessList={setAccessList} userInfo={userInfo} />
      </td>
      <td className={styles.nameBody} style={{ width: '20%' }}>
        <BusinessAccess
          accessList={accessList}
          setAccessList={setAccessList}
          businessAccessOptions={businessAccessOptions}
        />
      </td>
      <td style={{ width: '10%', position: 'relative' }} ref={ref}>
        <img
          className={styles.ellipsis}
          src={ellipsis}
          alt="ellipsis"
          onKeyDown={() => {}}
          onClick={() => setIsComponentVisible(!isComponentVisible)}
        />

        {isComponentVisible && (
          <div
            className={
              index + 1 === length
                ? `${styles.dropdownContent} ${styles.lastItem}`
                : styles.dropdownContent
            }
          >
            <div
              className={styles.function}
              onKeyDown={() => {}}
              onClick={() => {
                setIsComponentVisible(false);
                setAccessList((prev) => [
                  ...prev,
                  {
                    id: uniqueId('business-access'),
                    accessLevel: ACCESS_LEVEL[0].name,
                    businessAccessName: ACCESS_LEVEL[0].name,
                    businessType: ACCESS_LEVEL[0].name,
                  },
                ]);
              }}
            >
              <img src={editNote} className={`mr-2 ${styles.editIcon}`} alt="editNote" />
              <div className={styles.content_edit}>Add Access</div>
            </div>
          </div>
        )}
      </td>
    </tr>
  );
};

export function RoleTable(props) {
  const { userInfo, selected, setSelected, setRoles, rolesEdit } = props;
  const roleActive = useSelector((state) => state.user.roleActive);
  const [roleList, setRoleList] = useState([]);
  const [businessAccessOptions, setBusinessAccessOptions] = useState([]);
  useEffect(() => {
    if (!roleActive.roleId) return;
    async function fetchApi() {
      const roles = {
        role: roleActive,
        id: USER_ID,
        permission: PERMISSION.AD_USER_ACCESS_MANAGEMENT,
      };

      await Promise.allSettled([
        adminApi.getAllRoleForCreateUser(roles),
        adminApi.getOpuDivisionDropdownUserRoles(roles),
        // adminApi.getAllOpuAndDivsion(roles),
        // adminApi.getJobFamilies(roles),
        // adminApi.getBusinessUnit(roles),
      ])
        .then((results) => {
          if (results[0].status === 'fulfilled') {
            let data =
              results[0].value.data.result.map((m) => ({
                ...m,
                userAccessLevels: isEmpty(m.accessLevels)
                  ? [
                      {
                        accessLevel: 'PETRONAS',
                        businessType: 'PETRONAS',
                        businessAccessName: 'PETRONAS',
                      },
                    ]
                  : m.accessLevels.map((ac) => {
                      if (ac === 'PETRONAS')
                        return [
                          {
                            accessLevel: 'PETRONAS',
                            businessType: 'PETRONAS',
                            businessAccessName: 'PETRONAS',
                          },
                        ];
                      if (ac === 'Business')
                        return [
                          {
                            accessLevel: 'Business',
                            businessType: 'Business',
                            businessAccessName: userInfo.businessUnit,
                          },
                        ];
                      if (ac === 'OPU')
                        return [
                          {
                            accessLevel: 'OPU',
                            businessType: 'OPU',
                            businessAccessName: userInfo.opu,
                          },
                        ];
                      if (ac === 'Division')
                        return [
                          {
                            accessLevel: 'Division',
                            businessType: 'Division',
                            businessAccessName: userInfo.division,
                          },
                        ];
                      if (ac === 'Sector')
                        return [
                          {
                            accessLevel: 'Sector',
                            businessType: 'Sector',
                            businessAccessName: userInfo.sector,
                          },
                        ];
                      if (ac === 'SKG')
                        return [
                          {
                            accessLevel: 'SKG',
                            businessType: 'SKG',
                            businessAccessName: userInfo.skg,
                          },
                        ];
                      if (ac === 'Job Family')
                        return [
                          {
                            accessLevel: 'Job Family',
                            businessType: 'Job Family',
                            businessAccessName: userInfo.jobFamily,
                          },
                        ];
                    }),
              })) || [];
            setRoleList(data);
          }
          if (results[1].status === 'fulfilled') {
            setBusinessAccessOptions(results[1].value.data.result);
          }
          // setOpuDivisionList(results[1].status === 'fulfilled' ? results[1].value.data.result : []);
          // setJobFamilyList(results[3].status === 'fulfilled' ? results[3].value.data.result : []);
          // setBusinessUnitList(results[3].status === 'fulfilled' ? results[3].value.data.result : []);
        })
        .catch((error) => {
          console.error(Promise.resolve(error));
        });
    }

    fetchApi();
  }, [roleActive.roleId]);

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = roleList.map((row) => {
        return row.id;
      });
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleSelected = (_id) => {
    const selectedIndex = selected.indexOf(_id);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, _id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const convertToGetBusinessAccessId = (userAccessLevels) => {
    return userAccessLevels.map((m) => {
      if (m.businessType === 'PETRONAS') return { ...m, businessAccessId: null };
      const businessAccess = businessAccessOptions.find(
        (f) => f.category === m.businessType
      )?.label;
      if (isEmpty(businessAccess)) return { ...m, businessAccessId: null };
      if (m.businessType === 'Business')
        return {
          ...m,
          businessAccessId:
            businessAccess.find((f) => f.business_unit === m.businessAccessName)?.dev_bu_unit_id ||
            null,
        };
      if (m.businessType === 'OPU')
        return {
          ...m,
          businessAccessId:
            businessAccess.find((f) => f.opu_name === m.businessAccessName)?.dev_opu_id || null,
        };
      if (m.businessType === 'Division')
        return {
          ...m,
          businessAccessId:
            businessAccess.find((f) => f.division === m.businessAccessName)?.dev_div_id || null,
        };
      if (m.businessType === 'Sector')
        return {
          ...m,
          businessAccessId:
            businessAccess.find((f) => f.sector === m.businessAccessName)?.sector_id || null,
        };
      if (m.businessType === 'SKG')
        return {
          ...m,
          businessAccessId: businessAccess.find((f) => f.name === m.businessAccessName)?.id || null,
        };
      if (m.businessType === 'Job Family')
        return {
          ...m,
          businessAccessId: businessAccess.find((f) => f.name === m.businessAccessName)?.id || null,
        };
    });
  };

  function updateRoleList({ id, userAccessLevels }) {
    setRoleList((prev) =>
      [...prev].map((m) => ({
        ...m,
        userAccessLevels: m.id === id ? userAccessLevels : m.userAccessLevels,
      }))
    );
  }
  useEffect(() => {
    setRoles(
      roleList
        .filter((f) => selected.includes(f.id))
        // eslint-disable-next-line no-unused-vars
        .map(({ accessLevels, fullName, ...m }) => ({
          ...m,
          userAccessLevels: convertToGetBusinessAccessId(m.userAccessLevels),
        }))
    );
  }, [selected, roleList]);

  return (
    <div className={styles.table}>
      <table>
        <thead>
          <tr className={styles.table_header}>
            <td
              className="d-flex justify-content-start align-items-center"
              style={{ width: '10%' }}
            >
              <input
                data-testid="checkbox-all"
                type="checkbox"
                onChange={(e) => handleSelectAllClick(e)}
                value={
                  selected.length > 0 && roleList.length > 0 && selected.length === roleList.length
                }
                checked={
                  selected.length > 0 && roleList.length > 0 && selected.length === roleList.length
                }
              />
            </td>
            <td className={styles.nameHeader} style={{ width: '30%' }}>
              Role
            </td>
            <td style={{ width: '30%' }}>Access Level</td>
            <td className={styles.nameHeader} style={{ width: '30%' }}>
              Business Access
            </td>
          </tr>
        </thead>
        <tbody>
          {!isEmpty(roleList) && !isEmpty(businessAccessOptions) ? (
            roleList.map((item, index) => {
              const isSelected = selected.indexOf(item.id) !== -1;
              return (
                <Roles
                  key={item?.id}
                  item={item}
                  index={index}
                  length={roleList.length}
                  isSelected={isSelected}
                  handleSelected={handleSelected}
                  userInfo={userInfo}
                  rolesEdit={rolesEdit}
                  businessAccessOptions={businessAccessOptions}
                  updateRoleList={updateRoleList}
                />
              );
            })
          ) : (
            <>
              <tr>
                <td colSpan={5}>
                  <div>No data</div>
                </td>
              </tr>
            </>
          )}
        </tbody>
      </table>
    </div>
  );
}
