import { Dropdown, Space } from 'antd';
import { isEmpty } from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import { AiButton, BasicButton, BasicInput, BasicTable } from '../../../../../assets/common';
import { filter_agenda_active, filter_agenda_inactive, sort_teal } from '../../../../../assets/img';
import ConfirmModalCopyToAgenda from '../../../../../components/ConfirmModalCopyToAgenda/ConfirmModalCopyToAgenda';
import CustomAntModal from '../../../../../components/CustomAntModal/CustomAntModal';
import Pill from '../../../../../components/FilterPill/Pill';
import { meetingApi } from '../../../../../services/tcMeeting';
import { AGENDA_SHORT_TYPES, AGENDA_TYPES, SORT_TYPE } from '../../../../../utils/constants';
import { paginationConfig } from '../../../../../utils/helper';
import * as styles from './popup-list-concluded-agenda.module.scss';

const INIT_SEARCH_PARAMS = {
  search: '',
  page: 1,
  limit: 15,
  status: '',
  sort_order: SORT_TYPE.DESC,
};

const PopupListConcludedAgenda = ({
  type,
  agendaDetail,
  setAgendaAsMain,
  agendaAsMain,
  handleSetAsMain,
  meetingId,
  addedStaffId,
  addedPositionCode,
  ...otherProps
}) => {
  const { roleId } = useSelector((state) => state.user.roleActive);
  const [open, setOpen] = useState(false);
  const [searchParams, setSearchParams] = useState(INIT_SEARCH_PARAMS);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({ agendas: [], total: 0 });
  const [openCopyRemark, setOpenCopyRemark] = useState({ open: false, record: null });
  const inputRef = useRef(null);

  const fetchData = useCallback(async () => {
    try {
      setLoading(true);
      let tempParams = {};
      switch (type) {
        case AGENDA_TYPES.TR_AGENDA:
          tempParams = { selected_staff_id: addedStaffId || '' };
          break;
        case AGENDA_TYPES.SP_AGENDA:
          tempParams = {
            selected_position_codes: !isEmpty(addedPositionCode)
              ? addedPositionCode.toString()
              : '',
          };
          break;
        default:
          break;
      }
      const res = await meetingApi.getListConcludedAgendas(
        {
          ...searchParams,
          ...tempParams,
          type:
            type === AGENDA_TYPES.SP_AGENDA
              ? AGENDA_SHORT_TYPES.SUCCESSION_PLANNING
              : AGENDA_SHORT_TYPES.TALENT_REVIEW,
          meeting_id: meetingId || '',
        },
        roleId
      );
      if (res.status === 200) {
        setData(res.data.result);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, [searchParams, type, addedStaffId, addedPositionCode, meetingId, roleId]);

  const handleClickSearch = () => {
    const searchKeyword = inputRef.current.value;
    setSearchParams((prev) => ({ ...prev, search: searchKeyword, page: 1 }));
  };

  const handleClickShowPopup = () => {
    setOpen(true);
    setSearchParams(INIT_SEARCH_PARAMS);
  };

  const textByType = useMemo(() => {
    switch (type) {
      case AGENDA_TYPES.SP_AGENDA:
        return {
          BUTTON: 'SP Pending Action',
          TITLE: 'List of Concluded Agenda',
          COLUMN: {
            title: (
              <span>
                Position Name & <br /> Position Code
              </span>
            ),
            dataIndex: 'action_type',
            fieldName1: 'first_position_name',
            fieldName2: 'first_position_code',
          },
          PLACEHOLDER: 'Search by Position Code / Position Name / Agenda Name',
        };
      case AGENDA_TYPES.TR_AGENDA:
        return {
          BUTTON: 'TR Pending Action',
          TITLE: 'List of Concluded TR Agenda',
          COLUMN: {
            title: (
              <span>
                Staff Name <br /> Staff ID
              </span>
            ),
            dataIndex: 'action_type',
            fieldName1: 'birth_name',
            fieldName2: 'staff_id',
          },
          PLACEHOLDER: 'Search by Staff ID / Staff Name / Agenda Name',
        };
      default:
        return;
    }
  }, [type]);

  const handleViewAgenda = useCallback(
    (record) => {
      if (type === AGENDA_TYPES.SP_AGENDA) {
        window.open(
          `/meeting/${record.meeting_id}/agenda/${record?.agenda_id}?type=view`,
          '_blank'
        );
      } else {
        window.open(
          `/meeting/${record.meeting_id}/talent-review-agenda/${record?.agenda_id}?type=view`,
          '_blank'
        );
      }
    },
    [type]
  );

  const handleFilter = (status) => {
    setSearchParams((prev) => ({ ...prev, status }));
  };

  const handleClearFilter = () => {
    setSearchParams((prev) => ({ ...prev, status: '' }));
  };

  const dropDownMenu = useMemo(
    () => ({
      items: [
        {
          label: 'Reviewed',
          onClick: () => handleFilter('Reviewed'),
        },
        {
          label: 'Endorsed',
          onClick: () => handleFilter('Endorsed'),
        },
      ],
    }),
    []
  );

  async function copyToAgenda(record, isGetRemark) {
    setOpen(false);
    setLoading(true);
    setAgendaAsMain(record);
    await handleSetAsMain(record, isGetRemark);
    setLoading(false);
    if (otherProps?.setIsPositionChanges) {
      otherProps.setIsPositionChanges(true);
    }
    if (otherProps?.setPrevAgendaData) {
      otherProps.setPrevAgendaData((prev) => ({
        ...prev,
        positions: [],
      }));
    }
  }

  const columns = useMemo(() => {
    const handleClickSort = () => {
      if (searchParams.sort_order === SORT_TYPE.DESC) {
        setSearchParams((prev) => ({ ...prev, sort_order: SORT_TYPE.ASC }));
        return;
      }
      setSearchParams((prev) => ({ ...prev, sort_order: SORT_TYPE.DESC }));
    };
    return [
      {
        title: 'No.',
        width: '5%',
        dataIndex: 'no',
        render: (text) => (
          <span className={styles.textField}>
            {text + (searchParams.page - 1) * searchParams.limit}
          </span>
        ),
      },
      {
        title: 'Agenda',
        width: '20%',
        dataIndex: 'agenda_name',
        render: (text) => <span className={styles.agendaField}>{text}</span>,
      },
      {
        title: 'Meeting Name',
        width: '15%',
        dataIndex: 'meeting_name',
        render: (text) => <span className={styles.textField}>{text}</span>,
      },
      {
        title: textByType.COLUMN.title,
        width: '12%',
        dataIndex: textByType.COLUMN.dataIndex,
        render: (_, record) => (
          <span className={styles.textField}>
            {record[textByType.COLUMN.fieldName1] || 'N/A'}
            <br />
            {!isEmpty(record[textByType.COLUMN.fieldName2]) &&
              `(${record[textByType.COLUMN.fieldName2]})`}
          </span>
        ),
      },
      {
        title: 'Status',
        width: '11%',
        dataIndex: 'action_type',
        render: (text) => <span className={styles.textField}>{text || 'N/A'}</span>,
      },
      {
        title: (
          <div className={styles.createdDate}>
            <span>
              Created Date & Time{' '}
              <img
                src={sort_teal}
                className={searchParams.sort_order === SORT_TYPE.DESC ? styles.desc : ''}
                alt="sort"
                onClick={handleClickSort}
                onKeyDown={() => {}}
              />
            </span>
          </div>
        ),
        width: '13%',
        dataIndex: 'start_time',
        render: (text, record) => (
          <span className={styles.textField}>
            {moment(record?.meeting_start_at).format('DD MMMM YYYY')}
            <br />
            {moment(text).format('hh:mmA')}
          </span>
        ),
      },
      {
        title: '',
        width: '23%',
        dataIndex: 'agenda_id',
        render: (text, record) => (
          <div className={styles.toolButton}>
            <BasicButton onClick={() => handleViewAgenda(record)} style={{ minWidth: '120px' }}>
              View Agenda
            </BasicButton>
            {text !== (agendaAsMain?.agenda_id || agendaDetail?.agenda_as_main) ? (
              <BasicButton
                onClick={() =>
                  record.isRemark
                    ? setOpenCopyRemark({ open: true, record })
                    : copyToAgenda(record, false)
                }
                mode="teal"
                style={{ minWidth: '132px' }}
              >
                Copy To Agenda
              </BasicButton>
            ) : (
              <div className={styles.mainAgenda}>Main</div>
            )}
          </div>
        ),
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    textByType,
    handleViewAgenda,
    searchParams.sort_order,
    searchParams.page,
    searchParams.limit,
    agendaAsMain,
    agendaDetail,
    setAgendaAsMain,
    handleSetAsMain,
  ]);

  const pagination = useMemo(() => {
    const onChange = (pageNum) => {
      setSearchParams((prevState) => ({ ...prevState, page: pageNum }));
    };
    return paginationConfig(data.total, searchParams.page, onChange, searchParams.limit);
  }, [data.total, searchParams.page, setSearchParams, searchParams.limit]);

  useEffect(() => {
    if (open) {
      fetchData();
    }
  }, [open, fetchData]);

  return (
    <>
      <AiButton className={`ml-2 ${styles.customBtn}`} onClick={handleClickShowPopup}>
        {textByType.BUTTON}
      </AiButton>
      <CustomAntModal
        title={textByType.TITLE}
        width={1330}
        open={open}
        setOpen={setOpen}
        hideDefaultBtn
        styles={{
          containerPaddingLeftRight: 50,
          containerPaddingTopBottom: 40,
          closePosition: 40,
          titleFontSize: 20,
          headerPaddingBottom: 34,
        }}
      >
        <div className={styles.searchBar}>
          <div className={styles.search}>
            <BasicInput width={415} placeholder={textByType.PLACEHOLDER} ref={inputRef} />
            <BasicButton height={36} mode="teal" onClick={handleClickSearch}>
              Search
            </BasicButton>
          </div>
          <Dropdown
            menu={dropDownMenu}
            trigger={['click']}
            style={{ minWidth: 110 }}
            placement="bottomRight"
            getPopupContainer={(trigger) => trigger.parentElement}
          >
            <Space style={{ cursor: 'pointer' }}>
              <img
                src={searchParams.status ? filter_agenda_active : filter_agenda_inactive}
                alt="filter_user_management"
              />
            </Space>
          </Dropdown>
        </div>
        {searchParams.status && (
          <div className={styles.filterSelected}>
            <div className={styles.title}>Filter selected:</div>
            <div className={styles.status}>
              <Pill label={searchParams.status} handleRemove={handleClearFilter} />
              <span onKeyDown={() => {}} onClick={handleClearFilter}>
                Clear Filters
              </span>
            </div>
          </div>
        )}
        <BasicTable
          loading={loading}
          rowKey="no"
          dataSource={
            !isEmpty(data.agendas) &&
            data.agendas.map((item, index) => ({
              ...item,
              no: index + 1,
              agenda_as_main: item.agenda_id,
            }))
          }
          columns={columns}
          pagination={pagination}
        />
      </CustomAntModal>
      {openCopyRemark.open && (
        <ConfirmModalCopyToAgenda
          openCopyRemark={openCopyRemark}
          setOpenCopyRemark={setOpenCopyRemark}
          copyToAgenda={copyToAgenda}
        />
      )}
    </>
  );
};

PopupListConcludedAgenda.propTypes = {
  type: PropTypes.oneOf([AGENDA_TYPES.SP_AGENDA, AGENDA_TYPES.TR_AGENDA]).isRequired,
  agendaDetail: PropTypes.object,
  setAgendaAsMain: PropTypes.func,
  agendaAsMain: PropTypes.object,
  handleSetAsMain: PropTypes.func,
  meetingId: PropTypes.string,
  addedStaffId: PropTypes.string,
  addedPositionCode: PropTypes.array,
};

export default PopupListConcludedAgenda;
