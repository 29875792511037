import './box.scss';
import { Tooltip } from 'antd';
import { isEmpty, max } from 'lodash';
import moment from 'moment';
import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { DATE_TIME_FORMAT } from '../../../../../../utils/constants';
import {
  decodeBase64ToImage,
  handleShowCT,
  handleShowRetirementDate,
  handleShowRetirementForSP,
  handleShowTextTooltip,
  handleShowTooltip,
  handleShowYIPAndYISG,
  hideOtherTalentLabel,
  infoPosition,
} from '../../../../../../utils/helper';
import { Draggable } from '../wrappers/Draggable';
import { ApprovedLabel } from './ApprovedLabel';
import styles from './box.module.scss';

const ViewBox = (props) => {
  const { text, line, columnMode, blockStyle } = props;
  const {
    positions,
    agenda_as_main_type,
    agenda_as_main_platform,
    agenda_as_main,
    agenda_as_main_start_at,
  } = useSelector((state) => state.app.meetingModule.agenda.agendaIncumbent);

  const getCardHeight = () => {
    const listCardElem = document.querySelectorAll('.cardBoxItem');
    if (listCardElem.length > 0) {
      let tempValue = [];
      for (const cardItem of listCardElem) {
        tempValue.push(cardItem?.clientHeight);
      }
      return max(tempValue);
    }
  };

  return (
    <div className={styles.collapse}>
      <div
        className={styles.collapseHeader}
        style={{ backgroundColor: blockStyle.headerColor, marginTop: 0 }}
      >
        <div className={styles.left}>{text}</div>
      </div>
      <div
        className={styles.collapseBody}
        style={{
          minHeight: columnMode ? 670 : 'initial',
          maxHeight: columnMode ? 670 : 'initial',
          overflow: columnMode ? 'hidden' : 'initial',
          overflowY: columnMode ? 'auto' : 'initial',
          border: 'none',
        }}
      >
        {!isEmpty(line) ? (
          <div
            style={{
              gridTemplateColumns: columnMode ? '1fr' : '1fr 1fr 1fr',
              gap: '10px',
              padding: '15px 15px',
            }}
            className={styles.collapseBody__grid}
          >
            {line.map((item, index) => (
              <div
                className={`${styles.card_box} cardBoxItem`}
                key={`${item.staff_id}.${index}`}
                style={{ minHeight: columnMode ? getCardHeight() : 'auto' }}
              >
                <div
                  className={`collapseBody__line col-4 ${styles.left}`}
                  style={{ backgroundColor: blockStyle.inforColor, marginBottom: 0 }}
                  data={{ ...item, index }}
                >
                  <div className="row flex-column no-gutters align-items-center h-100">
                    <div className={styles.viewNumber}>{index + 1}</div>
                    <div className={styles.viewImage}>
                      <img src={decodeBase64ToImage(item?.image)} alt="" />
                    </div>
                    <div
                      style={{
                        marginTop: '5px',
                        marginBottom: '5px',
                        fontSize: '12px',
                        fontWeight: 'bold',
                      }}
                    >
                      {item?.identity_number || item?.staff_id}
                    </div>
                    {!item?.isUnsearchable && (
                      <div className={styles.position}>
                        <Tooltip
                          placement="bottom"
                          title={handleShowTextTooltip(item, positions)}
                          color={'black'}
                          key={'black'}
                          overlayInnerStyle={{
                            borderRadius: '6px',
                            minWidth: 'max-content',
                            padding: '12px',
                            width: '100%',
                          }}
                        >
                          {handleShowTooltip(item, positions)}
                        </Tooltip>
                      </div>
                    )}

                    <ApprovedLabel
                      positions={positions}
                      item={item}
                      isOldUI={true}
                      blockStyle={blockStyle}
                    />
                    {agenda_as_main && agenda_as_main === item.agenda_as_main && (
                      <Tooltip
                        overlayClassName={styles.tooltip}
                        placement="top"
                        title={
                          <div className={styles.platformName}>
                            <span>Platform Name: {agenda_as_main_platform}</span>
                            <br />
                            {agenda_as_main_start_at && (
                              <span>
                                Date:{' '}
                                {moment(agenda_as_main_start_at).format(
                                  DATE_TIME_FORMAT.DATE_SPACE
                                )}
                              </span>
                            )}
                          </div>
                        }
                      >
                        <div className={styles.mainAgendaStatus}>
                          {agenda_as_main_type || 'N/A'}
                        </div>
                      </Tooltip>
                    )}
                  </div>
                </div>
                <div className={`col-8 ${styles.right}`} style={{ paddingBottom: 0 }}>
                  <div className="row  no-gutters justify-content-between align-item?s-stretch">
                    <div className={styles.name} style={{ marginBottom: '5px' }}>
                      <Link
                        to={`/profile/${item?.staff_id}`}
                        target="_blank"
                        className={styles.recommendation__name}
                        draggable="false"
                      >
                        {item?.birth_name} &#160;
                        {!item?.isUnsearchable &&
                          !hideOtherTalentLabel(
                            item.assignment_status_type,
                            item.role_level,
                            item.ct
                          ) &&
                          !['119463', '1032501'].includes(item?.staff_id) &&
                          handleShowCT(item.ct)}
                        {!item?.isUnsearchable
                          ? item?.matching_percentage
                            ? `${item?.matching_percentage}%`
                            : ''
                          : ''}
                      </Link>
                    </div>
                  </div>
                  <Draggable
                    id={`${text}-drap-${index}`}
                    className="collapseBody__line"
                    data={{ ...item, index }}
                    style={{ marginBottom: 0 }}
                  >
                    <div style={columnMode ? { marginBottom: '3px' } : { marginBottom: '5px' }}>
                      <Tooltip
                        placement="top"
                        title={infoPosition(
                          item.position_name,
                          item.department_name,
                          item.division,
                          item.opu,
                          item.business_unit
                        )}
                        color={'black'}
                        key={'black'}
                        overlayInnerStyle={{ borderRadius: '6px', width: '400px' }}
                      >
                        <span>Position: </span>
                        {item?.position_name}
                      </Tooltip>
                    </div>
                    {!item?.isUnsearchable && (
                      <div style={columnMode ? { marginBottom: '3px' } : { marginBottom: '5px' }}>
                        <span>YIP: </span>
                        {handleShowYIPAndYISG(item?.year_in_position, item?.date_in_position)}
                        &#160;&#160;&#160;
                        <span>YISG: </span>
                        {item?.salary_grade} (
                        {handleShowYIPAndYISG(item?.year_in_sg, item?.date_in_sg)})
                      </div>
                    )}

                    <div style={columnMode ? { marginBottom: '3px' } : { marginBottom: '5px' }}>
                      <span>Age: </span>
                      {item?.age}&#160;&#160;&#160;
                      {!item?.isUnsearchable && (
                        <>
                          <span>Retirement: </span>
                          {handleShowRetirementDate(item.retirement_date)}
                          {handleShowRetirementForSP(item)}
                        </>
                      )}
                    </div>
                    {!item?.isUnsearchable && (
                      <div style={columnMode ? { marginBottom: '3px' } : { marginBottom: '5px' }}>
                        <span>Performance Rating: </span>
                        {item?.performance_rating}
                      </div>
                    )}
                  </Draggable>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className="w-100" style={{ textAlign: 'center' }}>
            No data
          </div>
        )}
      </div>
    </div>
  );
};

export default ViewBox;
