import { useEffect } from 'react';
import { SP_CARD_HAS_BORDER } from '../utils/constants';
import { checkSubset } from '../utils/helper';

export const useCardSetting = (props) => {
  const { boxRef, lineSettings, line, loading, remarks } = props;

  useEffect(() => {
    if (!boxRef.current) return;
    const printSectionsDOM = boxRef.current.querySelectorAll('[data-cardSettings]');
    const lineSettingsValue = lineSettings.map((i) => i.value);
    printSectionsDOM.forEach((element) => {
      const printSection = element.attributes['data-cardSettings'].value;
      if (!lineSettingsValue.includes(printSection)) {
        element.style.display = 'none';
      } else {
        element.style.removeProperty('display');
      }
      if (printSection === 'border') {
        if (checkSubset(SP_CARD_HAS_BORDER, lineSettingsValue)) {
          element.style.removeProperty('display');
        } else {
          element.style.display = 'none';
        }
      }
    });
  }, [JSON.stringify(lineSettings), boxRef.current, line, loading, remarks]);

  return {};
};
