import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import talentFinderApi from '../../../services/talentFinderApi';
import { PERMISSION } from '../../../utils/constants';
import TalentFinderExportOption from '../TalentFinderExportOption/TalentFinderExportOption';
import { TF_EXPORT_DEFAULT_SELECT_OPTIONS } from '../TalentFinderExportOption/talent-finder-export.constants';
import { Spin } from 'antd';

const TalentExport = (props) => {
  const { filter, keyword, role, totalData, setShowExporting, talentSearchResult } = props;
  const permissions = useSelector((state) => state.user.permissions);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedExportOptions, setSelectedExportOptions] = useState(TF_EXPORT_DEFAULT_SELECT_OPTIONS);

  const handleOnExport = async (selectedOptions) => {
    setIsLoading(true);
    try {
      const res = await talentFinderApi.requestDataExport(
        {
          ...filter,
          filter_search_result: talentSearchResult,
          keyword,
          export_sections: selectedOptions.map((o) => o.value),
        },
        role
      );
      if (res.status === 200) {
        setShowExporting(true);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Spin spinning={isLoading}>
      <TalentFinderExportOption
        selectedExportOptions={selectedExportOptions}
        setSelectedExportOptions={setSelectedExportOptions}
        handleOnExport={handleOnExport}
        disabled={!permissions.includes(PERMISSION.TALENT_EXPORT) || !totalData || isLoading}
      />
    </Spin>
  );
};

TalentExport.propTypes = {
  filter: PropTypes.object,
  keyword: PropTypes.string,
  role: PropTypes.object,
  totalData: PropTypes.number,
  setShowExporting: PropTypes.func,
};
export default TalentExport;
