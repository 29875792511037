/* eslint-disable react-hooks/exhaustive-deps */
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Col, Dropdown, Menu, Modal, notification, Pagination, Spin, Table } from 'antd';
import _, { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';

import { BasicButton } from '../../../../assets/common';
import {
  admin_filter_active,
  avatar,
  editNote,
  ellipsis,
  filter_user_management,
} from '../../../../assets/img';
import { BreadCrumb } from '../../../../components';
import { useShowAlertUploadDownloadWhenNavigate } from '../../../../hooks/useShowAlertUploadDownloadWhenNavigate';
import { useShowAlertWhenRefresh } from '../../../../hooks/useShowAlertWhenRefresh';
import { adminApi } from '../../../../services/admin';
import { PERMISSION, USER_ACCESS_MESSAGE, USER_ID } from '../../../../utils/constants';
import { getBreadCrumbList } from '../../../../utils/helper';
import { Filter, Name } from './components';
import * as SC from './styled';

export function UserAccessList() {
  const [filter, setFilter] = useState(false);
  const [isFiltering, setIsFiltering] = useState(false);
  const [userList, setUserList] = useState([]);
  const history = useHistory();
  const roleActive = useSelector((state) => state.user.roleActive);
  const [totalUsers, setTotalUsers] = useState(0);
  const regrexRole = new RegExp(/user\d+_role\d+/);
  const regrexAccess = new RegExp(/user\d+_role\d+_access\d+/);
  const [filters, setFilterOptions] = useState({});
  const [selectedOptions, setSelectedOptions] = useState({});
  const [page, setPage] = useState(1);
  const [selected, setSelected] = useState([]);
  const [selectedFilters, setSelectedFilters] = useState({});
  const [search, setSearch] = useState('');
  const [submitSearch, setSubmitSearch] = useState(false);
  const [loading, setLoading] = useState(false);
  const limit = 16;
  const { setIsUploadingOrDownloading } = useShowAlertUploadDownloadWhenNavigate();
  const { setShowAlertRefresh } = useShowAlertWhenRefresh();

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = userList.map((row) => {
        return row.id;
      });
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleSelected = (_id) => {
    const selectedIndex = selected.indexOf(_id);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, _id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const sharedOnCellWithMultipleRole = (record) => {
    if (!record.key.match(regrexRole) && !record.key.match(regrexAccess)) {
      return { rowSpan: record.totalRoles > 0 ? record.totalRoles : 1 };
    }
    return { rowSpan: 0 };
  };

  const sharedOnCellWithMultipleAccessLevel = (record) => {
    const regrexSpecialAccess = new RegExp(/user\d+_role0/);
    if (!record.key.match(regrexRole) && !record.key.match(regrexAccess)) {
      return { rowSpan: record.totalAccess > 0 ? record.totalAccess : 1 };
    }
    if (
      record.key.match(regrexRole) &&
      !record.key.match(regrexAccess) &&
      !record.key.match(regrexSpecialAccess)
    ) {
      return { rowSpan: record.totalAccess > 0 ? record.totalAccess : 1 };
    }
    return { rowSpan: 0 };
  };
  const columns = [
    {
      title: () => {
        return (
          <input
            type="checkbox"
            onChange={(e) => handleSelectAllClick(e)}
            value={
              selected.length > 0 && userList.length > 0 && selected.length === userList.length
            }
            checked={
              selected.length > 0 && userList.length > 0 && selected.length === userList.length
            }
          />
        );
      },
      render: (record) => {
        const isSelected = selected.indexOf(record.id) !== -1;

        return (
          <input
            id={`role_${record.id}`}
            type="checkbox"
            onChange={() => handleSelected(record.id)}
            value={isSelected}
            checked={isSelected}
          />
        );
      },
      onCell: sharedOnCellWithMultipleRole,
    },
    {
      title: '',
      dataIndex: 'avatar',
      onCell: sharedOnCellWithMultipleRole,
    },
    {
      title: 'Name',
      dataIndex: 'name',
      onCell: sharedOnCellWithMultipleRole,
    },
    {
      title: 'Staff ID',
      dataIndex: 'staffID',
      onCell: sharedOnCellWithMultipleRole,
    },
    {
      title: 'Email',
      dataIndex: 'email',
      onCell: sharedOnCellWithMultipleRole,
    },
    {
      title: 'Role',
      dataIndex: 'role',
      onCell: sharedOnCellWithMultipleAccessLevel,
    },
    {
      title: 'Access Level',
      dataIndex: 'accessLevel',
    },
    {
      title: 'Business Access',
      dataIndex: 'businessAccess',
    },
    {
      title: '',
      dataIndex: 'action',
      onCell: sharedOnCellWithMultipleRole,
      render: (_, record) => {
        const menu = (
          <Menu style={{ minWidth: 110 }}>
            <Menu.Item key="edit" icon={<img src={editNote} alt="" />}>
              <Link to={`/admin/user-access-management/${record?.id}/edit`}>Edit</Link>
            </Menu.Item>
          </Menu>
        );

        return (
          <Dropdown
            overlay={menu}
            trigger={['click']}
            placement="bottomRight"
            getPopupContainer={(trigger) => trigger.parentElement}
          >
            <div style={{ cursor: 'pointer' }}>
              <img src={ellipsis} alt="" />
            </div>
          </Dropdown>
        );
      },
    },
  ];

  const renderUserList = (users) => {
    const data = [];
    _.forEach(users, (user, index) => {
      let totalRoles = 0;
      let previousTotalRows = data.length;
      const userInfo = {
        key: `user${index}`,
        id: user.id,
        avatar: (
          <div className="avatar">
            <img src={user.image_url || avatar} alt="" />
          </div>
        ),
        name: (
          <Name
            birthName={user.birth_name}
            positionName={user.business_unit}
            departmentName={user.department_name}
            businessUnit={user.business_unit}
          />
        ),
        staffID: user.staff_id,
        email: user.email,
      };
      if (!user.roles || user.roles.length === 0) {
        totalRoles++;
        data.push(userInfo);

        return;
      }
      _.forEach(user.roles, (role, i) => {
        const userRoleInfo = { ...userInfo };
        userRoleInfo.role = role.name;
        if (i !== 0) userRoleInfo.key = `user${index}_role${i}`;

        if (!role.accessLevels || role.accessLevels.length === 0) {
          data.push(userRoleInfo);
          totalRoles++;
          return;
        }

        _.forEach(role.accessLevels, (accessLevel, idx) => {
          const userRoleAccessInfo = { ...userRoleInfo };
          userRoleAccessInfo.accessLevel = accessLevel.accessLevel;
          userRoleAccessInfo.businessAccess = accessLevel.businessAccessName;

          if (i === 0 && idx === 1) {
            userRoleAccessInfo.key = `user${index}_role${i}`;
            userRoleAccessInfo.totalAccess = role.accessLevels.length;

            data.push(userRoleAccessInfo);
            totalRoles++;

            return;
          }
          if (idx !== 0) userRoleAccessInfo.key = `user${index}_role${i}_access${idx}`;
          totalRoles++;
          userRoleAccessInfo.totalAccess = role.accessLevels.length;
          data.push(userRoleAccessInfo);
        });
      });
      data[previousTotalRows].totalRoles = totalRoles;
    });

    return data;
  };

  const getAdminUsers = async (pageGoTo, pageSize, filters = {}) => {
    setLoading(true);
    try {
      const headers = { id: USER_ID, role: roleActive };
      const response = await adminApi.getAdminUsers(headers, pageSize, pageGoTo, filters);
      if (response.status === 200) {
        setUserList(response.data.result.users);
        setTotalUsers(response.data.result.total);
        setPage(pageGoTo);
        setSelected([]);
      }
      setLoading(false);
    } catch (error) {
      setUserList([]);
      setTotalUsers(0);
      setSelected([]);
      setLoading(false);
    }
  };
  const getRoleOptions = async () => {
    try {
      const response = await adminApi.getRoleOptions(roleActive.roleId);
      if (response.status === 200) {
        let roles = _.get(response, 'data.result');
        roles = roles.map((item) => ({
          id: item.id,
          value: item.fullname,
        }));
        setFilterOptions({
          roles,
        });
      }
    } catch (error) {
      setUserList([]);
      setTotalUsers(0);
    }
  };

  const getAccessLevelOptions = async (roleIdSelected) => {
    try {
      if (!roleIdSelected) {
        setFilterOptions({ ...filters, accessLevels: [] });
        setSelectedFilters({ ...selectedFilters, accessLevel: '' });

        return;
      }

      const response = await adminApi.getAccessLevelOptions(roleActive.roleId, {
        roleIdSelected,
      });
      if (response.status === 200) {
        let accessLevels = _.get(response, 'data.result');
        accessLevels = accessLevels.map((item, idx) => ({
          id: idx + 1,
          value: item.access_level,
        }));
        setFilterOptions({
          ...filters,
          accessLevels,
        });
      }
    } catch (error) {
      setUserList([]);
      setTotalUsers(0);
    }
  };

  const getBuAccessOptions = async (roleIdSelected, accessLevelSelected) => {
    try {
      if (!roleIdSelected) {
        setFilterOptions({ ...filters, accessLevels: [], buAccessLevels: [] });
        setSelectedFilters({ ...selectedFilters, accessLevel: '', businessAccess: '' });
        return;
      }

      if (!accessLevelSelected) {
        setFilterOptions({ ...filters, buAccessLevels: [] });
        setSelectedFilters({ ...selectedFilters, businessAccess: '' });
        return;
      }

      const response = await adminApi.getBuAccessOptions(roleActive.roleId, {
        roleIdSelected,
        accessLevelSelected,
      });
      if (response.status === 200) {
        let buAccessLevels = _.get(response, 'data.result');
        buAccessLevels = buAccessLevels.map((item) => ({
          id: item.id,
          value: item.name,
        }));
        setFilterOptions({
          ...filters,
          buAccessLevels,
        });
      }
    } catch (error) {
      setUserList([]);
      setTotalUsers(0);
    }
  };

  const filterUser = (selectedItem) => {
    const filter = { [selectedItem.type]: selectedItem.value };
    setSelectedFilters({ ...selectedFilters, ...filter });
  };

  useEffect(() => {
    getRoleOptions();
  }, []);

  useEffect(() => {
    getAccessLevelOptions(selectedOptions?.role?.id);
  }, [selectedOptions?.role?.id]);

  useEffect(() => {
    getBuAccessOptions(selectedOptions?.role?.id, selectedOptions?.accessLevel?.value);
  }, [selectedOptions?.role?.id, selectedOptions?.accessLevel?.value]);

  useEffect(() => {
    if (submitSearch || isFiltering) {
      setPage(1);
      getAdminUsers(1, limit, selectedFilters);
    }
  }, [selectedFilters]);

  useEffect(() => {
    getAdminUsers(1, limit, selectedFilters);
  }, []);

  async function deleteUser() {
    try {
      const roles = {
        role: roleActive,
        id: USER_ID,
        permission: PERMISSION.SEARCH_USER_BY_EMAIL,
      };
      const res = await adminApi.deleteUser(roles, selected);
      if (res.status === 200) {
        notification.success({
          message: 'Deleted Successful !!',
          duration: 5,
        });
        getAdminUsers(1, limit, selectedFilters);
      }
    } catch (error) {
      notification.error({
        message: 'An occur error !!',
        duration: 5,
      });
      setSelected([]);
    }
  }

  const handleSearch = () => {
    setSubmitSearch(true);
    filterUser({ type: 'search', value: search });
  };
  const changeSearch = (e) => {
    setSearch(e?.target?.value);
  };
  const { confirm } = Modal;

  function showConfirm() {
    confirm({
      icon: <ExclamationCircleOutlined />,
      content: <div>Are you sure you want to delete user(s)</div>,
      className: 'antd-modal-confirm-custom',
      onOk() {
        deleteUser();
      },
      onCancel() {
        setSelected([]);
      },
    });
  }

  const handleExport = async () => {
    try {
      setLoading(true);
      setIsUploadingOrDownloading(true);
      setShowAlertRefresh(true);
      const res = await adminApi.getFileUrlToExport(
        selected,
        { role: roleActive },
        selectedFilters
      );
      const fileUrl = res?.data?.result?.url;
      if (res.status === 200 && fileUrl) {
        fetch(fileUrl)
          .then((res) => {
            return res.blob();
          })
          .then((blob) => {
            const href = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.setAttribute('download', `User Access Management`);
            link.href = href;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          })
          .catch((err) => {
            console.error(err);
            notification.error({
              message: USER_ACCESS_MESSAGE.AN_UNEXPECTED_ERROR,
              duration: 5,
            });
          })
          .finally(() => {
            setLoading(false);
          });
      }
    } catch (error) {
      notification.error({
        message: USER_ACCESS_MESSAGE.AN_UNEXPECTED_ERROR,
        duration: 5,
      });
      setSelected([]);
    } finally {
      setIsUploadingOrDownloading(false);
      setShowAlertRefresh(false);
      setLoading(false);
    }
  };

  const fromResult = (page - 1) * limit + 1;
  const toResults = (page - 1) * limit + limit;

  return (
    <SC.Container data-testid="user-access-list">
      <BreadCrumb
        level={3}
        breadCrumbList={getBreadCrumbList([`Dashboard`, `Admin`, `User Access Management`])}
      />
      <SC.Header>
        <div className="left">
          <div className="title">User Access Management</div>
          <div className="sub-title">List of user access management</div>
        </div>
        <div className="right" />
      </SC.Header>
      <SC.ToolBar>
        <Col className="left" span={16}>
          <Col span={18}>
            <input
              className="searchInput"
              data-testid="search-input"
              placeholder="Search Staff Name/Staff ID/Email"
              onChange={changeSearch}
            />
          </Col>
          <Col>
            <BasicButton style={{ marginLeft: '8px' }} mode="teal" onClick={() => handleSearch()}>
              Search
            </BasicButton>
          </Col>
        </Col>
        <Col className="right" style={{ gap: '8px' }} span={8}>
          <BasicButton mode="border-teal" onClick={handleExport} disabled={loading}>
            Export
          </BasicButton>

          <BasicButton
            mode="danger"
            disabled={isEmpty(selected)}
            onClick={isEmpty(selected) ? null : showConfirm}
          >
            Confirm delete user
          </BasicButton>
          <BasicButton
            mode="teal"
            onClick={() => {
              history.push('/admin/user-access-management/create-user');
            }}
          >
            Create User
          </BasicButton>
          <img
            className="filterButton"
            src={filter ? admin_filter_active : filter_user_management}
            alt=""
            onKeyDown={() => {}}
            onClick={() => setFilter(!filter)}
          />
        </Col>
      </SC.ToolBar>
      {filter && (
        <Filter
          filters={filters}
          filterUser={filterUser}
          selectedOptions={selectedOptions}
          setSelectedOptions={setSelectedOptions}
          setIsFiltering={setIsFiltering}
        />
      )}
      <Spin spinning={loading} size="large">
        <SC.TableManagement>
          <Table columns={columns} dataSource={renderUserList(userList)} pagination={false} />
          {!isEmpty(userList) && (
            <div className="pagination-custom" data-testid="user-access-pagination">
              <div>{`Showing ${fromResult} to ${
                toResults > totalUsers ? totalUsers : toResults
              } of ${totalUsers} results`}</div>
              <Pagination
                total={totalUsers}
                current={page}
                defaultPageSize={limit}
                onChange={(page, pageSize) => {
                  setSubmitSearch(false);
                  setIsFiltering(false);
                  getAdminUsers(page, pageSize, selectedFilters);
                }}
                showSizeChanger={false}
              />
            </div>
          )}
        </SC.TableManagement>
      </Spin>
    </SC.Container>
  );
}
