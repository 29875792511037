import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { AiButton } from '../../../assets/common';
import { illustration_contribution, tc_meeting_breadcrumb } from '../../../assets/img';
import { BreadCrumb } from '../../../components';
import { useSelector } from 'react-redux';
import { AiDesc, AiTitle, Container, Row } from './Styled';

const itemIncumbent = {
  title: 'Great! You have concluded the Meeting!',
  desc: 'The meeting has been concluded, and the finalized minutes of meeting (MOM) can now be viewed on the completed meeting list page in TC Meeting.',
  desc2ndLind: 'Thank you!',
  btnCancel: 'Back to Home',
  borderCancel: true,
  modeCancel: '',
  img: illustration_contribution,
};
const EndConfirmConclude = () => {
  const history = useHistory();
  const meeting = useSelector((state) => state.app.meetingModule.preMeeting.meeting);
  const [breadCrumbList, setBreadCrumbList] = useState([
    { name: 'TC Meeting', icon: tc_meeting_breadcrumb },
    { name: 'Board Management Meeting' },
  ]);

  useEffect(() => {
    setBreadCrumbList([
      { name: 'TC Meeting', icon: tc_meeting_breadcrumb },
      { name: meeting.meeting_name },
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meeting && meeting.meeting_name]);

  return (
    <>
      <div style={{ marginLeft: '20px' }}>
        <BreadCrumb level={2} breadCrumbList={breadCrumbList} />
      </div>
      <Container>
        <Row>
          <div>
            <AiTitle>{itemIncumbent.title}</AiTitle>
            <AiDesc>
              {itemIncumbent.desc}
              <br />
              <br />
              {itemIncumbent.desc2ndLind}
            </AiDesc>
            <AiButton
              border={itemIncumbent?.borderCancel}
              mode={itemIncumbent.modeCancel}
              onClick={() => history.push('/homepage')}
            >
              {itemIncumbent.btnCancel}
            </AiButton>
          </div>
          <div>
            <img src={itemIncumbent.img} alt="" />
          </div>
        </Row>
      </Container>
    </>
  );
};
export default EndConfirmConclude;
