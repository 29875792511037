import _, { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import Skeleton from 'react-loading-skeleton';

import PositionItemForSpDashboard from '../../../Meeting/AgendaDetails/components/Positions/PositionItemForSpDashboard';
import styles from './positions-dashboard-sp.module.scss';

// const checkPositionParent = (item) => {
//   let isPositionParent = false;
//   if (Object.keys(item).includes('positionParent')) {
//     isPositionParent = true;
//   }
//   return {
//     isPositionParent,
//   };
// };

// const PositionItem = (props) => {
//   const { index, item, length } = props;
//   const { isPositionParent } = checkPositionParent(item);
//   const [isShowHierarchy, setIsShowHierarchy] = useState(false);
//   const ref = useRef();

//   useEffect(() => {
//     const checkIfClickedOutside = (e) => {
//       if (isShowHierarchy && ref.current && !ref.current.contains(e.target)) {
//         setIsShowHierarchy(false);
//       }
//     };
//     document.addEventListener('mousedown', checkIfClickedOutside);

//     return () => {
//       document.removeEventListener('mousedown', checkIfClickedOutside);
//     };
//   }, [isShowHierarchy]);

//   return (
//     <div className={`d-flex ${styles.title}`} ref={ref} style={{ gap: '5px' }}>
//       {length === 1 ? (
//         <div
//           style={{
//             color: isShowHierarchy ? '#00A19C' : '#344563',
//           }}
//           onKeyDown={() => {}}
//           onClick={() => setIsShowHierarchy(!isShowHierarchy)}
//         >
//           <TooltipPoisition item={item} />
//           {isShowHierarchy && (
//             <div className={styles.box_hierarchy}>
//               <div style={{ marginBottom: '34px' }}>
//                 <div className={styles.box_hierarchy_title}>Hierarchy</div>
//               </div>

//               <div className="row">
//                 <div className="col-3"></div>
//                 <div className="col-6">
//                   <div className="d-flex" style={{ flexDirection: 'column', alignItems: 'center' }}>
//                     {isPositionParent && (
//                       <>
//                         <div
//                           className={styles.section_1}
//                           style={{
//                             background: '#50A4B9',
//                           }}
//                         >
//                           <div className="d-flex align-items-center" style={{ gap: '20px' }}>
//                             <img src={decodeBase64ToImage(item?.url_image)} alt="" width="38" height="38" style={{ borderRadius: '100px' }} />

//                             <div>
//                               <div className={styles.text_hierarchy} style={{ marginBottom: '7px', fontWeight: 600 }}>
//                                 {item?.positionParent?.position_name}
//                               </div>
//                               <div className={styles.text_hierarchy} style={{ fontWeight: 500 }}>
//                                 {item?.supervisor?.birth_name}
//                               </div>
//                             </div>
//                           </div>
//                         </div>

//                         <div className={styles.line_vertical}></div>
//                       </>
//                     )}

//                     <div
//                       className={styles.section_1}
//                       style={{
//                         background: '#5F5C97',
//                       }}
//                     >
//                       <div className="d-flex align-items-center" style={{ gap: '20px' }}>
//                         <img src={decodeBase64ToImage(item?.url_image)} alt="" width="38" height="38" style={{ borderRadius: '100px' }} />

//                         <div>
//                           <div className={styles.text_hierarchy} style={{ marginBottom: '7px', fontWeight: 600 }}>
//                             {item?.position_name}
//                           </div>
//                           <div className={styles.text_hierarchy} style={{ fontWeight: 500 }}>
//                             {item?.incumbent.name}
//                           </div>
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//                 <div className="col-3"></div>
//               </div>
//             </div>
//           )}
//         </div>
//       ) : (
//         <Tooltip placement="top" title={handleShowPosition(item)} color={'black'} key={'black'} overlayInnerStyle={{ borderRadius: '6px' }}>
//           <div
//             className={styles.title_ellipsis}
//             style={{
//               color: isShowHierarchy ? '#00A19C' : '#344563',
//             }}
//             onKeyDown={() => {}}
//             onClick={() => setIsShowHierarchy(!isShowHierarchy)}
//           >
//             <TooltipPoisition item={item} />
//             {isShowHierarchy && (
//               <div className={styles.box_hierarchy}>
//                 <div style={{ marginBottom: '34px' }}>
//                   <div className={styles.box_hierarchy_title}>Hierarchy</div>
//                 </div>

//                 <div className="row">
//                   <div className="col-3"></div>
//                   <div className="col-6">
//                     <div className="d-flex" style={{ flexDirection: 'column', alignItems: 'center' }}>
//                       {isPositionParent && (
//                         <>
//                           <div
//                             className={styles.section_1}
//                             style={{
//                               background: '#50A4B9',
//                             }}
//                           >
//                             <div className="d-flex align-items-center" style={{ gap: '20px' }}>
//                               <img src={decodeBase64ToImage(item?.url_image)} alt="" width="38" height="38" style={{ borderRadius: '100px' }} />

//                               <div>
//                                 <div className={styles.text_hierarchy} style={{ marginBottom: '7px', fontWeight: 600 }}>
//                                   {item?.positionParent?.position_name}
//                                 </div>
//                                 <div className={styles.text_hierarchy} style={{ fontWeight: 500 }}>
//                                   {item?.supervisor?.birth_name}
//                                 </div>
//                               </div>
//                             </div>
//                           </div>

//                           <div className={styles.line_vertical}></div>
//                         </>
//                       )}

//                       <div
//                         className={styles.section_1}
//                         style={{
//                           background: '#5F5C97',
//                         }}
//                       >
//                         <div className="d-flex align-items-center" style={{ gap: '20px' }}>
//                           <img src={decodeBase64ToImage(item?.url_image)} alt="" width="38" height="38" style={{ borderRadius: '100px' }} />

//                           <div>
//                             <div className={styles.text_hierarchy} style={{ marginBottom: '7px', fontWeight: 600 }}>
//                               {item?.position_name}
//                             </div>
//                             <div className={styles.text_hierarchy} style={{ fontWeight: 500 }}>
//                               {item?.birth_name}
//                             </div>
//                           </div>
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                   <div className="col-3"></div>
//                 </div>
//               </div>
//             )}
//           </div>
//         </Tooltip>
//       )}

//       {index + 1 !== length && `/`}
//     </div>
//   );
// };

// PositionItem.propTypes = {
//   index: PropTypes.number,
//   item: PropTypes.object,
//   length: PropTypes.number,
// };

const PositionsDashboardSP = (props) => {
  const { incumbents, loading, approved_date, platform } = props;
  // const { incumbents, loading, approved_date, platform } = props;
  // const data = _.unionBy(incumbents, 'position_code');
  const [isShowHierarchy, setIsShowHierarchy] = useState(false);
  const ref = useRef();
  // const [information, setInformation] = useState({
  //   incumbent: [],
  //   SG: [],
  //   yearsInPosition: [],
  //   age: [],
  //   retirement: [],
  //   approvedDate: [],
  // });

  // const handleDataInformationStaff = () => {
  //   setInformation({
  //     incumbent: [],
  //     SG: [],
  //     yearsInPosition: [],
  //     age: [],
  //     retirement: [],
  //     approvedDate: [],
  //   });

  //   data.forEach((item) => {
  //     if (!_.isEmpty(item.incumbent)) {
  //       setInformation((preState) => {
  //         return {
  //           ...preState,
  //           approvedDate: [...preState.approvedDate, item.last_approved_date],
  //           incumbent: [...preState.incumbent, item.incumbent.name],
  //           SG: [...preState.SG, item.incumbent.salary_grade],
  //           yearsInPosition: [...preState.yearsInPosition, item.incumbent.date_in_position],
  //           age: [...preState.age, item.incumbent.age],
  //           retirement: [...preState.retirement, item.incumbent.retirement_date],
  //         };
  //       });
  //     }
  //   });
  // };

  // useEffect(() => {
  //   handleDataInformationStaff();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [incumbents]);

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (isShowHierarchy && ref.current && !ref.current.contains(e.target)) {
        setIsShowHierarchy(false);
      }
    };
    document.addEventListener('mousedown', checkIfClickedOutside);

    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, [isShowHierarchy]);

  return (
    <div className={styles.positions}>
      {/* {!loading && (
        <div
          className="d-flex align-items-center"
          style={{ gap: '30px', marginBottom: '23px', position: 'relative', flexWrap: 'wrap' }}
        >
          {!!data && data?.length > 0 ? (
            data?.map((item, index) => (
              <PositionItem
                key={item?.incumbent?.staff_id}
                item={item}
                index={index}
                length={data?.length}
              />
            ))
          ) : (
            <div>None</div>
          )}

          <div></div>
        </div>
      )} */}
      {/* {loading && <Skeleton count={1} width={'50%'} height={50} style={{ marginBottom: '10px' }} />}
      {loading && <Skeleton count={1} width={'50%'} height={20} style={{ marginBottom: '10px' }} />} */}
      {/* {!loading && (
        <div className={styles.planning_date}>
          Succession Planning Approval Date:{' '}
          <span data-testid="last-approved-date">
            {handleShowInformation(information.approvedDate, 'time')}
          </span>
        </div>
      )} */}

      {/* <div className="row no-gutters justify-content-between align-items-stretch" style={{ marginBottom: '20px' }}>
        <div className="col-12">
          <div className={styles.fontMedium}>{!approved_date && !platform ? `Current Incumbent` : `Previous Incumbent`} Details</div>
          <div className="row no-gutters justify-content-start align-items-start">
            <div className="col-6">
              {loading && <Skeleton count={3} width={'50%'} />}
              {!loading && (
                <>
                  <div className={styles.fontMinimal}>
                    {!approved_date && !platform ? `Current Incumbent:` : `Previous Incumbent:`}{' '}
                    <span data-testid="incumbent-current">{handleShowInformation(information.incumbent)}</span>
                  </div>
                  <div className={styles.fontMinimal}>
                    Salary Grade: <span data-testid="salary-grade">{handleShowInformation(information.SG)}</span>
                  </div>
                  <div className={styles.fontMinimal}>
                    Date In Position: <span data-testid="year-in-position">{handleShowInformation(information.yearsInPosition, 'time')}</span>
                  </div>
                </>
              )}
            </div>
            {!approved_date && !platform && (
              <div className="col-6">
                {loading && <Skeleton count={2} width={'50%'} />}
                {!loading && (
                  <>
                    <div className={styles.fontMinimal}>
                      Age: <span data-testid="age">{handleShowInformation(information.age)}</span>
                    </div>
                    <div className={styles.fontMinimal}>Retirement / Contract End Date: {handleShowRetirement(information.retirement)}</div>
                  </>
                )}
              </div>
            )}
          </div>
        </div>
      </div> */}

      <div className={styles.positionListWrapper}>
        <div className={styles.positionTitle}>
          {!approved_date && !platform ? `Current Incumbent` : `Previous Incumbent`} Details
        </div>
        <div style={{ marginBottom: '24px' }}>
          {!isEmpty(incumbents) &&
            incumbents.map((item, index) => (
              <PositionItemForSpDashboard
                key={index}
                data={item}
                isEdit={false}
                handleDeletePosition={() => {}}
                lastItem={false}
                approved_date={approved_date}
                platform={platform}
              />
            ))}
        </div>
      </div>
      {loading && <Skeleton count={1} width={'50%'} height={50} style={{ marginBottom: '10px' }} />}
      {loading && <Skeleton count={1} width={'50%'} height={20} style={{ marginBottom: '10px' }} />}

      <div className={styles.fontMedium}>Description of role(s)/ requirements</div>
      <div className={styles.textArea}>
        <textarea
          className={styles.textAreaInput}
          placeholder=""
          disabled={true}
          value={incumbents?.[0]?.remark}
        />
      </div>
    </div>
  );
};

PositionsDashboardSP.propTypes = {
  incumbents: PropTypes.array,
  loading: PropTypes.bool,
};

export default PositionsDashboardSP;
