import { isNaN } from 'lodash';
import moment from 'moment';
import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  calculateTime,
  decodeBase64ToImage,
  handleShowCT,
  handleShowRetirementDate,
  handleShowTooltip,
  hideOtherTalentLabel,
} from '../../../../../../utils/helper';
import { positionsSelector } from '../../../../selector';
import styles from '../Box/box.module.scss';

// eslint-disable-next-line react/display-name
const CardItem = React.forwardRef((props, ref) => {
  const { item, index } = props;
  const positions = useSelector(positionsSelector);
  const handleShowRetirement = (item) => {
    let total =
      Number(moment(item?.retirement_date).format('YYYY')) -
      Number(moment(item?.birth_date, 'DD-MM-YYYY').format('YYYY'));
    if (!isNaN(total)) {
      return '(' + total + ')';
    }

    return '';
  };

  return (
    <div style={{ width: '400px', backgroundColor: '#fff' }}>
      <div
        style={{
          padding: '10px',
          border: '1px solid #e7e7f0',
          // borderTop: `8px solid ${color}`,
          borderRadius: '6px',
        }}
        ref={ref}
      >
        <div>
          <div className={styles.card_box}>
            <div className="d-flex align-items-center h-100" style={{ gap: '15px' }}>
              <div className={styles.number}>{index + 1}</div>
              <div className={styles.image}>
                <img src={decodeBase64ToImage(item?.image)} alt="" />
              </div>
            </div>
            <div className={`${styles.right}`}>
              <div className="d-flex no-gutters justify-content-between align-items-stretch">
                <div className={styles.name}>
                  <Link
                    to={`/profile/${item?.staff_id}`}
                    target="_blank"
                    className={styles.recommendation__name}
                    draggable="false"
                  >
                    {item?.birth_name} &#160;
                    {!item?.isUnsearchable &&
                      !hideOtherTalentLabel(
                        item.assignment_status_type,
                        item.role_level,
                        item.ct
                      ) &&
                      !['119463', '1032501'].includes(item?.staff_id) &&
                      handleShowCT(item.ct)}
                    <>
                      {!item.is_hide_matching_percentate_stg && (
                        <>
                          {!item?.isUnsearchable
                            ? item?.matching_percentage
                              ? `${item?.matching_percentage}%`
                              : ''
                            : ''}
                        </>
                      )}
                    </>
                  </Link>
                </div>
              </div>

              <div className="mb-1">
                <span
                  style={{
                    fontWeight: '500',
                    fontSize: '14px',
                    lineHeight: '14px',
                    color: '#3d3d3d',
                  }}
                >
                  {item?.position_name}, {item?.business_unit}
                </span>
              </div>
              {/* <div style={{ fontSize: '12px' }}>
                                    <div className="mb-1">
                                      <span>Business: </span>
                                      <span style={{ fontWeight: '500' }}>{item?.business_unit}</span>
                                    </div>
                                    <div className="mb-1">
                                      <span>Sector: </span>
                                      <span style={{ fontWeight: '500' }}>{item?.sector}</span>
                                    </div>
                                    <div className="mb-1">
                                      <span>OPU: </span>
                                      <span style={{ fontWeight: '500' }}>{item?.opu}</span>
                                    </div>
                                    <div className="mb-1">
                                      <span>Division: </span>
                                      <span style={{ fontWeight: '500' }}>{item?.division}</span>
                                    </div>
                                    <div className="mb-1">
                                      <span>Department: </span>
                                      <span style={{ fontWeight: '500' }}>{item?.department_name}</span>
                                    </div>
                                  </div> */}
              <div
                className="mb-1 d-flex justify-content-start"
                style={{ gap: '10px', flexWrap: 'wrap', alignItems: 'center' }}
              >
                <div>
                  <span>ID: </span>
                  <span style={{ fontWeight: 'bold' }}>
                    {' '}
                    {item?.identity_number || item?.staff_id}
                  </span>
                </div>
                {handleShowTooltip(item, positions)}
              </div>
            </div>
          </div>
          <div
            style={{
              border: '1px solid #CBD6E2',
              backgroundColor: '#F2F8FD',
              borderRadius: '6px',
              fontSize: '12px',
              padding: '10px',
              marginTop: '5px',
            }}
          >
            {!item?.isUnsearchable && (
              <div className="mb-2 d-flex justify-content-start" style={{ gap: '20px' }}>
                <div>
                  <span>YIP: </span>
                  <div style={{ fontWeight: 'bold' }}>{calculateTime(item?.date_in_position)}</div>
                </div>
                <div>
                  <span>YISG: </span>
                  <div style={{ fontWeight: 'bold' }}>
                    {item?.salary_grade} ({calculateTime(item?.date_in_sg)})
                  </div>
                </div>
              </div>
            )}
            <div className="mb-2 d-flex justify-content-start" style={{ gap: '20px' }}>
              <div>
                <span>Age: </span>
                <div style={{ fontWeight: 'bold' }}>{item?.age}</div>{' '}
              </div>
              {!item?.isUnsearchable && (
                <div>
                  <span>Retirement: </span>
                  <div style={{ fontWeight: 'bold' }}>
                    {handleShowRetirementDate(item.retirement_date)}
                    {handleShowRetirement(item)}
                  </div>
                </div>
              )}
              {!item?.isUnsearchable && (
                <div className="mb-2">
                  <span>Performance Rating: </span>
                  <div style={{ fontWeight: 'bold' }}>{item?.performance_rating}</div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});
export default CardItem;
