import styled from 'styled-components';
import { Input, List } from 'antd';

export const Wrapper = styled.div`
  position: relative;
  background-color: white;
  width: fit-content;
`;
export const WrapperPopUp = styled.div`
  position: absolute;
  z-index: 1000;
  top: 50px;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #d3dee8;
  background: var(--Shades-White, #fff);
  box-shadow: 4px 4px 20px 0px rgba(0, 0, 0, 0.2);
`;

export const WrapperPopUpImage = styled.div`
  position: absolute;
  z-index: 1000;
  top: 30px;
  transform: translateX(-26%);
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #d3dee8;
  background: var(--Shades-White, #fff);
  box-shadow: 4px 4px 20px 0px rgba(0, 0, 0, 0.2);
`;

export const TitleDiv = styled.div`
  display: grid;
  grid-template-columns: repeat(
    ${(props) => (props.columnNumber ? props.columnNumber : 4)},
    ${(props) => (props.columnNumber === 1 ? '300px' : '200px')}
  );
  border-bottom: 1px solid var(--Light-Gray-300, #d6d6d6);
  background: var(--Light-Blue-Grays-100, #f2f4f8);
  height: 44px;
  div {
    color: #181818;
    padding-left: 20px;
    font-size: 14px;
    font-weight: 500;
    line-height: 44px; /* 142.857% */
    letter-spacing: 0.235px;
  }
`;

export const ListAntd = styled(List)`
  .ant-list-items {
    li:first-child {
      height: auto !important;
    }
    .ant-list-item {
      align-items: flex-start;
      cursor: pointer;
      display: grid;
      grid-template-columns: repeat(
        ${(props) => (props.columnNumber ? props.columnNumber : 4)},
        ${(props) => (props.columnNumber === 1 ? '300px' : '200px')}
      );
      border-bottom: 1px solid var(--Light-Gray-300, #d6d6d6);
      background: var(--Base-White, #fff);
      padding: 0;
      &:hover {
        background: #e0f3f3;
        .function-name {
          background: #e0f3f3 !important;
        }
      }
      div {
        color: var(--Base-Black, #181818);
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
        letter-spacing: 0.235px;
        padding: 12px 0;
        padding-left: 20px;
      }
      .function-name {
        background-color: #f2f4f8;
        height: 100%;
      }
    }
  }
`;
export const InputStyle = styled(Input)`
  padding: 0 5px !important;
  width: 250px;
  border-radius: 4px;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
  background: ${(props) => (props.disabled ? '#e0e0e0' : 'white')};
  border: ${(props) => (props.status === 'error' ? '1px solid red' : `1px solid #d9d9d9`)};
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  :hover {
    border: 1px solid ${(props) => (props.status === 'error' ? 'red' : '#00a19c')} !important;
  }
  .ant-input {
    outline: none !important;
    border: none !important;
  }
  input {
    padding: 10px 5px !important;
  }
  .ant-input-prefix {
    margin: 0 !important;
  }
  .ant-input-suffix {
    margin: 0 !important;
  }
`;

export const SelectedItem = styled.div`
  border-radius: 4px;
  border: 1px solid #d9d9d9;
  background: #fff;
  /* Shadow sm */
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
  display: flex;
  padding: 10px;
  justify-content: flex-start;
  align-items: center;
  align-self: stretch;
  width: 250px;
  gap: 10px;
  .truncate {
    width: 220px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;
