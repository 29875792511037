import { isEmpty } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { masterDataManagementApi } from '../services/masterDataManagementApi';
import { adminApi } from '../services/admin';

export const useGetDataContentOptions = (props) => {
  const { dataField } = props;
  const roleActive = useSelector((state) => state.user.roleActive);

  // Use state
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  const fetch = useCallback(async () => {
    setLoading(true);
    try {
      const res = await masterDataManagementApi.getBuMappingFilters(
        [...dataField],
        roleActive?.roleId
      );
      if (res.status === 200 && res?.data?.result) {
        setData(res.data.result);
      }
    } catch (error) {
      setError(error);
    }
    setLoading(false);
  }, [dataField, roleActive]);

  useEffect(() => {
    if (isEmpty(dataField)) return;

    fetch();
  }, [dataField, fetch]);

  return { dataContent: data, loading, error, fetch };
};

export const useGetBusinessUnitOptions = () => {
  const roleActive = useSelector((state) => state.user.roleActive);

  // Use state
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  const fetch = useCallback(async () => {
    setLoading(true);
    try {
      const res = await masterDataManagementApi.getBusinessOptions(roleActive?.roleId);
      if (res.status === 200 && res?.data?.result) {
        setData(res.data.result);
      }
    } catch (error) {
      setError(error);
    }
    setLoading(false);
  }, [roleActive]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  return { buOptions: data, loading, error, fetch };
};

export const useGetRoleLevelOptions = () => {
  const roleActive = useSelector((state) => state.user.roleActive);

  // Use state
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  const fetch = useCallback(async () => {
    setLoading(true);
    try {
      const res = await adminApi.getSGJGMappingFilter(roleActive?.roleId);
      if (res.status === 200 && res?.data?.result) {
        setData(res.data.result?.role_level);
      }
    } catch (error) {
      setError(error);
    }
    setLoading(false);
  }, [roleActive]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  return { roleLevelOptions: data, loading, error, fetch };
};
