import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { sp_card_setting } from '../../assets/img';
import { setCardSettingsDSP } from '../../store/pageSlice';
import {
  SP_CARD_DEFAULT,
  SP_CARD_DEFAULT_SELECT_OPTIONS,
  SP_CARD_SELECT_OPTIONS,
} from '../../utils/constants';
import BasicTreeSelect from '../BasicTreeSelect/BasicTreeSelect';
import { setCardSettings } from '../../pages/Meeting/redux/agendaSlice';
import { useParams } from 'react-router-dom';
import { meetingApi } from '../../services/tcMeeting';

const CardSettings = (props) => {
  const { lineSelected, type } = props;
  const dispatch = useDispatch();
  const cardSettings = useSelector(
    (state) => state.app.meetingModule.agenda.agendaIncumbent.cardSettings
  );
  const { agendaId } = useParams();
  const roleActive = useSelector((state) => state.user.roleActive);
  const cardSettingsDSP = useSelector((state) => state.page.cardSettingsDSP);
  const [selectedOptions, setSelectedOptions] = useState(
    type === 'card-dsp'
      ? SP_CARD_DEFAULT_SELECT_OPTIONS.filter(
          (f) =>
            ![SP_CARD_DEFAULT.ADDITIONAL_INPUT, SP_CARD_DEFAULT.MATCHING_PERCENTAGE].includes(
              f.value
            )
        )
      : SP_CARD_DEFAULT_SELECT_OPTIONS
  );

  const prevSelectedOptions = useMemo(() => {
    return type === 'card-dsp' ? cardSettingsDSP : cardSettings;
  }, [type, JSON.stringify(cardSettings), JSON.stringify(cardSettingsDSP)]);

  async function saveNewSetting(params) {
    try {
      await meetingApi.saveCardSettings(params, roleActive.roleId);
    } catch (error) {
      console.log(error);
    }
  }
  const handleApply = () => {
    const action = type === 'card-dsp' ? setCardSettingsDSP : setCardSettings;
    if (agendaId && !isNaN(Number(agendaId)) && type !== 'card-dsp') {
      const params = {
        agenda_id: agendaId,
        line: lineSelected,
        view: selectedOptions.map((m) => ({ ...m, checked: true })),
      };
      dispatch(action({ line: lineSelected, data: selectedOptions }));
      saveNewSetting(params);
    } else {
      dispatch(action({ line: lineSelected, data: selectedOptions, agendaId }));
    }
  };

  useEffect(() => {
    setSelectedOptions(
      type === 'card-dsp' ? cardSettingsDSP[lineSelected] : cardSettings[lineSelected]
    );
  }, [JSON.stringify(cardSettings), JSON.stringify(cardSettingsDSP)]);

  return (
    <BasicTreeSelect
      placement="bottomRight"
      treeDefaultExpandAll={true}
      dropdownStyle={{ minWidth: 415, maxWidth: 415 }}
      onApply={handleApply}
      options={
        type === 'card-dsp'
          ? SP_CARD_SELECT_OPTIONS.filter(
              (f) =>
                ![SP_CARD_DEFAULT.ADDITIONAL_INPUT, SP_CARD_DEFAULT.MATCHING_PERCENTAGE].includes(
                  f.value
                )
            )
          : SP_CARD_SELECT_OPTIONS
      }
      selectedOptions={selectedOptions}
      setSelectedOptions={setSelectedOptions}
      prevSelectedOption={prevSelectedOptions[lineSelected]}
      valueSelectAll={SP_CARD_DEFAULT.SELECT_ALL}
      style={{ height: 24 }}
    >
      <img src={sp_card_setting} alt="print_setting" />
    </BasicTreeSelect>
  );
};

export default CardSettings;
