import React, { useState } from 'react';

import { BreadCrumb } from '../../components';
import { getBreadCrumbList } from '../../utils/helper';
import Calendar from './components/Calendar/Calendar';
import MeetingStatus from './components/MeetingStatus/MeetingStatus';
import styles from './talent-committee.module.scss';
import { useLocation } from 'react-router-dom';

const TalentCommittee = () => {
  const [meetingTypeSelected, setMeetingTypeSelected] = useState('all');
  const location = useLocation();
  const title =
    location.pathname === '/pdc'
      ? 'People Development Committee Meeting'
      : 'Talent Committee Meeting';
  const breadCrumbList =
    location.pathname === '/pdc'
      ? [`Homepage`, `PDC Meeting`]
      : [`Dashboard`, `Talent Committee Meeting`];

  return (
    <div className={styles.maxWidth} data-testid="talent-committee">
      <div className={styles.control}>
        <BreadCrumb level={2} breadCrumbList={getBreadCrumbList(breadCrumbList)} />
        <h3 className={styles.title}>{title}</h3>
        <MeetingStatus selected={meetingTypeSelected} setSelected={setMeetingTypeSelected} />
        <div className="mt-3">
          <div className={styles.overallProfileUser}>
            <Calendar meetingType={meetingTypeSelected} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TalentCommittee;
