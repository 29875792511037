import moment from 'moment';
import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import { useSelector } from 'react-redux';
import { calendarApi } from '../../../../services/calendarApi';
import CalendarYearView from '../../components/CalendarYearView/CalendarYearView';
import DetailMonthlyView from '../../components/DetailMonthlyView/DetailMonthlyView';
import MenuCalendar from '../../components/MenuCalendar/MenuCalendar';
import { Container } from './styled';
import { CALENDAR } from '../../../../utils/constants';
import { useLocation } from 'react-router-dom';
import { get } from 'lodash';
import { Spin } from 'antd';

const Calendar = (props) => {
  const { meetingType } = props;
  const location = useLocation();
  const user = useSelector((state) => state.user);
  const [startDate, setStartDate] = useState({
    value: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
    scrollToCurrentDate: false,
  });
  const [isMonthlyView, setIsMonthlyView] = useState(true);
  const [category, setCategory] = useState({ business: CALENDAR.MEETING_CATEGORY, value: '' });
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const [toDate, setToDate] = useState(moment(startDate.value).endOf('month').format('YYYY-MM-DD'));
  const [fromDate, setFromDate] = useState(
    moment(startDate.value).startOf('month').format('YYYY-MM-DD')
  );

  const scrollToElement = () => {
    if (isMonthlyView) {
      const element = document.getElementById(
        moment(startDate.value).format('YYYY-MM-DD') + 'scroll'
      );
      if (element) element.scrollIntoView({ behavior: 'smooth', block: 'center' });
    } else {
      const month = startDate.value.getMonth();
      if ([0, 1, 2, 3].includes(month)) {
        const element = document.getElementById('panel-1');
        if (element) element.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
      } else if ([4, 5, 6, 7].includes(month)) {
        const element = document.getElementById('panel-2');
        if (element) element.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
      } else if ([8, 9, 10, 11].includes(month)) {
        const element = document.getElementById('panel-3');
        if (element) element.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
      }
    }
    setStartDate((prev) => ({ ...prev, scrollToCurrentDate: false }));
  };

  useEffect(() => {
    scrollToElement();
  }, [startDate.scrollToCurrentDate]);

  useEffect(() => {
    if (isMonthlyView === true) {
      setToDate(moment(startDate.value).endOf('month').format('YYYY-MM-DD'));
      setFromDate(moment(startDate.value).startOf('month').format('YYYY-MM-DD'));
    } else {
      setToDate(moment(startDate.value).endOf('year').format('YYYY-MM-DD'));
      setFromDate(moment(startDate.value).startOf('year').format('YYYY-MM-DD'));
    }
  }, [startDate.value, isMonthlyView]);

  useEffect(() => {
    const query = {
      dateFilterType: 'range',
      fromDate: fromDate,
      toDate: toDate,
      limit: 2,
      category: category.business.toString(),
      category_value: category.value,
      meetingType: meetingType,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    };
    async function getMeeting() {
      setLoading(true);
      try {
        const res =
          location.pathname === '/pdc'
            ? await calendarApi.getPdcMeetings(query, user.roleActive)
            : await calendarApi.getMeetings(query, user.roleActive);
        setData(get(res, 'data.result') || []);
        startDate.scrollAfterLoadData &&
          setStartDate((prev) => ({
            ...prev,
            scrollToCurrentDate: true,
            scrollAfterLoadData: false,
          }));
      } catch (error) {
        console.error(error);
        setData([]);
      }
      setLoading(false);
    }
    getMeeting();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    fromDate,
    toDate,
    meetingType,
    category.business.toString(),
    user.roleActive.roleId,
    location.pathname,
    category.value,
  ]);

  return (
    <Container>
      {isMonthlyView ? (
        <>
          <Spin spinning={loading}>
            <DatePicker
              selected={startDate.value}
              inline
              minDate={new Date(fromDate)}
              maxDate={new Date(toDate)}
              formatWeekDay={(nameOfDay) => {
                return moment(nameOfDay).isValid() === true
                  ? moment(nameOfDay).format('dddd')
                  : nameOfDay;
              }}
              renderCustomHeader={({
                date,
                decreaseMonth,
                increaseMonth,
                changeYear,
                changeMonth,
              }) => {
                const handleDecreaseMonth = () => {
                  const newDay = new Date(moment(date).subtract(1, 'months'));
                  decreaseMonth();
                  setStartDate((prev) => ({ ...prev, value: newDay, scrollAfterLoadData: false }));
                };
                const handleIncreaseMonth = () => {
                  const newDay = new Date(moment(date).add(1, 'months'));
                  increaseMonth();
                  setStartDate((prev) => ({ ...prev, value: newDay, scrollAfterLoadData: false }));
                };
                return (
                  <MenuCalendar
                    setFromDate={setFromDate}
                    setToDate={setToDate}
                    decrease={handleDecreaseMonth}
                    increase={handleIncreaseMonth}
                    date={startDate.value}
                    setStartDate={setStartDate}
                    isMonthlyView={isMonthlyView}
                    setIsMonthlyView={setIsMonthlyView}
                    category={category}
                    setCategory={setCategory}
                    permissions={user.permissions}
                    changeYear={changeYear}
                    changeMonth={changeMonth}
                    startDate={startDate}
                  />
                );
              }}
              renderDayContents={(day, date) => (
                <DetailMonthlyView
                  day={day}
                  date={date}
                  data={data}
                  params={{
                    category: category.business.toString(),
                    category_value: category.value,
                    meetingType,
                    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                  }}
                />
              )}
              onChange={(date) => setStartDate((prev) => ({ ...prev, value: date }))}
              calendarStartDay={1}
            />
          </Spin>
        </>
      ) : (
        <>
          <Spin spinning={loading}>
            <CalendarYearView
              setFromDate={setFromDate}
              setToDate={setToDate}
              date={startDate.value}
              setStartDate={setStartDate}
              isMonthlyView={isMonthlyView}
              setIsMonthlyView={setIsMonthlyView}
              category={category}
              setCategory={setCategory}
              permissions={user.permissions}
              data={data}
              params={{
                category: category.business.toString(),
                category_value: category.value,
                meetingType,
                timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
              }}
              startDate={startDate}
            />
          </Spin>
        </>
      )}
    </Container>
  );
};

export default Calendar;
