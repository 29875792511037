import './box.scss';

import { notification, Popover, Tooltip } from 'antd';
import { isEmpty, isNaN } from 'lodash';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import Collapse from 'react-bootstrap/Collapse';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';

import {
  aditional_dot_remark,
  chevron_down,
  plus_collapse,
  sp_comment,
  sp_trash,
  three_dot_sp_card,
} from '../../../../../../assets/img';
import CardSettings from '../../../../../../components/CardSettings/CardSettings';
import { useCardSetting } from '../../../../../../hooks/useCardSetting';
import {
  DATE_TIME_FORMAT,
  MEETING_DETAIL_STATUS,
  SHOW_REMARK_ICON_STATUS,
  SP_CARD_DEFAULT,
  SP_CARD_DEFAULT_SELECT_OPTIONS,
} from '../../../../../../utils/constants';
import {
  decodeBase64ToImage,
  handleShowCT,
  handleShowRetirementDate,
  handleShowTextTooltip,
  handleShowTooltip,
  handleShowYIPAndYISG,
  hideOtherTalentLabel,
} from '../../../../../../utils/helper';
import { removeTalents, setCardSettings } from '../../../../redux/agendaSlice';
import { remarksRemainingSelector } from '../../../../selector';
import AddRemark from '../AddRemark/AddRemark';
import AddTalent from '../AddTalent/AddTalent';
import { Draggable } from '../wrappers/Draggable';
import { Droppable } from '../wrappers/Droppable';
import { ApprovedLabel } from './ApprovedLabel';
import styles from './box.module.scss';

const convertLine = [
  { text: '1st', value: 'first_line' },
  { text: '2nd', value: 'second_line' },
  { text: '3rd', value: 'third_line' },
];

const Box = (props) => {
  const { color, text, line, positionsCode, addItem, isReadOnly, code, lines, meetingDetail } =
    props;
  const agendaParam = useParams();
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.spTemplate);
  const remarks = useSelector(remarksRemainingSelector);
  const {
    positions,
    agenda_as_main_type,
    agenda_as_main_platform,
    agenda_as_main,
    agenda_as_main_start_at,
  } = useSelector((state) => state.app.meetingModule.agenda.agendaIncumbent);
  const { status, is_secretariat_or_facilitator } = useSelector(
    (state) => state.app.meetingModule.preMeeting.meeting
  );
  const [collapseMenu, setCollapseMenu] = useState(true);
  const [addTalent, setAddTalent] = useState(false);
  const [firstRender, setFirstRender] = useState(false);
  const [showAddRemark, setShowAddRemark] = useState({
    status: false,
    staff: '',
  });
  const [openDot, setOpenDot] = useState(false);

  function additionalInputRemark(cardItem) {
    if (isEmpty(remarks)) return null;
    const remarkItems = remarks.filter(
      (remark) =>
        remark.staffId === cardItem?.staff_id && remark?.remarksCategory === 'Additional Input'
    );

    if (isEmpty(remarkItems)) return null;
    return (
      <div className={styles.additionalInput} data-cardSettings={SP_CARD_DEFAULT.ADDITIONAL_INPUT}>
        <ul>
          {remarkItems.map((item) => (
            <li key={item.remarks}>
              <span>
                <img src={aditional_dot_remark} />
              </span>
              <span>{item.remarks}</span>
            </li>
          ))}
        </ul>
      </div>
    );
  }

  function showHide() {
    setCollapseMenu(!collapseMenu);
  }

  function deleteItem(id) {
    const newLine = [...line].filter((item) => item.staff_id !== id);
    dispatch(removeTalents({ code: code, line: newLine }));
    notification.success({
      message: `Delete successful.`,
      placement: 'topRight',
      duration: 2,
    });
  }

  function addItemNotFromAi(item) {
    const existedItem = !isEmpty(lines) && lines.find((d) => d.staff_id === item.staff_id);
    if (existedItem) {
      notification.error({
        message: `Talent is already in SP readiness table.`,
        placement: 'topRight',
        duration: 2,
      });
    } else {
      addItem(item, text);
      notification.success({
        message: `Add successful.`,
        placement: 'topRight',
        duration: 2,
      });
    }
  }

  const handleShowRetirement = (item) => {
    let total =
      Number(moment(item?.retirement_date).format('YYYY')) -
      Number(moment(item?.birth_date, 'DD-MM-YYYY').format('YYYY'));
    if (!isNaN(total)) {
      return '(' + total + ')';
    }
  };

  const boxRef = useRef();

  const cardSettings = useSelector(
    (state) => state.app.meetingModule.agenda.agendaIncumbent.cardSettings
  );
  const lineSelected = convertLine.find((f) => f.text === text).value;
  const lineSettings = cardSettings[lineSelected];
  useCardSetting({ boxRef, lineSettings, line, loading: loading.addPosition, remarks });

  useEffect(() => {
    if (!firstRender) {
      setFirstRender(true);
      return;
    }
    if (
      !isEmpty(line) &&
      line.find((f) => additionalInputRemark(f)) &&
      [
        MEETING_DETAIL_STATUS.IN_REVIEW,
        MEETING_DETAIL_STATUS.IN_PROGRESS,
        MEETING_DETAIL_STATUS.CONCLUDED,
      ].includes(meetingDetail?.status)
    ) {
      dispatch(
        setCardSettings({
          line: lineSelected,
          data: [
            ...SP_CARD_DEFAULT_SELECT_OPTIONS,
            { label: 'Additional Input', value: 'additional_input' },
          ],
        })
      );
    }
  }, [firstRender]);
  return (
    <>
      <div className={styles.collapse} ref={boxRef}>
        <div
          onKeyDown={() => {}}
          onClick={() => showHide()}
          className={styles.collapseHeader}
          style={{ backgroundColor: color }}
          data-testid="show-hide"
        >
          <div className={styles.left}>{text}</div>
          <img src={chevron_down} alt="" className={collapseMenu ? styles.animate : ``} />
        </div>
        <div className={styles.icon_settings}>
          <CardSettings text={text} lineSelected={lineSelected} />
          {!isReadOnly &&
            status !== MEETING_DETAIL_STATUS.CONCLUDED &&
            !(status === MEETING_DETAIL_STATUS.IN_REVIEW && !is_secretariat_or_facilitator) && (
              <div
                data-testid="show-add-talent"
                onKeyDown={() => {}}
                onClick={() => setAddTalent(true)}
              >
                <img src={plus_collapse} alt="" />
              </div>
            )}
        </div>

        <Droppable disabled={isReadOnly} id={`${text}`} key={`${text}`}>
          <Collapse in={collapseMenu}>
            <div className={styles.collapseBody}>
              <div
                style={{ gap: '10px', padding: '15px 15px' }}
                className={styles.collapseBody__grid}
              >
                {loading.addPosition ? (
                  <div className="w-100" style={{ textAlign: 'center', gridColumn: '1/3' }}>
                    <div className="spinner-border text-primary" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                  </div>
                ) : (
                  <>
                    {line && line.length > 0 ? (
                      line.map((item, index) => (
                        <Droppable
                          id={`${text}-drap-${index}`}
                          key={`${text}-drap-${index}`}
                          className="collapseBody__line"
                          data={{ ...item, index }}
                          disabled={isReadOnly}
                          style={{
                            padding: '0 10px',
                            border: '1px solid #e7e7f0',
                            borderTop: `8px solid ${color}`,
                            borderRadius: '6px',
                          }}
                        >
                          <div>
                            <div className={styles.card_box}>
                              <Draggable
                                id={`${text}-drap-${index}`}
                                className={`collapseBody__line`}
                                data={{ ...item, index }}
                                disabled={isReadOnly}
                              >
                                <div className="row flex-column no-gutters align-items-start h-100">
                                  <div className={styles.number}>{index + 1}</div>
                                  <div
                                    className={styles.image}
                                    data-cardSettings={SP_CARD_DEFAULT.PROFILE_PICTURE}
                                  >
                                    <img src={decodeBase64ToImage(item?.image)} alt="" />
                                  </div>
                                </div>
                              </Draggable>
                              <div className={`${styles.right}`}>
                                <div className="d-flex no-gutters justify-content-between align-items-stretch">
                                  <div className={styles.name}>
                                    <Link
                                      to={`/profile/${item?.staff_id}`}
                                      target="_blank"
                                      className={styles.recommendation__name}
                                      draggable="false"
                                      data-cardSettings={SP_CARD_DEFAULT.STAFF_NAME}
                                    >
                                      {item?.birth_name} &#160;
                                    </Link>
                                    <div
                                      className={styles.recommendation__name}
                                      data-cardSettings={SP_CARD_DEFAULT.TOP_TALENT_STATUS}
                                    >
                                      {!item?.isUnsearchable &&
                                        !hideOtherTalentLabel(
                                          item.assignment_status_type,
                                          item.role_level,
                                          item.ct
                                        ) &&
                                        !['119463', '1032501'].includes(item?.staff_id) &&
                                        handleShowCT(item.ct)}
                                    </div>
                                    <div
                                      className={styles.recommendation__name}
                                      data-cardSettings={SP_CARD_DEFAULT.MATCHING_PERCENTAGE}
                                    >
                                      {!item?.isUnsearchable
                                        ? item?.matching_percentage
                                          ? `${item?.matching_percentage}%`
                                          : ''
                                        : ''}
                                    </div>
                                  </div>
                                  {((!isReadOnly &&
                                    !isNaN(Number(agendaParam?.agendaId)) &&
                                    SHOW_REMARK_ICON_STATUS.includes(status) &&
                                    status !== MEETING_DETAIL_STATUS.CONCLUDED &&
                                    !(
                                      status === MEETING_DETAIL_STATUS.IN_REVIEW &&
                                      !is_secretariat_or_facilitator
                                    )) ||
                                    (!isReadOnly &&
                                      status !== MEETING_DETAIL_STATUS.CONCLUDED &&
                                      !(
                                        status === MEETING_DETAIL_STATUS.IN_REVIEW &&
                                        !is_secretariat_or_facilitator
                                      ))) && (
                                    <div className={styles.icon_card}>
                                      <Popover
                                        overlayInnerStyle={{
                                          border: '1px solid #D3DEE8',
                                          borderRadius: '6px',
                                        }}
                                        content={
                                          <div>
                                            {!isReadOnly &&
                                              !isNaN(Number(agendaParam?.agendaId)) &&
                                              SHOW_REMARK_ICON_STATUS.includes(status) &&
                                              status !== MEETING_DETAIL_STATUS.CONCLUDED &&
                                              !(
                                                status === MEETING_DETAIL_STATUS.IN_REVIEW &&
                                                !is_secretariat_or_facilitator
                                              ) && (
                                                <div
                                                  className="d-flex justify-content-start align-items-center"
                                                  style={{ gap: '10px', cursor: 'pointer' }}
                                                  onClick={() =>
                                                    setShowAddRemark({
                                                      status: true,
                                                      staff: item,
                                                    })
                                                  }
                                                >
                                                  <img
                                                    data-testid="show-remark"
                                                    src={sp_comment}
                                                    alt=""
                                                    draggable="false"
                                                    onKeyDown={() => {}}
                                                  />
                                                  <span>Add Remark</span>
                                                </div>
                                              )}
                                            {!isReadOnly &&
                                              status !== MEETING_DETAIL_STATUS.CONCLUDED &&
                                              !(
                                                status === MEETING_DETAIL_STATUS.IN_REVIEW &&
                                                !is_secretariat_or_facilitator
                                              ) && (
                                                <div
                                                  className="d-flex justify-content-start align-items-center"
                                                  style={{ gap: '10px', cursor: 'pointer' }}
                                                  onClick={() => deleteItem(item?.staff_id)}
                                                >
                                                  <img
                                                    data-testid="delete"
                                                    src={sp_trash}
                                                    alt=""
                                                    onKeyDown={() => {}}
                                                    draggable="false"
                                                  />
                                                  <span
                                                    style={{ color: '#DA2228', marginTop: '2px' }}
                                                  >
                                                    Delete
                                                  </span>
                                                </div>
                                              )}
                                          </div>
                                        }
                                        trigger="click"
                                        open={openDot === (item?.identity_number || item?.staff_id)}
                                        onOpenChange={(newOpen) => {
                                          setOpenDot(
                                            newOpen
                                              ? item?.identity_number || item?.staff_id
                                              : newOpen
                                          );
                                        }}
                                      >
                                        <img src={three_dot_sp_card} />
                                      </Popover>
                                    </div>
                                  )}
                                </div>

                                <Draggable
                                  id={`${text}-drap-${index}`}
                                  className={`collapseBody__line`}
                                  data={{ ...item, index }}
                                  disabled={isReadOnly}
                                >
                                  <div
                                    className="mb-1"
                                    data-cardSettings={SP_CARD_DEFAULT.POSITION}
                                  >
                                    <span
                                      style={{
                                        fontWeight: '500',
                                        fontSize: '14px',
                                        lineHeight: '14px',
                                        color: '#3d3d3d',
                                      }}
                                    >
                                      {item?.position_name}
                                    </span>
                                  </div>
                                  <div style={{ fontSize: '12px' }}>
                                    <div
                                      className="mb-1"
                                      data-cardSettings={SP_CARD_DEFAULT.BUSINESS}
                                    >
                                      <span>Business: </span>
                                      <span style={{ fontWeight: '500' }}>
                                        {item?.business_unit}
                                      </span>
                                    </div>
                                    <div
                                      className="mb-1"
                                      data-cardSettings={SP_CARD_DEFAULT.SECTOR}
                                    >
                                      <span>Sector: </span>
                                      <span style={{ fontWeight: '500' }}>{item?.sector}</span>
                                    </div>
                                    <div className="mb-1" data-cardSettings={SP_CARD_DEFAULT.OPU}>
                                      <span>OPU: </span>
                                      <span style={{ fontWeight: '500' }}>{item?.opu}</span>
                                    </div>
                                    <div
                                      className="mb-1"
                                      data-cardSettings={SP_CARD_DEFAULT.DIVISION}
                                    >
                                      <span>Division: </span>
                                      <span style={{ fontWeight: '500' }}>{item?.division}</span>
                                    </div>
                                    <div
                                      className="mb-1"
                                      data-cardSettings={SP_CARD_DEFAULT.DEPARTMENT}
                                    >
                                      <span>Department: </span>
                                      <span style={{ fontWeight: '500' }}>
                                        {item?.department_name}
                                      </span>
                                    </div>
                                  </div>
                                  <div
                                    className="mb-1 d-flex justify-content-start"
                                    style={{ gap: '10px', flexWrap: 'wrap', alignItems: 'center' }}
                                  >
                                    <div data-cardSettings={SP_CARD_DEFAULT.STAFF_ID}>
                                      <span>ID: </span>
                                      <span style={{ fontWeight: 'bold' }}>
                                        {' '}
                                        {item?.identity_number || item?.staff_id}
                                      </span>
                                    </div>
                                    {!item?.isUnsearchable && (
                                      <span>
                                        {lineSettings
                                          .map((i) => i.value)
                                          .some((l) =>
                                            [
                                              SP_CARD_DEFAULT.FIRST_LINE,
                                              SP_CARD_DEFAULT.SECOND_LINE,
                                              SP_CARD_DEFAULT.THIRD_LINE,
                                            ].includes(l)
                                          ) && (
                                          <Tooltip
                                            placement="bottom"
                                            title={handleShowTextTooltip(
                                              item,
                                              positions,
                                              lineSettings
                                            )}
                                            color={'black'}
                                            key={'black'}
                                            overlayInnerStyle={{
                                              borderRadius: '6px',
                                              minWidth: 'max-content',
                                              padding: '12px',
                                              width: '100%',
                                            }}
                                            className={styles.sp_counter}
                                          >
                                            {handleShowTooltip(item, positions, lineSettings)}
                                          </Tooltip>
                                        )}
                                      </span>
                                    )}
                                    <div data-cardSettings={SP_CARD_DEFAULT.APPROVAL_STATUS}>
                                      <ApprovedLabel
                                        positions={positions}
                                        item={item}
                                        isOldUI={true}
                                      />
                                    </div>
                                    {agenda_as_main && agenda_as_main === item.agenda_as_main && (
                                      <Tooltip
                                        overlayClassName={styles.tooltip}
                                        placement="top"
                                        title={
                                          <div className={styles.platformName}>
                                            <span>Platform Name: {agenda_as_main_platform}</span>
                                            <br />
                                            {agenda_as_main_start_at && (
                                              <span>
                                                Date:{' '}
                                                {moment(agenda_as_main_start_at).format(
                                                  DATE_TIME_FORMAT.DATE_SPACE
                                                )}
                                              </span>
                                            )}
                                          </div>
                                        }
                                      >
                                        <div className={styles.mainAgendaStatus}>
                                          {agenda_as_main_type || 'N/A'}
                                        </div>
                                      </Tooltip>
                                    )}
                                  </div>
                                </Draggable>
                              </div>
                            </div>
                            <Draggable
                              id={`${text}-drap-${index}`}
                              className={`collapseBody__line`}
                              data={{ ...item, index }}
                              disabled={isReadOnly}
                            >
                              <div
                                style={{
                                  border: '1px solid #CBD6E2',
                                  backgroundColor: '#F2F8FD',
                                  borderRadius: '6px',
                                  fontSize: '12px',
                                  padding: '10px',
                                }}
                                data-cardSettings={'border'}
                              >
                                {!item?.isUnsearchable && (
                                  <div
                                    className="mb-2 d-flex justify-content-start"
                                    style={{ gap: '20px' }}
                                  >
                                    <div data-cardSettings={SP_CARD_DEFAULT.YEAR_IN_POSITION}>
                                      <span>YIP: </span>
                                      <div style={{ fontWeight: 'bold' }}>
                                        {handleShowYIPAndYISG(
                                          item?.year_in_position,
                                          item?.date_in_position
                                        )}
                                      </div>
                                    </div>
                                    <div data-cardSettings={SP_CARD_DEFAULT.YEAR_IN_SG}>
                                      <span>YISG: </span>
                                      <div style={{ fontWeight: 'bold' }}>
                                        {item?.salary_grade} (
                                        {handleShowYIPAndYISG(item?.year_in_sg, item?.date_in_sg)})
                                      </div>
                                    </div>
                                  </div>
                                )}
                                <div
                                  className="mb-2 d-flex justify-content-start"
                                  style={{ gap: '20px' }}
                                >
                                  <div data-cardSettings={SP_CARD_DEFAULT.AGE}>
                                    <span>Age: </span>
                                    <div style={{ fontWeight: 'bold' }}>{item?.age}</div>{' '}
                                  </div>
                                  {!item?.isUnsearchable && (
                                    <div data-cardSettings={SP_CARD_DEFAULT.RETIREMENT_DATE}>
                                      <span>Retirement: </span>
                                      <div style={{ fontWeight: 'bold' }}>
                                        {handleShowRetirementDate(item.retirement_date)}
                                        {handleShowRetirement(item)}
                                      </div>
                                    </div>
                                  )}
                                  {!item?.isUnsearchable && (
                                    <div
                                      className="mb-2"
                                      data-cardSettings={SP_CARD_DEFAULT.PERFORMANCE_RATING}
                                    >
                                      <span>Performance Rating: </span>
                                      <div style={{ fontWeight: 'bold' }}>
                                        {item?.performance_rating}
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </div>
                              {additionalInputRemark(item)}
                            </Draggable>
                          </div>
                        </Droppable>
                      ))
                    ) : (
                      <div className="w-100" style={{ textAlign: 'center', gridColumn: '1/3' }}>
                        No data
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
          </Collapse>
        </Droppable>
      </div>
      {showAddRemark.status && (
        <AddRemark
          setIsVisible={setShowAddRemark}
          showAddRemark={showAddRemark}
          lineSelected={lineSelected}
        />
      )}
      {addTalent && (
        <AddTalent
          isVisible={addTalent}
          setIsVisible={setAddTalent}
          addItem={addItemNotFromAi}
          positionsCode={positionsCode}
          currentLine={text}
        />
      )}
    </>
  );
};

export default Box;
