import { Tooltip } from 'antd';
import moment from 'moment';
import React from 'react';

import { deleteIcon, sp_delete } from '../../../../../assets/img';
import {
  decodeBase64ToImage,
  getLableClassForSP,
  getRetirementContractEndDateLabelForSP,
  handleShowCT,
  handleShowInformationPosition,
  handleShowPositionForSP,
  handleShowRetirementDate,
  handleShowRetirementToView,
  handleShowRoleForSP,
  handleShowYIPAndYISG,
} from '../../../../../utils/helper';
import { PositionListWrapper, TooltipWrapper } from './PositionItem';

const PositionItemForSpDashboard = (props) => {
  const { data, isEdit, handleDeletePosition, lastItem, approved_date, platform } = props;

  const content = () => {
    return <span>JG: {data?.job_grade}</span>;
  };

  return (
    <PositionListWrapper>
      <div
        className={'listPositionItem'}
        style={lastItem ? { marginBottom: '5px' } : { marginBottom: '16px' }}
      >
        <div className={'positionItemTop'} style={isEdit ? { paddingRight: '45px' } : {}}>
          <TooltipWrapper>
            <div className={'positionItemName'}>
              <span className={`title`}>{handleShowPositionForSP(data)}</span>
              <Tooltip
                placement="bottom"
                title={content}
                overlayClassName={`customTooltipPosition`}
              >
                <span className={`JG_hover`}> {handleShowRoleForSP(data)}</span>
              </Tooltip>
            </div>
            {data?.position_label && (
              <span className={`${`position_label`} ${getLableClassForSP(data)}`}>
                {data?.position_label}
              </span>
            )}
          </TooltipWrapper>
          <div className="date">
            Succession Planning Approval Date:{' '}
            {handleShowInformationPosition(
              data?.last_approved_date
                ? data?.last_approved_date
                : data?.incumbent?.contract_end_date,
              'time'
            )}
          </div>
          {isEdit && (
            <img
              style={{ cursor: 'pointer' }}
              src={deleteIcon}
              alt={sp_delete}
              onKeyDown={() => {}}
              onClick={() => handleDeletePosition(data.position_code)}
              className={'deleteBtn'}
            />
          )}
        </div>
        <div className={'positionItemBot'}>
          <div className="avatar">
            <img src={decodeBase64ToImage(data?.incumbent?.image)} alt="avatar" />
          </div>
          <div className="itemInfo">
            <div className="name">
              {data?.incumbent?.name || data?.incumbent?.candidate_name}{' '}
              {handleShowCT(data?.incumbent?.ct)}
            </div>
            <ul className="listInfo">
              {!approved_date && !platform && <li>Age: {data?.incumbent?.age ?? 'None'}</li>}
              <li>
                Salary Grade:{' '}
                {handleShowInformationPosition(`${data?.incumbent?.salary_grade || ''}`)}
              </li>
              <li>
                Years In Position:{' '}
                {handleShowInformationPosition(
                  `${handleShowYIPAndYISG(
                    data?.incumbent?.years_in_position,
                    data?.incumbent?.date_in_position
                  )} ${
                    data?.incumbent?.date_in_position
                      ? `(since ${moment(new Date(data?.incumbent?.date_in_position)).format(
                          'DD/MM/YYYY'
                        )})`
                      : ''
                  }`
                )}
              </li>
              {!approved_date && !platform && (
                <li>
                  {getRetirementContractEndDateLabelForSP(data?.incumbent)}{' '}
                  {handleShowInformationPosition(
                    `${handleShowRetirementDate(
                      data?.incumbent?.retirement_date || data?.incumbent?.contract_end_date
                    )} (${
                      data?.incumbent?.retirement_date || data?.incumbent?.contract_end_date
                        ? handleShowRetirementToView(data?.incumbent)
                        : 'None'
                    })`
                  )}
                </li>
              )}
            </ul>
          </div>
        </div>
      </div>
    </PositionListWrapper>
  );
};

export default PositionItemForSpDashboard;
